<template>
  <tr 
  v-if="this.DBitems.product"
  class="bg-white dark:bg-slate-900">
  <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
                                    <td class="p-4">
                 <i @click="show = true" class="mdi mdi-window-close text-red-600"></i>
                                       </td>
                                    <td class="p-4">
                                        <span class="flex items-center">
                                            <router-link
                                        :to="{
                                       name: 'product',
                                      params: { storeName: $store.storeName, 
                                        id: this.item.product_id ,
                                         name:this.DBitems.product.name,lang: $i18n.locale },
                                             }"
                                        >
                                            <img 
                                           :src="DBitems.product.image" 
                                           :alt="DBitems.product.name"
                                            class="rounded shadow dark:shadow-gray-800 w-12"
                                             >
                                            </router-link>
                                            <span class="ms-3">
                                                <span class="block font-semibold">
                                                    {{ DBitems.product.name }}</span>
                                            </span>
                                        </span>
                                    </td>
                                    <td class="p-4 text-center"
                                    v-if="item.offer_price != 0"
                                    >
                                    {{ ((item.offer_price/item.quantity)*this.$store.state.rate).toFixed(2)}} 
                                    </td>
                                    <td class="p-4 text-center"
                                    v-else
                                    >
                                    {{ ((item.total_price/item.quantity)*this.$store.state).toFixed(2)}}
                                    </td>
                                    <td class="p-4 text-center">
                                        <div class="qty-icons">
                                            <button 
                                            @click="decrementClick()"
                                             class="item_cart_btn size-9 inline-flex 
                                             items-center justify-center 
                                             tracking-wide align-middle text-base
                                              text-center rounded-md bg-orange-500/5 
                                              hover:bg-orange-500 text-orange-500 
                                              hover:text-white minus">-</button>
                                            <input
                                             min="0"
                                             name="quantity" 
                                            :value="item.quantity"
                                            type="number" 
                                            class="item_cart_btn h-9 inline-flex
                                             items-center justify-center 
                                             tracking-wide align-middle 
                                             text-base text-center rounded-md
                                             bg-orange-500/5 hover:bg-orange-500 
                                             text-orange-500 hover:text-white
                                             pointer-events-none w-16 ps-4 quantity"
                                             
                                             >
                                            <button 
                                            @click="incrementClick()"
                                            class=" item_cart_btn size-9 inline-flex
                                             items-center justify-center
                                            tracking-wide align-middle
                                            text-base text-center
                                            rounded-md bg-orange-500/5
                                            hover:bg-orange-500
                                            text-orange-500
                                            hover:text-white plus">+</button>
                                        </div>
                                    </td>
                                    <td v-if="item.offer_price!=0" 
                                    class="p-4  text-end">
                                    {{ (item.offer_price*this.$store.state.rate).toFixed(2) }}
                                   </td>
                                   <td v-else 
                                    class="p-4  text-end">
                                    {{ (item.total_price*this.$store.state.rate).toFixed(2) }}
                                   </td>

                                   <Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{$t('confirmdelete')}}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="deleteItem() ">{{$t('confirm')}}</button>
                <button
                  class="modal-default-button"
                  @click="(show = false), $emit('close')"
                >{{$t('cancel')}}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </Transition>
                                </tr>

</template>

<script>
export default {
  props: {
    itemId: String
  },
  data: function() {
    return {
      loading: false,
      DBitems: null,
      item: null,
      show: false,
      langChange: '',
      unit:this.$store.state.unit
    };
  },
  created() {
    this.unit=this.$store.state.unit;
    if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    else
      {
        this.unit=this.$store.state.unit;
    }

    console.log('new_gg')
    this.loading = true;
    this.item = this.$cookies.get(this.itemId);
    if (this.item != null) {
      console.log('itemsss',this.$i18n.locale)
      //console.log(this.item)
      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/cart_item",
          {
            product_id: this.item.product_id,
            final_option: this.item.final_option,
            option_check: this.item.option_check
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
       
        })
        .then(() => (this.loading = false));
    }
  },
  methods: {
    
    incrementClick() {
      // ++this.counterVal;
      console.log(
        "incrementtt" + this.item.quantity + " " + this.item.quantityAll
      );


      
      //////////////////////////////////////////////////////////
      let S_name_full =
        this.$store.storeName +
        "/" +
        this.item.product_id +
        this.item.final_option +
        this.item.option_check;

      let S_name =
       this.$store.storeName +
        "/" +
        this.item.product_id +
        this.item.final_option;
      console.log("S_name " + S_name);

      let qun = this.item.quantityAll;
      console.log('qun****************',qun)
     let min_qun =this.item.min_quantity;

    
          let  max_qun =this.item.max_quantity;
      console.log("this.item.quantityAll" + this.item.quantityAll);

      let AllCookies = this.$cookies.keys();
      for (let i = 0; i < AllCookies.length; i++) {
        if (AllCookies[i].includes(S_name)) {
          if (AllCookies[i] == S_name_full) {
            console.log("hii i am there ");
          } else {
            qun = qun - this.$cookies.get(AllCookies[i]).quantity;
            min_qun =this.$cookies.get(AllCookies[i]).min_quantity;
            max_qun = this.$cookies.get(AllCookies[i]).max_quantity;
            console.log(
              this.$cookies.get(AllCookies[i]).quantity + " quuuuun " + qun
            );

            console.log(
              this.$cookies.get(AllCookies[i]).min_quantity + " min quuuuun " + min_qun
            );
          }
        }
      }
      //////////////////////////////////////////////////////////
      if (this.item.quantityAll > 0) {
        if (this.item.quantity < qun 
        && this.item.quantity < max_qun 
        // && this.item.quantity > min_qun
        ) {
          ++this.item.quantity;
          this.item.total_price =
            this.item.total_price / (this.item.quantity - 1) +
            this.item.total_price;
          /*if (this.item.offer_price != 0) {
          this.item.offer_price =
            this.item.total_price -
            (this.item.discount / 100) * this.item.total_price;
        }*/
          if (this.item.offer_price != 0) {
            this.item.offer_price =
              this.item.offer_price / (this.item.quantity - 1) +
              this.item.offer_price;
          }

          //////////update this key
          let product = {
            product_id: this.item.product_id,
            total_price: this.item.total_price,
            offer_price: this.item.offer_price,
            quantity: this.item.quantity,
            quantityAll: this.item.quantityAll,
            min_quantity: this.item.min_quantity,
            max_quantity: this.item.max_quantity,
            final_option: this.item.final_option,
            option_check: this.item.option_check,
            discount: this.item.discount,
            note: this.item.note
          };
          this.$cookies.set(this.itemId, product);
          this.$parent.updateProducts();
        }
      } else {
        ++this.item.quantity;
        this.item.total_price =
          this.item.total_price / (this.item.quantity - 1) +
          this.item.total_price;
        /* if (this.item.offer_price != 0) {
          this.item.offer_price =
            this.item.total_price -
            (this.item.discount / 100) * this.item.total_price;
        }*/
        if (this.item.offer_price != 0) {
          this.item.offer_price =
            this.item.offer_price / (this.item.quantity - 1) +
            this.item.offer_price;
        }

        //////////update this key
        let product = {
          product_id: this.item.product_id,
            total_price: this.item.total_price,
            offer_price: this.item.offer_price,
            quantity: this.item.quantity,
            quantityAll: this.item.quantityAll,
            min_quantity: this.item.min_quantity,
            max_quantity: this.item.max_quantity,
            final_option: this.item.final_option,
            option_check: this.item.option_check,
            discount: this.item.discount,
            note: this.item.note
        };
        this.$cookies.set(this.itemId, product);
        this.$parent.updateProducts();
      }
    },
    decrementClick() {
      let min_qun =this.item.min_quantity;

    
          let  max_qun =this.item.max_quantity;
      console.log(
        "decrementttt" + this.item.quantity + " " ,this.item
      );
      if (this.item.quantity > 1  
      && this.item.quantity > min_qun
 ) {
        //  --this.counterVal;
        --this.item.quantity;
        //this.price = this.price/2
        this.item.total_price =
          this.item.total_price -
          this.item.total_price / (this.item.quantity + 1);
        /*if (this.item.offer_price != 0) {
          this.item.offer_price =
            this.item.total_price -
            (this.item.discount / 100) * this.item.total_price;
        }*/
        if (this.item.offer_price != 0) {
          this.item.offer_price =
            this.item.offer_price -
            this.item.offer_price / (this.item.quantity + 1);
        }

        //////////update this key
        let product = {
          product_id: this.item.product_id,
          total_price: this.item.total_price,
          offer_price: this.item.offer_price,
          quantity: this.item.quantity,
          quantityAll: this.item.quantityAll,
          min_quantity: this.item.min_quantity,
          max_quantity: this.item.max_quantity,
          final_option: this.item.final_option,
          option_check: this.item.option_check,
          discount: this.item.discount,
          note: this.item.note
        };
        this.$cookies.set(this.itemId, product);
        this.$parent.updateProducts();
      }
    },
    deleteItem() {
      //this.loading = true;
      this.$cookies.remove(this.itemId);
      console.log("remove" + this.$cookies.keys());
      this.$store.state.cartCounter = this.$store.state.cartCounter - 1;
      this.$destroy();
      this.$parent.updateProducts();
      this.$el.parentNode.removeChild(this.$el);
    }
,
    updatedata() {
  
    this.$store.state.langchange=false;
    this.loading = true;
    this.item = this.$cookies.get(this.itemId);
    if (this.item != null) {
      //console.log(this.item)
      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/cart_item",
          {
            product_id: this.item.product_id,
            final_option: this.item.final_option,
            option_check: this.item.option_check
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
       
        })
        .then(() => (this.loading = false));
    }
    }
  },
  watch:{
    langChange(){
      this.loading = true;
    this.item = this.$cookies.get(this.itemId);
    if (this.item != null) {
      //console.log(this.item)
      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/cart_item",
          {
            product_id: this.item.product_id,
            final_option: this.item.final_option,
            option_check: this.item.option_check
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
       
        })
        .then(() => (this.loading = false));
    }
    },
    unit()
    {
      this.unit=this.$store.state.unit;
    if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    else
      {
        this.unit=this.$store.state.unit;
    }
  },
},
  updated() {
    this.langChange=this.$i18n.locale
    
    console.log('******nnnnnnnnn******'+this.langChange)
  },
}
</script>
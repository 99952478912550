var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"relative table w-full py-20 lg:py-24 md:pt-28 bg-gray-50 dark:bg-slate-800"},[_c('div',{staticClass:"container relative"},[_c('div',{staticClass:"grid grid-cols-1 mt-14"},[_c('h3',{staticClass:"text-3xl leading-normal font-semibold"},[_vm._v(_vm._s(_vm.$t('brands')))])]),_c('div',{staticClass:"relative mt-3"},[_c('ul',{staticClass:"tracking-[0.5px] mb-0 inline-block"},[_c('li',{staticClass:"inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500"},[_c('router-link',{attrs:{"to":{
                              name: 'brands',
                             params: { storeName: this.$store.storeName,lang:_vm.$store.state.routeLang },
                                     }}},[_vm._v(_vm._s(_vm.$t('home')))])],1),_vm._m(0),_c('li',{staticClass:"inline-block uppercase text-[13px] font-bold text-orange-500",attrs:{"aria-current":"page"}},[_vm._v(_vm._s(_vm.$t('brands')))])])])])]),_c('section',{staticClass:"relative md:py-24 py-16"},[_c('div',{staticClass:"container relative"},[_c('div',{staticClass:"md:flex justify-between items-center mb-6"},[_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm.$t('Showing'))+" "+_vm._s(_vm.pagefrom)+"- "+_vm._s(_vm.pageto)+" "+_vm._s(_vm.$t('of'))+" "+_vm._s(_vm.count)+" "+_vm._s(_vm.$t('items')))])]),_c('div',{staticClass:"grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6"},_vm._l((this.DBitems),function(item,index){return _c('div',{key:index,staticClass:"group"},[_c('div',{staticClass:"relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md duration-500"},[_c('img',{staticClass:"group-hover:scale-110 duration-500 section_one_list",attrs:{"src":item.image,"alt":item.name}}),_c('div',{staticClass:"absolute -bottom-20 group-hover:bottom-3 start-3 end-3 duration-500"},[_c('router-link',{staticClass:"py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-slate-900 text-white w-full rounded-md",attrs:{"to":{
                                   name: 'brand',
                                    params: { storeName: _vm.$store.storeName,
                                     id: item.id  ,
                                      name:item.name,
                                      lang:_vm.$store.state.routeLang},
                                       }}},[_vm._v(_vm._s(item.name))])],1)]),_c('div',{staticClass:"mt-4"},[_c('router-link',{staticClass:"hover:text-orange-500 text-lg font-medium",attrs:{"to":{
                                   name: 'brand',
                                    params: { storeName: _vm.$store.storeName,
                                     id: item.id  ,
                                      name:item.name,
                                      lang:_vm.$store.state.routeLang
                                    },
                                       }}},[_vm._v(" "+_vm._s(item.name))])],1)])}),0),_c('div',{staticClass:"grid md:grid-cols-12 grid-cols-1 mt-6"},[_c('div',{staticClass:"md:col-span-12 text-center"},[_c('nav',{attrs:{"aria-label":"Page navigation example"}},[_c('b-pagination',{staticClass:"paginate_theme8",attrs:{"total-rows":_vm.count,"per-page":_vm.pageSize,"prev-text":_vm.$t('prev'),"next-text":_vm.$t('next'),"pills":"","size":"sm"},on:{"change":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180"},[_c('i',{staticClass:"mdi mdi-chevron-right"})])
}]

export { render, staticRenderFns }
<template>
    <div>
    <!-- Start -->
    <section_list
     v-for="(item, index) in this.sections"
    :name="item.name"
    :id="item.id"
    :products="item.products"
    >

    </section_list>
    </div>
      
</template>

<script>
import section_list from "../components/one_section_home.vue";

export default {
  components: { section_list},
  data() {
    return {
        DBitems: null,
      loading: false,
     sections:[
               ],
      page: 1,
      count: 0,
      pageSize: 12,
      langChange:"",
      sort:'id',
      order:'desc',
      paginate:12,
      type:'new',
     
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/theme5/home/sections",
        
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        this.sections = response.data.data;

      })
      .then(() => (this.loading = false));
      console.log('*****************************sections home  ',this.sections);

  },
  watch: {
    langChange() {
      this.sections=[];
      this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/theme5/home/sections",
        
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        this.sections = response.data.data;

      })
      .then(() => (this.loading = false));
      console.log('*****************************sections home  ',this.sections);

    },
  },
  updated() {
    this.langChange=this.$i18n.locale
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/theme5/home/sections",
        
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        this.sections = response.data.data;
       
      })
      .then(() => (this.loading = false));
  },
  methods: {
  

  },
};
</script>
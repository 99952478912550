<template>
  <section id="Second" class="profile_page"  v-if="this.$localStorage.get(this.$store.storeName) != null">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-4 mb-4">
          <router-link 
          :to="{
              name: 'profile',
              params: { storeName: this.$store.storeName,lang: $i18n.locale },
            }"
           class="profile_btn">
            {{
            $t("profile")
            }}
          </router-link>
          <router-link
          :to="{
              name: 'myAddresses',
              params: { storeName: this.$store.storeName,lang: $i18n.locale },
            }">
            {{
            $t("myAddresses")
            }}
          </router-link>
          <router-link 
          :to="{
              name: 'myOrders',
              params: { storeName: this.$store.storeName,lang: $i18n.locale },
            }"
          class="profile_btn">
            {{
            $t("myOrders")
            }}
          </router-link>
        </div>
        <div
          v-if="loading"
          class="d-flex flex-row justify-content-center col-lg-9 col-md-8 mb-4"
          style="margin-top: 100px; margin-bottom: 200px"
        >
          <HalfCircleSpinner
            :animation-duration="1000"
            :dot-size="35"
            :dots-num="3"
            color="var(--primary-color)"
          />
        </div>
        <div v-if="!loading" class="col-lg-9 col-md-8 mb-4">
          <div class="container">
            <div class="row">
              <div class="col-lg-5 mb-4">
                <div class="card add_address">
                  <div v-for="(item, index) in DBitems" :key="index">
                    <button @click="getAddress(item.id)">
                      <h4>{{ item.title }}</h4>
                      <img
                        :src="
                          $i18n.locale == 'ar'
                            ? require('../../public/assets/image/icons/arrow-head.png')
                            : require('../../public/assets/image/icons/arrow-head-r.png')
                        "
                      />
                    </button>
                    <hr />
                  </div>
                  <button
                  
                    @click="
                      addAddress = true;
                      address = null;
                    "
                  >
                    <h4 style="color: var(--primary-color)">{{ $t("AddNewAddress") }}</h4>
                  </button>
                </div>
              </div>
              <transition
                name="bounce"
                enter-active-class="bounceInLeft"
                leave-active-class="bounceOutRight"
              >
                <div v-if="this.address != null" class="col-lg-7 mb-4">
                  <view-address :address="address"></view-address>
                </div>
              </transition>
              <transition
                name="bounce1"
                enter-active-class="bounceInLeft"
                leave-active-class="bounceOutRight"
              >
                <div v-if="this.addAddress == true" class="col-lg-7 mb-4">
                  <add-address></add-address>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ViewAddress from "../components/ViewAddress.vue";
import AddAddress from "../components/AddAddress.vue";
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

export default {
  components: { ViewAddress, AddAddress, HollowDotsSpinner, HalfCircleSpinner },
  data: function() {
    return {
      loading: false,
      DBitems: null,
      address: null,
      addAddress: false
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/addresses",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.DBitems = response.data.data.addresses.addresses;
        // console.log(this.DBitems);
      })
      .then((this.loading = false))
      .catch(error => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
                    window.location.href="/";

        }
      });
    // console.log("from add : "+this.$localStorage.id())

    if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  methods: {
    getAddress(id) {
      this.loading = true;
      this.addAddress = false;
      this.address = null;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/addresses/" +
            id +
            "/edit",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.address = response.data.data;
          //console.log(this.address.address.title);
        })
        .then((this.loading = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
                      window.location.href="/";

          }
        });
    },
    updatAddress() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/addresses",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data.addresses.addresses;
          this.address = null;
          this.addAddress = true;
          //console.log(this.addAddress)
          // console.log(this.DBitems);
        })
        .then((this.loading = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
                      window.location.href="/";

          }
        });
    }
  }
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

#Second {
  margin-top: 120px;
  min-height: 600px;
}
.card {
  background-color: #f8f8f8;
  width: 100%;
  border-radius: 15px;
  color: var(--secondary-color);
  font-family: "Araboto-Bold";
  text-align: center;
  font-size: 16px;
  padding: 15px 3px;
  border: 0;
}

.card h4 {
  font-size: 15px;
  font-family: "Araboto-Medium";
  display: inline;
  color: var(--secondary-color);
}
.card h4:hover {
  color: var(--primary-color);
}
button {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  color: var(--secondary-color);
}
hr {
  height: 2px;
  margin: 12px;
  color: #cccccc;
}
[dir="rtl"] button img {
  left: 15px;
  margin-top: 10px;
  position: absolute;
}
[dir="ltr"] button img {
  right: 15px;
  margin-top: 10px;
  position: absolute;
}
a {
  display: block;
  background-color: #f8f8f8;
  padding: 13px 0 17px 0;
  width: 80%;
  border-radius: 15px;
  color: var(--secondary-color);
  font-family: "Araboto-Bold";
  font-size: 15px;
  text-align: center;
  margin-bottom: 20px;
}

a:hover {
  color: var(--primary-color) !important;
}

.router-link-exact-active,
.router-link-exact-active:focus {
  background-color: #fff;
  border: 2px solid var(--primary-color);
}
.mapStyle {
  margin-bottom: 10px;
  border-radius: 15px;
  height: 315px;
  width: 80%;
}
.input-icons {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.input-icons input,
textarea.form-control {
  width: 100%;
  border-radius: 9px;
  background-color: #fff;
  margin-bottom: 10px;
  border: 2px solid #cccccc;
  font-size: 13px;
  font-family: "Araboto-Medium";
  color: var(--secondary-color);
  padding: 10px 20px 15px 20px;
}

.button {
  background-color: var(--primary-color);
  border-radius: 10px;
  width: 60%;

  margin: 20px 20%;
  padding: 10px 15px;
  border: 2px solid var(--primary-color);
  color: #fff;
  font-size: 15px;
  font-family: "Araboto-Medium";
}

.button:hover {
  background-color: #fff;
  color: #000;
}

/*@media (min-width: 1400px){
.container {
    max-width: 1400px !important;
}

}*/

@media (max-width: 992px) {
  a {
    width: 100%;
  }
}
@media (max-width: 772px) {
  a {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
}
</style>

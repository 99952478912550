<template>
  <div>
      <div v-if="this.$store.state.langchange1">{{ updatedata() }}</div>
  <footer id="footer" v-if="!loading">
    <!--<div class="footer-image mobile-inactive"></div>-->
    <div class="container mt-5">
      <div class="row footer-width" style="width: 100%">
        <div class="col-lg-4 mb-3">
          <div class="logo d-flex flex-row">
            <a href=""
              ><img :src="DBitems.social.logo_footer" alt="" class="img-fluid" style="max-width:130px"
            /></a>
          </div>
          <h2 class="mb-4">{{ DBitems.market.title }}</h2>
          <p>{{ DBitems.market.description }}</p>
          <p style="color: var(--primary-color)">
            {{ DBitems.market.sub_description }}
          </p>
          <div class="social-linkes1 mt-4"><!-- v-if="DBitems.social.andrioad_link!=null"-->
            <a v-if="DBitems.social.andrioad_link!=null" target="_blank" :href="DBitems.social.andrioad_link"
              ><img class="m-1" src="../../public/assets/image/icons/Google play.png"
            /></a>
            <a v-if="DBitems.social.ios_link!=null" target="_blank" :href="DBitems.social.ios_link"
              ><img class="m-1" src="../../public/assets/image/icons/App Store.png"
            /></a>
          </div>

         
        </div>
        <div class="container mobile-active">
          <hr />
        </div>
        <div class="col-lg-4 col-6 mb-3" style="margin-top: 75px">
          <div class="d-flex flex-row justify-content-around">
            <div>
              <h2 class="mb-4">{{ $t("quickAccess") }}</h2>
                <router-link
                  :to="{
                    name: 'about',
                    params: { storeName: this.$store.storeName },
                  }"
                  ><p>{{ $t("about") }}</p></router-link
                >
                <router-link
                  :to="{
                    name: 'privacy',
                    params: { storeName: this.$store.storeName },
                  }"
                  ><p>{{ $t("privacyPolicy") }}</p></router-link
                >
                <router-link
                  :to="{
                    name: 'contactUs',
                    params: { storeName: this.$store.storeName },
                  }"
                  ><p>{{ $t("contactUs") }}</p></router-link
                >
              <router-link
              :to="{
                name: 'shoppingCart',
                params: { storeName: this.$store.storeName },
              }"
              ><p>{{$t('shoppingCart')}}</p></router-link
            >
            </div>
          </div>
        </div>
        <!--animate__animated animate__fadeInLeft-->
        <div class="col-lg-4 col-6 mb-3" style="margin-top: 75px">
          <div class="d-flex flex-row justify-content-around">
            <div class="contact-info">
              <h2 class="mb-4">{{ $t("contact") }}</h2>
              <a target="_blank"  :href="'tel:'+DBitems.social.phone">
                <p class="phone">{{ DBitems.social.phone }}</p>
                <!--edittttttttttttt tow phone-->
              </a>
              <a target="_blank" :href="'mailto:'+  DBitems.social.email"
                ><!--edittttttttttttt-->
                <p>{{ DBitems.social.email }}</p>
              </a>
              <a>
                <p>{{ DBitems.social.website }}</p>
              </a>
            </div>
          </div>
           <div class="social-linkes mt-4">
            
            <a v-if="DBitems.social.instgram" target="_blank" :href="DBitems.social.instgram">
              <img class="m-1" src="../../public/assets/image/icons/insta.png"
            /></a>
            <a v-if="DBitems.social.twiter" target="_blank" :href="DBitems.social.twiter"
              ><img class="m-1" src="../../public/assets/image/icons/twitter.svg"
            /></a>
            <a v-if="DBitems.social.facebook" target="_blank" :href="DBitems.social.facebook"
              ><img class="m-1" src="../../public/assets/image/icons/facebook.svg"
            /></a>
            <a v-if="DBitems.social.tiktok" target="_blank" :href="DBitems.social.tiktok">
              <img class="m-1" src="../../public/assets/image/icons/tiktok.png"
            /></a>
            <a v-if="DBitems.social.whatsapp" target="_blank" :href="'https://api.whatsapp.com/send?phone='+DBitems.social.whatsapp+'&text=Hello!'"
              ><img class="m-1" src="../../public/assets/image/icons/whats.png"
            /></a>
            <a v-if="DBitems.social.snapchat" target="_blank" :href="DBitems.social.snapchat"
              ><img class="m-1" src="../../public/assets/image/icons/snap.png"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: left;
    margin-left: 3%;
    cursor: pointer;
    color: white !important;
    border-top: 3px solid var(--primary-color);
    height:55px
    ">
      <p style="float: left;
    margin: 2% !important;
    "onclick="window.open('http://etrolley.net', '_blank');"> Powered by : ETrolley </p>
      <p v-if="DBitems.market.copy_right" style="float: right;
    margin: 2% !important;" onclick="window.open('http://etrolley.net', '_blank');"> {{ DBitems.market.copy_right }}</p>

    <p  v-else style="float: right;
    margin: 2% !important;" onclick="window.open('http://etrolley.net', '_blank');"> {{ $t("all rights are save") }}  {{ DBitems.market.name }}</p>

      </div>  </footer>
  </div>
</template>

<script>
//import { KinesisContainer, KinesisElement} from 'vue-kinesis'
export default {
  // eslint-disable-next-line
  /*eslint-disable */
  name: "Footer",
  //components:{ KinesisContainer, KinesisElement },
  props: {
    // msg: String,
  },
  data: function () {
    return {
      //storeName: "markat",
      logo: "",
      DBitems: null,
      loading: false,
    };
  },
  created() {
    this.loading = true;
    this.$store.storeName = this.$store.state.storeName;
    console.log(this.$store.storeName);
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/footer",
      {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        })
      .then((response) => (this.DBitems = response.data.data))
      .then(() => (this.loading = false));
  },
  methods: {
    updatedata() {
      this.loading = true;
    this.$store.storeName = this.$store.state.storeName;
    console.log(this.$store.storeName);
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/footer",
      {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        })
      .then((response) => (this.DBitems = response.data.data))
      .then(() => (this.loading = false));
      this.$store.state.langchange1 = false;
      
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 @media only screen and (max-width: 600px) {

.footer_saved
{
        float: none !important;
    margin: 0px !important;
    margin-top:3%;
    position: relative;
    right: 0px !important;
    /* pointer-events: none; */
}

.footer_saved2
{
        float: none !important;
    margin: 0px !important;
    position: relative;
    margin-top:3%;
    right: 0px !important;
    /* pointer-events: none; */
}
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group",attrs:{"id":'fav'+_vm.id}},[_c('div',{staticClass:"relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md duration-500"},[_c('img',{staticClass:"product_list_img group-hover:scale-110 duration-500",attrs:{"src":_vm.image,"alt":_vm.name}}),_c('div',{staticClass:"absolute -bottom-20 group-hover:bottom-3 start-3 end-3 duration-500"},[_c('router-link',{staticClass:"py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-slate-900 text-white w-full rounded-md",attrs:{"to":{
                             name: 'product',
                             params: {
                             storeName: _vm.$store.storeName,
                              id: _vm.productid  ,
                               name:_vm.name,
                               lang: _vm.$i18n.locale
                                        },
                               }}},[_vm._v(_vm._s(_vm.$t('Add to Cart')))])],1),_c('ul',{staticClass:"list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500 space-y-1"},[_c('li',[_c('a',{staticClass:"size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.changeFav}},[(this.fav)?_c('i',{staticClass:"mdi mdi-heart",staticStyle:{"color":"#e22020","font-size":"24px"}}):_c('i',{domProps:{"innerHTML":_vm._s(_vm.heart)}})])]),_c('li',{staticClass:"mt-1"},[_c('router-link',{staticClass:"size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow",attrs:{"to":{
                             name: 'product',
                             params: {
                             storeName: _vm.$store.storeName,
                              id: _vm.productid  ,
                               name:_vm.name,
                               lang: _vm.$i18n.locale
                                        },
                               }}},[_c('i',{staticClass:"size-3",domProps:{"innerHTML":_vm._s(_vm.eye)}})])],1)])]),_c('div',{staticClass:"mt-4"},[_c('router-link',{staticClass:"hover:text-orange-500 text-lg font-medium",attrs:{"to":{
                             name: 'product',
                             params: {
                             storeName: _vm.$store.storeName,
                              id: _vm.productid  ,
                               name:_vm.name,
                               lang: _vm.$i18n.locale
                                        },
                               }}},[_vm._v(" "+_vm._s(_vm.name.substr(0,25)))]),_c('div',{staticClass:"flex justify-between items-center mt-1"},[(_vm.is_option)?_c('p',[_vm._v(_vm._s(_vm.$t('PriceOnRequest')))]):((_vm.new_price>0)&&(!_vm.is_option))?_c('p',[_vm._v(_vm._s(_vm.new_price.toFixed(2))+_vm._s(_vm.unit)+" "),_c('del',{staticClass:"text-slate-400"},[_vm._v(_vm._s(_vm.old_price.toFixed(2))+_vm._s(_vm.unit))])]):(!_vm.is_option)?_c('p',[_vm._v(_vm._s(_vm.old_price.toFixed(2))+" "+_vm._s(_vm.unit))]):_vm._e(),_c('ul',{staticClass:"font-medium text-amber-400 list-none"},[_vm._l((_vm.rate),function(n){return _c('li',{key:n,staticClass:"inline"},[_c('i',{staticClass:"mdi mdi-star"})])}),_vm._l((5 - _vm.rate),function(n){return _c('li',{key:n,staticClass:"inline"},[_c('i',{staticClass:"mdi mdi-star-outline"})])})],2)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
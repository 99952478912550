<template>
  <div style="min-height: 600px">
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <div style="margin-top: 100px" v-if="!loading">
      <!-- Breadcrumb Section Begin -->
      <section
        class="breadcrumb-section set-bg"
        :style="{ 'background-image': 'url(' + DBitems.social.image + ')' }"
      >
        <div class="container">
          <div
            class="text-center TitleCard"
            style="
              margin: 0 20%;
              background-color: rgba(80, 79, 79, 0.9);
              padding: 20px 0px 10px;
              width: 60%;
              border-radius: 25px;
            "
          >
            <div class="breadcrumb__text">
              <h2>{{ DBitems.social.market_name }}</h2>
              <div class="breadcrumb__option">
                <span>{{ $t("contactUs") }}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Breadcrumb Section End -->

      <!-- Contact Section Begin -->
      <section class="contact spad">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-3 col-sm-6 text-center">
              <div class="contact__widget">
                <span>
                  <i class="bi bi-telephone"></i>
                </span>
                <h4>{{ $t("Phone") }}</h4>
                <p class="phone_dir">{{ DBitems.social.phone }}</p>
                <p v-if="DBitems.social.other_phone != null">{{ DBitems.social.other_phone }}</p>
              </div>
            </div>
            <div v-if="DBitems.social.address" class="col-lg-4 col-md-3 col-sm-6 text-center">
              <div  class="contact__widget">
                <span>
                  <i class="bi bi-geo-alt"></i>
                </span>
                <h4>{{ $t("Address") }}</h4>
                <p>{{ DBitems.social.address }}</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-3 col-sm-6 text-center">
              <div class="contact__widget">
                <span>
                  <i class="bi bi-envelope"></i>
                </span>
                <h4>{{ $t("Email") }}</h4>
                <p>{{ DBitems.social.email }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Contact Section End -->

      <!-- Map Begin  allowfullscreen=""-->
      <section class="contactMap" v-if="this.$store.state.apperance_map==1">
        <div class="map">
      
          <iframe
          :src="
            'https://maps.google.com/maps?q=' +
            DBitems.social.lat +
            ',' +
            DBitems.social.long +
            '&t=&z=15&ie=UTF8&iwloc=&output=embed'
          "
          height="500"
          style="border: 0"
          aria-hidden="false"
          tabindex="0"
          ></iframe>
          <div class="map-inside">
            <i class="icon_pin"></i>
            <div class="inside-widget">
              <h4>{{ DBitems.social.address }}</h4>
              <ul>
                <li class="phone_dir">{{ DBitems.social.phone }}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <!-- Map End -->

      <section v-if="DBitems.working_time.working_time.length>0"class="contact spad" style="background-color: rgb(247, 248, 251)">
        <div class="container">
          <div class="contact__form__title">
            <h2>
              <span>
                <i class="bi bi-clock-history"></i>
              </span>
              {{ $t("WorkingHours") }}
            </h2>
            <div class="container mt-4">
              <div class="row">
                <p
                  class="col-lg-6"
                  v-for="(item, index) in DBitems.working_time.working_time"
                  :key="index"
                  style="text-align: start; line-height: 50px"
                >
                  <i class="bi bi-check2" style="color: var(--primary-color); font-weight: 500"></i>
                  {{ item.day }} {{ $t("from") }} {{ item.from }}
                  {{ $t("to") }} {{ item.to }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Contact Form Begin -->
      <div class="contact-form spad">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="contact__form__title">
                <h2>
                  <span>
                    <i class="bi bi-envelope"></i>
                  </span>
                  {{ $t("LeaveMessage") }}
                </h2>
              </div>
            </div>
          </div>
          <form @submit.prevent="submitmessage" @keydown="form.onKeydown($event)">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <input
                  name="name"
                  type="text"
                  :placeholder="$t('name')"
                  v-model="form.name"
                  required
                />
              </div>
              <div class="col-lg-6 col-md-6">
                <input
                  name="email"
                  type="email"
                  :placeholder="$t('Email')"
                  v-model="form.email"
                  required
                />
              </div>
              <div class="col-lg-12 text-center">
                <textarea :placeholder="$t('message')" v-model="form.message" required></textarea>
                <button type="submit" :disabled="form.busy" class="s-btn">{{ $t("SendMessage") }}</button>
              </div>
            </div>
          </form>
          <div style="position: fixed; width: 500px; bottom: 10px; left: 10px">
            <AlertSuccess :form="form" :message="$t('Message1')" />
            <AlertErrors :form="form" :message="$t('Message0')" />
          </div>
        </div>
      </div>
      <!-- Contact Form End -->
    </div>
  </div>
</template>
<script>
import Form from "vform";
import { AlertErrors, AlertSuccess } from "vform/src/components/bootstrap5";

export default {
  components: {
    AlertErrors,
    AlertSuccess
  },
  data: function() {
    return {
      loading: false,
      DBitems: null,

      form: new Form({
        name: null,
        email: null,
        message: null
      }),

      marker: { position: {  
        lat: 51.677878,
          lng: 30.67676 } },
      center: { lat: 51.677878,
          lng: 30.67676 },
      mapOptions: {
        disableDefaultUI: true
      }
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/contact_us",
        {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        }
      )
      .then(response => {
        this.DBitems = response.data.data;
        this.marker.position = {
          lat: this.DBitems.social.lat,
          lng: this.DBitems.social.long
        };
        this.center = {
          lat: this.DBitems.social.lat,
          lng: this.DBitems.social.long
        };
      })
      .then(() => (this.loading = false));
      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  methods: {
    updatedata() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/contact_us",
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
        })
        .then(() => (this.loading = false));
      this.$store.state.langchange = false;
    },
    async submitmessage() {
      const response = await this.form
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/send_message",
          {
            message: this.form.message,
            name: this.form.name,
            email: this.form.email
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.form.message = "";
            this.form.name = "";
            this.form.email = "";
            console.log(response.data.message);
          }
          if (response.data.error) {
            this.errorMessage = response.data.message;
          }
        });
    },

    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },
    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      this.$refs.mapRef.setZoom(18);
    },
    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      console.log(e);
    }
  }
};
</script>
<style scoped>
/*---------------------
  Breadcrumb
-----------------------*/

.breadcrumb-section {
  display: flex;
  align-items: center;
  padding: 45px 0 40px;
  font-family: "Araboto-Bold";
}

.breadcrumb__text h2 {
  font-size: 46px;
  color: var(--primary-color);
  font-weight: 700;
}

.breadcrumb__option a {
  display: inline-block;
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
  margin-right: 20px;
  position: relative;
}

.breadcrumb__option a:after {
  position: absolute;
  right: -12px;
  top: 13px;
  height: 1px;
  width: 10px;
  background: #ffffff;
  content: "";
}

.breadcrumb__option span {
  display: inline-block;
  font-size: 26px;
  line-height: 70px;
  color: var(--primary-color);
}

/*---------------------
  Contact
-----------------------*/

.contact {
  padding-top: 80px;
  padding-bottom: 50px;
  font-family: "Araboto-Medium";
}

.contact__widget {
  margin-bottom: 30px;
}

.contact__widget span {
  font-size: 36px;
  color: var(--primary-color);
}

.contact__widget h4 {
  color: #1c1c1c;
  font-weight: 700;
  margin-bottom: 16px;
  margin-top: 18px;
  font-size: 16px;
}

.contact__widget p {
  color: #666666;
  margin-bottom: 0;
}

/*---------------------
  Map
-----------------------*/

.map {
  height: 500px;
  font-family: "Araboto-Medium";
  position: relative;
}

.map iframe {
  width: 100%;
}

.map .map-inside {
  position: absolute;
  left: 50%;
  top: 160px;
  -webkit-transform: translateX(-175px);
  -ms-transform: translateX(-175px);
  transform: translateX(-175px);
}

.map .map-inside i {
  font-size: 48px;
  color: var(--primary-color);
  position: absolute;
  bottom: -75px;
  left: 50%;
  -webkit-transform: translateX(-18px);
  -ms-transform: translateX(-18px);
  transform: translateX(-18px);
}

.map .map-inside .inside-widget {
  width: 350px;
  background: #ffffff;
  text-align: center;
  padding: 23px 0;
  position: relative;
  z-index: 1;
  -webkit-box-shadow: 0 0 20px 5px rgba(12, 7, 26, 0.15);
  box-shadow: 0 0 20px 5px rgba(12, 7, 26, 0.15);
}

.map .map-inside .inside-widget:after {
  position: absolute;
  left: 50%;
  bottom: -30px;
  -webkit-transform: translateX(-6px);
  -ms-transform: translateX(-6px);
  transform: translateX(-6px);
  border: 12px solid transparent;
  border-top: 30px solid #ffffff;
  content: "";
  z-index: -1;
}

.map .map-inside .inside-widget h4 {
  font-size: 22px;
  font-family: "Araboto-Medium";
  font-weight: 700;
  color: #1c1c1c;
  margin-bottom: 4px;
}

.map .map-inside .inside-widget ul li {
  list-style: none;
  font-size: 16px;
  font-family: "Araboto-Medium";
  color: #666666;
  line-height: 26px;
}

/*---------------------
  Contact Form
-----------------------*/

.contact__form__title {
  margin-bottom: 50px;
  text-align: start;
  font-family: "Araboto-Medium";
}

.contact__form__title h2 {
  color: #1c1c1c;
  font-weight: 700;
  font-size: 25px;
}

.contact-form {
  padding-top: 80px;
  padding-bottom: 80px;
}

.contact-form input {
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-family: "Araboto-Medium";
  color: #6f6f6f;
  padding-left: 20px;
  margin-bottom: 30px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
}

.contact-form input::placeholder {
  color: #6f6f6f;
}

.contact-form textarea {
  width: 100%;
  height: 150px;
  font-size: 16px;
  font-family: "Araboto-Medium";
  color: #6f6f6f;
  padding-left: 20px;
  margin-bottom: 24px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding-top: 12px;
  resize: none;
}

.contact-form textarea::placeholder {
  color: #6f6f6f;
}

.s-btn {
  font-size: 16px;
  font-family: "Araboto-Medium";
  color: #ffffff;
  font-weight: 800;
  text-transform: uppercase;
  display: inline-block;
  padding: 13px 30px 12px;
  border: 2px solid var(--primary-color);
  background: var(--primary-color);
  border-radius: 10px;
}
.s-btn:hover {
  background-color: #fff;
  color: #000;
}

.set-bg {
  aspect-ratio: 1200/400;
  /*text-shadow: 5px 5px 10px grey;
  filter: brightness(0.6);*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
@media (max-width: 992px) {
  /*.TitleCard {
    margin: 0 15% !important;
    width: 70% !important;
  }*/
  .breadcrumb__text h2 {
    font-size: 30px;
  }
  .breadcrumb__option span {
    font-size: 18px;
    line-height: 20px;
  }
}
@media (max-width: 500px) {
  .breadcrumb__text h2 {
    font-size: 20px;
  }
  .breadcrumb__option span {
    font-size: 14px;
    line-height: 20px;
  }
  .contact__form__title h2 {
    font-size: 18px;
  }
  p {
    font-size: 14px;
    line-height: 20px !important;
  }
  .contact__widget h4 {
    font-size: 14px;
  }
  /*.inside-widget  {
  width: 200px;
}*/
  .inside-widget h4 {
    font-size: 18px !important;
  }
}
</style>

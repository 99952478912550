<template>
  <section id="Second">
    <!-- v-if="this.$localStorage.get(this.$store.storeName) == null"-->
    <div class="container">
      <h3>{{ $t("logIn") }}</h3>
      <div v-if="phoneShow">
        <!--v-if=" this.$store.state.auth==1"-->
        <p>{{ $t("inputPhone") }}</p>
        <form @submit.prevent="sendPhone(false)" @keydown="form.onKeydown($event)">
          <div class="input1">
            <VuePhoneNumberInput
              dir="ltr"
              required
              fetch-country
              show-code-on-list
              no-example
              @update="sendPhone1($event)"
              v-model="partphone"
            />
          </div>
          <div v-if="validateMessage != ''" style="text-align: center; margin-top: 10px">
            <span style="color: red; margin: 0">{{ validateMessage }}*</span>
          </div>
          <button type="submit" :disabled="form.busy" class="button">{{ $t("continue") }}</button>
        </form>
      </div>
      <div v-else>
        <div class="container">
          <p>{{ $t("EnterCode") }}</p>
          <div dir="ltr">
            <CodeInput
              :loading="false"
              required
              class="input"
              @change="onChange"
              @complete="onComplete"
            />
          </div>
          <p
            style="margin-top: 15px; margin-bottom: 0"
            v-if="$i18n.locale == 'en'"
          >{{ countDown }} second</p>
          <p style="margin-top: 15px; margin-bottom: 0" v-else>{{ countDown }} ثانية</p>
          <div
            v-if="validateMessage != ''"
            style="text-align: center; margin-top: 10px; margin-bottom: 0"
          >
            <span style="color: red; margin: 0">{{ validateMessage }}</span>
          </div>
          <div
            v-if="validateMessage1 != ''"
            style="text-align: center; margin-top: 10px; margin-bottom: 0"
          >
            <span style="color: var(--primary-color); margin: 0">
              {{
              validateMessage1
              }}
            </span>
          </div>
          <div class="text-center w-100 mt-1">
            <button
              v-if="countDown==0"
              class="ResendCode"
              @click="sendPhone(true)"
              style="margin: 20px"
            >{{ $t("ResendCode") }}</button>
            <button
              @click="checkCode"
              class="button"
              :disabled="countDown==0"
              style="margin-top:20px"
            >{{ $t("continue") }}</button>
          </div>
        </div>
      </div>
      <div id="recaptcha-container"></div>
    </div>
  </section>
</template>
<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import Form from "vform";

import firebase1 from "firebase";
import CodeInput from "vue-verification-code-input";

export default {
  components: {
    VuePhoneNumberInput,
    CodeInput
  },
  data: function() {
    return {
      isValid: false,
      partphone: null,
      appVerifier: "",
      otherApp: null,
      phoneShow: true,
      checkcomplete: false,
      validateMessage: "",
      validateMessage1: "",
      finalcode: null,
      countDown: 90,
      form: new Form({
        phone: null
      })
    };
  },
  mounted() {
    try {
      if (this.$i18n.locale == "ar") {
        document
          .getElementsByClassName("input-tel__input")[0]
          .setAttribute("placeholder", "رقم الهاتف");
        document.getElementsByClassName(
          "country-selector__label"
        )[0].innerHTML = "رمز الدولة";
        document.getElementsByClassName("input-tel__label")[0].innerHTML =
          "* رقم الهاتف";
        /*document.getElementsByClassName('select-country-container')[0].setAttribute('dir', 'ltr');
           document.getElementsByClassName('select-country-container')[0].setAttribute('dir', 'ltr');*/
      }
    } catch (e) {}
  },
  created() {
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/firebase_config",
        {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        }
      )
      .then(response => {
        if (response.data.code == "-1") {
          //  console.log( response.data.code);
          this.validateMessage = response.data.message;
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        } else if (response.data.code == "1") {
          const firebaseConfig = {
            apiKey: response.data.data.apiKey,
            authDomain:  response.data.data.authDomain,
            projectId:  response.data.data.projectId,
            storageBucket:  response.data.data.storageBucket,
            messagingSenderId:  response.data.data.messagingSenderId,
            appId: response.data.data.appId,
            measurementId: response.data.data.measurementId,
          };
          if (firebase1.apps.length<2) {
           console.log("hloooooooooooooooooooo" + firebase1.apps.length)
          this.otherApp =  firebase1.initializeApp(firebaseConfig, "otherApp");
          }else{
            this.otherApp = firebase1.apps[1]
          }
          this.initReCaptcha();
        }
      });
  },
  watch: {
    phoneShow: {
      handler(newValue) {
        if (newValue == false) this.countDown = 90;
        this.countDownTimer();
      },
      immediate: true
    }
  },
  methods: {
    async sendPhone(Resend) {
      if (this.isValid == true) {
        let appVerifier = this.appVerifier;
        let vm = this;
        //
        this.otherApp
          .auth()
          .signInWithPhoneNumber(this.form.phone, appVerifier)
          .then(function(confirmationResult) {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult;
            //
            if (vm.$i18n.locale == "en") {
              alert("SMS sent");
            } else {
              alert("تم ارسال الرسالة بنجاح");
            }

            vm.phoneShow = false;
            if (Resend) {
              vm.countDown = 90;
              vm.countDownTimer();
              vm.validateMessage1 = response.data.message;
              setTimeout(() => {
                vm.validateMessage1 = "";
              }, 3000);
            }
          })
          .catch(function(error) {
            // Error; SMS not sent
            if (vm.$i18n.locale == "en") {
              vm.validateMessage = "Error; SMS not sent";
              setTimeout(() => {
                vm.validateMessage = "";
              }, 3000);
            } else {
              vm.validateMessage = "عذراً لم يتم ارسال الرسالة..حاول مجدداً";
              setTimeout(() => {
                vm.validateMessage = "";
              }, 3000);
            }
          });
      } else {
        if (this.$i18n.locale == "en") {
          this.validateMessage = "your number isn't valid";
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        } else {
          this.validateMessage = "هذا الرقم غير صالح";
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        }
      }
    },
    sendPhone1(event) {
      this.isValid = event.isValid;
      if (this.isValid == true) {
        this.form.phone = event.e164;
      }
    },
    initReCaptcha() {
      setTimeout(() => {
        let vm = this;
        window.recaptchaVerifier = new firebase1.auth.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: function(response) {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              // ...
            },
            "expired-callback": function() {
              // Response expired. Ask user to solve reCAPTCHA again.
              // ...
            }
          },
          this.otherApp
        );
        //
        this.appVerifier = window.recaptchaVerifier;
      }, 1000);
    },

    checkCode() {
      if (this.checkcomplete == true) {
        let vm = this;
        let code = this.finalcode;
        window.confirmationResult
          .confirm(code)
          .then(function(result) {
            // User signed in successfully.

            vm.axios
              .post(
                vm.$store.state.base_api_url +
                  vm.$store.storeName +
                  "/app/login",
                {
                  /*/register_code*/
                  phone: vm.form.phone,
                  code: vm.$store.state.master_code,
                  fcm_token: vm.$store.state.fsm_token
                },
                {
                  headers: {
                    "Accept-Language": vm.$i18n.locale
                  }
                }
              )
              .then(response => {
                if (response.data.code == "-1") {
                  //  console.log( response.data.code);
                  vm.validateMessage = response.data.message;
                  setTimeout(() => {
                    vm.validateMessage = "";
                  }, 3000);
                } else if (response.data.code == "1") {
                  vm.$localStorage.set(
                    vm.$store.storeName,
                    response.data.data.token
                  );
                  if (response.data.message == "register") {
                    window.location.href =
                      "https://etrolley.net/store/" +
                      vm.$store.storeName +
                      "/profile";
                  } else {
                    window.location.href =
                      "https://etrolley.net/store/" + vm.$store.storeName;
                  }
                }
              });
          })
          .catch(function(error) {
            // User couldn't sign in (bad verification code?)
            // ...

            if (vm.$i18n.locale == "en") {
              vm.validateMessage = "error please retray";
              setTimeout(() => {
                vm.validateMessage = "";
              }, 3000);
            } else {
              vm.validateMessage = "خطأ..حاول مجدداً";
              setTimeout(() => {
                vm.validateMessage = "";
              }, 3000);
            }
          });
      } else {
        if (this.$i18n.locale == "en") {
          this.validateMessage = "your code isn't complete";
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        } else {
          this.validateMessage = "يرجى إكمال كافة الحقول";
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        }
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    onChange(v) {
      //console.log("onChange ", this.phone);
      this.checkcomplete = false;
    },
    onComplete(v) {
      //console.log("onComplete ", v);
      this.finalcode = v;
      this.checkcomplete = true;
    }
  }
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#Second {
  margin-top: 100px;
  min-height: 500px;
}

input {
  border: 0;
  border: 2px solid var(--primary-color);
  background-color: transparent;
  width: 100%;
  height: 32px;
  /*margin-top: 1%;*/
  border-radius: 10px;
  text-align: center;
  color: var(--secondary-color);
  padding: 25px 0 30px 0;
}
h3 {
  font-size: 20px;
  font-family: "Araboto-Bold";
  color: var(--primary-color);
  text-decoration: underline;
  text-underline-offset: 10px;
}
h4 {
  font-size: 18px;
  font-family: "Araboto-Medium";
  color: #000;
  margin-top: 20px;
  margin-bottom: 20px;
}
p {
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 15px;
  font-family: "Araboto-Medium";
  color: var(--secondary-color);
  text-align: center;
}
.button {
  background-color: var(--primary-color);
  border-radius: 10px;
  width: 30%;
  margin-left: 35%;
  margin-right: 35%;
  padding: 10px 0 15px;
  text-align: center;
  border: 2px solid var(--primary-color);
  color: #fff;
  font-size: 16px;
  font-family: "Araboto-Medium";
  margin-top: 60px;
}

.button:hover {
  background-color: #fff;
  color: #000;
}
.input1 {
  width: 40%;
  margin-left: 30%;
  margin-right: 30%;
}
/*@media (min-width: 1400px){
.container {
    max-width: 1200px !important;
}
}*/
@media (max-width: 992px) {
  h4 {
    font-size: 15px;
  }
  .card-img-actions .preview-1 {
    margin-top: 30px;
    width: 120px !important;
    height: 120px !important;
  }
  .card-img-actions {
    margin-top: 40px;
  }
  .input1 {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 20px;
  }
  .button {
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
  }
}
@media (max-width: 772px) {
  .input1 {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 20px;
  }
  .button {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
  }
  p {
    font-size: 12px;
  }
}
</style>

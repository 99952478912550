<template>
  <section>
    <div
        v-if="loading"
        class="d-flex flex-row justify-content-center"
        style="margin-top: 100px; margin-bottom: 200px"
      >
        <!-- LOADER -->
<div class="preloader">
    <div class="lds-ellipsis">
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>
<!-- END LOADER -->

      </div>
  <!-- START SECTION BREADCRUMB -->
<div class="breadcrumb_section bg_gray page-title-mini">
  <div class="container"><!-- STRART CONTAINER -->
      <div class="row align-items-center">
        <div class="col-md-6">
              <div class="page-title">
              <h1>{{ this.DBitems.product.name }}</h1>
              

              <div class="d-flex flex-row justify-content-between pb-0">
              <router-link
                :to="{
                  name: 'login',
                  params: { storeName: this.$store.storeName,lang: $i18n.locale },
                }"
                v-if="loginMassage != ''"
                style="
                  margin: 10px;
                  color: var(--primary-color);
                  text-decoration: 1px underline !important;
                  text-underline-offset: 5px;
                "
              >{{ loginMassage }}</router-link>
              </div>



              </div>
          </div>
          <div class="col-md-6">
              <ol class="breadcrumb justify-content-md-end">
                  <li class="breadcrumb-item"><router-link to="/">
                      {{ $t('home') }}</router-link>
                      </li>
                  <li class="breadcrumb-item">
                    <router-link to="/products">{{ $t('products') }}</router-link></li>
                  <li class="breadcrumb-item active">{{ this.DBitems.product.name }}</li>
              </ol>
          </div>
      </div>
  </div><!-- END CONTAINER-->
</div>
<!-- END SECTION BREADCRUMB -->


<!-- START SECTION SHOP -->
<div class="section">
<div class="container">
  <div class="row">
          <div class="col-lg-6 col-md-6 mb-4 mb-md-0">
            <div class="product-image">
                  
                    <div class="product_img_box" >
                      <imageZoom 
    :regular="this.first_image" 
    :zoom="this.first_image"
    :zoom-amount="3" 
    id="product_img"
    :alt="this.DBitems.product.name"
    img-class="img-fluid"
    img-id="product_img2"
    >
    <img  id="product_img2"
     :src='this.images[0].src' 
    data-zoom-image="/pet_shop/assets/images/product_img1.jpg" :alt="this.DBitems.product.name" />
</imageZoom>
                        
                    </div>

                    
                      <VueSlickCarousel 
                                      ref="slick"
                                      v-if="this.new_images.length !=0 &&this.loading==false&&this.$i18n.locale=='en'"
                                           :options="settings"
                                           @reInit="handlereInit"
                                           
                                           id="pr_item_gallery" 
                                           class="row 
                                           product_gallery_item 
                                           slick_slider
                                          products_image" 
                                           data-slides-to-show="4" 
                                           data-slides-to-scroll="1" 
                                           data-infinite="false"

                                           style=" direction: rtl; !important"

                                  
                                       >
                        <div
                        v-for="(item, index) in this.new_images" :key="index"
                        
                         class="item item_image" style="margin:8px;"

                      
                         >
                            <a class="product_gallery_item " 
                            :id="'image'+index"
                            @click="change_image(index,item.src)"
                            >
                            
                                <img :src="item.src" alt="">
                            </a>

                            
                            
                        </div>
                      </VueSlickCarousel>


                      <!-- if arabic -->


                      <VueSlickCarousel 
                                      ref="slick"
                                      v-if="this.new_images.length !=0 &&this.loading==false&&this.$i18n.locale=='ar'"
                                           :options="settings_ar"
                                           @reInit="handlereInit"
                                           
                                           id="pr_item_gallery" 
                                           class="row 
                                           product_gallery_item 
                                           slick_slider
                                          products_image" 
                                           data-slides-to-show="4" 
                                           data-slides-to-scroll="1" 
                                           data-infinite="false"
                                           style=" direction: ltr; !important"
                                      
                                  
                                       >
                        <div
                        v-for="(item, index) in this.new_images" :key="index"
                        
                         class="item item_image" style="margin:10px;"

                         @click="change_image(index,item.src)"
                         >
                            <a  class="product_gallery_item " 
                            :id="'image'+index"
                            >
                                <img :src="item.src" alt="">
                            </a>
                            
                        </div>
                      </VueSlickCarousel>
                    
                
              </div>
          </div>
          <div class="col-lg-6 col-md-6">
              <div class="pr_detail">
                  <div class="product_description">
                      <h4 class="product_title"><a href="#">{{ this.DBitems.product.name  }}</a></h4>
                      


                      <div  v-if="selectOption" class="product_price">
                          <span style="color:var(--primary-color)">
                            {{ $t('selectOption') }}</span>
                
  
                      </div>

                      <div  v-else-if="offer_price==0" class="product_price">
                          <span class="price">{{ this.price.toFixed(2) }} {{ this.unit }}</span>
                
                          <div
            class="orderInfo d-flex flex-row justify-content-between mt-1"
            v-if="this.DBitems.product.is_offer == true"
          >
            
          </div>
                      </div>

                      <!--  -->
                      <div  v-else class="product_price">
                          <span class="price">{{ this.offer_price.toFixed(2) }} {{ this.unit }}</span>
                          <del>{{ price.toFixed(2) }} {{ unit }}</del>
                          <div class="on_sale">
                              <span  v-if="this.DBitems.product.is_offer == true">{{DBitems.product.discount}}% </span>
                              
                          </div>
                          <div
            class="orderInfo d-flex flex-row justify-content-between mt-1"
            v-if="this.DBitems.product.is_offer == true"
          >
            <span>{{ $t("offerEnd") }} :</span>
            <h5 style="color: var(--primary-color)">{{ this.DBitems.product.offer_end }}</h5>
          </div>
                      </div>
                      <!--  -->
                      <div class="rating_wrap">
                  


                <div class="d-flex flex-row justify-content-center">
                  <span v-for="n in DBitems.rate" :key="n">
                    <i class="fa fa-star rate" ></i>
                  </span>
                  <div class="d-flex flex-row justify-content-center">
                    <span v-for="n in 5 - DBitems.rate" :key="n">
                      <i class="fa fa-star-o rate" style=""></i>
                    </span>
                    <span >({{ DBitems.count_rate }})</span>
                  </div>
               

               
              </div>
                          </div>
                          <br>
                      <div class="pr_desc" v-html="DBitems.product.description">
                      </div>
                      <div>
                        <br>
                        </div>
                     
                      <div v-for="(item, index) in DBitems.options.options" :key="index">
                      <div v-if="item.type=='select'" class="pr_switch_wrap">
                          <span class="switch_lable">{{ item.name }}</span>
                          <div class="product_size_switch">
                              
                                <label v-for="(item1, index) in item.option_values.select_values"
                                 :for="item1.id+'select'+index"
                                 
                                 
                                 >
                                <input type="radio" 
                                :id="item1.id+'select'+index" 
                                :name="'select'+item.id" 
                                :value="item1.id"
                                :title="$t('SelectOne')"
                                @change="upadateprice($event, item.id)"
                                style="display:none"
                                :class="item.id+'select_value'+index"
                                >
                                <span 
                                @click="change_select(item1.id,item.id)"
                                :id="'span'+item1.id"
                                :class="'span_value'+item.id"
                                >
                                {{ item1.name}}
                                </span>

                                 </label>
                             
                          </div>
                      </div>
                  
                      </div>

                      <!-- check box -->
                      <h4 v-if="DBitems.options_check.options.length!=0" 
                      style="color:var(--primary-color)">{{ $t('optional') }}:</h4>
                      <div v-for="(item, index) in DBitems.options_check.options" :key="index">
    
                        <div>
                          <div v-for="(item1, index1) in checked" :key="index1">
                            
                <div
                  class="orderInfo d-flex flex-row justify-content-between"
                  style="margin-bottom: 15px"
                  v-if="checked[index1].id == item.id"
                >
                  <h6>{{ item.name }} :</h6>
                  <div class="d-flex flex-row justify-content-between" style="width: 120px">
                    <label
                      v-if="$i18n.locale == 'ar'"
                      for="checkbox"
                    >{{ item.price }} {{ $store.state.unitAR }}</label>
                    <label v-else for="checkbox">{{ item.price }} {{ $store.state.unit }}</label>
                    <input
                      type="checkbox"
                      id="checkbox"
                      v-model="checked[index1].value"
                      style="width: 30px; height: 25px"
                      @change="changeCheckbox(item, checked[index1].value)"
                    />
                  </div>
                </div>
              </div>
            </div>
                        </div>

                      <!-- end check box -->
                  </div>
                  <hr />
                  <div class="cart_extra">
                      <div class="cart-product-quantity">
                    
                        <div v-if="((quanity == 0 ||(this.max_quanity==0))&& !selectOption) " 
                        class="NoQuantity">{{ $t("NoQuantity") }}</div>
                        <div v-else-if="selectOption">
                          {{ $t("selectOption_qut") }}
                          
                            </div>
                          <div v-else class="quantity">
                              <input type="button" value="-" class="minus"  @click="decrementClick()">
                              <input type="text" name="quantity" :value="counterVal" title="Qty" 
                              class="qty" 
                              size="4">
                              <input type="button" value="+" class="plus"  @click="incrementClick()">
                          </div>
                      </div>
                      <br>
                      <div class="cart_btn">
                          <button 
                        
                          class="btn btn-fill-out btn-addtocart"
                          id="#addToCart"
                          v-if="quanity != 0|| max_quanity!=0"
                         @click="addToCart" 
                          type="button">
                          <i class="icon-basket-loaded"></i> 
                          {{ $t("Addtocart") }}
                          </button>
                          <a v-if="this.$localStorage.get(this.$store.storeName) != null" class="add_wishlist" >
                            <i v-if="this.fav"  class="fa fa-heart" style="color:red"
                             @click="changeFav"></i>
                            <i   v-else class="ti-heart" @click="changeFav"></i>
                            </a>
                      </div>
                  </div>
                  <hr/>
                  <ul class="product-meta">
                      <li v-if=" this.DBitems.product.sku">{{ $t('sku') }}: 
                        <a href=""></a>{{ this.DBitems.product.sku }}</a></li>
                      <li>{{ $t('category') }}: <a href="#">
                        {{ this.DBitems.product.section }} ,
                        {{ this.DBitems.product.category }}</a></li>
                        <li v-if="this.DBitems.product.attrubites.length!=0"> <a href="#">
                          {{$t('Attrubites') }}:
                       </a></li>
                      <li 
                       v-for="(attrubite, index) in this.DBitems.product.attrubites" 
                       >
                         {{ attrubite.name }}:
                        <a  v-for="(value, index2) in attrubite.values" >
                          {{ value.name }},
                         </a>
                      
                        </li>
                  </ul>
                  
      
                  <div class="product_share">
                      <span>{{ $t('share') }}:</span>
                      <ul class="social_icons">
                          <li> 
                            <ShareNetwork
                         network="facebook"
                         :url="this.DBitems.product.link"
                        :title="this.DBitems.product.name"
                        :description="this.DBitems.product.description"
                        :quote="this.DBitems.product.name"
                        :hashtags="this.$store.state.StoreName"
                        media="https://etrolley.net/websiteImages/logoAR.png"
                                >
                       <i class="ion-social-facebook"></i>
                       </ShareNetwork></li>

                          <li>
                           

                          <a :href="this.DBitems.product.instgram_link" target="_blank" rel="noopener">
                            <i class="ion-social-instagram"></i>
                              </a>
                            </li>
                          <li> <ShareNetwork
                         network="whatsapp"
                        :url="this.DBitems.product.link"
                        :title="this.DBitems.product.name"
                        :description="this.DBitems.product.sub_description"
                        :quote="this.DBitems.product.name"
                        :hashtags="this.$store.state.StoreName"
                           >
                           <i class="ion-social-whatsapp"></i></ShareNetwork></li>


                                <li> <ShareNetwork
                         network="twitter"
                        :url="this.DBitems.product.link"
                        :title="this.DBitems.product.name"
                        :description="this.DBitems.product.description"
                        :quote="this.DBitems.product.name"
                        :hashtags="this.$store.state.StoreName"
                                ><img class="ion-social-twitter" 
                                src="https://www.gazaliperfumes.com/pet_shop/assets/images/x_icon.png" width="15px"/>
                                </ShareNetwork></li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
      <div class="row">
        <div class="col-12">
            <div class="large_divider clearfix"></div>
          </div>
      </div>
      <div class="row">
        <div class="col-12">
            <div class="tab-style3">
        <ul class="nav nav-tabs" role="tablist">
          
                      <li class="nav-item">
                        <a class="nav-link" id="Additional-info-tab" data-toggle="tab" href="#Additional-info" role="tab" aria-controls="Additional-info" aria-selected="false"> {{ $t('Additional info') }}</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="Reviews-tab" data-toggle="tab" href="#Reviews" role="tab" aria-controls="Reviews" aria-selected="false">{{ $t('Reviews') }} ({{ this.DBitems.count_rate }})</a>
                      </li>
                  </ul>
                <div class="tab-content shop_info_tab">
                      
                      <div class="tab-pane fade show active" id="Additional-info" role="tabpanel" aria-labelledby="Additional-info-tab">
                        <table class="table table-bordered"
                        v-if="DBitems.options.options.length!=0"
                        
                        >
                            <tr v-for="(item, index) in DBitems.options.options"
                            :key="index"
                            v-if="item.type=='select'"
                             >
                                <td>{{ item.name }}</td>
                                <td>

                                  <span  v-for="(item1, index)
                                   in item.option_values.select_values"
                                   :key="index"
                                   >
                                   {{ item1.name}} ,
                                    
                                  </span>
                                </td>
                            </tr>
                            
                        </table>

                        <div v-else>
                          {{ this.empty_msg }}
                          </div>
                      </div>
                      <div class="tab-pane fade" id="Reviews" role="tabpanel" aria-labelledby="Reviews-tab">
                        <div class="comments"
                      
                        
                        >
                            <h5 v-if="this.DBitems.reviews.length!=0"  class="product_tab_title">{{ this.DBitems.count_rate }} {{ $t('Review For') }} 
                              <span>{{ this.DBitems.product.name }}</span></h5>
                              <ul   v-if="this.DBitems.reviews.length!=0" class="list_none comment_list mt-4">
                                  <li  v-for="(item, index) in this.DBitems.reviews"
                                    :key="index">
                                      <div class="comment_img">
                                          <img :src="item.image"  />
                                      </div>
                                      <div class="comment_block">
                                          <div class="rating_wrap">
                                            <div class="d-flex flex-row justify-content-center">
                  <span v-for="n in item.rate" :key="n">
                    <i class="fa fa-star rate"></i>
                  </span>
                  <div class="d-flex flex-row justify-content-center">
                    <span v-for="n in 5 - item.rate" :key="n">
                      <i class="fa fa-star-o rate" style=""></i>
                    </span>
                    <span>({{ item.rate }})</span>
                  </div>
                </div>
                                          </div>
                                          <p class="customer_meta">
                                              <span class="review_author">{{ item.name }}</span>
                                              <span class="comment-date">{{ item.date }}</span>
                                          </p>
                                          <div class="description">
                                              <p>{{ item.msg }}</p>
                                          </div>
                                      </div>
                                  </li>
                                 
                              </ul>
                              <div v-else>
                          {{ this.empty_msg }}
                          </div>
                        </div>
                      
                          <div  v-if="this.DBitems.product.is_rate" class="review_form field_form">
                              <h5>{{ $t('Add a review') }}</h5>
                           
                         
                              <add-evaluation></add-evaluation>
                          </div>
                      </div>
                </div>
              </div>
          </div>
      </div>
      <div class="row">
        <div class="col-12">
            <div class="small_divider"></div>
            <div class="divider"></div>
              <div class="medium_divider"></div>
          </div>
      </div>
      <div  v-if="this.DBitems.similar_products.length != 0" class="row">
        <div class="col-12">
            <div class="heading_s1">
                <h3>{{ $t("similarProducts") }}</h3>
              </div>
            <div class="row" data-margin="20" data-responsive='{"0":{"items": "1"}, "481":{"items": "2"}, "768":{"items": "3"}, "1199":{"items": "4"}}'>
                <div class=" item col-lg-3 col-md-4 col-6" 
                v-for="(item, index) in this.DBitems.similar_products"
          :key="index"
                >
                <product_list
                :name="item.name"
              :description="item.description"
              :is_favorit="item.is_favorit"
              :image="item.image"
              :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
              :rate="item.rate"
              :count_view="item.count_views"
              :is_option="item.is_option"
              :is_offer="item.is_offer"
               :unit="item.unit"
               :new="item.is_new"
               :fetuered="item.is_fetured"
               :discount="item.discount"
               :qnt="item.qnt"
               :min_qnt="item.min_qnt"
               :max_qnt="item.max_qnt"
                      >
                          </product_list>
                  </div>
                  
              </div>
          </div>
      </div>
  </div>
</div>

<div
      v-if="validateMessage != ''"
      style="
        position: fixed;
        z-index: 100;
        width: 300px;
        bottom: 50px;
        left: 50px;
        border-radius: 5px;
        background-color: #fff;
        border: 2px solid var(--primary-color);
        text-align: center;
        padding-top: 5px;
        color:red
      "
    >
      <p>{{ validateMessage }}</p>

     
     
  </div>
<!-- END SECTION SHOP -->
</section>
</template>

<script>
import product_list from "../components/product_list2.vue";
import AddEvaluation from "../components/AddEvaluation.vue";
import VueSlickCarousel from 'vue-slick'

import Lingallery from "lingallery";

import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";
import imageZoom from 'vue-image-zoomer';

export default {
components: { Vodal, product_list, Lingallery,AddEvaluation ,VueSlickCarousel,imageZoom},

data: function() {
  return {
    unit:this.$store.state.unit,
    counterVal: 1,
    loginVodal: false,
    loginMassage: "",
    old_qnt:0,
    currentId: null,
    width: 600,
    height: 350,
    images:[],
    new_images:[],
    count_images:0,
    DBitems: null,
    loading: false,
    first_image:null,
    options: [],
    selectedOptionArray: [],
    price: 0,
    quanity: 0,
    max_quanity: 0,
    min_quanity: 0,
    Firstquanity: 0,
    finalOptions: 0,
    checked: [],
    validateMessage: "",
    selectOption: false,
    langChange:"",
        rtl:true,
    fav: false,
    offer_price: 0,
    note: "",
    empty_msg:"Empty ...",
    addeval: false,
    settings:
        {

  "arrows": true,
  "dots": false,
  "infinite": true,
  "slidesToShow": 4,
  "slidesToScroll": 1,
  "autoplay": false,
  "speed": 2000,
  "autoplaySpeed": 2000,
  "cssEase": "linear",
  
  "responsive": [
    {
      "breakpoint": 1024,
      "settings": {
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "infinite": true,
      }
    },

    {
      "breakpoint": 900,
      "settings": {
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "infinite": true,
      }
    },
    {
      "breakpoint": 600,
      "settings": {
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "initialSlide": 1
      }
    },

    {
      "breakpoint": 500,
      "settings": {
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "initialSlide": 1
      }
    },
    {
      "breakpoint": 480,
      "settings": {
        "slidesToShow": 4,
        "slidesToScroll": 1
      }
    },
    {
      "breakpoint": 300,
      "settings": {
        "slidesToShow": 4,
        "slidesToScroll": 1
      }
    }
  ],
 "centerMode": true,
   "centerPadding": "20px",
   "centerMargin": "20px",
   "focusOnSelect": false,
   rtl:true
  
  
  // "variableWidth": true
},

settings_ar:
        {

  "arrows": true,
  "dots": false,
  "infinite": true,
  "slidesToShow": 4,
  "slidesToScroll": 1,
  "autoplay": false,
  "speed": 2000,
  "autoplaySpeed": 2000,
  "cssEase": "linear",
  
  "responsive": [
    {
      "breakpoint": 1024,
      "settings": {
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "infinite": true,
      }
    },

    {
      "breakpoint": 900,
      "settings": {
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "infinite": true,
      }
    },
    {
      "breakpoint": 600,
      "settings": {
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "initialSlide": 1
      }
    },

    {
      "breakpoint": 500,
      "settings": {
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "initialSlide": 1
      }
    },
    {
      "breakpoint": 480,
      "settings": {
        "slidesToShow": 4,
        "slidesToScroll": 1
      }
    },
    {
      "breakpoint": 300,
      "settings": {
        "slidesToShow": 4,
        "slidesToScroll": 1
      }
    }
  ],
 "centerMode": true,
   "centerPadding": "20px",
   "centerMargin": "20px",
   "focusOnSelect": false,
   rtl:false
  
  
  // "variableWidth": true
},
  };
},
created() {
  if(this.$i18n.locale=="ar")
  {
    this.rtl=false
    this.empty_msg='لايوجد....';
    this.unit=this.$store.state.unitAR;
  }
  else
  {
     this.rtl=true
    this.empty_msg="Empty"
    this.unit=this.$store.state.unit;
  }
  this.loading = true;
  this.axios
    .get(
      this.$store.state.base_api_url +
        this.$store.storeName +
        "/product/theme5/" +
        this.$route.params.id,
      {
        headers: {
          "Accept-Language": this.$i18n.locale,
          Authorization:
            "Bearer " + this.$localStorage.get(this.$store.storeName)
        }
      }
    )
    .then(response => {
      this.checked = [];
      this.DBitems = response.data.data;
      this.images=response.data.data.images.images;
      this.new_images=[];
      this.new_images=this.images;
      this.count_images=response.data.data.images.images.length;
      this.fav = this.DBitems.product.is_favorit;
      if(this.DBitems.product.seo_title)
      document.title = this.DBitems.product.seo_title;
    else
    document.title = this.DBitems.product.name;
      if(this.DBitems.product.seo_description)
      document.getElementById("description_meta").content=this.DBitems.product.seo_description;
      if(this.DBitems.product.seo_keyword)
      document.getElementById("keywords_meta").content=this.DBitems.product.seo_keyword;

      //og image
      document.querySelectorAll('meta[property=og\\:image]')[0]
      .setAttribute('content', this.images[0].src)
      document.querySelectorAll('meta[property=og\\:description]')[0]
      .setAttribute('content', this.DBitems.product.sub_description)

      document.querySelectorAll('meta[property=og\\:title]')[0]
      .setAttribute('content', this.DBitems.product.name)

      var option1 = this.DBitems.options.options;
      var option2 = this.DBitems.options_check.options;
      for (var i = 0; i < option1.length; i++) {
        //if option  not empty
        if (option1[i].option_values.select_values != null) {
          // if select
          var element = {};
          element.value_id = null;//option1[i].option_values.select_values[0].id;
          element.option_id = option1[i].id;
          this.options.push(element);
          ///console.log(this.options.length +"iam theeeeeeeeeeeeeeer")
        } 
        }
        
        for (var i = 0; i < option2.length; i++) 
        {
          // if checkbox
          var checked = {};
          checked.id = option2[i].id;
          checked.value = false;
          this.checked.push(checked);
          //console.log(this.checked)
        }
      
      //console.log(this.options)
    })
    .then(() => {
      this.loading = false;
      if (this.options.length != 0) {
        this.selectOption = true;
      
      } 
      else if (this.options.length == 0) {
        // if we not found select options
        //console.log("hiiiiiiiiii created" + this.DBitems.product.quanity);
        this.price = this.DBitems.product.sale_price;
        this.offer_price = this.DBitems.product.offer_price;
        this.quanity = this.DBitems.product.quantity;
        this.max_quanity = this.DBitems.product.quantity;
        this.min_quanity=1;
        if(this.DBitems.product.min_qnt)
        this.min_quanity = this.DBitems.product.min_qnt;
        if(this.DBitems.product.max_qnt>0)
        this.max_quanity = this.DBitems.product.max_qnt;
        this.Firstquanity = this.DBitems.product.quantity;

        /////////////nottttttttttttttttttteeeeeeeeeeeeeeeeeeee////////////
        var checked_true = [];
        for (var i = 0; i < this.checked.length; i++) {
          if (this.checked[i].value == true) {
            checked_true.push(this.checked[i].id);
          }
        }
        let S_name_full =
          this.$store.storeName +
          "/" +
          this.$route.params.id +
          this.finalOptions +
          checked_true;

        let S_name2 =
          this.$store.storeName +
          "/" +
          this.$route.params.id +
          this.finalOptions;
        console.log("S_name " + S_name2);

        let qun = this.quanity;
        let min_qun = this.min_quanity;
        let max_qun = this.max_quanity;
        let AllCookies = this.$cookies.keys();
        for (let i = 0; i < AllCookies.length; i++) {
          if (AllCookies[i].includes(S_name2)) {
            // if (AllCookies[i] == S_name_full) {
            //  console.log("hii i am there ");
            //} else {
              this.old_qnt=this.$cookies.get(AllCookies[i]).quantity;
            qun = qun - this.$cookies.get(AllCookies[i]).quantity;
           min_qun = min_qun- this.$cookies.get(AllCookies[i]).quantity;
           if(min_qun<0)
           {
            min_qun=1;
           }
           if(max_qun!=-1)
           max_qun = max_qun- this.$cookies.get(AllCookies[i]).quantity;
           if(max_qun==-1)
           {
            max_qun=-1;
           } 

           else if(max_qun<=0&&max-qun!=-1)
           {
            max_qun=0;
           } 
           
            console.log(
              this.$cookies.get(AllCookies[i]).quantity + " quuuuunb" + qun
            );
            // }
          }
        }
        this.quanity = qun;
        this.min_quanity = min_qun;
        this.max_quanity = max_qun;
      
      }
      if(this.min_quanity>0)
       {
        this.counterVal=this.min_quanity;
        this.price *= this.counterVal;
        this.offer_price *= this.counterVal;
       }
    });


    //counter value 

  //document.getelementById('image0').style="border:1px solid --var(--primary-color) !important"
       
    //end counter value
},
methods: {
  reInit() {
            // Helpful if you have to deal with v-for to update dynamic lists
            this.$nextTick(() => {
                this.$refs.slick.reSlick();
            });
        },
  zoom_image()
  {
    var image = document.getelementById('#product_img');
    this.zoomActive = !this.zoomActive;
	if(this.zoomActive) {
		if ((image).length > 0){
			(image).elevateZoom({
				cursor: "crosshair",
				easing : true, 
				gallery:'pr_item_gallery',
				zoomType: "inner",
				galleryActiveClass: "active"
			}); 
		}
	}
  },
  next_product() {
            this.$refs.slick.next();
        },

        prev_product() {
            this.$refs.slick.prev();
        },
        
  change_select(id,option)
  {
    console.log('77777777777')
    var values=document.getElementsByClassName("span_value"+option)
    var i=0;
      for(i=0;i<values.length;i++)
      {
           var element=values[i];
           if(element.classList.contains( "active" ))
           {
            element.classList.remove("active");
           }
      }
      //
      var element=document.getElementById('span'+id);
      element.classList.add( "active" );
  },
  incrementClick() {
  

    if (this.quanity > 0) {
      console.log("*************** max qnt   "+this.max_quanity+" ");
      if (this.counterVal < this.quanity&&this.counterVal < this.max_quanity) {
        ++this.counterVal;
        this.price = this.price / (this.counterVal - 1) + this.price;
        if (this.offer_price>0) {
          this.offer_price =
            this.offer_price / (this.counterVal - 1) + this.offer_price;
          /*   this.offer_price =
            this.price - (this.DBitems.product.discount / 100) * this.price;*/
        }
      }
    } else {
      ++this.counterVal;
      this.price = this.price / (this.counterVal - 1) + this.price;
      if (this.offer_price>0) {
        this.offer_price =
          this.offer_price / (this.counterVal - 1) + this.offer_price;
        /* this.offer_price =
          this.price - (this.DBitems.product.discount / 100) * this.price;*/
      }
    }
    //}
  },
  decrementClick() {
    if (this.counterVal > 1&&this.counterVal >this.min_quanity) {
      --this.counterVal;
      //this.price = this.price/2
      this.price = this.price - this.price / (this.counterVal + 1);
      if (this.offer_price>0) {
        this.offer_price =
          this.offer_price - this.offer_price / (this.counterVal + 1);
        /*    this.offer_price =
          this.price - (this.DBitems.product.discount / 100) * this.price;*/
      }
    }
  },
  updatedata() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/product/theme5/" +
          this.$route.params.id,
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.DBitems = response.data.data;
        if(this.DBitems.product.seo_title)
      document.title = this.DBitems.product.seo_title;
      if(this.DBitems.product.seo_description)
      document.getElementById("description_meta").content=this.DBitems.product.seo_description;
      if(this.DBitems.product.seo_keyword)
      document.getElementById("keywords_meta").content=this.DBitems.product.seo_keyword;
        this.images=response.data.data.images.images;
        this.new_images=[];
      this.new_images=this.images;
        // this.counterVal = 1;
        //   if(this.DBitems.min_quanity>1)
        //   this.counterVal = this.DBitems.min_quanity;
        this.fav = this.DBitems.product.is_favorit;
      })
      .then(() => (this.loading = false));

    //this.$refs.childref.update(this.categoryID)
    this.$store.state.langchange = false;
  },
  upadateprice(event, option_id) {
    for (var i = 0; i < this.options.length; i++) {
      if (this.options[i].option_id == option_id) {
       { console.log('gggg ',this.options[i].option_id,'vvvvvvv ',option_id)
        this.options[i].value_id = event.target.value;}
        if (this.selectedOptionArray.length < this.options.length) {
          //if (!this.selectedOptionArray.includes(this.options[i].option_id)) {
          this.selectedOptionArray.push(this.options[i].option_id);
          //}
        }
      }
    }
    console.log(this.options.length  +" hii " +this.selectedOptionArray.length)
    console.log('options selected : ',this.options)

    if (this.options.length == this.selectedOptionArray.length) {
      this.selectOption = false;
     
      var options = this.options;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/product_price/" +
            this.$route.params.id,
          {
            options
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.price = response.data.data.price;
          this.offer_price = response.data.data.new_price;
          this.quanity = response.data.data.quanity;
          this.max_quanity = response.data.data.quanity;
          this.min_quanity=1;
          if(response.data.data.min_qnt>1)
          this.min_quanity = response.data.data.min_qnt;
          if(response.data.data.max_qnt>0)
          this.max_quanity = response.data.data.max_qnt;
          this.Firstquanity = response.data.data.quanity;
          this.finalOptions = response.data.data.final_option;
          for (var i = 0; i < this.checked.length; i++) {
            this.checked[i].value = false;
          }

          /////////////nottttttttttttttttttteeeeeeeeeeeeeeeeeeee////////////
          // this.counterVal = 1;
          // if(this.min_quanity>1)
          // this.counterVal = this.min_quanity;
          if(response.data.data.image)
          {
         //   this.images=[];
        // this.images=[];
         this.images=this.DBitems.images.images;

               if(this.images.length>this.count_images)
               this.images.shift();
          //console.log('count old image',this.old_images.length,"  count images  ",this.images.length)
            // this.images.unshift(
             
            //   {
            //     id:'0',
            //    src: response.data.data.image,
            //     thumbnail: response.data.data.image
            //   }
             
            // )

            this.first_image=response.data.data.image;
            this.new_images=this.images;
           // this.images=[];
            //this.images=this.new_images;
          }
          this.note = "";
          var checked_true = [];
          for (var i = 0; i < this.checked.length; i++) {
            if (this.checked[i].value == true) {
              checked_true.push(this.checked[i].id);
            }
          }
          let S_name_full =
            this.$store.storeName +
            "/" +
            this.$route.params.id +
            this.finalOptions +
            checked_true;

          let S_name2 =
            this.$store.storeName +
            "/" +
            this.$route.params.id +
            this.finalOptions;
          console.log("S_name " + S_name2);

          let qun = this.quanity;
          let min_qun = this.min_quanity;
          let max_qun = this.max_quanity;

          let AllCookies = this.$cookies.keys();
          for (let i = 0; i < AllCookies.length; i++) {
            if (AllCookies[i].includes(S_name2)) {
              // if (AllCookies[i] == S_name_full) {
              //  console.log("hii i am there ");
              //} else {
                this.old_qnt=this.$cookies.get(AllCookies[i]).quantity;
              qun = qun - this.$cookies.get(AllCookies[i]).quantity;
              min_qun = min_qun- this.$cookies.get(AllCookies[i]).quantity;
           if(min_qun<0)
           {
            min_qun=1;
           }
           if(this.max_qun!=-1)
           max_qun = max_qun- this.$cookies.get(AllCookies[i]).quantity;
           if(max_qun==0&&max_qun!=-1)
           {
            max_qun=0;
           } 

              console.log(
                this.$cookies.get(AllCookies[i]).quantity + " quuuuunx " + qun
              );
              // }
            }
          }
          this.quanity = qun;
          this.max_quanity = max_qun;
          this.min_quanity = min_qun;
          

//test
if(this.min_quanity>0)
       {
        this.counterVal=this.min_quanity;
        this.price *= this.counterVal;
        this.offer_price *= this.counterVal;
       }
       else
            {
        this.counterVal=1;
        this.price *= this.counterVal;
        this.offer_price *= this.counterVal;
            } 


        });
    }
  },
  updateall() {
    this.loading = true;
    this.selectOption = false;
    this.counterVal = 1;
    this.options = [];
    this.selectedOptionArray = [];
    this.price = 0;
    this.offer_price = 0;
    this.quanity = 0;
    this.min_quanity = 0;
    this.max_quanity = 0;
    this.Firstquanity = 0;
    this.finalOptions = 0;
    this.checked = [];
    //this.validateMessage = "";
   this.new_images=[];
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/product/theme5/" +
          this.$route.params.id,
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.checked = [];
        this.DBitems = response.data.data;
        if(this.DBitems.product.seo_title)
      document.title = this.DBitems.product.seo_title;
      if(this.DBitems.product.seo_description)
      document.getElementById("description_meta").content=this.DBitems.product.seo_description;
      if(this.DBitems.product.seo_keyword)
      document.getElementById("keywords_meta").content=this.DBitems.product.seo_keyword;
        this.images=response.data.data.images.images;
      this.new_images=this.images;
        this.fav = this.DBitems.product.is_favorit;
        var option1 = this.DBitems.options.options;
        var option2 = this.DBitems.options_check.options;
        for (var i = 0; i < option1.length; i++) {
          //if option  not empty
          if (option1[i].option_values.select_values != null) {
            // if select
            var element = {};
            element.value_id = option1[i].option_values.select_values[0].id;
            element.option_id = option1[i].id;
            this.options.push(element);
          } 
          
          
          for (var i = 0; i < option2.length; i++)
          {
            // if checkbox
            var checked = {};
            checked.id = option2[i].id;
            checked.value = false;
            this.checked.push(checked);
            //console.log(this.checked)
          }
        }
        //console.log(this.options)
      })
      .then(() => {
        this.loading = false;
        if (this.options.length != 0) {
          this.selectOption = true;
         
        } else if (this.options.length == 0) {
          // if we not found select options
          console.log(
            "hiiiiiiiiii updateall" + this.DBitems.product.sale_price
          );
          this.price = this.DBitems.product.sale_price;
          this.offer_price = this.DBitems.product.offer_price;
          this.min_quanity=1;
          if(this.DBitems.product.min_qnt)
          this.min_quanity = this.DBitems.product.min_qnt;

          this.max_quanity = this.DBitems.product.quantity;
          if(this.DBitems.product.max_qnt>0)
          this.max_quanity = this.DBitems.product.max_qnt;

          this.quanity = this.DBitems.product.quantity;
          this.Firstquanity = this.DBitems.product.quantity;

          /////////////nottttttttttttttttttteeeeeeeeeeeeeeeeeeee////////////
          var checked_true = [];
          for (var i = 0; i < this.checked.length; i++) {
            if (this.checked[i].value == true) {
              checked_true.push(this.checked[i].id);
            }
          }
          let S_name_full =
            this.$store.storeName +
            "/" +
            this.$route.params.id +
            this.finalOptions +
            checked_true;

          let S_name2 =
            this.$store.storeName +
            "/" +
            this.$route.params.id +
            this.finalOptions;
          console.log("S_name " + S_name2);

          let qun = this.quanity;
          let min_qun = this.min_quanity;
          let max_qun = this.max_quanity;

          let AllCookies = this.$cookies.keys();
          for (let i = 0; i < AllCookies.length; i++) {
          if (AllCookies[i].includes(S_name2)) {
              
          console.log(
                 " qnt " + qun
                +'<br> qnt_cookies '+this.$cookies.get(AllCookies[i]).quantity
                +"<br> max_qnt "+max_qun
                 +"<br> max_qnt cookies "+max_qun
              );

                this.old_qnt=this.$cookies.get(AllCookies[i]).quantity;
              qun = qun - this.$cookies.get(AllCookies[i]).quantity;
              min_qun = min_qun- this.$cookies.get(AllCookies[i]).quantity;
           if(min_qun<0)
           {
            min_qun=1;
           }
           if(this.max_qun>0)
           max_qun = max_qun- this.$cookies.get(AllCookies[i]).quantity;
           if(max_qun==0&&(max_qun!=-1))
           {
            max_qun=0;
           }  
              //this.$cookies.get(AllCookies[i]).quantity;
              console.log(
                this.$cookies.get(AllCookies[i]).quantity + " quuuuuny  " + qun
                +"max_qnt "+max_qun
              );
              // }
            }
            
          }
          this.quanity = qun;
          this.min_quanity = min_qun;
          this.max_quanity = max_qun;

          if(this.min_quanity>0)
       {
        this.counterVal=this.min_quanity;
        this.price *= this.counterVal;
        this.offer_price *= this.counterVal;
       }
        }
      });
  },
  addToCart() {

    document.getElementById("#addToCart").style.display = "none";
    var checked_true = [];
    for (var i = 0; i < this.checked.length; i++) {
      if (this.checked[i].value == true) {
        checked_true.push(this.checked[i].id);
      }
    }
    this.axios
      .post(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/check_cart_item",
        {
          product_id: this.$route.params.id,
          total_price: this.price,
          offer_price: this.offer_price,
          quantity: this.counterVal,
          old_qnt:this.old_qnt,
          final_option: this.finalOptions,
          option_check: checked_true
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        }
      )
      .then(response => {
        
        let product = null;
        if (response.data.code == "1") {
          // let products = new Map;
          let S_name1 =
            this.$store.storeName +
            "/" +
            this.$route.params.id +
            this.finalOptions +
            checked_true;
          if (this.$cookies.isKey(S_name1)) {
            /*console.log(
              "this.price " +
                this.price +
                "this.$cookies.get( S_name1 ).total_price" +
                this.$cookies.get(S_name1).total_price
            );*/
            product = {
              product_id: this.$route.params.id,
              total_price:
                this.price + this.$cookies.get(S_name1).total_price,
              offer_price:
                this.offer_price + this.$cookies.get(S_name1).offer_price,
              quantity: Number(this.counterVal) + Number(this.$cookies.get(S_name1).quantity),
              quantityAll: this.$cookies.get(S_name1).quantityAll,
              max_quantity: this.$cookies.get(S_name1).max_quantity,
              min_quantity: this.$cookies.get(S_name1).min_quantity,
              final_option: this.finalOptions,
              option_check: checked_true,
              discount: this.DBitems.product.discount,
              note: this.note + " " + this.$cookies.get(S_name1).note
            };
          } else {
            console.log("hlooooooooooo ::::::::; " + this.Firstquanity);
            product = {
              product_id: this.$route.params.id,
              total_price: this.price,
              offer_price: this.offer_price,
              quantity: Number(this.counterVal),
              quantityAll: this.Firstquanity,
              min_quantity: this.min_quanity,
              max_quantity: this.max_quanity,
              final_option: this.finalOptions,
              option_check: checked_true,
              discount: this.DBitems.product.discount,
              note: this.note
            };
          }
          //check if item is exist on cart or not
          //if (this.$cookies.isKey(this.$store.storeName+this.$route.params.id)) {
          // this.$cookies.remove('storeName')
          // products = this.$cookies.get(this.$store.storeName);
          //}
          //products.set(this.$route.params.id , product);
          let S_name = this.$store.storeName + "/";

          if (
            !this.$cookies.isKey(
              S_name +
                product.product_id +
                product.final_option +
                product.option_check
            )
          ) {
            this.$store.state.cartCounter = this.$store.state.cartCounter + 1;
           
          }

          this.$cookies.set(
            S_name +
              product.product_id +
              product.final_option +
              product.option_check,
            product
          );
          console.log(this.$cookies.keys());
        }
        this.validateMessage = response.data.message;
       
        this.updateall();
        this.$store.state.update_cart_list=true;

        document.getElementById("#addToCart").style.display = "block";
        setTimeout(() => {
          // this.updateall();
          this.validateMessage = "";
        }, 3000);
      });

  },
  zoom(e){
    console.log('zoooom');
  var zoomer = e.currentTarget;
  e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
  e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX
  var x = offsetX/zoomer.offsetWidth*100
  var y = offsetY/zoomer.offsetHeight*100
  zoomer.style.backgroundPosition = x + '% ' + y + '%';
},
  addEvalu() {
    if (
      this.$localStorage.get(this.$store.storeName) != null &&
      this.DBitems.product.is_rate == true
    ) {
      this.addeval = true;
    } else if (
      this.$localStorage.get(this.$store.storeName) != null &&
      this.DBitems.product.is_rate == false
    ) {
      if (this.$i18n.locale == "en") {
        document
          .getElementsByClassName("hovertext")[0]
          .setAttribute(
            "data-hover",
            "You must purchase the product to add a review"
          );
      } else {
        document
          .getElementsByClassName("hovertext")[0]
          .setAttribute("data-hover", "يجب أن تشتري المنتج لإضافة تقييم");
      }
    } else {
      if (this.$i18n.locale == "en") {
        document
          .getElementsByClassName("hovertext")[0]
          .setAttribute("data-hover", "login to add evaluation");
      } else {
        document
          .getElementsByClassName("hovertext")[0]
          .setAttribute("data-hover", "سجل دخول لإضافة تقييم");
      }
    }
  },
  changeFav() {
    if (this.$localStorage.get(this.$store.storeName) != null) {
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/favourite_product_change/" +
            this.$route.params.id,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.fav = !this.fav;
          }
        });
    } else {
      if (this.$i18n.locale == "en") {
        this.loginMassage = "please Login";
        setTimeout(() => {
          this.loginMassage = "";
        }, 3000);
      } else {
        this.loginMassage = "يجب تسجيل الدخول";
        setTimeout(() => {
          this.loginMassage = "";
        }, 3000);
      }
    }
  },
  changeCheckbox(item, val) {
    if (val) {
      this.price = this.price + item.price * this.counterVal;
      if (this.offer_price != 0) {
        this.offer_price = this.offer_price + item.price * this.counterVal;
      }
    } else {
      /*(this.offer_price =
          this.price - (this.DBitems.product.discount / 100) * this.price))*/
      this.price = this.price - item.price * this.counterVal;
      if (this.offer_price != 0) {
        this.offer_price = this.offer_price - item.price * this.counterVal;
      }
      /*(this.offer_price =
          this.price - (this.DBitems.product.discount / 100) * this.price));*/
    }
  }
  ,
  change_image(index,src){
console.log('src ',src)
    for(var i=0;i<this.images.length;i++)
    {
       document.getElementById('image'+i).style="border:1px solid lightgrey !important";
    }
    document.getElementById('image'+index).style="border:1px solid var(--primary-color) !important";
   //document.getElementById('product_img').src=src;
     this.first_image=src;
    document.getElementsByClassName("img-fluid")[0].src=src

  }
},
watch: {
  "$route.params": {
    handler(newValue) {
      if(this.$i18n.locale=="ar")
  {
    this.rtl=false
    this.empty_msg='لايوجد....';
    this.unit=this.$store.state.unitAR;
  }
  else
  {
     this.rtl=true
    this.empty_msg="Empty"
    this.unit=this.$store.state.unit;
  }
  this.loading = true;
  this.axios
    .get(
      this.$store.state.base_api_url +
        this.$store.storeName +
        "/product/theme5/" +
        this.$route.params.id,
      {
        headers: {
          "Accept-Language": this.$i18n.locale,
          Authorization:
            "Bearer " + this.$localStorage.get(this.$store.storeName)
        }
      }
    )
    .then(response => {
      this.checked = [];
      this.DBitems = response.data.data;
      if(this.DBitems.product.seo_title)
      document.title = this.DBitems.product.seo_title;
      if(this.DBitems.product.seo_description)
      document.getElementById("description_meta").content=this.DBitems.product.seo_description;
      if(this.DBitems.product.seo_keyword)
      document.getElementById("keywords_meta").content=this.DBitems.product.seo_keyword;
      this.images=response.data.data.images.images;
      this.new_images=response.data.data.images.images;
      this.first_image=this.images[0].src;
      this.count_images=response.data.data.images.images.length;
      this.fav = this.DBitems.product.is_favorit;
      
      var option1 = this.DBitems.options.options;
      var option2 = this.DBitems.options_check.options;
      for (var i = 0; i < option1.length; i++) {
        //if option  not empty
        if (option1[i].option_values.select_values != null) {
          // if select
          var element = {};
          element.value_id = option1[i].option_values.select_values[0].id;
          element.option_id = option1[i].id;
          this.options.push(element);
          ///console.log(this.options.length +"iam theeeeeeeeeeeeeeer")
        } 
        
        for (var i = 0; i < option2.length; i++) 
        {
          // if checkbox
          var checked = {};
          checked.id = option2[i].id;
          checked.value = false;
          this.checked.push(checked);
          //console.log(this.checked)
        }
      }
      //console.log(this.options)
    })
    .then(() => {
      this.loading = false;
      if (this.options.length != 0) {
        this.selectOption = true;
      
      } 
      else if (this.options.length == 0) {
        // if we not found select options
        //console.log("hiiiiiiiiii created" + this.DBitems.product.quanity);
        this.price = this.DBitems.product.sale_price;
        this.offer_price = this.DBitems.product.offer_price;
        this.quanity = this.DBitems.product.quantity;
        this.max_quanity = this.DBitems.product.quantity;
        this.min_quanity=1;
        if(this.DBitems.product.min_qnt)
        this.min_quanity = this.DBitems.product.min_qnt;
        if(this.DBitems.product.max_qnt>0)
        this.max_quanity = this.DBitems.product.max_qnt;
        this.Firstquanity = this.DBitems.product.quantity;

        /////////////nottttttttttttttttttteeeeeeeeeeeeeeeeeeee////////////
        var checked_true = [];
        for (var i = 0; i < this.checked.length; i++) {
          if (this.checked[i].value == true) {
            checked_true.push(this.checked[i].id);
          }
        }
        let S_name_full =
          this.$store.storeName +
          "/" +
          this.$route.params.id +
          this.finalOptions +
          checked_true;

        let S_name2 =
          this.$store.storeName +
          "/" +
          this.$route.params.id +
          this.finalOptions;
        console.log("S_name " + S_name2);

        let qun = this.quanity;
        let min_qun = this.min_quanity;
        let max_qun = this.max_quanity;
        let AllCookies = this.$cookies.keys();
        for (let i = 0; i < AllCookies.length; i++) {
          if (AllCookies[i].includes(S_name2)) {
            // if (AllCookies[i] == S_name_full) {
            //  console.log("hii i am there ");
            //} else {
              this.old_qnt=this.$cookies.get(AllCookies[i]).quantity;
            qun = qun - this.$cookies.get(AllCookies[i]).quantity;
           min_qun = min_qun- this.$cookies.get(AllCookies[i]).quantity;
           if(min_qun<0)
           {
            min_qun=1;
           }
           if( max_qun!=-1)
           max_qun = max_qun- this.$cookies.get(AllCookies[i]).quantity;
           if(max_qun==-1)
           {
            max_qun=-1;
           } 
           else if(max_qun<=0&& max_qun!=-1)
           {
            max_qun=0;
           } 
            console.log(
              this.$cookies.get(AllCookies[i]).quantity + " quuuuunb" + qun
            );
            // }
          }
        }
        this.quanity = qun;
        this.min_quanity = min_qun;
        this.max_quanity = max_qun;
      
      }
      if(this.min_quanity>0)
       {
        this.counterVal=this.min_quanity;
        this.price *= this.counterVal;
        this.offer_price *= this.counterVal;
       }
    });


    //counter value 

   
       
    //end counter value
    },
    immediate: true
  }
,
langChange()
  {
    if(this.$i18n.locale=="ar")
  {
    this.rtl=false
    this.empty_msg='لايوجد....';
    this.unit=this.$store.state.unitAR;
  }
  else
  {
     this.rtl=true
    this.empty_msg="Empty"
    this.unit=this.$store.state.unit;
  }
  this.loading = true;
  this.axios
    .get(
      this.$store.state.base_api_url +
        this.$store.storeName +
        "/product/theme5/" +
        this.$route.params.id,
      {
        headers: {
          "Accept-Language": this.$i18n.locale,
          Authorization:
            "Bearer " + this.$localStorage.get(this.$store.storeName)
        }
      }
    )
    .then(response => {
      this.checked = [];
      this.DBitems = response.data.data;
      this.images=response.data.data.images.images;
      this.first_image=this.images[0].src;
      this.count_images=response.data.data.images.images.length;
      this.fav = this.DBitems.product.is_favorit;
      
      var option1 = this.DBitems.options.options;
      var option2 = this.DBitems.options_check.options;
      for (var i = 0; i < option1.length; i++) {
        //if option  not empty
        if (option1[i].option_values.select_values != null) {
          // if select
          var element = {};
          element.value_id = option1[i].option_values.select_values[0].id;
          element.option_id = option1[i].id;
          this.options.push(element);
          ///console.log(this.options.length +"iam theeeeeeeeeeeeeeer")
        } 
        
        for (var i = 0; i < option2.length; i++) 
        {
          // if checkbox
          var checked = {};
          checked.id = option2[i].id;
          checked.value = false;
          this.checked.push(checked);
          //console.log(this.checked)
        }
      }
      //console.log(this.options)
    })
    .then(() => {
      this.loading = false;
      if (this.options.length != 0) {
        this.selectOption = true;
      
      } 
      else if (this.options.length == 0) {
        // if we not found select options
        //console.log("hiiiiiiiiii created" + this.DBitems.product.quanity);
        this.price = this.DBitems.product.sale_price;
        this.offer_price = this.DBitems.product.offer_price;
        this.quanity = this.DBitems.product.quantity;
        this.max_quanity = this.DBitems.product.quantity;
        this.min_quanity=1;
        if(this.DBitems.product.min_qnt)
        this.min_quanity = this.DBitems.product.min_qnt;
        if(this.DBitems.product.max_qnt>0)
        this.max_quanity = this.DBitems.product.max_qnt;
        this.Firstquanity = this.DBitems.product.quantity;

        /////////////nottttttttttttttttttteeeeeeeeeeeeeeeeeeee////////////
        var checked_true = [];
        for (var i = 0; i < this.checked.length; i++) {
          if (this.checked[i].value == true) {
            checked_true.push(this.checked[i].id);
          }
        }
        let S_name_full =
          this.$store.storeName +
          "/" +
          this.$route.params.id +
          this.finalOptions +
          checked_true;

        let S_name2 =
          this.$store.storeName +
          "/" +
          this.$route.params.id +
          this.finalOptions;
        console.log("S_name " + S_name2);

        let qun = this.quanity;
        let min_qun = this.min_quanity;
        let max_qun = this.max_quanity;
        let AllCookies = this.$cookies.keys();
        for (let i = 0; i < AllCookies.length; i++) {
          if (AllCookies[i].includes(S_name2)) {
            // if (AllCookies[i] == S_name_full) {
            //  console.log("hii i am there ");
            //} else {
              this.old_qnt=this.$cookies.get(AllCookies[i]).quantity;
            qun = qun - this.$cookies.get(AllCookies[i]).quantity;
           min_qun = min_qun- this.$cookies.get(AllCookies[i]).quantity;
           if(min_qun<0)
           {
            min_qun=1;
           }
           if(max_qun!=-1)
           max_qun = max_qun- this.$cookies.get(AllCookies[i]).quantity;
           if(max_qun==-1)
           {
            max_qun=-1;
           } 
           if(max_qun<=0&&max-qun!=-1)
           {
            max_qun=0;
           } 
            console.log(
              this.$cookies.get(AllCookies[i]).quantity + " quuuuunb" + qun
            );
            // }
          }
        }
        this.quanity = qun;
        this.min_quanity = min_qun;
        this.max_quanity = max_qun;
      
      }
      if(this.min_quanity>0)
       {
        this.counterVal=this.min_quanity;
        this.price *= this.counterVal;
        this.offer_price *= this.counterVal;
       }
    });


    //counter value 

   
       
    //end counter value
  }

},
updated() {
    this.langChange=this.$i18n.locale
  },
};
</script>
<style>
#image0{
  border:1px solid var(--primary-color) !important;
}
.product_rate_star::before {
  font-size: 12px;
  content: "\f006";
  top: 0;
  position: absolute;
  left: 0;
  font-weight: 900;
}
.product_size_switch span
{
  text-align: center;
  
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  margin:5px;

}
.product_size_switch span .active
{
background-color: var(--color-primary);
  color: white;
}

.lingallery figure img {
width:100%;
}

.lingallery figure img {
/* (A) DIMENSIONS */
width: 600px;
height: 360px;

/* (B) BACKGROUND IMAGE */
background-position: center;
background-size: cover;
}
.owl-carousel {
display: block !important;
}
.product_poup
{
width:100% !important;
max-width: 980px;
overflow-y: scroll;
}

#product_img img{
  
    /* width: 535px !important;
    height: 590px !important; */
  object-fit:cover;
  /* border: 1px solid lightgray; */
  padding: 2%;
  /* max-width:200% !important; */
}

.product_gallery_item.slick-slider .slick-slide
{
  /* margin-right:6px; */
}
img{
  /* max-width:200% !important; */
}
.products_image.slick-track
{
  height:180px;
  padding:2%;
}
.item_image
{
  min-height:124px
}

.product_gallery_item.slick-slider .slick-slide
{
  margin-left:2%;
}
.slick-slider {
    margin-left: 5%;
}
@media only screen and (min-width: 601px) and (max-width: 700px)
{

.product_img_box
{
  height:560px;
  width:500px
}
}

@media only screen and (max-width: 600px) and (min-width: 401px) 
{
  .product_img_box
{
  height:460px !important;
  width:400px !important
}
}


@media only screen and (max-width: 400px) and (min-width: 301px) 
{
  .product_img_box
{
  height:360px !important;
  width:300px !important
}
}


@media only screen and (max-width: 300px) 
{
  .product_img_box
{
  height:260px !important;
  width:200px !important;
}
}




@media only screen and (min-width: 1200px)
 {
  .item_image {
    width: 128px !important;
    height: 133px !important;
}
.product_gallery_item img {
    height: 124px;}
.product_img_box img
{
  
  width: 525px;
    /* height: 540px; */
    -o-object-fit: cover;
    object-fit: contain;
    /* border: 1px solid lightgray; */
    padding: 2%;
    /* max-width: 200% !important; */
    /* width: 100%; */
    /* height: 100%; */
    aspect-ratio: 9/10;
}



.product_gallery_item img {
    /* height: 126px; */
    
    border: none;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    aspect-ratio:1/1.2
}

.product_gallery_item a
{
  height: 135px;
    width: 128px;
    border:1px solid lightgray !important;
}
.item_image {
    width: 128px !important;
}
.product_img_box {
    height: 600px;
    width: 540px;
}
}
.slick-slider {
    margin-left: -4.5px;
}
.product_img_box {
    /* margin-right: 1.2%;
  
    margin-left: 1.2%; */
}
.image_next,.image_prev
{
top:90% !important;
}


.product_gallery_item img {
    border: none;
    width: 100%;
    object-fit: cover;
}
.item_image
{
max-width:126px;
}


.product_gallery_item img {
    /* height: 126px; */
    border: none;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    aspect-ratio:1/1;
}

.item_image {
    width: 126px !important;
}
.NoQuantity
{
  color:red;
}
.rtl .product-image .slick-track {
    display: flex !important;
    direction: rtl !important;
}
</style>
<template>

<section  id="section_login">
        <div class="container">
            <div class="row">

                <div class="col-lg-8 col-md-8 col-12">
                    <h3 class="color_font">{{$t('Plant Your Dream')}}</h3>
                    <h4>{{$t('Begin your Green Journey Now!')}}</h4>
                    <div v-if=" this.$store.state.auth==0">
                        <form @submit.prevent="sendEmail" @keydown="formEmail.onKeydown($event)">
                            <input class="input_all" 
                            type="email" 
                            autocomplete="false"
                            v-model="formEmail.email"
                           :placeholder="$t('Entertheemail')"
                            required
                           >
                            <br>
                            
                            
               
                    <button type="submit" 
                    class="butt_green  center_align login_but"
                     value="Sign Up " 
                     style="margin-top: 5%;">
                    {{ $t('continue') }}
                    </button>
                    <br>
                    <div v-if="validateMessage != ''" style="text-align: center; margin-top: 10px">
                   <span style="color: red; margin: 0">{{ validateMessage }}*</span>
                   </div>
                   <br>

                    <p style="font-size: 20px ; text-align: center;" >
                        {{ $t('Already have an account?') }}
                        <router-link to="/login"
                            class="color_font  "
                             style="font-size: 20px; ;   ">
                             {{$t('logIn')}}</router-link> 
                            </p>
                        </form>
                        <div v-if="loading" class="empty text-center center-text">
                 
                 <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                           <br>

                       </div>
                    </div>

                    <div v-else>
              <form  @submit.prevent="sendPhone" @keydown="formphone.onKeydown($event)">                           

                        <input class="input_all" 
                        type="text"
                        name="phone"
                        autocomplete="false"
                        v-model="formphone.basic_phone"
                        placeholder="xxxx xxxx"
                        required
                        :minlength=$store.state.country_length
                        :maxlength=$store.state.country_length
                        :pattern="'[0-9]{'+$store.state.country_length+'}'"
                        style="direction:ltr;text-align:center"
                           >
                           <span class="flag flag_profile">
                              <h2>{{ $store.state.country_code }}</h2>
                            <img :src="this.$store.state.country_flag" >
                           </span>
                            <br>
                            
                            
               
                    <button type="submit" class="butt_green login_but center_align"  style="margin-top: 5%;">
                      {{ $t('signup') }}
                      </button>
                      <br>
                    <div v-if="validateMessage != ''" style="text-align: center; margin-top: 10px">
                   <span style="color: red; margin: 0">{{ validateMessage }}*</span>
                   </div>
                    <br>
                    <p style="font-size: 20px ; text-align: center;" >{{ $t('Already have an account?') }} 
                      <router-link to="/login"
                            class="color_font  " style="font-size: 20px; ;   ">
                            {{$t('logIn')}}</router-link> 
                          </p>
                        </form>
                        <div v-if="loading" class="empty text-center center-text">
                 
                 <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                           <br>

                       </div>
                    </div>
                
                </div>


                <div class="col-lg-4 col-md-4 col-12">
                 <img src="/theme10/images/signup.webp"  class= "img_signup" />
                </div>

            </div>
        </div>


    </section>
</template>
<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import Form from "vform";

export default {
  components: { VuePhoneNumberInput },
  data: function() {
    return {
      isValid: false,
      partphone: null,
      loading:false,
      validateMessage: "",
      formphone: new Form({
        phone: null,
        basic_phone: null,
        
      }),
    
      formEmail: new Form({
        email: null,
        phone: null,
      })
    };
  },
  
 
  methods: {
    
    async sendEmail() {
      this.loading=true;
        this.$localStorage.set(
                  this.$store.register_email,
                  this.formEmail.email
                );
      await this.formEmail
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/genret_code/byemail",
          {
            email: this.formEmail.email,
            //country: this.form.country,
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.loading=false
          if (response.data.code == "-1") {
            console.log(response.data.code);
            this.validateMessage = response.data.message;
            setTimeout(() => {
              this.validateMessage = "";
            }, 3000);
          } else if (response.data.code == "1") {
            this.$router.push({
              name: "activateCode",
              params: {
                storeName: this.$store.storeName,
                email: this.formEmail.email,
                lang: this.$i18n.locale
              }
            });
            
          }
        });
     
    },
    async sendPhone() {
      this.loading=true;
      this.formphone.phone=this.$store.state.country_code+this.formphone.basic_phone
      //if (this.isValid == true) {
        this.$localStorage.set(
                  this.$store.register_phone,
                  this.formphone.basic_phone
                );

      await this.formphone
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/genret_code",
          {
            phone: this.formphone.phone,
            //country: this.form.country,
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.loading=false;
          if (response.data.code == "-1") {
            this.formphone.phone=null;
            console.log(response.data.code);
            this.validateMessage = response.data.message;
            setTimeout(() => {
              this.validateMessage = "";
            }, 3000);
          } else if (response.data.code == "1") {
            this.$router.push({
              name: "activateCode",
              params: {
                storeName: this.$store.storeName,
                phone: this.formphone.phone,
                lang: this.$i18n.locale
              }
            });
            //console.log( response.data.code);
            //console.log( response.data.message);
          }
        });
      
    }
  }
};
</script>
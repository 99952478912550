var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lg:w-1/4 md:w-1/3 md:px-3 sidebar"},[_c('div',{staticClass:"relative md:-mt-48 -mt-32"},[_c('div',{staticClass:"p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900"},[_c('div',{staticClass:"profile-pic text-center mb-5"},[_c('div',[_vm._m(0),_c('div',{staticClass:"mt-4"},[_c('h5',{staticClass:"text-lg font-semibold"},[_vm._v(_vm._s(_vm.form.name))]),_c('p',{staticClass:"text-slate-400"},[_vm._v(_vm._s(_vm.form.email))]),_c('p',{staticClass:"text-slate-400"},[_vm._v(_vm._s(_vm.form.phone))])])])]),_c('div',{staticClass:"border-t border-gray-100 dark:border-gray-700"},[_c('ul',{staticClass:"list-none sidebar-nav mb-0 pb-0",attrs:{"id":"navmenu-nav"}},[_c('li',{staticClass:"navbar-item account-menu"},[_c('router-link',{staticClass:"navbar-link text-slate-400 flex items-center py-2 rounded",attrs:{"to":{
                               name: 'profile',
                              params: { storeName: this.$store.storeName ,lang: _vm.$i18n.locale},
                                   }}},[_c('span',{staticClass:"me-2 mb-0"},[_c('i',{staticClass:"size-4",domProps:{"innerHTML":_vm._s(_vm.user)}})]),_c('h6',{staticClass:"mb-0 font-medium"},[_vm._v(_vm._s(_vm.$t('Account')))])])],1),_c('li',{staticClass:"navbar-item account-menu"},[_c('router-link',{staticClass:"navbar-link text-slate-400 flex items-center py-2 rounded",attrs:{"to":{
                                           name: 'myOrders',
                                           params: { storeName: this.$store.storeName,lang: _vm.$i18n.locale },
                                                 }}},[_c('span',{staticClass:"me-2 mb-0"},[_c('i',{staticClass:"size-4",domProps:{"innerHTML":_vm._s(_vm.shopping_cart)}})]),_c('h6',{staticClass:"mb-0 font-medium"},[_vm._v(_vm._s(_vm.$t('myOrders')))])])],1),_c('li',{staticClass:"navbar-item account-menu"},[_c('router-link',{staticClass:"navbar-link text-slate-400 flex items-center py-2 rounded",attrs:{"to":{
                                           name: 'myAddresses',
                                           params: { storeName: this.$store.storeName,lang: _vm.$i18n.locale },
                                                 }}},[_c('span',{staticClass:"me-2 mb-0"},[_c('i',{staticClass:"size-4",domProps:{"innerHTML":_vm._s(_vm.map_pin)}})]),_c('h6',{staticClass:"mb-0 font-medium"},[_vm._v(_vm._s(_vm.$t('myAddresses')))])])],1),_c('li',{staticClass:"navbar-item account-menu"},[_c('router-link',{staticClass:"navbar-link text-slate-400 flex items-center py-2 rounded",attrs:{"to":{
                                           name: 'notifications',
                                           params: { storeName: this.$store.storeName ,lang: _vm.$i18n.locale},
                                                 }}},[_c('span',{staticClass:"me-2 mb-0"},[_c('i',{staticClass:"size-4",domProps:{"innerHTML":_vm._s(_vm.bell)}})]),_c('h6',{staticClass:"mb-0 font-medium"},[_vm._v(_vm._s(_vm.$t('Notifications')))])])],1),_c('li',{staticClass:"navbar-item account-menu",on:{"click":function($event){_vm.show = true}}},[_c('a',{staticClass:"navbar-link text-slate-400 flex items-center py-2 rounded",on:{"click":function($event){this.show = true}}},[_c('span',{staticClass:"me-2 mb-0"},[_c('i',{staticClass:"size-4",domProps:{"innerHTML":_vm._s(_vm.log_out)}})]),_c('h6',{staticClass:"mb-0 font-medium"},[_vm._v(_vm._s(_vm.$t('logOut')))])])])])])])]),_c('Transition',[(_vm.show)?_c('div',{staticClass:"modal-mask"},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"modal-header"},[_vm._t("header",function(){return [_vm._v(_vm._s(_vm.$t("confirmlogout")))]})],2),_c('div',{staticClass:"modal-footer"},[_vm._t("footer",function(){return [_c('button',{staticClass:"modal-default-button",on:{"click":function($event){return _vm.logOut()}}},[_vm._v(_vm._s(_vm.$t("confirm")))]),_c('button',{staticClass:"modal-default-button",on:{"click":function($event){(_vm.show = false), _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t("cancel")))])]})],2)])])]):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative h-28 w-28 mx-auto"},[_c('img',{staticClass:"rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800",attrs:{"src":"/theme8/images/user_profile.png","id":"profile-image","alt":""}}),_c('label',{staticClass:"absolute inset-0 cursor-pointer",attrs:{"for":"pro-img"}})])
}]

export { render, staticRenderFns }
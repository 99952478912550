import { render, staticRenderFns } from "./LatestProducts.vue?vue&type=template&id=0de02658&scoped=true&"
import script from "./LatestProducts.vue?vue&type=script&lang=js&"
export * from "./LatestProducts.vue?vue&type=script&lang=js&"
import style0 from "./LatestProducts.vue?vue&type=style&index=0&id=0de02658&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0de02658",
  null
  
)

export default component.exports
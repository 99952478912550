<template>
 <!-- Start Hero -->
 <section style="position:absloute"
 v-if="this.$localStorage.get(this.$store.storeName) != null"
 class="relative lg:pb-24 pb-16 md:mt-[84px] mt-[70px]">
            <div class="md:container container-fluid relative">
                <div class="relative overflow-hidden md:rounded-md shadow
                 dark:shadow-gray-700 h-52 
                 bg-[url('https://marketetrolly.s3.eu-north-1.amazonaws.com/media/217/section/2024/03/327518311709552687.jpg')] 
                 bg-center bg-no-repeat bg-cover"
           style="background-image:url('/theme8/images/page_banner.jpg')"   
                    ></div>
            </div><!--end container-->

            <div class="container relative md:mt-24 mt-16">
                <div class="md:flex">
                    <side_bar></side_bar>

                    <div class="lg:w-3/4 md:w-2/3 md:px-3 mt-6 md:mt-0">
                        <div class="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                            <h5 class="text-lg font-semibold mb-4">
                              {{$t('Personal Detail')}} 
                              :</h5>
                            <form
                            method="post" name="enq"
                                  autocomplete="false"
                                 @submit.prevent="submitInfo"
                                @keydown="form.onKeydown($event)"
                            >
                                <div class="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                    <div>
                                        <label class="form-label font-medium">
                                          {{$t('name')}} :
                                           <span class="text-red-600"></span></label>
                                        <div class="form-icon relative mt-2">
                                            <i data-feather="user" class="w-4 h-4 absolute top-3 start-4"></i>
                                            <input type="text" 
                                            class="ps-12 w-full py-2 px-3 h-10
                                             bg-transparent dark:bg-slate-900 
                                             dark:text-slate-200 rounded outline-none
                                              border border-gray-100 dark:border-gray-800
                                               focus:ring-0" 
                                               :placeholder="$t('Name')" 
                                               id="firstname" 
                                               name="name" 
                                              autocomplete="false"
                                              v-model="form.name"
                                              maxlength="30"
                                               >
                                        </div>
                                    </div>
                                    
                                    <div v-if="this.$store.state.auth==1">
                                        <label class="form-label 
                                        font-medium">
                                        {{ $t('Email') }} : 
                                        <span class="text-red-600">*</span></label>
                                        <div class="form-icon relative mt-2">
                                            <i data-feather="mail" class="w-4 h-4 absolute top-3 start-4"></i>
                                            <input type="email"
                                             class="ps-12 w-full 
                                             py-2 px-3 h-10 bg-transparent
                                              dark:bg-slate-900 dark:text-slate-200 
                                              rounded outline-none border border-gray-100 
                                              dark:border-gray-800 focus:ring-0"
                                              v-model="form.email"
                                              required
                                              maxlength="50
                                              "
                                                >
                                        </div>
                                    </div>
                                    <div v-else>
                                        <label class="form-label font-medium">{{$t('phone')}} : </label>
                                        <div class="form-icon relative mt-2">
                                            <i data-feather="bookmark" class="w-4 h-4 absolute top-3 start-4"></i>
                                            <input 
                                            name="name" 
                                            id="occupation" 
                                            type="text"
                                             class="ps-12 w-full py-2 px-3
                                              h-10 bg-transparent dark:bg-slate-900 
                                              dark:text-slate-200 rounded outline-none 
                                              border border-gray-100 dark:border-gray-800 
                                              focus:ring-0"
                                               
                                              v-model="phone"
                                   placeholder="xxxx xxxx"
                                   required
                                  :minlength=$store.state.country_length
                                  :maxlength=$store.state.country_length
                                  style="text-align:center"
                                              >
                                              <span class="flag">  
                             <h2>{{ $store.state.country_code }}</h2>
                           <img :src="this.$store.state.country_flag" />
                           
                          
                           </span>
                                        </div>
                                    </div>
                                </div><!--end grid-->

                               

                                <input type="submit" id="submit" name="send" class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md mt-5" 
                                :value="$t('Save Changes')">
                            </form><!--end form-->


                            <div v-if="loading" class="empty text-center center-text">
                 
                 <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                           <br>

                       </div>

                            <div
                      v-if="errorMessage != ''"
                      style="
                        text-align: center;
                        color: var(--primary-color);
                        padding: 5px;
                      "
                    >
                      <p style="margin: 0">{{ errorMessage }}</p>
                    </div>
                    
                          </div>

                     
                        <div class="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-6">
                            <h5 class="text-lg font-semibold mb-5 text-red-600">{{ $t('Delete Account') }} :</h5>
    
                            <p class="text-slate-400 mb-4">{{ $t("Do you want to delete the account? Please press below 'Delete' button") }}</p>
    
                            <a @click="show=true" 
                            class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-red-600 text-white rounded-md">{{ $t('Delete') }}</a>
                        </div>
                    </div>
                </div><!--end grid-->
            </div><!--end container-->

            <Transition>
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{ $t("confirmdelet_account") }}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="delete_account()">{{ $t("confirm") }}</button>
                <button
                  class="modal-default-button"
                  @click="(show = false), $emit('close')"
                >{{ $t("cancel") }}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>


     
    </Transition>
        </section><!--end section-->
        <!-- End Hero -->
</template>
<script>
  
import Form from "vform";
import side_bar from "../components/sidebar_profile.vue";
import CodeInput from "vue-verification-code-input";

import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";
import "vodal/slide-left.css";

export default {
  components: {
    Vodal,
    CodeInput,
    side_bar
    
  },
  data: function() {
    return {
      //DBitems: null,
      forgetVodal: false,
      codeVodal: false,
      newPassVodal: false,

      errorMessageVodal: "",
      passemail: "",
      Newpassword: null,
      checkcomplete: false,
      finalcode: null,
      phone:null,
      errorMessage: "",
      show: false,
      loading:false,
      form: new Form({
        //preview: testImage,
        email: null,
        name: null,
        phone:null,
        
      })
    };
  },
  created() {
    this.$store.state.topbar='topbar_dark';
    this.$store.state.navbar='navbar_dark navbar_top';
    
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/profile",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.form.email = response.data.data.email;
        this.form.name = response.data.data.name;
        this.phone=response.data.data.phone.substr(4);
        console.log('profile**  ' +response.data.data);
        //console.log(this.form)
      })
      .catch(error => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
          window.location.href =
            "/" ;
        }
      });
      if(this.$i18n.locale=='ar')
    {
    document.title = this.$store.state.title_ar;
    document.getElementById("description_meta").content=this.$store.state.description_ar;
    document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
    }
    else{
      document.title = this.$store.state.title_en;
    document.getElementById("description_meta").content=this.$store.state.description_en;
    document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
    }
  },
  methods: {
    logOut() {
    this.loading = true;
    this.axios.get(
      this.$store.state.base_api_url + this.$store.storeName + "/logout",
      {
        headers: {
          "Accept-Language": this.$i18n.locale,
          Authorization:
            "Bearer " + this.$localStorage.get(this.$store.storeName)
        }
      }
    );
    //console.group("hiiiiii")
    console.log(
      "logout:" +
        //this.$localStorage.id() +
        this.$localStorage.get(this.$store.storeName)
    );
    //this.$localStorage.remove("userToken");
    this.show2=false;
    let AllCookies = this.$cookies.keys();
    for (let i = 0; i < AllCookies.length; i++) {
      console.log('hhhhhhhhhhhhhhh111');
      if (AllCookies[i].split("/")[0] == this.$store.state.storeName) {
        this.$cookies.remove(console.log(AllCookies[i]));
      
      }
      this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))

    }
    console.log(AllCookies);
    /*if (this.$cookies.isKey(this.$store.storeName + product.product_id + product.final_option + product.option_check)) {
    this.$cookies.remove(this.$store.storeName + product.product_id + product.final_option + product.option_check)
    }*/
    this.$localStorage.remove(this.$store.storeName);
    window.location.reload();
    this.loading = false;
  },

  delete_account() {
    this.loading = true;
    this.axios.get(
      this.$store.state.base_api_url + this.$store.storeName + "/delete_account",
      {
        headers: {
          "Accept-Language": this.$i18n.locale,
          Authorization:
            "Bearer " + this.$localStorage.get(this.$store.storeName)
        }
      }
    );
   
    let AllCookies = this.$cookies.keys();
    for (let i = 0; i < AllCookies.length; i++) {
      console.log('hhhhhhhhhhhhhhh111');
      if (AllCookies[i].split("/")[0] == this.$store.state.storeName) {
        this.$cookies.remove(console.log(AllCookies[i]));
      
      }
      this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
    }
  
    this.$localStorage.remove(this.$store.storeName);
    window.location.reload();
    this.loading = false;
  },
  
    async submitInfo() {
      this.loadng=true;
      this.form.phone=this.$store.state.country_code+this.phone;

      const response = await this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/profile/update",
          {
            email: this.form.email,
            name: this.form.name,
            phone:this.form.phone
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.loadingf=false;
          if (response.data.code == "1") {
            this.errorMessage = response.data.message;
            setTimeout(() => {
              this.errorMessage = "";
            }, 3000);
          } else if (response.data.code == "-1") {
            this.errorMessage = response.data.message;
            setTimeout(() => {
              this.errorMessage = "";
            }, 3000);
          }
          if (response.data.error) {
            this.errorMessage = response.data.error;
            setTimeout(() => {
              this.errorMessage = "";
            }, 3000);
          }
        })
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href="/";
          }
        });
    },
    resetpass(type) {
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/reset_password",
          {
            ///update_password
            send_type: type
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.forgetVodal = false;
            this.codeVodal = true;
            // console.log(response.data.message);
          } else if (response.data.code == "-1") {
            this.errorMessageVodal = response.data.message;
            setTimeout(() => {
              this.errorMessageVodal = "";
            }, 3000);
          }
          if (response.data.error) {
            this.errorMessageVodal = response.data.message;
            setTimeout(() => {
              this.errorMessageVodal = "";
            }, 3000);
          }
        })
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href="/";
          }
        });
    },
    checkCode() {
      if (this.checkcomplete == true) {
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/verivey_reset_password",
            {
              ///update_password
              code: this.finalcode
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName)
              }
            }
          )
          .then(response => {
            if (response.data.code == "1") {
              this.forgetVodal = false;
              this.codeVodal = false;
              this.newPassVodal = true;
              // console.log(response.data.message);
            } else if (response.data.code == "-1") {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
            if (response.data.error) {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
          })
          .catch(error => {
            if (error.response.status == "401") {
              this.$localStorage.remove(this.$store.storeName);
              window.location.href =
                "/";
            }
          });
      } else {
        if (this.$i18n.locale == "en") {
          this.errorMessageVodal = "your code isn't complete";
          setTimeout(() => {
            this.errorMessageVodal = "";
          }, 3000);
        } else {
          this.errorMessageVodal = "الرمز المدخل غير مكتمل";
          setTimeout(() => {
            this.errorMessageVodal = "";
          }, 3000);
        }
      }
    },
    SaveChanges() {
      if (this.Newpassword != null) {
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/auth/update_password",
            {
              ///update_password
              password: this.Newpassword
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName)
              }
            }
          )
          .then(response => {
            if (response.data.code == "1") {
              this.forgetVodal = false;
              this.codeVodal = false;
              this.newPassVodal = false;
              // console.log(response.data.message);
            } else if (response.data.code == "-1") {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
            if (response.data.error) {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
          })
          .catch(error => {
            if (error.response.status == "401") {
              this.$localStorage.remove(this.$store.storeName);
              window.location.href =
                "/" ;
            }
          });
      } else {
        this.errorMessageVodal = "error";
        setTimeout(() => {
          this.errorMessageVodal = "";
        }, 3000);
      }
    },
    onChange(v) {
      //console.log("onChange ", this.phone);
      this.checkcomplete = false;
    },
    onComplete(v) {
      //console.log("onComplete ", v);
      this.finalcode = v;
      this.checkcomplete = true;
    }
  },
  watch:{
    langchange()
    {
      this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/profile",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.form.email = response.data.data.email;
        this.form.name = response.data.data.name;
        this.phone=response.data.data.phone.substr(4);
        console.log('phone ' +this.phone);
        //console.log(this.form)
      })
      .catch(error => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
          window.location.href =
            "/" ;
        }
      });
    }
  }
};
</script>
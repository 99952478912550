<template>
    <form 
    @submit.prevent="submitInfo" @keydown="form.onKeydown($event)"
    class="text-start lg:py-20 py-8">
      <div
              v-if="errorMessage != ''"
              style="
                text-align: center;
                padding: 5px;
                background-color: #fff;
                display: inline;
                margin: 0 10px;
              "
            >
              <span style="margin: 0; color: red; display: inline">{{
                errorMessage
              }}</span>
            </div>

                                    <div class="grid grid-cols-1">
                                        <div class="mb-4">
                                            <label class="font-semibold" for="RegisterName">{{$t('Your Name')}}:</label>
                                            <input 
                                            id="RegisterName" 
                                            type="text" 
                                            class="mt-3 w-full py-2
                                             px-3 h-10 bg-transparent 
                                             dark:bg-slate-900 dark:text-slate-200 
                                             rounded outline-none border border-gray-100 
                                             dark:border-gray-800 focus:ring-0" 
                                             name="name"
                                             autocomplete="false"
                                            :placeholder="$t('EnterName')"
                                             v-model="form.name"
                                            maxlength="255"
                                            minlength="3"
                                    >
                                        </div>
        
                                        <div class="mb-4" v-if="this.$store.state.auth == 1">
                                            <label class="font-semibold" for="LoginEmail">{{ $t('Email') }}:</label>
                                            <input 
                                            id="LoginEmail" 
                                            type="email" 
                                            class="mt-3 w-full py-2 px-3 h-10 
                                            bg-transparent dark:bg-slate-900 
                                            dark:text-slate-200 rounded outline-none 
                                            border border-gray-100 dark:border-gray-800 
                                            focus:ring-0" 
                                            name="email"
                                         autocomplete="false"
                                          v-model="form.email"
                                        placeholder="example@gmail.com"
                                            >
                                        </div>

                                        <div class="mb-4" v-else>
                                            <label class="font-semibold"
                                             for="Loginphone">{{ $t('Phone') }} *:</label>
                                            <input 
                                            id="Loginphone" 
                                            type="text" 
                                            class="mt-3 w-full py-2 px-3 h-10 
                                            bg-transparent dark:bg-slate-900 
                                            dark:text-slate-200 rounded outline-none 
                                            border border-gray-100 dark:border-gray-800 
                                            focus:ring-0" 
                                            
                                           autocomplete=""
                                         v-model="form.phone_basic"
                                         :placeholder="$t('EnterPhone')"
                                          required
                                        :minlength=$store.state.country_length
                                       :maxlength=$store.state.country_length
                                        style="text-align: center;"
                                        :pattern="'[0-9]{'+$store.state.country_length+'}'"
                                            >
                                            <span class="flag">  <h2>{{ $store.state.country_code }}</h2>
                                         <img :src="this.$store.state.country_flag" >
                                                 </span>
                                        </div>
        
                                        <div class="mb-4">
                                            <label class="font-semibold" for="LoginPassword">
                                              {{ $t('password') }} *:</label>
                                            <input 
                                            id="LoginPassword" 
                                            type="password" 
                                            class="mt-3 w-full 
                                            py-2 px-3 h-10 bg-transparent 
                                            dark:bg-slate-900 dark:text-slate-200 
                                            rounded outline-none border border-gray-100 
                                            dark:border-gray-800 focus:ring-0" 
                                            autocomplete="new-password"
                                           v-model="form.password"
                                          :placeholder="$t('EnterPass')"
                                          required
                                          minlength="8"
                                           >
                                           <span style="margin: 0; color: red; display: inline">{{
                                           errorMessage_pass
                                           }}</span>
                                        </div>
                                        <div class="mb-4">
                                            <label class="font-semibold" for="passwordconfirm">
                                              {{ $t('passwordConfirm') }} *:</label>
                                            <input 
                                            class="mt-3 w-full 
                                            py-2 px-3 h-10 bg-transparent 
                                            dark:bg-slate-900 dark:text-slate-200 
                                            rounded outline-none border border-gray-100 
                                            dark:border-gray-800 focus:ring-0" 
                                            id="passwordconfirm"
                                            type="password"
                                            autocomplete="new-password"
                                            name="name"
                                           :placeholder="$t('EnterPass')"
                                           required
                                          v-model="confirmPassword"
                                          minlength="8"
                                           >
                                           <span style="margin: 0; color: red; display: inline">{{
                                             errorMessage_pass
                                              }}</span>
                                        </div>
        
                                        <div class="mb-4">
                                            <div class="flex items-center w-full mb-0">
                                                <input 
                                                class="form-checkbox rounded border-gray-100 
                                                dark:border-gray-800 text-orange-500
                                                 focus:border-orange-300 focus:ring 
                                                 focus:ring-offset-0 focus:ring-orange-200 
                                                 focus:ring-opacity-50 me-2" 
                                                 name="checkbox"
                                                 type="checkbox"
                                                  value="1"
                                                  required
                                                   id="AcceptT&C">
                                                <label class="form-check-label text-slate-400 title"
                                                 for="AcceptT&C">{{ $t('I Accept') }} <router-link
                                                 :to="{
                                              name: 'privacy',
                                           params: { storeName: this.$store.storeName,lang: $i18n.locale },
                                                 }"
                                                  class="text-orange-500 title">
                                                  {{ $t('Terms And Condition') }}
                                                  </router-link>
                                                  </label>
                                            </div>
                                        </div>
        
                                        <div class="mb-4">
                                            <input type="submit"
                                            :disabled="form.busy"  
                                            class="py-2 px-5 inline-block
                                             tracking-wide align-middle
                                              duration-500 text-base text-center
                                               bg-orange-500 text-white rounded-md w-full"
                                                :value="$t('register')">
                                        </div>
        
                                        <div class="text-center">
                                           
                                              <router-link to="/login"
                                               class="text-black
                                                dark:text-white font-bold inline-block title">
                                                <span class="text-slate-400 me-2">
                                              {{ $t('Already have an account?') }} 
                                              </span> 
                                               </router-link>
                                               <div v-if="loading" class="empty text-center center-text">
                 
                 <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                           <br>

                       </div>
                                        </div>
                                    </div>
                                </form>
</template>

<script>

import "vue-phone-number-input/dist/vue-phone-number-input.css";

import Form from "vform";
import { AlertErrors, AlertSuccess } from "vform/src/components/bootstrap5";

export default {
  components: {
    AlertErrors,
    AlertSuccess,
  },
  props: {
    phone: String,
    email: String,
  },
  data: function () {
    return {
      isValid: true,
      DBitems: null,
      confirmPassword: null,
      errorMessage: "",
      errorMessage_pass: "",
      partphone: null,
      loading:false,
      form: new Form({
        name: null,
        password: null,
        phone: null,
        phone_basic:null,
        email: null,
        fsm_token: this.$store.state.fsm_token,
      }),
    };
  },
  created() {
    if (this.phone != null) {
      this.form.phone = this.phone;
      this.partphone=true;
      this.isValid = true;
    } else if (this.email != null) {
      this.form.email = this.email;
    }
  },
  methods: {
    async submitInfo() {
      this.loading=true;
      if (this.isValid) {
        if (this.confirmPassword == this.form.password) {
          console.log(this.form.phone)
          if(this.form.phone==null)
          {
            this.form.phone=this.$store.state.country_code+this.form.phone_basic
          }
          console.log(this.form.phone)
          const response = await this.form
            .post(
              this.$store.state.base_api_url +
                this.$store.storeName +
                "/register",

              {
                form: this.form,
                headers: {
                  "Accept-Language": this.$i18n.locale,
                },
              }
            )
            .then((response) => {
              this.loading=false;
              if (response.data.code == "1") {
                
                this.$localStorage.set(
                  this.$store.storeName,
                  response.data.data.token
                );
                this.$localStorage.set(
              this.$store.user_name,
              response.data.data.name
            );
            if(!response.data.data.name||response.data.data.name=='')
             {
              this.$localStorage.set(
              this.$store.user_name,
              this.form.phone );
             }         
                console.log(
                  this.$localStorage.get(this.$store.storeName) +
                    " id : " +
                    this.$store.storeName
                );

                console.log(
                  "user token : " +
                    this.$localStorage.get(this.$store.storeName)
                );
               
                window.location.href=  '/' ;
                this.loading=false;
              } else if (response.data.code == "-1") {
                this.loading=false;
                this.errorMessage = response.data.message;
                setTimeout(() => {
                  this.errorMessage = "";
                }, 3000);
              }
              if (response.data.error) {
                this.errorMessage = "response.data.error";
                setTimeout(() => {
                  this.errorMessage = "";
                }, 3000);
              }
            });
        } else {
          this.loading=false;
          if (this.$i18n.locale == "en") {
            this.errorMessage_pass = "the password not matching";
            setTimeout(() => {
              this.errorMessage_pass = "";
            }, 3000);
          } else {
            this.errorMessage_pass = "كلمتا السر غير متطابقتين";
            setTimeout(() => {
              this.errorMessage_pass = "";
            }, 3000);
          }
        }
      } else {
        if (this.$i18n.locale == "en") {
          this.errorMessage_pass = "your number isn't valid";
          setTimeout(() => {
            this.errorMessage_pass = "";
          }, 3000);
        } else {
          this.errorMessage_pass = "هذا الرقم غير صالح";
          setTimeout(() => {
            this.errorMessage_pass = "";
          }, 3000);
        }
      }
    },
    
  },
};
</script>
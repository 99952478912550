<template>
    <section>
    <!-- START SECTION BREADCRUMB -->
<div class="breadcrumb_section bg_gray page-title-mini">
    <div class="container"><!-- STRART CONTAINER -->
        <div class="row align-items-center">
        	<div class="col-md-6">
                <div class="page-title">
            		<h1>{{ $t('All Sections') }}</h1>
                </div>
            </div>
            <div class="col-md-6">
                <ol class="breadcrumb justify-content-md-end">
                    <li class="breadcrumb-item"><router-link :to="{name:home,params:{lang: $i18n.locale}}">{{ $t('home') }}</router-link></li>
                    <li class="breadcrumb-item"><a href="#">{{ $t('All Sections') }}</a></li>
                </ol>
            </div>
        </div>
    </div><!-- END CONTAINER-->
</div>
<!-- END SECTION BREADCRUMB -->


<div
        v-if="loading"
        class="d-flex flex-row justify-content-center"
        style="margin-top: 100px; margin-bottom: 200px"
      >
        <!-- LOADER -->
<div class="preloader">
    <div class="lds-ellipsis">
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>
<!-- END LOADER -->

      </div>
      
<!-- START SECTION BLOG -->
<div class="section">
	<div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6"
            v-for="(item, index) in this.DBitems"
            :key="index"
            >
            <section1 :name="item.name" 
            :image="item.icon" 
            :sectionid="item.id">
            </section1>

            </div>
            
        </div>


        <b-pagination
                v-model="page"
                :total-rows="count"
                :per-page="pageSize"
                :prev-text="$t('prev')"
                :next-text="$t('next')"
                pills
                size="sm"
                class="pagination mt-3 justify-content-center pagination_style1"
                @change="handlePageChange"
              ></b-pagination>
    </div>
</div>
<!-- END SECTION BLOG -->

    </section>

</template>


<script>
import section1 from "../components/section_list.vue";
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

export default {
  components: { section1, HollowDotsSpinner, HalfCircleSpinner },
  data() {
    return {
      DBitems: null,
      loading: false,

      page: 1,
      count: 0,
      pageSize: 12,
      langChange:""
      //pageSizes: [3, 6, 9],
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/sections",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.DBitems = response.data.data.sections;
        this.pageSize=response.data.meta.per_page;

        this.count = response.data.meta.total;
      })
      .then(() => {
        this.loading = false;
      });

      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  watch: {
    langChange() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/sections?page=" +
            this.page,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.sections;
          //this.count = response.data.meta.total;
        }).then(() => {
        this.loading = false;
      });
    },
  },
  updated() {
    this.langChange=this.$i18n.locale
    /*  this.axios
        .get(
          "http://192.168.1.16:8000/api/" +
            this.$store.storeName +
            "/sections?page=" +
            this.page,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.sections;
          //this.count = response.data.meta.total;
        }).then(() => {
        this.loading = false;
      });*/
  },
  methods: {
    retrieveTutorials() {
      const params = this.page;

      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/sections?page=" +
            params,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.sections;
          //this.count = response.data.meta.total;
        })
        .then(() => (this.loading = false));
      /*.catch((e) => {
          console.log(params);
          console.log(e);
        });*/
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },
  },
};
</script>
<template>
    <section class="main_banner" style="direction: ltr !important;">
        <div
        v-if="loadingSlider"
        class="d-flex flex-row justify-content-center"
        style="margin-top: 100px; margin-bottom: 200px"
      >
        <!-- LOADER -->
<div class="preloader">
    <div class="lds-ellipsis">
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>
<!-- END LOADER -->

      </div>
<!-- START SECTION BANNER -->
<div dir="ltr" class="banner_section slide_medium shop_banner_slider staggered-animation-wrap">
    <div dir="ltr" id="carouselExampleControls" class="carousel slide carousel-fade light_arrow"
     data-ride="carousel"
     
     >
        <slick     
         ref="slick"
         class="carousel-inner banner_home"
              v-if="sliders.length !=0"
              :options="settings"
              @beforeChange="handleBeforeChange"
              >
                <div v-for="(item, idx) in sliders" :key="idx">

                   
                    <div  v-if="idx==0"
                    class="carousel-item background_bg active" 
            :id="'slide'+idx"
            data-img-src="pet_shop/assets/images/banner1.jpg"
            v-bind:style="'background-image:url('+item.image+')'"            >
                <div class="banner_slide_content">
                    <div class="container"><!-- STRART CONTAINER -->
                        <div class="row">
                            <div class="col-lg-7 col-9">
                                <div class="banner_content overflow-hidden">
                                    
                                	<h5 v-html="item.description" 
                                  class="mb-3 staggered-animation animated 
                                  slideInLeft font-weight-light animated 
                                  slideInLeft" data-animation="slideInLeft" 
                                  data-animation-delay="0.5s"
                                    
                                    ></h5>
                                    
                                    <h2 class="staggered-animation animated slideInLeft"
                                     data-animation="slideInLeft" data-animation-delay="1s"
                                    style="animation-delay:2s;"
                                    >{{ item.title }}</h2>
                               
                                    <a
                                     v-if="item.value"
                                     class="btn btn-fill-out rounded-0 staggered-animation text-uppercase animated slideInLeft"
                                     :href="item.value" 
                                     data-animation="slideInLeft"
                                    data-animation-delay="1.5s">{{ $t('Shop Now') }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div><!-- END CONTAINER-->
                </div>
            </div>



            <div  v-else
                    class="carousel-item background_bg" 
            :id="'slide'+idx"
            data-img-src="pet_shop/assets/images/banner1.jpg '"
            v-bind:style="'background-image:url('+item.image+')'"
            >
                <div class="banner_slide_content">
                    <div class="container"><!-- STRART CONTAINER -->
                        <div class="row">
                            <div class="col-lg-7 col-9">
                                <div class="banner_content overflow-hidden">
                                   

                                	<h5
                                  v-html="item.description"
                                   class="mb-3 staggered-animation font-weight-light animated slideInLeft" data-animation="slideInLeft" data-animation-delay="0.5s"
                                    
                                    ></h5>
                                    

                                     
                                    <h2 class="staggered-animation animated slideInLeft" data-animation="slideInLeft" data-animation-delay="1s"
                                    >{{ item.title }}</h2>
                               
                                    <a
                                     v-if="item.value"
                                     class="btn btn-fill-out rounded-0 staggered-animation text-uppercase animated slideInLeft"
                                     :href="item.value" 
                                     data-animation="slideInLeft"
                                    data-animation-delay="1.5s">{{ $t('Shop Now') }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div><!-- END CONTAINER-->
                </div>
            </div>
                </div>
              </slick>

              <div v-else > <img src="https://etrolley.net/store/assets/image/slide1.jpg" /></div>
              <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev" @click="prev()"><i class="ion-chevron-left"></i></a>
        <a v-if="this.$i18n.locale=='en'" class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next" @click="next()"><i class="ion-chevron-right"></i></a>
    
        <a v-else style="right: 0px !important;
		left: 94% !important;" class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next" @click="next()"><i class="ion-chevron-right"></i></a>

      </div>
</div>
<!-- END SECTION BANNER -->

</section>
<!-- END SECTION BANNER -->
</template>

<script>
 import VueSlickCarousel from 'vue-slick-carousel'
 import Slick from 'vue-slick';

    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { Carousel, Slide } from "vue-carousel";
import { BIconFileEarmarkEasel } from 'bootstrap-vue';

export default {
 
  name: "Banners",
  components: {
    Slick,
    VueSlickCarousel,
    Carousel,
    Slide,
   
  },
  props: {
   
  },
  data: function () {

    return {
        amount: {
        langChange: true,
        name: this.$i18n.t('key_name'),
        value: this.$i18n.t('key_value')
      },
      slickOptions: {
        "slidesToScroll": 1,
  "autoplay": true,
  "autoplaySpeed": 4000,
  "pauseOnDotsHover": true,
  "pauseOnFocus": true,
  "pauseOnHover": true,
  "touchThreshold": 5,
          autoplay: true,
          slidesToShow: 1,
          speed:200,
          "arrows": true,
          dots: false,
          "centerMode": true,
          "infinite": true,
          "fade":true,
          "useCSS":true,
          "waitForAnimate"	:true
                // Any other options that can be got from plugin documentation
            },
    sliders:[],
    array_slide:[],
    loadingSlider :true,
    items:[1,2],
    settings: {
  "slidesToScroll": 1,
  "autoplay": true,
  "autoplaySpeed": 4500,
  "pauseOnDotsHover": true,
  "pauseOnFocus": true,
  "pauseOnHover": true,
  "touchThreshold": 5,
          "autoplay": true,
          slidesToShow: 1,
          speed:200,
          "arrows": true,
          dots: false,
          "centerMode": true,
          "infinite": true,
          "fade":true,
          "useCSS":true,
          "waitForAnimate"	:true
        },
    };
  },
  created() {
    this.loadingSlider = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/sliders",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.sliders = response.data.data.sliders;


      })
      .then(() => {
        this.loadingSlider = false;
        console.log('sliders section',this.sliders)

      });
      console.log('sliders section',this.sliders)

  },

 // All slick methods can be used too, example here
// All slick methods can be used too, example here
methods: {
        next() {
            this.$refs.slick.next();
        },

        prev() {
            this.$refs.slick.prev();
        },

        reInit() {
            // Helpful if you have to deal with v-for to update dynamic lists
            this.$nextTick(() => {
                this.$refs.slick.reSlick();
            });
        },

        // Events listeners
        handleAfterChange(event, slick, currentSlide) {
            console.log('handleAfterChange', event, slick, currentSlide);
        },
        handleBeforeChange(event, slick, currentSlide, nextSlide) {
        
        var i=0;
        for(i=0;i<this.sliders.length;i++)
        {
             var element=document.getElementById('slide'+i);
             if(element.classList.contains( "active" ))
             {
              element.classList.remove("active");
             }
        }
        //
        var element=document.getElementById('slide'+nextSlide);
        element.classList.add( "active" );
        
          },
        handleBreakpoint(event, slick, breakpoint) {
            console.log('handleBreakpoint', event, slick, breakpoint);
        },
        handleDestroy(event, slick) {
            console.log('handleDestroy', event, slick);
        },
        handleEdge(event, slick, direction) {
            console.log('handleEdge', event, slick, direction);
        },
        handleInit(event, slick) {
            console.log('handleInit', event, slick);
        },
        handleReInit(event, slick) {
            console.log('handleReInit', event, slick);
        },
        handleSetPosition(event, slick) {
            console.log('handleSetPosition', event, slick);
        },
        handleSwipe(event, slick, direction) {
            console.log('handleSwipe', event, slick, direction);
        },
        handleLazyLoaded(event, slick, image, imageSource) {
            console.log('handleLazyLoaded', event, slick, image, imageSource);
        },
        handleLazyLoadError(event, slick, image, imageSource) {
            console.log('handleLazeLoadError', event, slick, image, imageSource);
        },
        updatedata() 
        {
            this.loadingSlider = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/sliders",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.sliders = response.data.data.sliders;


      })
      .then(() => {
        this.loadingSlider = false;
        console.log('sliders section',this.sliders)

      });
      console.log('sliders section',this.sliders)
        },
      

        
    },
    watch: {
    langChange() {
      this.loadingSlider = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/sliders",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.sliders = response.data.data.sliders;


      })
      .then(() => {
        this.loadingSlider = false;
        console.log('sliders section',this.sliders)

      });
      console.log('sliders section',this.sliders)
    },
  },
  updated() {
    this.langChange=this.$i18n.locale
  },



};
</script> 
<style>
.VueCarousel-navigation-button {
    text-indent: 1000em;
}

.VueCarousel-navigation-button:before {
    content: "bbb";
    position: absolute;
    top: 8px;
    height: 23px;
    width: 16px;
}

.VueCarousel-navigation-next::before {
    /* background: url('asset/images/next.png'); */
    background-color:var(--primary-color);
     content: "vvvv";
    right: 5%;
    width:60px;
    height: 60px;
}

.VueCarousel-navigation-next {
    /* background: url('asset/images/next.png'); */
    background-color:var(--primary-color);
     content: "vvvv";
    right: 5% !important;
}

.VueCarousel-navigation-prev::before {
    /* background: url('asset/images/next.png'); */
    background-color:var(--primary-color);
    content: "vv";
    width:60px;
    height: 60px;
    left: 5%;
}



.VueCarousel-navigation-prev {
    /* background: url('asset/images/next.png'); */
    background-color:var(--primary-color);
    content: "";
    font-family:Arial, Helvetica, sans-serif !important;
    left: 5% !important;
    
}
@media only screen and (min-width: 1000px)
{
.background_bg
{
  min-height:500px !important;
  max-height:700px !important;
  height:700px !important;
}
.background_bg.active
{
  min-height:500px !important;
  max-height:700px !important;
  height:700px !important;
}
}
</style>
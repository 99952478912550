<template>

    <tr v-if="this.DBitems.product">
      <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>

                            	<td class="product-thumbnail"><router-link
                                      :to="{
                                       name: 'product',
                                      params: { storeName: $store.storeName, 
                                        id: this.item.product_id ,
                                         name:this.DBitems.product.name
                                         ,lang: $i18n.locale
                                        },
                                                                                                 }"
                                                     >
          <img :src="DBitems.product.image" 
          :alt="DBitems.product.name" class="item_img_cart"></router-link></td>
                                <td class="product-name" :data-title="$t('product')">
                                  <router-link
                                      :to="{
                                       name: 'product',
                                      params: { storeName: $store.storeName, id: this.item.product_id , name:this.DBitems.product.name,lang: $i18n.locale },
                                                                                                 }"
                                                     >
                                    {{ DBitems.product.name }}</router-link></td>
                                <td v-if="item.offer_price != 0"
                                 class="product-price" 
                                 :data-title="$t('price')">
                                 {{ item.offer_price.toFixed(2)/item.quantity}}
                                </td>

                                 <td v-else
                                 class="product-price" :data-title="$t('price')">
                                 {{ item.total_price.toFixed(2)/item.quantity }}</td>

                                <td class="product-quantity" 
                                :data-title="$t('quantity')"><div class="quantity">
                                <input type="button" value="-"
                                 class="
                                 size-9 inline-flex items-center
                                  justify-center tracking-wide
                                   align-middle text-base text-center 
                                   rounded-md bg-orange-500/5
                                    hover:bg-orange-500 text-orange-500
                                     hover:text-white 
                                     minus
                                 " 
                                 
                                 @click="decrementClick()" >
                                <input type="text" name="quantity" :value="item.quantity" 
                                title="Qty" class="h-9 
                                qnt_cart
                                inline-flex 
                                items-center justify-center tracking-wide 
                                align-middle text-base text-center rounded-md
                                 bg-orange-500/5 hover:bg-orange-500 text-orange-500
                                  hover:text-white pointer-events-none w-16 ps-4 quantity
                                  " size="4" readonly>
                                <input type="button" value="+" 
                                class="
                                size-9 inline-flex items-center justify-center
                                 tracking-wide align-middle text-base text-center
                                  rounded-md bg-orange-500/5
                                 hover:bg-orange-500 text-orange-500
                                  hover:text-white plus
                                " @click="incrementClick()"
                                >
                              </div></td>
                              	<td v-if="item.offer_price!=0" class="product-subtotal"
                                 :data-title="$t('total')">{{ item.offer_price.toFixed(2) }}</td>
                                <td v-else class="product-subtotal" :data-title="$t('total')">{{ item.total_price.toFixed(2) }}</td>
                                <td class="product-remove" :data-title="$t('remove')"
                                 @click="show = true"><a href="#"><i class="ti-close"></i></a></td>
                                <Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{$t('confirmdelete')}}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="deleteItem() ">{{$t('confirm')}}</button>
                <button
                  class="modal-default-button"
                  @click="(show = false), $emit('close')"
                >{{$t('cancel')}}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </Transition>
                            </tr>
    </template>

    <script>
export default {
  props: {
    itemId: String
  },
  data: function() {
    return {
      loading: false,
      DBitems: null,
      item: null,
      show: false,
      langChange: ''
    };
  },
  created() {
    console.log('new_gg')
    this.loading = true;
    this.item = this.$cookies.get(this.itemId);
    if (this.item != null) {
      console.log('itemsss',this.$i18n.locale)
      //console.log(this.item)
      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/cart_item",
          {
            product_id: this.item.product_id,
            final_option: this.item.final_option,
            option_check: this.item.option_check
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
       
        })
        .then(() => (this.loading = false));
    }
  },
  methods: {
    
    incrementClick() {
      // ++this.counterVal;
      console.log(
        "incrementtt" + this.item.quantity + " " + this.item.quantityAll
      );


      
      //////////////////////////////////////////////////////////
      let S_name_full =
        this.$store.storeName +
        "/" +
        this.item.product_id +
        this.item.final_option +
        this.item.option_check;

      let S_name =
       this.$store.storeName +
        "/" +
        this.item.product_id +
        this.item.final_option;
      console.log("S_name " + S_name);

      let qun = this.item.quantityAll;
      console.log('qun****************',qun)
     let min_qun =this.item.min_quantity;

    
          let  max_qun =this.item.max_quantity;
      console.log("this.item.quantityAll" + this.item.quantityAll);

      let AllCookies = this.$cookies.keys();
      for (let i = 0; i < AllCookies.length; i++) {
        if (AllCookies[i].includes(S_name)) {
          if (AllCookies[i] == S_name_full) {
            console.log("hii i am there ");
          } else {
            qun = qun - this.$cookies.get(AllCookies[i]).quantity;
            min_qun =this.$cookies.get(AllCookies[i]).min_quantity;
            max_qun = this.$cookies.get(AllCookies[i]).max_quantity;
            console.log(
              this.$cookies.get(AllCookies[i]).quantity + " quuuuun " + qun
            );

            console.log(
              this.$cookies.get(AllCookies[i]).min_quantity + " min quuuuun " + min_qun
            );
          }
        }
      }
      //////////////////////////////////////////////////////////
      if (this.item.quantityAll > 0) {
        if (this.item.quantity < qun 
        && this.item.quantity < max_qun 
        // && this.item.quantity > min_qun
        ) {
          ++this.item.quantity;
          this.item.total_price =
            this.item.total_price / (this.item.quantity - 1) +
            this.item.total_price;
          /*if (this.item.offer_price != 0) {
          this.item.offer_price =
            this.item.total_price -
            (this.item.discount / 100) * this.item.total_price;
        }*/
          if (this.item.offer_price != 0) {
            this.item.offer_price =
              this.item.offer_price / (this.item.quantity - 1) +
              this.item.offer_price;
          }

          //////////update this key
          let product = {
            product_id: this.item.product_id,
            total_price: this.item.total_price,
            offer_price: this.item.offer_price,
            quantity: this.item.quantity,
            quantityAll: this.item.quantityAll,
            min_quantity: this.item.min_quantity,
            max_quantity: this.item.max_quantity,
            final_option: this.item.final_option,
            option_check: this.item.option_check,
            discount: this.item.discount,
            note: this.item.note
          };
          this.$cookies.set(this.itemId, product);
          this.$parent.updateProducts();
        }
      } else {
        ++this.item.quantity;
        this.item.total_price =
          this.item.total_price / (this.item.quantity - 1) +
          this.item.total_price;
        /* if (this.item.offer_price != 0) {
          this.item.offer_price =
            this.item.total_price -
            (this.item.discount / 100) * this.item.total_price;
        }*/
        if (this.item.offer_price != 0) {
          this.item.offer_price =
            this.item.offer_price / (this.item.quantity - 1) +
            this.item.offer_price;
        }

        //////////update this key
        let product = {
          product_id: this.item.product_id,
            total_price: this.item.total_price,
            offer_price: this.item.offer_price,
            quantity: this.item.quantity,
            quantityAll: this.item.quantityAll,
            min_quantity: this.item.min_quantity,
            max_quantity: this.item.max_quantity,
            final_option: this.item.final_option,
            option_check: this.item.option_check,
            discount: this.item.discount,
            note: this.item.note
        };
        this.$cookies.set(this.itemId, product);
        this.$parent.updateProducts();
      }
    },
    decrementClick() {
      let min_qun =this.item.min_quantity;

    
          let  max_qun =this.item.max_quantity;
      console.log(
        "decrementttt" + this.item.quantity + " " ,this.item
      );
      if (this.item.quantity > 1  
      && this.item.quantity > min_qun
 ) {
        //  --this.counterVal;
        --this.item.quantity;
        //this.price = this.price/2
        this.item.total_price =
          this.item.total_price -
          this.item.total_price / (this.item.quantity + 1);
        /*if (this.item.offer_price != 0) {
          this.item.offer_price =
            this.item.total_price -
            (this.item.discount / 100) * this.item.total_price;
        }*/
        if (this.item.offer_price != 0) {
          this.item.offer_price =
            this.item.offer_price -
            this.item.offer_price / (this.item.quantity + 1);
        }

        //////////update this key
        let product = {
          product_id: this.item.product_id,
          total_price: this.item.total_price,
          offer_price: this.item.offer_price,
          quantity: this.item.quantity,
          quantityAll: this.item.quantityAll,
          min_quantity: this.item.min_quantity,
          max_quantity: this.item.max_quantity,
          final_option: this.item.final_option,
          option_check: this.item.option_check,
          discount: this.item.discount,
          note: this.item.note
        };
        this.$cookies.set(this.itemId, product);
        this.$parent.updateProducts();
      }
    },
    deleteItem() {
      //this.loading = true;
      this.$cookies.remove(this.itemId);
      console.log("remove" + this.$cookies.keys());
      this.$store.state.cartCounter = this.$store.state.cartCounter - 1;
      this.$destroy();
      this.$parent.updateProducts();
      this.$el.parentNode.removeChild(this.$el);
    }
,
    updatedata() {
  
    this.$store.state.langchange=false;
    this.loading = true;
    this.item = this.$cookies.get(this.itemId);
    if (this.item != null) {
      //console.log(this.item)
      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/cart_item",
          {
            product_id: this.item.product_id,
            final_option: this.item.final_option,
            option_check: this.item.option_check
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
       
        })
        .then(() => (this.loading = false));
    }
    }
  },
  watch:{
    langChange(){
      this.loading = true;
    this.item = this.$cookies.get(this.itemId);
    if (this.item != null) {
      //console.log(this.item)
      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/cart_item",
          {
            product_id: this.item.product_id,
            final_option: this.item.final_option,
            option_check: this.item.option_check
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
       
        })
        .then(() => (this.loading = false));
    }
    }
  },
  updated() {
    this.langChange=this.$i18n.locale
    console.log('******nnnnnnnnn******'+this.langChange)
  },
};
</script>
<style scoped>
.item_img_cart {
    width: 100px;
    aspect-ratio: 1/1;
    object-fit: cover;
}
</style>
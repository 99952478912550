<template>
     <tr>
                                                <td>#{{ invoice_id }}</td>
                                                <td>{{ date }}</td>
                                                <td>{{ state }}</td>
                                                <td>{{ cost }}</td>
                                                <td>
                                                    <a @click="$router.push({name: 'invoice',params: { storeName: $store.storeName ,id:id,lang: $i18n.locale }})"
                                                    class="btn btn-fill-out btn-sm">{{ $t('view') }}</a>
                                                    
                                                    </td>
                                            </tr>
</template>

<script>
export default {
  props: {
   id:Number,
   invoice_id:Number,
   date:String,
   state:String,
   cost:Number

  },
  data: function () {
    return {};
  },
  updated() {
    this.langChange=this.$i18n.locale;
  },
};
</script>

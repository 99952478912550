<template>
    <div>
      <div v-if="this.$store.state.langchange||this.$store.state.update_cart_list">
        {{ updatedata() }}</div>

     <div class="cart_box dropdown-menu dropdown-menu-right" id="cart_list">
        
                            <ul  class="cart_list">
                             <li  v-for="item in this.items" 
                                :key="item"
                                v-if="item"
                                >
                                <item_cart
                               
                                :item-id="item"
                                >
                                    </item_cart>
                                    </li>
                            </ul>
                            <div class="cart_footer">
                                <p class="cart_total">
                                    <strong >{{ $t("subTotal") }}:</strong>
                                     <span class="cart_price"> 
                                        <span class="price_symbole" v-if=" $i18n.locale == 'ar'"> {{$store.state.unitAR}} </span>
                                        <span class="price_symbole" v-else> {{$store.state.unit}} </span>
                                        </span>{{ price.toFixed(2) }}</p>
                                <p class="cart_buttons"><router-link to="/shopping_cart" class="btn btn-fill-line rounded-0 view-cart">{{ $t('View Cart') }}</router-link>
                                  <router-link v-if="this.$localStorage.get(this.$store.storeName) == null" class="btn btn-fill-out rounded-0 checkout"  to="/login">{{ $t('Checkout') }}</router-link>

                                <router-link v-else to="/checkout" class="btn btn-fill-out rounded-0 checkout">{{ $t('Checkout') }}</router-link></p>
                            </div>
                        </div>
                    </div>
    </template>
<script>
import item_cart from "../components/cart_item.vue";
export default {
  components: { item_cart },
  data: function() {
    return {
      items: [],
      products: [],
      loading: false,
      show: false,
      price: 0,
      n: 0,
      update_cart_list: false
    };
  },
  created() {
    this.$store.state.add_cart=false;
    let AllCookies = this.$cookies.keys();
    this.products = [];
    this.price = 0;
    this.n=3
if(AllCookies.length<3)
{
  this.n=AllCookies.length;
}
    for (let i = 0; i < AllCookies.length; i++) {
      if (AllCookies[i].split("/")[0] == this.$store.storeName) {
        if(i<this.n)
        {
        
        this.items.push(AllCookies[i]);
        this.products.push(this.$cookies.get(AllCookies[i]));
        }
        if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.price += this.$cookies.get(AllCookies[i]).offer_price;
        } else {
          this.price += this.$cookies.get(AllCookies[i]).total_price;
        }
      }
    }

  },
  methods: {
    updateProducts() {
      this.items = [];
      this.products = [];
      this.price = 0;

      let AllCookies = this.$cookies.keys();
      var n=3
if(AllCookies.length<3)
{
  var n=AllCookies.length;
}
      for (let i = 0; i < n; i++) {
        if (AllCookies[i].split("/")[0] == this.$store.storeName) {
          this.items.push(AllCookies[i]);
          this.products.push(this.$cookies.get(AllCookies[i]));

          if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.price += this.$cookies.get(AllCookies[i]).offer_price;
        } else {
          this.price += this.$cookies.get(AllCookies[i]).total_price;
        }
        }
      }
    },
    updatedata() {
      this.update_cart_list=true;
  },
    deleteAllCookies() {
      this.loading = true;
      for (let i = 0; i < this.items.length; i++) {
        this.$cookies.remove(this.items[i]);
      }
      this.items = [];

      console.log("hiiiiiiii" + this.$cookies.keys());
      this.$store.state.cartCounter = 0;
      this.loading = false;
      this.show = false;
    },
    confirmOrder() {
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/check_all_item_card",
          {
            products: this.products
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.$router.push({
              name: "confirmOrder",
              params: { storeName: this.$store.storeName,lang: this.$i18n.locale }
            });
          } else {
            alert(response.data.message);
            // this.couponMsg = response.data.message;
          }
          //this.addresses = response.data.data.addresses.addresses;
          // console.log(this.addresses);
        })
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
                     window.location.href="/";

          }
        });
    }

    ,forceRerender() {
      

        this.$nextTick(() => {
          this.renderComponent = true;
          add_cart_item();
          
        });
      }
      ,
      add_cart_item(){
      
      let AllCookies = this.$cookies.keys();
    this.products = [];
    this.items = [];
    this.price = 0;
    this.n=3
if(AllCookies.length<3)
{
  this.n=AllCookies.length;
}
    for (let i = 0; i < AllCookies.length; i++) {
      if (AllCookies[i].split("/")[0] == this.$store.storeName) {
        this.items.push(AllCookies[i]);
        this.products.push(this.$cookies.get(AllCookies[i]));
        if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.price += this.$cookies.get(AllCookies[i]).offer_price;
        } else {
          this.price += this.$cookies.get(AllCookies[i]).total_price;
        }
      }
    }

    }
  },
  watch:{
    update_cart_list(){
      this.update_cart_list=false;
      console.log('gggggggggggggggggggg')
      let AllCookies = this.$cookies.keys();
    this.products = [];
    this.items = [];
    this.price = 0;
    this.n=3
if(AllCookies.length<3)

{
 this.n=AllCookies.length;
}
    for (let i = 0; i < AllCookies.length; i++) {
      console.log('fffff',i)
      if (AllCookies[i].split("/")[0] == this.$store.storeName) {
       
        {
        this.items.push(AllCookies[i]);
        this.products.push(this.$cookies.get(AllCookies[i]));
        }

        if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.price += this.$cookies.get(AllCookies[i]).offer_price;
        } else {
          this.price += this.$cookies.get(AllCookies[i]).total_price;
        }
      }
    }
    this.$store.state.update_cart_list=false
    },
    langChange()
    {
    let AllCookies = this.$cookies.keys();
    this.products = [];
    this.price = 0;
    var n=3
if(AllCookies.length<3)
{
  var n=AllCookies.length;
}
    for (let i = 0; i < n; i++) {
      if (AllCookies[i].split("/")[0] == this.$store.storeName) {
        this.items.push(AllCookies[i]);
        this.products.push(this.$cookies.get(AllCookies[i]));

        if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.price += this.$cookies.get(AllCookies[i]).offer_price;
        } else {
          this.price += this.$cookies.get(AllCookies[i]).total_price;
        }
      }
    }

  }





},

updated() {
    this.langChange=this.$i18n.locale;
  },}
</script>
<template>
  <router-link
    :to="{
      name: 'brand',
      params: { storeName: $store.storeName, id: id , name:this.name,lang: $i18n.locale},
    }"
  >
    <div>
      <div class="card">
        <div class="image">
          <img :src="image" />
        </div>
      </div>
      <div class=" product-name text-center">
        <p>{{ name }}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    id: Number,
    name: String,
    image: String,
  }
};
</script>

<style scoped>
.card {
  width: 207px;
  height: 130px;
  border: 0;
  background: transparent;

  margin: auto;
  overflow: hidden;
  z-index: 1;
  transform: scale(1);
  transition: 0.5s all;

  /*color: var(--primary-color);
  font-family: "Araboto-Bold";
  font-size: 22px;*/
}
.card .image:hover {
  transform: scale(1.1);
  filter: brightness(0.7);
  
}
.image {
  width: 80%;
  height: 80%;
  margin: auto;
  transition: 0.5s all ease-in-out;
}

.card img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: contain;
}
.product-name p {
  line-height: 30px;
  color: var(--primary-color);
  font-family: "Araboto-Bold";
  font-size: 18px;
}
.star-container {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 20px;
  font-size: 20px;
}
.star {
  color: #fff;
  margin-left: 2px;
  margin-right: 2px;
}
.star-fill {
  color: #ffcc00;
  margin-left: 2px;
  margin-right: 2px;
}

@media (min-width: 1400px) {
  .card {
    /*width: 95%;
    max-width: 270px;
    min-width: 250px;*/

    width: 100%;
    max-width: 220px;
  }
}
@media (max-width: 490px){
  .mask {
    width: 120px;
  }
  .product-name p{
    font-size: 14px;
  }
  .mask1{
    left: 20%;
    right: 20%;
  }
  .card {
    
    max-width: 120px;
  }
}
svg path {
  fill: var(--primary-color);
}
/*
@media (min-width: 1400px){
  .card {
    width: 250px;
  height: 300px;
  }

.mask .img1 {
  top: 0;
  width: 100px;
  border-radius: 0;
}
.mask .img2 {
  top: 0;
  width: 60px;
  border-radius: 0;
}
.mask .text {
  top: 18px;
}
.mask .text p{
  font-size: 20px !important;
}
.product-name {
  font-size: 20px;
  margin-top: 20px;
}
.star-container {
  font-size: 28px;
}

}

@media (min-width: 2000px){
  .card {
    width: 300px;
  height: 370px;
  }

.mask .img1 {
  top: 0;
  width: 128px;
  border-radius: 0;
}
.mask .img2 {
  top: 0;
  width: 80px;
  border-radius: 0;
}
.mask .text {
  top: 20px;
}
.mask .text p{
  font-size: 30px !important;
}
.product-name {
  font-size: 25px;
  margin-top: 30px;
}
.star-container {
  font-size: 30px;
}

}*/
</style>

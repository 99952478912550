<template>
 <div>
                    <h3 class="color_font">{{$t('Plant Your Dream')}}</h3>
                    <h4>{{$t('Begin your Green Journey Now!')}}</h4>
                    <div>
                      <div
              v-if="errorMessage != ''"
              style="
                text-align: center;
                padding: 5px;
                background-color: #fff;
                display: inline;
                margin: 0 10px;
              "
            >
              <span style="margin: 0; color: red; display: inline">{{errorMessage}}</span>
                        </div>


                        <div
              v-if="errorMessage_pass != ''"
              style="
                text-align: center;
                padding: 5px;
                background-color: #fff;
                display: inline;
                margin: 0 10px;
              "
            >
              <span style="margin: 0; color: red; display: inline">{{errorMessage_pass}}</span>
                        </div>
                        <form  @submit.prevent="submitInfo" @keydown="form.onKeydown($event)">
                            <input 
                            type="text" 
                             class="input_all"
                             name="name"
                             autocomplete="false"
                            :placeholder="$t('EnterName')"
                            v-model="form.name"
                            maxlength="255"
                            minlength="3"
                             >
                            <br>
                            <input 
                             v-if="this.$store.state.auth == 1"
                            class="input_all" 
                            type="email" 
                            name="email"
                            autocomplete="false"
                            v-model="form.email"
                            placeholder="example@gmail.com"
                            >
                            <span  v-if="this.$store.state.auth != 1" class="flag flag_profile" style="
                           
">
                                <h2>{{ $store.state.country_code }}</h2>
                                         <img :src="this.$store.state.country_flag" >
                                                 </span>
                            <input 
                            v-if="this.$store.state.auth != 1"
                            class="input_all" 
                            type="text" 
                            autocomplete=""
                            v-model="form.phone_basic"
                            required
                            :minlength=$store.state.country_length
                            :maxlength=$store.state.country_length
                            :pattern="'[0-9]{'+$store.state.country_length+'}'"
                            style="padding-left: 30%;padding-right:30%;;direction:ltr"
                            >
                            <br>
                            <input class="input_all " 
                            id="password"
                            type="password" 
                            autocomplete="new-password"
                            v-model="form.password"
                            :placeholder="$t('EnterPass')"
                            required
                            minlength="8"
                           >
                            <br>
                            <input
                            
                             class="input_all" 
                              id="confirm_password"
                             type="password" 
                             name="name"
                            :placeholder="$t('EnterPass')"
                            required
                            v-model="confirmPassword"
                            minlength="8"
                            >
                            
                            <br>
                            <div class="tearm">

                                <label>
                                    <input type="checkbox" checked name="remember"> 
                                    {{$t('By continuing, you agree to the')}}
                                     <router-link
                                     :to="{
                                              name: 'privacy',
                                           params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                                                 }"
                                       class="color_font">{{$t('Terms of use')}} 
                                      </router-link>{{$t('and')}}
                                      <router-link 
                                      :to="{
                                              name: 'privacy',
                                           params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                                                 }"
                                      class="color_font"> 
                                        {{$t('privacyPolicy')}}.</router-link>
                                  </label>
                            </div>
               
                    <button type="submit" class="butt_green  center_align login_but"  style="margin-top: 5%;">
                      {{ $t('signup') }}
                      </button>
                    <br>
                    <p style="font-size: 20px ; text-align: center;" >{{ $t('Already have an account?') }} 
                      <router-link 
                      to="/login"
                            class="color_font  " style="font-size: 20px; ;   ">{{$t('logIn')}}
                          </router-link> </p>
                        </form>

                        <div v-if="loading" class="empty text-center center-text">
                 
                 <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                           <br>

                       </div>

                    

                </div>
            </div>

</template>
<script>

import "vue-phone-number-input/dist/vue-phone-number-input.css";

import Form from "vform";
import { AlertErrors, AlertSuccess } from "vform/src/components/bootstrap5";
import router from "@/router";

export default {
  components: {
    AlertErrors,
    AlertSuccess,
  },
  props: {
    phone: String,
    email: String,
  },
  data: function () {
    return {
      isValid: true,
      DBitems: null,
      confirmPassword: null,
      errorMessage: "",
      errorMessage_pass: "",
      partphone: null,
      loading:false,
      form: new Form({
        name: null,
        password: null,
        phone: null,
        phone_basic:null,
        email: null,
        fsm_token: this.$store.state.fsm_token,
      }),
    };
  },
  created() {
    if (this.phone != null) {
      this.form.phone = this.phone;
      this.partphone=true;
      this.isValid = true;
    } else if (this.email != null) {
      this.form.email = this.email;
    }
  },
  methods: {
    async submitInfo() {
      this.loading=true;
      if (this.isValid) {
        if (this.confirmPassword == this.form.password) {
          console.log(this.form.phone)
          if(this.form.phone==null)
          {
            this.form.phone=this.$store.state.country_code+this.form.phone_basic
          }
          console.log(this.form.phone)
          const response = await this.form
            .post(
              this.$store.state.base_api_url +
                this.$store.storeName +
                "/register",

              {
                form: this.form,
                headers: {
                  "Accept-Language": this.$i18n.locale,
                },
              }
            )
            .then((response) => {
              this.loading=false;
              if (response.data.code == "1") {
                
                this.$localStorage.set(
                  this.$store.storeName,
                  response.data.data.token
                );
                this.$localStorage.set(
              this.$store.user_name,
              response.data.data.name
            );
            if(!response.data.data.name||response.data.data.name=='')
             {
              this.$localStorage.set(
              this.$store.user_name,
              this.form.phone );
             }         
                console.log(
                  this.$localStorage.get(this.$store.storeName) +
                    " id : " +
                    this.$store.storeName
                );

                console.log(
                  "user token : " +
                    this.$localStorage.get(this.$store.storeName)
                );
               
                window.location.href=  '/' ;
                this.loading=false;
              } else if (response.data.code == "-1") {
                this.loading=false;
                this.errorMessage = response.data.message;
                setTimeout(() => {
                  this.errorMessage = "";
                }, 3000);
              }
              if (response.data.error) {
                this.errorMessage = "response.data.error";
                setTimeout(() => {
                  this.errorMessage = "";
                }, 3000);
              }
            });
        } else {
          this.loading=false;
           document.getElementById("password").style="border:2px solid red"
            document.getElementById("confirm_password").style="border:2px solid red"
          if (this.$i18n.locale == "en") {
            this.errorMessage_pass = "the password not matching";
           
            setTimeout(() => {
              this.errorMessage_pass = "";
               document.getElementById("password").style="border:1px solid #66666659;"
              document.getElementById("confirm_password").style="border:1px solid #66666659;"
            }, 3000);
          } else {
            this.errorMessage_pass = "كلمتا السر غير متطابقتين";
            setTimeout(() => {
              this.errorMessage_pass = "";
              document.getElementById("password").style="border:1px solid #66666659;"
              document.getElementById("confirm_password").style="border:1px solid #66666659;"
            }, 3000);
          }
        }
      } else {
        if (this.$i18n.locale == "en") {
          this.errorMessage_pass = "your number isn't valid";
          setTimeout(() => {
            this.errorMessage_pass = "";
          }, 3000);
        } else {
          this.errorMessage_pass = "هذا الرقم غير صالح";
          setTimeout(() => {
            this.errorMessage_pass = "";
          }, 3000);
        }
      }
    },
    
  },
};
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"text-center",staticStyle:{"margin-top":"2%"}},[_c('img',{staticStyle:{"width":"100px","border-radius":"50%","aspect-ratio":"1/1","object-fit":"contain"},attrs:{"src":_vm.$store.state.logo}}),_c('h4',[_vm._v(_vm._s(_vm.form.name))]),_c('span',{staticStyle:{"color":"#818181"},attrs:{"dir":"ltr"}},[_vm._v(_vm._s(_vm.phone))]),_c('br'),_c('span',{staticStyle:{"color":"#818181"}},[_vm._v(_vm._s(_vm.form.email))])]),_c('div',{attrs:{"id":"div_but_order"}},[_c('router-link',{staticClass:"order_but",attrs:{"to":{
              name: 'myOrders',
              params: { storeName: this.$store.storeName,lang:_vm.$store.state.routeLang },
                  }}},[_c('i',{staticClass:"fa fa-shopping-cart"}),_vm._v(" "+_vm._s(_vm.$t('myOrders'))+" ")]),_c('router-link',{staticClass:"order_but",attrs:{"to":{
              name: 'favorites',
              params: { storeName: this.$store.storeName,lang:_vm.$store.state.routeLang },
                  }}},[_c('i',{staticClass:"fa fa-heart-o"}),_vm._v(" "+_vm._s(_vm.$t('favorites'))+" ")]),_c('router-link',{staticClass:"order_but",attrs:{"to":{
              name: 'myAddresses',
              params: { storeName: this.$store.storeName,lang:_vm.$store.state.routeLang },
                  }}},[_c('i',{staticClass:"fa fa-map-marker"}),_vm._v(" "+_vm._s(_vm.$t('myAddresses')))]),_c('router-link',{staticClass:"order_but",attrs:{"to":{
              name: 'profile',
              params: { storeName: this.$store.storeName,lang:this.$i18n.locale },
                  }}},[_c('i',{staticClass:"fa fa-pencil"}),_vm._v(" "+_vm._s(_vm.$t('Edit Profile')))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="container" id="container_note">
    <h2 style="text-align: center; ">{{ $t('Notifications') }}</h2>
    <profile_header></profile_header>
    <div class="container">
      <div class="d-flex flex-row justify-content-between align-items-center">
        <div class="section-title">
          <h2>{{ $t("Notifications") }}</h2>
        </div>
        <button v-if="this.notifications != null && this.notifications.length != 0" class="delete delete_all_notes"
          @click="show = true">
          <h6>
            {{ $t("deleteAll") }}
            <span style="color:#ed472b">
              <i class="bi bi-trash"></i>
            </span>
          </h6>
        </button>
      </div>
      <div v-if="loading" class="d-flex flex-row justify-content-center"
        style="margin-top: 100px; margin-bottom: 200px">
        <hollow-dots-spinner :animation-duration="1000" :dot-size="35" :dots-num="3" color="var(--primary-color)" />
      </div>
      <div v-else>

        <br>

        <div v-if="this.count != 0" class="row">
          <div class="collg6 col-sm-6 col-12 mb-4" v-for="(item, index) in this.notifications" :key="index">
            <div class="card_note">

              <div>
                <div class="d-flex flex-row justify-content-between align-items-center">
                  <h4 class="title_note">{{ item.title }}</h4>
                  <!--<div>
                      <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                    </div>-->
                </div>
                <p class="note_msg">{{ item.body }}</p>
                <div class="d-flex flex-row justify-content-between align-items-center">
                  <span class="note_date">{{ item.date }}</span>
                  <p style="color:#ed472b; cursor:pointer" @click="deleteNote(item.id, index)">
                    <i class="bi bi-trash"></i>
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div v-else class="empty text-center center-text ">

          <img src="/assets/images/empty.png" />

          <br>

          {{ $t('empty') }}
        </div>

        <div class="d-flex flex-row justify-content-center mt-5" v-if="this.count > 6">
          <b-pagination v-model="page" :total-rows="count" :per-page="pageSize" :prev-text="$t('prev')"
            :next-text="$t('next')" pills @change="handlePageChange"></b-pagination>
        </div>
      </div>
    </div>
    <Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{ $t('confirmdeleteAllNoti') }}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="deleteAll()">{{ $t('confirm') }}</button>
                <button class="modal-default-button" @click="(show = false), $emit('close')">{{ $t('cancel') }}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
      <div v-if="show1" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{ $t('confirmdeleteOneNoti') }}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="deleteOneNote()">{{ $t('confirm') }}</button>
                <button class="modal-default-button" @click="(show1 = false), $emit('close')">{{ $t('cancel') }}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import profile_header from "../components/profile_header.vue";

import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

export default {
  components: { HollowDotsSpinner, HalfCircleSpinner, profile_header },
  data() {
    return {
      loading: false,
      show: false,
      show1: false,
      deleteNoteId: null,
      deleteNoteIndex: null,
      page: 1,
      count: 0,
      pageSize: 5,
      langChange: "",
      notifications: null
    };
  },
  created() {
    if (this.$route.params.lang && this.$route.params.lang != this.$i18n.locale) {
      this.$i18n.locale = this.$route.params.lang; this.$store.state.UnitPrice = this.$store.state.unitAR
      if (this.$i18n.locale == 'en') {
        document.documentElement.setAttribute("dir", "ltr");
        let h = document.querySelector('body');
      }
    }

    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/new_note",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        if (response.data.data.code == "1") {
          this.notifications = response.data.data.notes;
          this.count = response.data.meta.total;
          console.log(this.notifications);
        }
      })
      .then((this.loading = false));
    if (this.$i18n.locale == 'ar') {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content = this.$store.state.description_ar;
      document.getElementById("keywords_meta").content = this.$store.state.keyword_ar;
    }
    else {
      document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content = this.$store.state.description_en;
      document.getElementById("keywords_meta").content = this.$store.state.keyword_en;
    }
  },
  watch: {
    langChange() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/new_note?page=" +
          this.page,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.data.code == "1") {
            this.notifications = response.data.data.notes;
            // console.log(this.notifications);
            this.pageSize = response.data.meta.per_page;

          }
        })
        .then(() => {
          this.loading = false;
        });

    }
  },
  updated() {
    this.langChange = this.$i18n.locale;
  },
  methods: {
    retrieveTutorials() {
      const params = this.page;

      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/new_note?page=" +
          params,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.data.code == "1") {
            this.notifications = response.data.data.notes;
          }
        })
        .then(() => (this.loading = false));

    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },
    deleteAll() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/delete_allnote",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.notifications = null;
            this.count = 0;
          }
        })
        .then(() => {
          this.loading = false;
          this.show = false;
        });
    },
    deleteOneNote() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/delete_note/" +
          this.deleteNoteId,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.$delete(this.notifications, this.deleteNoteIndex);
            this.$store.state.notificationUpdate = !this.$store.state.notificationUpdate;
            // this.$el.parentNode.removeChild(this.$el);
          }
        })
        .then(() => {
          this.loading = false;
          this.show1 = false;
        });
    },
    deleteNote(itemId, itemIndex) {
      this.show1 = true;
      this.deleteNoteId = itemId;
      this.deleteNoteIndex = itemIndex;
    }
  }
};
</script>

a<template>
    
    <footer>
      <div  v-if="DBitems.social.whatsapp"
       class="whatsapp_div">
      <a :href="'https://api.whatsapp.com/send?phone='+DBitems.social.whatsapp+'&text=Hello'" 
    class="float" target="_blank">
        <i class="fa fa-whatsapp my-float"></i>
        </a>
        </div>
          <div v-if="this.$store.state.langchange1">{{ updatedata() }}</div>
        <section class="footer_section" >
            <div class="container">
               <div class="row " >
                <div class="col-lg-4 col-md-6 col_foot">
                    <img :alt="this.$store.state.storeName" 
                    :src="DBitems.market.logo_footer" 
                    class="logo_footer"> 
                    <p class="mt-6 description_footer" style=" padding-top: 4%;">
                                        
                                        {{ DBitems.market.name }}
                                        {{ DBitems.market.description }}
                                   </p>
                                   <p v-if="DBitems.market.subdescription" 
                                   class="mt-6 text-gray-300 description_footer">
                                        {{ DBitems.market.subdescription }}
                                  </p>
        
                    <p 
                    v-if="DBitems.market.copy_right" 
                    style="color: aliceblue; 
                   "
                    > {{ DBitems.market.copy_right }}  
                    <a href="https://etrolley.net" 
                                 target="_blank" class="text-reset  link_etrolley">
                                 {{$t('Powered By Etrolley')}}</a>.
                  </p>
                  <p v-else
                   style="color: aliceblue; 
                   "
                  >
                                    {{ $t("all rights are save") }} 
                                     {{ this.DBitems.market.name }} 

                               
                               <a href="https://etrolley.net" 
                                 target="_blank" class="text-reset link_etrolley">

                                 {{$t('Powered By Etrolley')}}</a>.</p>
                   
                    <div>
                      <a v-if="DBitems.social.instgram" target="_blank" :href="DBitems.social.instgram">
              <img class="m-1 social_icon" src="/assets/image/icons/insta.png"
            /></a>
            <a v-if="DBitems.social.twiter" target="_blank" :href="DBitems.social.twiter"
              ><img class="m-1 social_icon" src="/assets/image/icons/twitter.svg"
            /></a>
            <a v-if="DBitems.social.facebook" target="_blank" :href="DBitems.social.facebook"
              ><img class="m-1 social_icon" src="/assets/image/icons/facebook.svg"
            /></a>
            <a v-if="DBitems.social.tiktok" target="_blank" :href="DBitems.social.tiktok">
              <img class="m-1 social_icon" src="/assets/image/icons/tiktok.png"
            /></a>
            <a  v-if="DBitems.social.whatsapp" target="_blank" 
            :href="'https://api.whatsapp.com/send?phone='+DBitems.social.whatsapp+'&text=Hello!'"
              ><img class="m-1 social_icon" src="/assets/image/icons/whats.png"
            /></a>
            <a v-if="DBitems.social.snapchat" target="_blank" :href="DBitems.social.snapchat"
              ><img class="m-1 social_icon" src="/assets/image/icons/snap.png"
            /></a>
                  
                    </div>
    
                </div>
                <div class="col-lg-2 col-md-6 col_foot" >
                    
    <p class="text_foot">{{ $t("quickAccess") }}</p>
    <li class="text_foot">
    
      <router-link
      class="footer_link"
      :to="{
              name: 'latestProducts',
              params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
            }"
                  >{{ $t("products") }}</router-link>
    </li>
    <li class="text_foot">
      <router-link
       class="footer_link"
      :to="{
              name: 'sections',
              params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
            }"
                  >
                    {{ $t("sections") }}
                </router-link>
              </li>
    <li class="text_foot">
       <router-link
        class="footer_link"
      :to="{
              name: 'brands',
              params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
            }"
                  >
                    {{ $t("brands") }}
                </router-link></li>
    <li class="text_foot">
      <router-link
       class="footer_link"
      :to="{
                name: 'shoppingCart',
                params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
              }"
                  >
                    {{$t('shoppingCart')}}
                </router-link>
    </li>
                </div>
                <div class="col-lg-2 col-md-6 col_foot"  >
                    
                    <p class="text_foot">{{$t('support')}}</p>
                    <li class="text_foot">
    
    <router-link
    class="footer_link"
    :to="{
            name: 'home',
            params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
          }"
                >{{ $t("home") }}</router-link>
  </li>
  <li class="text_foot">
    <router-link
     class="footer_link"
    :to="{
            name: 'about',
            params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
          }"
                >
                  {{ $t("about") }}
              </router-link>
            </li>
  <li class="text_foot">
     <router-link
      class="footer_link"
    :to="{
            name: 'privacy',
            params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
          }"
                >
                  {{ $t("privacyPolicy") }}
              </router-link></li>
  <li class="text_foot">
    <router-link
     class="footer_link"
    :to="{
              name: 'contactUs',
              params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
            }"
                >
                  {{$t('contactUs')}}
              </router-link>
  </li>
                                </div>
                                <div class="col-lg-4 col-md-6 col_foot">
                    
                                    <p class="text_foot">{{ $t("contact") }}</p>
                                    <div class="text_foot" >
                                        <i class="fa fa-phone icon_footer" style="display: inline-block;"> </i>

                                        <a dir="ltr" :href="'tel:'+DBitems.social.phone"  target="_blank" style="display: inline-block; color:white; text-decoration: none;">
                                          {{ DBitems.social.phone }}
                                        </a>
                                    <div class="text_foot"  v-if="DBitems.social.other_phone">

                                        <i 
                                        class="fa fa-phone icon_footer" style="display: inline-block;"> </i>

                                        <a dir="ltr" :href="'tel:'+DBitems.social.other_phone"  target="_blank" style="display: inline-block; color:white; text-decoration: none;">
                                          {{ DBitems.social.other_phone }}
                                        </a>
                                      </div>
                                    </div>
                                   <div  v-if="DBitems.social.email" class="text_foot" >    
                                     <i class="fa fa-envelope icon_footer" style="display: inline-block;"> </i>

                                    <p style="display: inline-block;">
                                      {{ DBitems.social.email }}
                                    </p>
                                </div>
                                <div v-if="DBitems.social.address" class="text_foot">
    
                              
                                     <i class="fa fa-map-marker icon_footer" style="display: inline-block;"> </i>
                                     <p style="display: inline-block;"> {{DBitems.social.address}}</p>
                                    
                                                </div>
    
               </div>
            </div>
            </div>
        </section>
    </footer>
    
  </template>
  
  <script>
  //import { KinesisContainer, KinesisElement} from 'vue-kinesis'
  export default {
    // eslint-disable-next-line
    /*eslint-disable */
    name: "Footer",
    //components:{ KinesisContainer, KinesisElement },
    props: {
      // msg: String,
    },
    data: function () {
      return {
        //storeName: "markat",
        logo: "",
        DBitems: null,
        loading: false,
      };
    },
    created() {
      this.loading = true;
      this.$store.storeName = this.$store.state.storeName;
      console.log(this.$store.storeName);
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName + "/footer",
        {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          })
        .then((response) => (this.DBitems = response.data.data))
        .then(() => (this.loading = false));
    },
    methods: {
      updatedata() {
        this.loading = true;
      this.$store.storeName = this.$store.state.storeName;
      console.log(this.$store.storeName);
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName + "/footer",
        {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          })
        .then((response) => (this.DBitems = response.data.data))
        .then(() => (this.loading = false));
        this.$store.state.langchange1 = false;
        
      }
    }
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
   @media only screen and (max-width: 600px) {
  
  .footer_saved
  {
          float: none !important;
      margin: 0px !important;
      margin-top:3%;
      position: relative;
      right: 0px !important;
      /* pointer-events: none; */
  }
  
  .footer_saved2
  {
          float: none !important;
      margin: 0px !important;
      position: relative;
      margin-top:3%;
      right: 0px !important;
      /* pointer-events: none; */
  }
  }
  </style>
  
<template>
<section>
  
  <div
        v-if="loading"
        class="d-flex flex-row justify-content-center"
        style="margin-top: 100px; margin-bottom: 200px"
      >
        <!-- LOADER -->
<div class="preloader">
    <div class="lds-ellipsis">
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>
<!-- END LOADER -->

      </div>
    <!-- START SECTION BREADCRUMB -->
<div class="breadcrumb_section bg_gray page-title-mini">
    <div class="container"><!-- STRART CONTAINER -->
        <div class="row align-items-center">
        	<div class="col-md-6">
                <div class="page-title">
            		<h1>{{ $t('shoppingCart') }}</h1>
                </div>
            </div>
            <div class="col-md-6">
                <ol class="breadcrumb justify-content-md-end">
                    <li class="breadcrumb-item">
                        <router-link :to="{
                               name: 'home',
                              params: { 
                                storeName: this.$store.storeName ,
                                lang: $i18n.locale},
                                   }">
                        {{ $t('home') }}</router-link>
                        </li>
                    <li class="breadcrumb-item active">{{ $t('shoppingCart') }}</li>
                </ol>
            </div>
        </div>
    </div><!-- END CONTAINER-->
</div>
<!-- END SECTION BREADCRUMB -->


<!-- START SECTION SHOP -->
<div v-if="this.items.length != 0" class="section">
  <div
          v-if="this.$localStorage.get(this.$store.storeName) == null && this.items.length != 0"
          style="display: inline;color:#9c0008; margin: 10%;"
        >( {{ $t("loginMessage1") }}  <router-link  class=""
          style="color:#9c0008 ; text-decoration: 2px underline !important;
  text-underline-offset: 5px; font-weight: bold;"
          :to="{
            name: 'login',
            params: { storeName: this.$store.storeName,lang: $i18n.locale },
          }">{{ $t("loginMessage2") }}</router-link>  {{ $t("loginMessage3") }} )
          </div>
  

	<div class="container">


   
          <br>
        <div class="row">
            <div class="col-12">
                <div class="table-responsive shop_cart_table">
                	<table class="table">
                    	<thead>
                        	<tr>
                            	<th class="product-thumbnail">&nbsp;</th>
                                <th class="product-name">{{ $t('product') }}</th>
                                <th class="product-price">{{ $t('price') }}</th>
                                <th class="product-quantity">{{ $t('quantity') }}</th>
                                <th class="product-subtotal">{{ $t('total') }}</th>
                                <th class="product-remove">{{ $t('remove') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <cart_item v-for="item in this.items" :key="item" 
                                :item-id="item"
                                v-if="item"
                                 >

                                </cart_item>
                        </tbody>
                       
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
            	<div class="medium_divider"></div>
            	<div class="divider center_icon"><i class="ti-shopping-cart-full"></i></div>
            	<div class="medium_divider"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
            	<div class="border p-3 p-md-4">
                    <div class="heading_s1 mb-3">
                        <h6>{{ $t('Cart Totals') }}</h6>
                    </div>
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                               
                                <tr>
                                    <td class="cart_total_label">{{ $t('Cart Subtotal') }} : </td>
                                    <td>
                                      {{ price.toFixed(2) }}
                                      </td>
                                  
                                    
                                </tr>
                                <tr v-if="$store.state.tax!=0&&$store.state.tax != null">
                                  <td  class="cart_total_label">  
                                    {{ $t("tax") }} ( {{ $store.state.tax }}% ): 

{{ (price.toFixed(2)* $store.state.tax/100).toFixed(2)}}
</td>
<td class="cart_total_amount">{{ ( price.toFixed(2)* $store.state.tax/100).toFixed(2)}}</td>
                                  </tr>
                               
                                <tr>
                                    <td class="cart_total_label">{{ $t('total') }}</td>
                                    <td class="cart_total_amount">
                                      <strong >{{
                
                
                    (this.price +
                      ($store.state.tax * this.price) / 100 
                
                ).toFixed(2)
              }} {{ unit }}</strong></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                   
                    <div class=" d-flex flex-row justify-content-start align-items-start mb-5 ">
                      <router-link 
                    v-if="this.$localStorage.get(this.$store.storeName)"
               
            :to="{
                  name: 'checkout',
                  params: { storeName: this.$store.storeName,lang: $i18n.locale },
                }"
                    class="btn btn-fill-out order_btn">{{ $t('confirmOrder') }}</router-link> 


                    <router-link 
               
            :to="{
              name: 'home',
              params: { storeName: $store.storeName,lang: $i18n.locale },
                }"
                    class="btn btn-fill-out shopping_btn">{{ $t("Continueshopping") }}</router-link>  

                      <a v-if="this.items.length != 0" 
     class="btn  btn-sm clear-cart"
     style="cursor:pointer;
     color:rgb(214, 21, 21);
     font-size:16px;
    
     "
  @click="show = true">
    <i class="ti-eraser"></i>
         
         {{ $t("Emptycart") }}
      
     </a>    
   
             </div>

  



                </div>

                
            </div>

<!-- test -->


    


    

<!-- end test -->
           
        </div>

        
    </div>
</div>

<div v-else>
 <div class="container">
          <h4
            style="text-align: center; text-decoration: 2px underline; text-underline-offset: 3px; margin-top:1%;"
          >{{ $t('cartEmpty')}} !!</h4>
          <a
      style="text-align: center; text-decoration: 2px underline; text-underline-offset: 3px; 
      margin-top:1%;
      cursor:pointer;
      "
        
        @click="$router.push({
              name: 'home',
              params: { storeName: $store.storeName,lang: $i18n.locale },
            })
            "
      >{{ $t("Continueshopping") }}</a>
    
      <img
        v-if="this.items.length == 0"
        style="width: 50%; margin-left: 25%; margin-right: 25%;    
         filter: grayscale(0.85);"
        src="https://etrolley.net/store/assets/image/emptyCard.gif"
      />

      
       </div>
      </div>
<!-- END SECTION SHOP -->
<Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{$t('confirmdeleteAll')}}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="deleteAllCookies() ">{{$t('confirm')}}</button>
                <button
                  class="modal-default-button"
                  @click="(show = false), $emit('close')"
                >{{$t('cancel')}}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    </section>
</template>

<script>
import cart_item from "../components/cart_item_shoopping.vue";
export default {
  components: { cart_item},
  data: function() {
    return {
      unit: this.$store.state.unit,
      langChange:'',
      items: [],
      products: [],
      loading: false,
      show: false,
      price: 0,
    };
  },
  watch: {
    langChange() {
      
console.log('///////////11111111111')
      this.loading=true;
      if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    let AllCookies = this.$cookies.keys();
    this.products = [];
    this.items = [];
    this.price = 0;

    for (let i = 0; i < AllCookies.length; i++) {
      if (AllCookies[i].split("/")[0] == this.$store.storeName) {
        this.items.push(AllCookies[i]);
        this.products.push(this.$cookies.get(AllCookies[i]));

        if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.price += this.$cookies.get(AllCookies[i]).offer_price;
        } else {
          this.price += this.$cookies.get(AllCookies[i]).total_price;
        }
        //console.log(this.items);
      }
    }
    console.log(this.items);

    //console.log(this.items[0])
    /* this.$cookies.isKey(
          this.$store.storeName +
            product.product_id +
            product.final_option +
            product.option_check
        )*/
        this.loading=false;
  }
  },
  updated() {
    this.langChange=this.$i18n.locale
    console.log('/*******************/',this.langChange)
    
  },
  created() {
    this.loading=true;
    if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    let AllCookies = this.$cookies.keys();
    this.products = [];
    this.price = 0;

    for (let i = 0; i < AllCookies.length; i++) {
      if (AllCookies[i].split("/")[0] == this.$store.storeName) {
        this.items.push(AllCookies[i]);
        this.products.push(this.$cookies.get(AllCookies[i]));

        if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.price += this.$cookies.get(AllCookies[i]).offer_price;
        } else {
          this.price += this.$cookies.get(AllCookies[i]).total_price;
        }
        //console.log(this.items);
      }
    }
    console.log(this.items);

    //console.log(this.items[0])
    /* this.$cookies.isKey(
          this.$store.storeName +
            product.product_id +
            product.final_option +
            product.option_check
        )*/

        this.loading=false;

        if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  methods: {
    updateProducts() {
      // console.log("hiiiiiiiiiiiiiii");
      this.items = [];
      this.products = [];
      this.price = 0;

      let AllCookies = this.$cookies.keys();
      for (let i = 0; i < AllCookies.length; i++) {
        if (AllCookies[i].split("/")[0] == this.$store.storeName) {
          this.items.push(AllCookies[i]);
          this.products.push(this.$cookies.get(AllCookies[i]));

          if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.price += this.$cookies.get(AllCookies[i]).offer_price;
        } else {
          this.price += this.$cookies.get(AllCookies[i]).total_price;
        }
          //console.log(this.items);
        }
      }
    },
    updatedata() {

      this.loading = true;
      this.items = [];
      this.products = [];
      this.price = 0;
      
      let AllCookies = this.$cookies.keys();
      for (let i = 0; i < AllCookies.length; i++) {
        if (AllCookies[i].split("/")[0] == this.$store.storeName) {
          this.items.push(AllCookies[i]);
          this.products.push(this.$cookies.get(AllCookies[i]));

          if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.price += this.$cookies.get(AllCookies[i]).offer_price;
        } else {
          this.price += this.$cookies.get(AllCookies[i]).total_price;
        }
          console.log(this.items);
        }
      }
      this.$store.state.langchange = false;
      setTimeout(() => {
        // this.updateall();
        this.loading = false;
      }, 0.1);
    },
    deleteAllCookies() {
      this.loading = true;
      for (let i = 0; i < this.items.length; i++) {
        this.$cookies.remove(this.items[i]);
      }
      this.items = [];

      console.log("hiiiiiiii" + this.$cookies.keys());
      this.$store.state.cartCounter = 0;
      this.loading = false;
      this.show = false;
    },
    confirmOrder() {
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/check_all_item_card",
          {
            products: this.products
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.$router.push({
              name: "confirmOrder",
              params: { storeName: this.$store.storeName,lang: this.$i18n.locale }
            });
          } else {
            alert(response.data.message);
            // this.couponMsg = response.data.message;
          }
          //this.addresses = response.data.data.addresses.addresses;
          // console.log(this.addresses);
        })
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
                     window.location.href="/";

          }
        });
    }
  }
};
</script>
<style>
.ltr .clear-cart
{
  position: absolute;
  left:auto;
  right:0px;
}

.rtl .clear-cart
{
  position: absolute;
  left:0px !important;
  right:auto !important;
}

.order_btn.btn-fill-out::before, .order_btn.btn-fill-out::after 
{
  background-color: #23b14d;
  color:var(--primary-color) !important;
}
.shopping_btn.btn-fill-out::before, .shopping_btn.btn-fill-out::after 
{
  background-color: #c3c3c3;
  color:black;
  
}
.order_btn,.shopping_btn
{
  border-color: transparent !important;
}
</style>
<template>
  <section id="Second" v-if="this.$localStorage.get(this.$store.storeName) != null">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-4 mb-4">
          <router-link 
          :to="{
              name: 'profile',
              params: { storeName: this.$store.storeName,lang: $i18n.locale },
            }"
           class="profile_btn">
            {{
            $t("profile")
            }}
          </router-link>
          <router-link
          :to="{
              name: 'myAddresses',
              params: { storeName: this.$store.storeName,lang: $i18n.locale },
            }">
            {{
            $t("myAddresses")
            }}
          </router-link>
          <router-link 
          :to="{
              name: 'myOrders',
              params: { storeName: this.$store.storeName,lang: $i18n.locale },
            }"
          class="profile_btn">
            {{
            $t("myOrders")
            }}
          </router-link>
        </div>
        <div class="col-lg-9 col-md-8 mb-4">
          <div class="container">
            <form
              autocomplete="false"
              @submit.prevent="submitInfo"
              @keydown="form.onKeydown($event)"
            >
              <div class="row">
                <!--<div class="col-lg-4 mb-2">
                <div
                  class="
                    mt-3
                    card-img-actions
                    d-flex
                    flex-row
                    justify-content-center
                  "
                >
                  <div class="file-uploader">
                    <img class="preview-1" :src="preview" />
                    <label name="upload-label" class="upload-img-btn">
                      <input
                        type="file"
                        class="upload-field-1"
                        style="display: none"
                        @change="readURL"
                        accept=".png, .jpg, .jpeg"
                      />
                      <img
                        class="icon"
                        src="../../public/assets/image/icons/pencil.svg"
                      />
                    </label>
                  </div>
                </div>
                </div>-->
                <div class="col-lg-6 col-md-6">
                  <h4>{{ $t("name") }}</h4>
                  <input
                    id="name"
                    autocomplete="false"
                    class="input-field"
                    type="text"
                    name="name"
                    v-model="form.name"
                    required
                    maxlength="30"
                  />
                </div>
                <div v-if="this.$store.state.auth==1" class="col-lg-6 col-md-6">
                  <h4>{{ $t("Email") }}</h4>
                  <input
                    autocomplete="false"
                    class="input-field"
                    type="email"
                    name="email"
                    v-model="form.email"
                    required
                    maxlength="50"
                  />
                  <button
                    type="submit"
                    :disabled="form.busy"
                    class="button1"
                  >{{ $t("SaveChanges") }}</button>
                  <div
                    v-if="errorMessage != ''"
                    style="
                      text-align: center;
                      color: var(--primary-color);
                      padding: 5px;
                    "
                  >
                    <p style="margin: 0">{{ errorMessage }}</p>
                  </div>
                </div>

                <div v-else class="col-lg-6 col-md-6">
                  <h4>{{ $t("phone") }}</h4>
                  <div class="input1">
            <input
              class="input-field"
              type="text"
              name="phone"
              autocomplete="false"
              v-model="phone"
              placeholder="xxxx xxxx"
                required
                :minlength=$store.state.country_length
                :maxlength=$store.state.country_length

              />
            <span class="flag">  <h2>{{ $store.state.country_code }}</h2>
              <img :src="this.$store.state.country_flag" >
</span>
          </div>
                  <button
                    type="submit"
                    :disabled="form.busy"
                    class="button1"
                  >{{ $t("SaveChanges") }}</button>
                  <div
                    v-if="errorMessage != ''"
                    style="
                      text-align: center;
                      color: var(--primary-color);
                      padding: 5px;
                    "
                  >
                    <p style="margin: 0">{{ errorMessage }}</p>
                  </div>
                </div>

              </div>
            </form>
            <button
              v-if="this.$store.state.auth == 0||this.$store.state.auth == 1"
              @click="resetpass(0)"
              class="resetPass"
            >
              <p>{{ $t("resetPass") }}</p>
            </button>
            <div v-if="this.$store.state.auth == 0||this.$store.state.auth == 1">
              <!--<vodal
                :show="forgetVodal"
                animation="flip"
                @hide="forgetVodal = false"
                :width="500"
                :height="350"
                style="margin-top: 20px"
              >
                <div class="container" style="width: 80%; margin: 10%">
                  <h5 style="color: var(--primary-color)">{{ $t("sendTo") }} :</h5>
                  <button class="button text-center mt-5" @click="resetpass(0)">{{ $t("Email") }}</button>
                  <button class="button text-center mt-3" @click="resetpass(1)">{{ $t("Phone") }}</button>
                  <p style="text-align: center">{{ errorMessageVodal }}</p>
                </div>
              </vodal>-->
              <vodal
                :show="codeVodal"
                animation="slideLeft"
                @hide="codeVodal = false"
                :width="500"
                :height="350"
                style="margin-top: 20px"
              >
                <div class="container" style="width: 80%; margin: 10%">
                  <h5
                    style="
                    color: var(--primary-color);
                    margin-left: 20px;
                    margin-right: 20px;
                  "
                  >{{ $t("EntertheCode") }} :</h5>
                  <div dir="ltr" class="mt-5">
                    <CodeInput
                      :loading="false"
                      required
                      class="input"
                      @change="onChange"
                      @complete="onComplete"
                    />
                  </div>
                  <button class="button text-center mt-5" @click="checkCode()">{{ $t("continue") }}</button>
                  <p style="text-align: center; margin-top: 10px">{{ errorMessageVodal }}</p>
                </div>
              </vodal>
              <vodal
                :show="newPassVodal"
                animation="slideLeft"
                @hide="newPassVodal = false"
                :width="500"
                :height="350"
                style="margin-top: 20px"
              >
                <div class="container" style="width: 80%; margin: 10%">
                  <h5
                    style="
                    color: var(--primary-color);
                    margin-left: 20px;
                    margin-right: 20px;
                  "
                  >{{ $t("EnterNewPass") }} :</h5>
                  <div dir="ltr" class="mt-5">
                    <input
                      id="newpassword"
                      class="input-field"
                      type="password"
                      name="name"
                      v-model="Newpassword"
                      :placeholder="$t('EnterPass')"
                      required
                      minlength="8"
                    />
                  </div>
                  <button
                    class="button text-center mt-5"
                    @click="SaveChanges()"
                  >{{ $t("SaveChanges") }}</button>
                  <p style="text-align: center; margin-top: 10px">{{ errorMessageVodal }}</p>
                </div>
              </vodal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
//import testImage from "../../public/assets/image/icons/defult.svg";
import Form from "vform";

import CodeInput from "vue-verification-code-input";

import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";
import "vodal/slide-left.css";

export default {
  components: {
    Vodal,
    CodeInput
  },
  data: function() {
    return {
      //DBitems: null,
      forgetVodal: false,
      codeVodal: false,
      newPassVodal: false,

      errorMessageVodal: "",
      passemail: "",
      Newpassword: null,
      checkcomplete: false,
      finalcode: null,
      phone:null,
      errorMessage: "",
      form: new Form({
        //preview: testImage,
        email: null,
        name: null,
        phone:null,
      })
    };
  },
  created() {
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/profile",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.form.email = response.data.data.email;
        this.form.name = response.data.data.name;
        this.phone=response.data.data.phone.substr(4);
        console.log('phone ' +this.phone);
        //console.log(this.form)
      })
      .catch(error => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
          window.location.href =
            "/" ;
        }
      });
      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  methods: {
    /*readURL(e) {
      this.preview = URL.createObjectURL(e.target.files[0]);
    },*/
    async submitInfo() {
      this.form.phone=this.$store.state.country_code+this.phone;

      const response = await this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/profile/update",
          {
            email: this.form.email,
            name: this.form.name,
            phone:this.form.phone
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.errorMessage = response.data.message;
            setTimeout(() => {
              this.errorMessage = "";
            }, 3000);
          } else if (response.data.code == "-1") {
            this.errorMessage = response.data.message;
            setTimeout(() => {
              this.errorMessage = "";
            }, 3000);
          }
          if (response.data.error) {
            this.errorMessage = response.data.error;
            setTimeout(() => {
              this.errorMessage = "";
            }, 3000);
          }
        })
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href="/";
          }
        });
    },
    resetpass(type) {
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/reset_password",
          {
            ///update_password
            send_type: type
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.forgetVodal = false;
            this.codeVodal = true;
            // console.log(response.data.message);
          } else if (response.data.code == "-1") {
            this.errorMessageVodal = response.data.message;
            setTimeout(() => {
              this.errorMessageVodal = "";
            }, 3000);
          }
          if (response.data.error) {
            this.errorMessageVodal = response.data.message;
            setTimeout(() => {
              this.errorMessageVodal = "";
            }, 3000);
          }
        })
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href="/";
          }
        });
    },
    checkCode() {
      if (this.checkcomplete == true) {
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/verivey_reset_password",
            {
              ///update_password
              code: this.finalcode
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName)
              }
            }
          )
          .then(response => {
            if (response.data.code == "1") {
              this.forgetVodal = false;
              this.codeVodal = false;
              this.newPassVodal = true;
              // console.log(response.data.message);
            } else if (response.data.code == "-1") {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
            if (response.data.error) {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
          })
          .catch(error => {
            if (error.response.status == "401") {
              this.$localStorage.remove(this.$store.storeName);
              window.location.href =
                "/";
            }
          });
      } else {
        if (this.$i18n.locale == "en") {
          this.errorMessageVodal = "your code isn't complete";
          setTimeout(() => {
            this.errorMessageVodal = "";
          }, 3000);
        } else {
          this.errorMessageVodal = "الرمز المدخل غير مكتمل";
          setTimeout(() => {
            this.errorMessageVodal = "";
          }, 3000);
        }
      }
    },
    SaveChanges() {
      if (this.Newpassword != null) {
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/auth/update_password",
            {
              ///update_password
              password: this.Newpassword
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName)
              }
            }
          )
          .then(response => {
            if (response.data.code == "1") {
              this.forgetVodal = false;
              this.codeVodal = false;
              this.newPassVodal = false;
              // console.log(response.data.message);
            } else if (response.data.code == "-1") {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
            if (response.data.error) {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
          })
          .catch(error => {
            if (error.response.status == "401") {
              this.$localStorage.remove(this.$store.storeName);
              window.location.href =
                "/" ;
            }
          });
      } else {
        this.errorMessageVodal = "error";
        setTimeout(() => {
          this.errorMessageVodal = "";
        }, 3000);
      }
    },
    onChange(v) {
      //console.log("onChange ", this.phone);
      this.checkcomplete = false;
    },
    onComplete(v) {
      //console.log("onComplete ", v);
      this.finalcode = v;
      this.checkcomplete = true;
    }
  }
};
</script>
<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

#Second {
  margin-top: 120px;
  min-height: 500px;
}
a {
  display: block;
  background-color: #f8f8f8;
  padding: 13px 0 17px 0;
  width: 80%;
  border-radius: 15px;
  color: var(--secondary-color);
  font-family: "Araboto-Bold";
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
}
a:hover {
  color: var(--primary-color) !important;
}
.router-link-exact-active,
.router-link-exact-active:focus {
  background-color: #fff;
  border: 2px solid var(--primary-color);
}

input {
  border: 0;
  border: 2px solid var(--primary-color);
  background-color: transparent;
  width: 100%;
  height: 32px;
  margin-top: 1%;
  border-radius: 10px;
  text-align: center;
  color: var(--secondary-color);
  padding: 25px 0 30px 0;
}

h4 {
  font-size: 16px;
  font-family: "Araboto-Medium";
  color: #000;
  margin-top: 20px;
  margin-bottom: 10%;
}

.button1 {
  background-color: var(--primary-color);
  border-radius: 10px;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  padding: 10px 0 15px;
  text-align: center;
  border: 2px solid var(--primary-color);
  color: #fff;
  font-size: 16px;
  font-family: "Araboto-Medium";
  margin-top: 40px;
}

.button1:hover,
.button:hover {
  background-color: #fff;
  color: #000;
}

.button {
  background-color: var(--primary-color);
  border-radius: 10px;
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  padding: 10px 0 15px;
  text-align: center;
  border: 2px solid var(--primary-color);
  color: #fff;
  font-size: 16px;
  font-family: "Araboto-Medium";
  margin-top: 20px;
}

.resetPass {
  border: 0;
  margin-top: 50px;
  background: transparent;
  text-decoration: underline;
  text-underline-offset: 6px;
}
.resetPass:hover {
  color: var(--primary-color);
  text-underline-offset: 10px;
}
.card-img-actions .preview-1 {
  margin-top: 30px;
  width: 170px !important;
  height: 170px !important;
  border-radius: 50%;
  border: 4px solid var(--primary-color);
  object-fit: cover;
}

.icon {
  margin-top: -120px;
}

/*@media (min-width: 1400px) {
  .container {
    max-width: 1400px !important;
  }
}*/

@media (max-width: 992px) {
  a {
    width: 100%;
  }
  h4 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .card-img-actions .preview-1 {
    margin-top: 10px;
  }
  .card-img-actions {
    margin-top: 0 !important;
  }
}
@media (max-width: 772px) {
  a {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.DBitems.product)?_c('a',{staticClass:"item_remove",on:{"click":_vm.deleteItem}},[_c('i',{staticClass:"ion-close"})]):_vm._e(),_c('router-link',{attrs:{"to":{
            name: 'product',
            params: {
             storeName: _vm.$store.storeName, 
            id: this.item.product_id ,
            name:this.DBitems.product.name,
            lang: _vm.$i18n.locale
            },
          }}},[_c('img',{staticClass:"item_cart_img",attrs:{"src":_vm.DBitems.product.image,"alt":_vm.DBitems.product.name}}),_vm._v(" "+_vm._s(_vm.DBitems.product.name)+" ")]),_c('span',{staticClass:"cart_quantity"},[_vm._v(" "+_vm._s(_vm.item.quantity)+" x "),_c('span',{staticClass:"cart_amount"},[(_vm.item.offer_price==0)?_c('span',[_vm._v(" "+_vm._s((_vm.item.total_price.toFixed(2) / _vm.item.quantity).toFixed(2))+" ")]):_c('span',[_vm._v(_vm._s((_vm.item.offer_price.toFixed(2) / _vm.item.quantity).toFixed(2))+" ")]),( _vm.$i18n.locale == 'ar')?_c('span',{staticClass:"price_symbole"},[_vm._v(" "+_vm._s(_vm.$store.state.unitAR)+" ")]):_vm._e(),( _vm.$i18n.locale == 'en')?_c('span',{staticClass:"price_symbole"},[_vm._v(" "+_vm._s(_vm.$store.state.unit)+" ")]):_vm._e()])]),_c('Transition',{attrs:{"name":"modal"}},[(_vm.show)?_c('div',{staticClass:"modal-mask"},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"modal-header"},[_vm._t("header",function(){return [_vm._v(_vm._s(_vm.$t('confirmdelete')))]})],2),_c('div',{staticClass:"modal-footer"},[_vm._t("footer",function(){return [_c('button',{staticClass:"modal-default-button",on:{"click":function($event){return _vm.deleteItem()}}},[_vm._v(_vm._s(_vm.$t('confirm')))]),_c('button',{staticClass:"modal-default-button cancel",on:{"click":function($event){(_vm.show = false), _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t('cancel')))])]})],2)])])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
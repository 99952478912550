<template>
  <div v-if="this.show==true">
    <div
      v-if="loading"
      class="d-flex flex-row justify-content-center"
      style="margin-top: 100px; margin-bottom: 200px;width:400px;height:300px"
    >
      <HalfCircleSpinner
        :animation-duration="1000"
        :dot-size="35"
        :dots-num="3"
        color="var(--primary-color)"
      />
    </div>
  <div  v-if="!loading" class="card">
    <div
    
      style="
        margin-top: 30px;
        margin-bottom: 20px;
        text-align: center;
        font-size: 18px;
      "
    >
      <h4 
      v-if="this.$store.state.apperance_map_address==1"
       style="color: var(--primary-color)">
        <img src="https://etrolley.net/store/assets/image/icons/location.svg" />
        {{ $t("determineLocation") }}
      </h4>

     
      <h3   v-else class="font-semibold address_title" for="title">
        {{ $t("AddAdderss") }}</h3>
      
    </div>
    <div v-if="this.$store.state.apperance_map_address==1" class="map">
      <GmapMap
    :center="center"
    :zoom="18"
    map-style-id="roadmap"
    :options="mapOptions"
    class="mapStyle"
    style="width: 80%; height: 50vmin"
    ref="mapRef"
    @click="handleMapClick"
  >
    <GmapMarker
      :position="marker.position"
      :clickable="true"
      :draggable="true"
      @drag="handleMarkerDrag"
      @click="panToMarker"
    />
  </GmapMap>
    </div>

<!--  -->
<div class="grid grid-cols-1">
                                        <div class="mb-4">
                                            <label class="font-semibold" for="title">
                                              {{$t('placeHolderTitle')}} *:</label>
                                            <input 
                                            type="text" 
                                            class="mt-3 w-full py-2
                                             px-3 h-10 bg-transparent 
                                             dark:bg-slate-900 dark:text-slate-200 
                                             rounded outline-none border border-gray-100 
                                             dark:border-gray-800 focus:ring-0" 
                                             name="name"
                                             autocomplete="false"
                                             :placeholder="$t('placeHolderTitle')"
                                                 required
                                                maxlength="255"
                                               minlength="3"
                                                id="title"
                                                v-model="title"
                                    >
                                        </div>

                                        <div class="mb-4">
                                            <label class="font-semibold" for="country">
                                              {{$t('country')}} *:</label>
                                        
                                          <v-select
                                         v-model="country"
                                          :reduce="(option) => option.id"
                                          :options="countries"
                                           label="city"
                                           :placeholder="$t('country')"
                                           class="form-select form-input mt-2 
                                        w-full px-3 h-10 bg-transparent 
                                        dark:bg-slate-900 dark:text-slate-200 
                                        rounded outline-none border border-gray-100 
                                        dark:border-gray-800 focus:ring-0"
                                        id="country"
                                        >
                                      </v-select>
                                        
                                        </div>



                                        <div class="mb-4">
                                            <label class="font-semibold" for="city">
                                              {{$t('city')}} *:</label>
                                        
                                          <v-select
                                         v-model="city"
                                          :reduce="(option) => option.id"
                                          :options="cities"
                                           label="city"
                                           :placeholder="$t('city')"
                                           class="form-select form-input mt-2 
                                        w-full px-3 h-10 bg-transparent 
                                        dark:bg-slate-900 dark:text-slate-200 
                                        rounded outline-none border border-gray-100 
                                        dark:border-gray-800 focus:ring-0"
                                        id="city"
                                        >
                                      </v-select>
                                        
                                        </div>


                                        <div class="mb-4">
                                            <label class="font-semibold" for="region">
                                              {{$t('region')}} *:</label>
                                        
                                          <v-select
                                          v-model="region"
                                         :reduce="(option) => option.id"
                                          :options="regions"
                                          label="city"
                                         :placeholder="$t('region')"
                                        class="form-select form-input mt-2 
                                        w-full py-2 px-3 h-10 bg-transparent 
                                        dark:bg-slate-900 dark:text-slate-200 
                                        rounded outline-none border border-gray-100 
                                        dark:border-gray-800 focus:ring-0"
                                        id="region"
                                        >
                                      </v-select>
                                        
                                        </div>

                                        <div class="mb-4">
                                            <label class="font-semibold" for="street">
                                              {{$t('street')}} :</label>
                                            <input 
                                            class="mt-3 w-full py-2
                                             px-3 h-10 bg-transparent 
                                             dark:bg-slate-900 dark:text-slate-200 
                                             rounded outline-none border border-gray-100 
                                             dark:border-gray-800 focus:ring-0" 
                                             autocomplete="false"
                                                 required
                                                 type="text"
                                               :placeholder="$t('street')"
                                                maxlength="255"
                                                id="street"
                                                v-model="street"
                                    >
                                        </div>

                                        <div class="mb-4">
                                            <label class="font-semibold" for="building">
                                              {{$t('building')}} :</label>
                                            <input 
                                            class="mt-3 w-full py-2
                                             px-3 h-10 bg-transparent 
                                             dark:bg-slate-900 dark:text-slate-200 
                                             rounded outline-none border border-gray-100 
                                             dark:border-gray-800 focus:ring-0" 
                                             name="name"
                                             autocomplete="false"
                                                 type="text"
                                                 :placeholder="$t('building')"
                                                  maxlength="255"
                                                   v-model="building"
                                                   id="building"
                                    >
                                        </div>


                                        <div class="mb-4">
                                            <label class="font-semibold" for="detailes">
                                              {{$t('OtherDetails')}} :</label>
                                            <textarea
                                         v-model="other_details"
                                            class="mt-3 w-full py-2
                                             px-3  bg-transparent 
                                             dark:bg-slate-900 dark:text-slate-200 
                                             rounded outline-none border border-gray-100 
                                             dark:border-gray-800 focus:ring-0" 
                                                   id="detailes"
                                    >
                                    </textarea>
                                        </div>
                                        <!--  -->
                                    <button class="py-2 px-5 inline-block 
                                 tracking-wide align-middle 
                                 duration-500 text-base text-center 
                                 bg-orange-500 text-white rounded-md w-full" 
                                 @click="addAddress">{{ $t("AddAdderss") }}</button>


  </div>
<!--  -->
    <div
      v-if="Message != ''"
      style="
        position: fixed;
        width: 250px;
        bottom: 80px;
        left: 50px;
        min-height:80px;
        border-radius:5px;
        border: 2px solid var(--primary-color);
        text-align: center;
        padding: 5px;
        background: linear-gradient(#fff, var(--primary-color) 200%);
      "
    >
      <p style="margin: 0">{{ Message }}</p>
    </div>
  </div>
  </div>
</template>


<script>
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

export default {
  components: { HollowDotsSpinner, HalfCircleSpinner },
  data: function () {
    return {
      show: true,
      loading: false,
      title: null,
      street: null,
      building:null,
      other_details: null,
      city: null,
      country:[],
      countries: [],
      cities: [],
      region: null,
      regions: [],
      lat: 0,
      long:0,
      Message: "",

      marker: { position: { lat: 300, lng: 300 } },
      center: { lat: 10, lng: 10 },
      mapOptions: {
        disableDefaultUI: true,
      },
    };
  },

  created() {
    this.loading=true;
    console.log("hiiiiiiiiiiiiiii");
    if(this.$store.state.apperance_map_address==1)
    this.geolocate();
   
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/addresses/create/v1",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          },
        }
      )
      .then((response) => {
        this.countries = response.data.data.countries;
      }).then(this.loading=false);
  },
  methods: {
    addAddress() {
      
if(this.$store.state.apperance_map_address==1)
{
           this.lat=this.marker.position.lat,
            this.long=this.marker.position.lng
}
      if(this.title&&this.title!="")
{
  this.loading=true;

      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/addresses",
          {
            title: this.title,
            city_id: this.city,
            region_id: this.region,
            street: this.street,
            building: this.building,
            other_details: this.other_details,
            lat: this.lat,
            long: this.long,
            
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          if (response.data.code == "1") {
            
            console.log(response.data.message);
            this.Message = response.data.message;
            try {
                this.$parent.$parent.updatAddress();
              } catch (Ex) {
                this.$parent.updatAddress();
              }
              this.loading=false
            setTimeout(() => {
              this.Message = "";
              this.show=false;
              this.$store.state.addAddress=false;
              //this.$router.go(this.$router.currentRoute)
            }, 3000);
          } else {
         
            console.log(response.data.message);
            this.Message = response.data.message;
           
      
            this.loading=false

      

            setTimeout(() => {
              this.Message = "";
            }, 3000);
          }
        });
      }
      else
      {
       
        document.getElementById('title').style="border:2px solid red !important;"
      
      }
    },
    //detects location from browser
     geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.panToMarker();
      });
    },
    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },
    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      this.$refs.mapRef.setZoom(18);
    },
    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      console.log(e);
    },
  },
  mounted() {
    if(this.$store.state.apperance_map_address==1)
    this.geolocate();
  },
  watch: {
    city(newVal) {
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/addresses/regions/" +
            this.city,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.regions = response.data.data.regions;
          if (this.regions.find((o) => o.id == this.region) == null) {
            this.region = null;
          }
        });
    },
    country(newVal) {
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/addresses/cities/" +
            this.country,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.cities = response.data.data.cities;
          if (this.cities.find((o) => o.id == this.city) == null) {
            this.city = null;
          }
        });
    },


    changeLang()
    {
      this.loading=true;
    console.log("hiiiiiiiiiiiiiii");
    if(this.$store.state.apperance_map_address==1)
    this.geolocate();
   
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/addresses/create/v1",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          },
        }
      )
      .then((response) => {
        this.countries = response.data.data.countries;
      }).then(this.loading=false);}
  },
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.card{
  margin:5%;
}
#Second {
  margin-top: 120px;
}

.card h4 {
  font-size: 15px;
  font-family: "Araboto-Medium";
  display: inline;
  color: var(--secondary-color);
}
.card h4:hover {
  color: var(--primary-color);
}
button {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  color: var(--secondary-color);
}
hr {
  height: 2px;
  margin: 12px;
  color: #cccccc;
}
[dir="rtl"] button img {
  left: 15px;
  margin-top: 10px;
  position: absolute;
}
[dir="ltr"] button img {
  right: 15px;
  margin-top: 10px;
  position: absolute;
}
a {
  display: block;
  background-color: #f8f8f8;
  padding: 13px 0 17px 0;
  width: 80%;
  border-radius: 15px;
  color: var(--secondary-color);
  font-family: "Araboto-Bold";
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
}

.router-link-exact-active,
.router-link-exact-active:focus {
  background-color: #fff;
  border: 2px solid var(--primary-color);
}
.mapStyle {
  margin-bottom: 20px;
  border-radius: 15px;
  margin-left: 10%;
  margin-right: 10%;
  height: 315px;
  width: 80%;
}
.input-icons {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.input-icons input,
textarea.form-control {
  width: 100%;
  border-radius: 9px;
  background-color: #fff;
  margin-bottom: 10px;
  border: 2px solid #cccccc;
  font-size: 13px;
  font-family: "Araboto-Medium";
  color: var(--secondary-color);
  padding: 10px 20px 15px 20px;
}

.button {
  background-color: var(--primary-color);
  border-radius: 10px;
  width: 60%;

  margin: 20px 20%;
  padding: 10px 15px;
  border: 2px solid var(--primary-color);
  color: #fff;
  font-size: 15px;
  font-family: "Araboto-Medium";
}

.button:hover {
  background-color: #fff;
  color: #000;
}

@media (max-width: 992px) {
  a {
    width: 100%;
  }
}
@media (max-width: 772px) {
  a {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
}
</style>

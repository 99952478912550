<template>
    <!-- START SECTION SHOP -->
<div class="section small_pt pb_70">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-md-6">
            	<div class="heading_s1 text-center">
                	<h2>{{ $t('Exclusive Products') }}</h2>
                </div>
            </div>
		</div>
        <div class="row">
        	<div class="col-12">
            	<div class="tab-style1">
                    <ul class="nav nav-tabs justify-content-center" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="arrival-tab" data-toggle="tab" href="#arrival" role="tab" aria-controls="arrival" aria-selected="true" @click="change_type('new')">{{ $t('New Arrival') }}</a>
                        </li>
                        <li class="nav-item" v-if="this.$store.state.apperance_best_saller==1">
                            <a class="nav-link" id="sellers-tab" data-toggle="tab" href="#sellers" role="tab" aria-controls="sellers" aria-selected="false" @click="change_type('best_saller')">{{ $t('Best Sellers') }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="featured-tab"  v-if="this.$store.state.apperance_fetured_products==1 " data-toggle="tab" href="#featured" role="tab" aria-controls="featured" aria-selected="false" @click="change_type('feautred')">{{$t('Featured')}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="special-tab"  v-if="this.$store.state.apperance_offers==1 " data-toggle="tab" href="#special" role="tab" aria-controls="special" aria-selected="false" @click="change_type('offer')">{{$t('Special Offer')}}
</a>
                        </li>
                    </ul>
                </div>
                <div class="tab-content">
                	<div class="tab-pane fade show active products_list_slick" >
                                  	
                                      <VueSlickCarousel 
                                      ref="slick"
                                      v-if="products.length !=0 &&this.loading==false&&this.$i18n.locale=='en'"
                                           :options="settings"
                                           @reInit="handlereInit"
                                      class="product_slider carousel_slider owl-carousel owl-theme nav_style1"
                                      
                                       >
                          <div  class="item one_product_list" v-for="(item, index) in this.products"
                      :key="index" 
                      >
                    <product_list
              :name="item.name"
              :description="item.description"
              :is_favorit="item.is_favorit"
              :image="item.image"
              :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
              :rate="item.rate"
              :count_view="item.count_views"
              :is_option="item.is_option"
              :is_offer="item.is_offer"
               :unit="item.unit"
               :new="item.is_new"
               :fetuered="item.is_fetured"
               :discount="item.discount"
               :qnt="item.qnt"
               :min_qnt="item.min_qnt"
               :max_qnt="item.max_qnt"
                        >
                            </product_list>
                          
                    </div>
                  </VueSlickCarousel>

                  	
                  <VueSlickCarousel 
                                      ref="slick"
                                      v-else-if="products.length !=0 &&this.loading==false&&this.$i18n.locale=='ar'"
                                           :options="settings_ar"
                                           @reInit="handlereInit"
                                      class="product_slider carousel_slider owl-carousel owl-theme nav_style1"
                                      
                                       >
                          <div  class="item one_product_list" v-for="(item, index) in this.products"
                      :key="index" 
                      >
                    <product_list
              :name="item.name"
              :description="item.description"
              :is_favorit="item.is_favorit"
              :image="item.image"
              :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
              :rate="item.rate"
              :count_view="item.count_views"
              :is_option="item.is_option"
              :is_offer="item.is_offer"
               :unit="item.unit"
               :new="item.is_new"
               :fetuered="item.is_fetured"
               :discount="item.discount"
               :qnt="item.qnt"
               :min_qnt="item.min_qnt"
               :max_qnt="item.max_qnt"
                        >
                            </product_list>
                          
                    </div>
                  </VueSlickCarousel>

                  <div v-else-if="this.loading">
                  <div class="empty text-center center-text">
                 
                  <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                  <br>
                  
                  </div>
                  </div>
                  <div v-else>
                  <div class="empty text-center center-text">
                 
                  <img src="/pet_shop/assets/images/empty.png"/>
                  <br>
                  {{ $t('empty') }}
                  </div>
                  </div>


                    
                  <a class="carousel-control-prev product_next" role="button" data-slide="prev" @click="prev_product()"><i class="ion-chevron-left"></i></a>
                  <a class="carousel-control-next product_prev"  role="button" data-slide="next" @click="next_product()"><i class="ion-chevron-right"></i></a>

                    </div>
                
                </div>
            </div>
        </div> 
    </div>
    
</div>
<!-- END SECTION SHOP -->


</template>
<script>
import product_list from "../components/product_list2.vue";
import VueSlickCarousel from 'vue-slick'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  components: { product_list ,VueSlickCarousel},
  data() {
    return {
        DBitems: null,
      loading: false,
      products:[],
      page: 1,
      count: 0,
      pageSize: 12,
      langChange:"",
      sort:'id',
      order:'desc',
      paginate:12,
      type:'new',
      settings:
        {
  "arrows": true,
  "dots": false,
  "infinite": true,
  "slidesToShow": 4,
  "slidesToScroll": 1,
  "autoplay": false,
  "speed": 2000,
  "autoplaySpeed": 2000,
  "cssEase": "linear",
  
  "responsive": [
    {
      "breakpoint": 1024,
      "settings": {
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "infinite": true,
      }
    },

    {
      "breakpoint": 900,
      "settings": {
        "slidesToShow": 2,
        "slidesToScroll": 1,
        "infinite": true,
      }
    },
    {
      "breakpoint": 600,
      "settings": {
        "slidesToShow": 2,
        "slidesToScroll": 1,
        "initialSlide": 1
      }
    },

    {
      "breakpoint": 500,
      "settings": {
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "initialSlide": 1
      }
    },
    {
      "breakpoint": 480,
      "settings": {
        "slidesToShow": 1,
        "slidesToScroll": 1
      }
    },
    {
      "breakpoint": 300,
      "settings": {
        "slidesToShow": 1,
        "slidesToScroll": 1
      }
    }
  ],
  // "centerMode": true,
  "centerPadding": "20px",
  "centerMargin": "20px",
  //  "focusOnSelect": false,
},
settings_ar:
        {

  "arrows": true,
  "dots": false,
  "infinite": true,
  "slidesToShow": 4,
  "slidesToScroll": 1,
  "autoplay": false,
  "speed": 2000,
  "autoplaySpeed": 2000,
  "cssEase": "linear",
  "responsive": [
    {
      "breakpoint": 1024,
      "settings": {
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "infinite": true,
      }
    },

    {
      "breakpoint": 900,
      "settings": {
        "slidesToShow": 2,
        "slidesToScroll": 1,
        "infinite": true,
      }
    },
    {
      "breakpoint": 600,
      "settings": {
        "slidesToShow": 2,
        "slidesToScroll": 1,
        "initialSlide": 1
      }
    },

    {
      "breakpoint": 500,
      "settings": {
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "initialSlide": 1
      }
    },
    {
      "breakpoint": 480,
      "settings": {
        "slidesToShow": 1,
        "slidesToScroll": 1
      }
    },
    {
      "breakpoint": 300,
      "settings": {
        "slidesToShow": 1,
        "slidesToScroll": 1
      }
    }
  ],
  
 "centerMode": true,
   "centerPadding": "20px",
   "centerMargin": "20px",
   "focusOnSelect": false,
   rtl:false
  
  
  // "variableWidth": true
},

      
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/theme5/products/type/new",
        
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        this.products = response.data.data;
       
      })
      .then(() => (this.loading = false));
  },
  watch: {
    langChange() {
      this.loading = true;
      
      this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/theme5/products/type/new",
        
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        this.products = response.data.data;
       
      })
      .then(() => (this.loading = false));


    },
  },
  updated() {
    this.langChange=this.$i18n.locale
  },
  methods: {
    next_product() {
            this.$refs.slick.next();
        },

        prev_product() {
            this.$refs.slick.prev();
        },
        reInit() {
            // Helpful if you have to deal with v-for to update dynamic lists
            this.$nextTick(() => {
                this.$refs.slick.reSlick();
            });
        },
    change_type(new_type) {
      this.products=[];
      console.log('type',new_type)
      this.type=new_type;

      this.loading = true;
     
      this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/theme5/products/type/"+this.type,
        
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        this.products = response.data.data;
       
      })
      .then(() => (this.loading = false));
      // reInit();
    },

  },
};
</script>
<style>
@media only screen and (min-width: 1200px) {
  .product_slider .slick-slide{
    /* width:262.5px !important; */
  }

  
}
.product_next,.product_prev
{
  background-color: transparent !important;
    font-size: 18px;
    color: black !important;
    height: 50px;
    opacity: 1 !important;
    top: 50%;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 50px;
    transition: all 0.3s ease-in-out;
}
.product_prev{
    right: -5% !important;
}
.product_next {
    left: -5% !important;
}
.product_slider .slick-slide
{
  
  margin-right:20px !important;
}
@media screen and (min-width: 1200px)
{
.products_list_slick{
  width:1130px !important
}
.one_product_list
{
  width:262.5px !important;
}

}


</style>
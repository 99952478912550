<template>
  <div>
          <!-- Start Hero -->
          <section class="relative table w-full py-20 lg:py-24 md:pt-28 bg-gray-50 dark:bg-slate-800">
              <div class="container relative">
                  <div class="grid grid-cols-1 mt-14">
                      <h3 class="text-3xl leading-normal font-semibold">{{ $t('brands') }}</h3>
                  </div><!--end grid-->
  
                  <div class="relative mt-3">
                      <ul class="tracking-[0.5px] mb-0 inline-block">
                          <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500">
                              <router-link :to="{
                                name: 'brands',
                               params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                                       }" >{{$t('home')}}</router-link>
                                       </li>
                          <li class="inline-block text-base text-slate-950 dark:text-white mx-0.5 
                          ltr:rotate-0 rtl:rotate-180"><i class="mdi mdi-chevron-right"></i></li>
                          <li class="inline-block uppercase text-[13px] font-bold text-orange-500"
                           aria-current="page">{{$t('brands')}}</li>
                      </ul>
                  </div>
              </div><!--end container-->
          </section><!--end section-->
          <!-- End Hero -->
  
          <!-- Start -->
          <section class="relative md:py-24 py-16">
              <div class="container relative">
                  <div class="md:flex justify-between items-center mb-6">
                      <span class="font-semibold">{{ $t('Showing') }} {{ pagefrom }}-
                          {{ pageto }}
                          
                           {{ $t('of') }} {{ count }} {{ $t('items') }}</span>
  
                     
                  </div>
                  <div class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6">
                     
                     
                      <div v-for="(item, index) in this.DBitems"
                      :key="index" class="group">
                          <div class="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md duration-500">
                              <img :src="item.image" 
                              class="group-hover:scale-110 duration-500 section_one_list" 
                              :alt="item.name"
                              >
      
                              <div 
                              class="absolute -bottom-20
                               group-hover:bottom-3 start-3
                                end-3 duration-500">
                                  <router-link :to="{
                                     name: 'brand',
                                      params: { storeName: $store.storeName,
                                       id: item.id  ,
                                        name:item.name,
                                        lang:$store.state.routeLang},
                                         }"
                                   class="py-2 px-5 inline-block 
                                   font-semibold tracking-wide align-middle
                                    duration-500 text-base text-center bg-slate-900
                                     text-white w-full rounded-md">{{ item.name }}</router-link>
                              </div>
      
                          </div>
  
                          <div class="mt-4">
                              <router-link
                              :to="{
                                     name: 'brand',
                                      params: { storeName: $store.storeName,
                                       id: item.id  ,
                                        name:item.name,
                                        lang:$store.state.routeLang
                                      },
                                         }" class="hover:text-orange-500 text-lg font-medium">
                                        {{item.name}}</router-link>
                              
                          </div>
                      </div><!--end content-->
  
                  </div><!--end grid-->
  
                  <div class="grid md:grid-cols-12 grid-cols-1 mt-6">
                      <div class="md:col-span-12 text-center">
                          <nav aria-label="Page navigation example">
                              <b-pagination
                  v-model="page"
                  :total-rows="count"
                  :per-page="pageSize"
                  :prev-text="$t('prev')"
                  :next-text="$t('next')"
                  pills
                  size="sm"
                  class="paginate_theme8"
                  @change="handlePageChange"
                ></b-pagination>
                          </nav>
                      </div><!--end col-->
                  </div><!--end grid-->
              </div><!--end container-->
          </section><!--end section-->
          <!-- End -->
  </div>
  </template>
  
<script>
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

export default {
  components: {HollowDotsSpinner, HalfCircleSpinner },
  data() {
    return {
      DBitems: null,
      loading: false,

      page: 1,
      count: 0,
      pageSize: 10,
      langChange:""
      //lang: null,
    };
  },
  created() {
    this.$store.state.topbar='topbar_light';
   this.$store.state.navbar='navbar_dark';

    this.loading = true;
    this.axios
      .get( this.$store.state.base_api_url+this.$store.storeName+"/brands", {
        headers: {
          "Accept-Language": this.$i18n.locale,
        },
      })
      .then((response) => {(this.DBitems = response.data.data.brands)
        this.count = response.data.meta.total;})
      .then(() => (this.loading = false));
      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  updated() {
    this.langChange=this.$i18n.locale
  },
  watch: {
    langChange() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/brands?page=" +
            this.page,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.brands;
          //this.count = response.data.meta.total;
          this.pageSize=response.data.meta.per_page;

        }).then(() => {
        this.loading = false;
      });
    },
  },
  methods: {
    retrieveTutorials() {
      const params = this.page;

      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/brands?page=" +
            params,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.brands;
          //this.count = response.data.meta.total;
        })
        .then(() => (this.loading = false));
      /*.catch((e) => {
          console.log(params);
          console.log(e);
        });*/
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },
  },
}
</script>
  
<template>
 <div>

  <a v-if="this.DBitems.product"
  @click="deleteItem"
  class="item_remove">
  <i class="ion-close"></i></a>
  <router-link
   
            :to="{
            name: 'product',
            params: {
             storeName: $store.storeName, 
            id: this.item.product_id ,
            name:this.DBitems.product.name,
            lang: $i18n.locale
            },
          }"
          >
    
    <img :src="DBitems.product.image" 
    :alt="DBitems.product.name"
    class="item_cart_img"
    >
    {{ DBitems.product.name }}
    </router-link>
  
  <span class="cart_quantity"> {{ item.quantity }} x <span class="cart_amount"> 
    
    <span v-if="item.offer_price==0">
    {{ (item.total_price.toFixed(2) / item.quantity).toFixed(2) }}
     </span>
    <span v-else >{{ (item.offer_price.toFixed(2)  / item.quantity).toFixed(2)}}
     </span>

     <span v-if=" $i18n.locale == 'ar'" class="price_symbole">
         {{$store.state.unitAR}}
    </span>

    <span v-if=" $i18n.locale == 'en'" class="price_symbole">
        {{$store.state.unit}}
    </span>

    </span>
  </span>
    </span>

    <Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{$t('confirmdelete')}}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="deleteItem() ">{{$t('confirm')}}</button>
                <button
                  class="modal-default-button cancel"
                  @click="(show = false), $emit('close')"
                >{{$t('cancel')}}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </Transition>
</div>
</template>

<script>
export default {
  props: {
    itemId: String
  },
  data: function() {
    return {
      loading: false,
      DBitems: null,
      item: null,
      show: false
    };
  },
  created() {


    this.loading = true;
    this.item = this.$cookies.get(this.itemId);
    if (this.item != null) {
      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/cart_item",
          {
            product_id: this.item.product_id,
            final_option: this.item.final_option,
            option_check: this.item.option_check
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;

       
        })
        .then(() => (this.loading = false));
    }
  },
  methods: {
   
    deleteItem() {
      //this.loading = true;
      this.$cookies.remove(this.itemId);
      console.log("remove" + this.$cookies.keys());
      this.$store.state.cartCounter = this.$store.state.cartCounter - 1;
      this.$destroy();
      this.$parent.updateProducts();
      this.$el.parentNode.removeChild(this.$el);
    },
updatedata()
{
  this.loading = true;
    this.item = this.$cookies.get(this.itemId);
    if (this.item != null) {
      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/cart_item",
          {
            product_id: this.item.product_id,
            final_option: this.item.final_option,
            option_check: this.item.option_check
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          // this.DBitems = response.data.data;
          console.log('********************hhhhhhhhhhhh');
          
       
        })
        .then(() => (this.loading = false));
    }
   
}
  },
  watch:{
    update_cart_list(){
    if (this.item != null) {
      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/cart_item",
          {
            product_id: this.item.product_id,
            final_option: this.item.final_option,
            option_check: this.item.option_check
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
        })
        .then(() => (this.loading = false));
    }
   
    },
    langChange()
    {
      this.loading = true;
    this.item = this.$cookies.get(this.itemId);
    if (this.item != null) {
      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/cart_item",
          {
            product_id: this.item.product_id,
            final_option: this.item.final_option,
            option_check: this.item.option_check
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;

       
        })
        .then(() => (this.loading = false));
    }
  }
}

};
</script>

<style >
.item_cart_img
{
  width:80px;
  aspect-ratio: 1/1;
}
</style>
<template>
    <section>
      <div
        v-if="loading"
        class="d-flex flex-row justify-content-center"
        style="margin-top: 100px; margin-bottom: 200px"
      >
        <!-- LOADER -->
<div class="preloader">
    <div class="lds-ellipsis">
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>
<!-- END LOADER -->

      </div>
        <!-- START SECTION BREADCRUMB -->
<div class="breadcrumb_section bg_gray page-title-mini">
    <div class="container"><!-- STRART CONTAINER -->
        <div class="row align-items-center">
        	<div class="col-md-6">
                <div class="page-title">
            		<h1>{{ $t('Section') }}</h1>
                </div>
            </div>
            <div class="col-md-6">
                <ol class="breadcrumb justify-content-md-end">
                    <li class="breadcrumb-item">
                        <router-link :to="{name:home,params:{lang: $i18n.locale}}">{{ $t('home') }}</router-link>
                        </li>
                    <li class="breadcrumb-item">
                        <router-link :to="{name:sections,params:{lang: $i18n.locale}}">{{ $t('sections') }}</router-link>
                        </li>
                    <li class="breadcrumb-item active">{{ this.name }}</li>
                </ol>
            </div>
        </div>
    </div><!-- END CONTAINER-->
</div>
<!-- END SECTION BREADCRUMB -->

<!-- START SECTION SHOP -->
<div class="section">
	<div class="container">
    	<div class="row">
			<div class="col-lg-9">
            	<div class="row align-items-center mb-4 pb-1">
                    <div class="col-12">
                        <div class="product_header">
                            <div class="product_header_left">
                                <div class="custom_select">
                                    <select class="select" @change="upadatesort($event)">
                                      <option value="desc/id">{{ $t("default sorting") }}</option>
            <option value="desc/id">{{ $t("sort1") }}</option>
            <option value="asc/id">{{ $t("sort2") }}</option>
            <option value="desc/sale_price">{{ $t("sort3") }}</option>
            <option value="asc/sale_price">{{ $t("sort4") }}</option>
            <option value="desc/count_view">{{ $t("sort5") }}</option>
            <option value="asc/count_view">{{ $t("sort6") }}</option>
            <option value="desc/quantity">{{ $t("sort7") }}</option>
            <option value="asc/quantity">{{ $t("sort8") }}</option>
            <option value="desc/count_sold">{{ $t("sort9") }}</option>
            <option value="asc/count_sold">{{ $t("sort10") }}</option>
          </select>
                                </div>
                            </div>
                            <div class="product_header_right">
                            	<div class="products_view">
                                    <a href="javascript:Void(0);" id="grid" class="shorting_icon grid active" @click="change_style_product('grid')"><i class="ti-view-grid"></i></a>
                                    <a href="javascript:Void(0);" id="list" class="shorting_icon list" @click="change_style_product('list')"><i class="ti-layout-list-thumb"></i></a>
                                </div>
                                <div class="custom_select">
                                    <select class="form-control form-control-sm"
                                    v-model="paginate"
                                    @change="updatepaginate($event)"
                                    >
                                        <option value="12">{{ $t('showing') }}</option>
                                        <option value="12">12</option>
                                        <option value="24">24</option>
                                        <option value="36">36</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div v-if="this.products.length!=0" class="row shop_container">
                    <div class="col-md-4 col-6"
                     v-for="(item, index) in this.products"
                      :key="index">
                       <product_list
                       :name="item.name"
              :description="item.description"
              :is_favorit="item.is_favorit"
              :image="item.image"
              :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
              :rate="item.rate"
              :count_view="item.count_views"
              :is_option="item.is_option"
              :is_offer="item.is_offer"
               :unit="item.unit"
               :new="item.is_new"
               :fetuered="item.is_fetured"
               :discount="item.discount"
               :qnt="item.qnt"
               :min_qnt="item.min_qnt"
               :max_qnt="item.max_qnt"
               
                       ></product_list>
                    </div>
                </div>
                <div v-else-if="this.loading">
                  <div class="empty text-center center-text">
                 
                  <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                  <br>
                  
                  </div>
                  </div>
                  
                <div v-else>
                  <div class="empty text-center center-text">
                 
                  <img src="/pet_shop/assets/images/empty.png"/>
                  <br>
                  {{ $t('empty') }}
                  </div>
                  </div>
        		<div class="row">
              <b-pagination
              v-if="this.products.length!=0"
                v-model="page"
                :total-rows="count"
                :per-page="this.paginate"
                :prev-text="$t('prev')"
                :next-text="$t('next')"
                pills
                size="sm"
                class="pagination mt-3 justify-content-center pagination_style1 text-center center-text"
                @change="handlePageChange"
              ></b-pagination>
                </div>
        	</div>
            <div class="col-lg-3 order-lg-first mt-4 pt-2 mt-lg-0 pt-lg-0">
            	<div class="sidebar">
                	<div class="widget">
                        <h5 class="widget_title">{{this.name}}</h5>
                        <ul class="widget_categories">



                          <li  >
                                <a class="category active" @click="filter_category(0,'all_category')" id='all_category'>
                                    <span class="categories_name">{{$t('all')}}</span>
                                    <span class="categories_num">({{ this.count }})</span></a>
                                
                                </li>

                            <li  v-for="(item, index) in this.categories"
                                 :key="index">
                                <a class="category" @click="filter_category(item.id,index)" :id="'category'+index">
                                    <span class="categories_name">{{item.label}}</span>
                                    <span class="categories_num">({{ item.count_product }})</span></a>
                                
                                </li>
                            
                        </ul>
                    </div>
                    <div  v-if="this.brands.length!=0" class="widget">
                    	<h5   class="widget_title">{{ $t('brands') }}</h5>	
                        <ul class="list_brand">
                            <li  v-for="(item, index) in this.brands"
                                 :key="index">
                                <div class="custome-checkbox">
                                    <input class="form-check-input"
                                     type="checkbox"
                                    :id=item.id 
                                    :value=item.id
                                    v-model="brands_array"
                                    @change="filter_product()"
                                    checked
                                    >
                                    <label class="form-check-label" :for=item.id><span>{{ item.label }}</span></label>
                                </div>
                            </li>
                            
                        </ul>
                    </div>
                    
                    
                    
                    <div class="widget" v-for="(item, index) in this.options"
                                 :key="index" >
                    	<h5 class="widget_title">{{ item.name}}</h5>	
                        <ul class="list_brand">
                            <li v-for="(item2, index) in item.option_values.select_values"
                                 :key="index">
                                <div class="custome-checkbox">
                                    <input class="form-check-input"
                                     type="checkbox" 
                                     name="checkbox" 
                                     :id=item2.id 
                                     :value=item2.id
                                     v-model=options_array
                                     @change="filter_product()"
                                     >
                                    <label class="form-check-label" :for=item2.id><span>{{item2.name}}</span></label>
                                </div>
                            </li>
                           
                        </ul>
                    </div>
                    
                   
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END SECTION SHOP -->
<Transition name="modal">
      <div v-if="this.$store.state.show_product" class="modal-mask product_mask">
        <div class="modal-wrapper product_wapper open">
          <div class="modal-container product_poup open">
            <div class="modal-header" style="border-bottom:none !important">
              <slot name="header"><i class="fa fa-close close_icon" 
                
                 @click="closepopup" ></i></slot>
             
            </div>
            <product_popup></product_popup>
          
          </div>
        </div>
      </div>
     

     
    </Transition>

        </section>
</template>

<script>
import product_list from "../components/product_list.vue";
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";
import product_popup from "../components/product_popup.vue";

export default {
  components: { product_list, HollowDotsSpinner, HalfCircleSpinner,product_popup },
  data() {
    return {
      DBitems: null,
      loading: false,
      element:null,
      page: 1,
      count: 0,
      pageSize: 12,
      langChange:"",
      products:null,
      name:null,
      categories:null,
      brands:null,
      options:null,
      options_array:[],
      brands_array:[],
      category_id:null,
      sort:'id',
      order:'desc',
      paginate:12,
       section_id:null,
       price_from:0,
       price_to:null,
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName +
         "/theme5/section/"+this.$route.params.id,
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
      
        this.DBitems = response.data.data;

        console.log('test ',response.data.data);

        this.name=response.data.data.name;
        this.brands=this.DBitems.brands;
        this.categories=this.DBitems.categories;
        this.options=this.DBitems.options;


      })
      .then(() => {
        this.loading = false;
      });



      //post product


    this.axios
      .post(
        this.$store.state.base_api_url + this.$store.storeName +
         "/products/filter_product_theme_5",
        {
            section_id: this.$route.params.id,
            category: this.category_id,
            options: this.options_array,
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate,
            brands:  this.brands_array
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale
           
          }
        }
      )
      .then(response => {
        this.products = response.data.data.products;
        this.count = response.data.meta.total;
      })
      ;

      /////////////////////////
      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
    
  },
  watch: {
    langChange() {
      this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName +
         "/theme5/section/"+this.$route.params.id,
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
      
        this.DBitems = response.data.data;

        console.log('test ',response.data.data);

        this.name=response.data.data.name;
        this.brands=this.DBitems.brands;
        this.categories=this.DBitems.categories;
        this.options=this.DBitems.options;


      })
      .then(() => {
        this.loading = false;
      });



      //post product


    this.axios
      .post(
        this.$store.state.base_api_url + this.$store.storeName +
         "/products/filter_product_theme_5",
        {
            section_id: this.$route.params.id,
            category: this.category_id,
            options: this.options_array,
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate,
            brands:  this.brands_array
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale
           
          }
        }
      )
      .then(response => {
        this.products = response.data.data.products;
        // this.count = response.data.meta.total;
      })
      ;

    },
    "$route.params": {
      handler(newValue) {
        this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName +
         "/theme5/section/"+this.$route.params.id,
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
      
        this.DBitems = response.data.data;

        console.log('test ',response.data.data);

        this.name=response.data.data.name;
        this.brands=this.DBitems.brands;
        this.categories=this.DBitems.categories;
        this.options=this.DBitems.options;


      })
      .then(() => {
        this.loading = false;
      });



      //post product


    this.axios
      .post(
        this.$store.state.base_api_url + this.$store.storeName +
         "/products/filter_product_theme_5",
        {
            section_id: this.$route.params.id,
            category: this.category_id,
            options: this.options_array,
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate,
            brands:  this.brands_array
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale
           
          }
        }
      )
      .then(response => {
        this.products = response.data.data.products;
        // this.count = response.data.meta.total;
      })
      ;

      },
      immediate: true
    }
  },
  updated() {
    this.langChange=this.$i18n.locale
    /*  this.axios
        .get(
          "http://192.168.1.16:8000/api/" +
            this.$store.storeName +
            "/sections?page=" +
            this.page,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.sections;
          //this.count = response.data.meta.total;
        }).then(() => {
        this.loading = false;
      });*/
  },
  methods: {
    closepopup()
    {
      this.$store.state.show_product=false;
    },
    retrieveTutorials() {
      const params = this.page;

      this.loading = true;

      this.axios
      .post(
        this.$store.state.base_api_url + this.$store.storeName +
         "/products/filter_product_theme_5?page="+this.page,
        {
            section_id: this.$route.params.id,
            category: this.category_id,
            options: this.options_array,
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate,
            brands:  this.brands_array
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale
           
          }
        }
      )
      .then(response => {
        this.products = response.data.data.products;
         this.count = response.data.meta.total;
         this.loading = false;

      })
      
     
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },


    filter_product()
    {
        console.log('brands array  ',this.brands_array);


        this.axios
      .post(
        this.$store.state.base_api_url + this.$store.storeName +
         "/products/filter_product_theme_5",
        {
            section_id: this.$route.params.id,
            category: this.category_id,
            options: this.options_array,
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate,
            brands:  this.brands_array
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale
           
          }
        }
      )
      .then(response => {
        this.products = response.data.data.products;
        this.count = response.data.meta.total;
      })
    }

    ,
    filter_category($id,$index)
    {
        this.element=document.getElementById('category'+$index);
        document.getElementById('all_category').classList.remove("active");

        for(var i=0;i<this.categories.length;i++)
        {
          var element2=document.getElementById('category'+i);
          if(element2)
          element2.classList.remove("active");

        }
        if(this.element)
        this.element.classList.add("active");
      else if('id'==0)
      {
        document.getElementById('all_category').classList.add("active");

      }
        this.category_id=$id;
       this.filter_product();
    }
    ,
    upadatesort(event)
  {
    this.order = event.target.value.split("/")[0]; //asc
      this.sort = event.target.value.split("/")[1]; //id

      this.filter_product();
  },

  updatepaginate(event)
  {
    this.paginate = event.target.value

      this.filter_product();
  },

  ////////////
  /*===================================*
	18. List Grid JS
	*===================================*/
	change_style_product($type)
  {
   // this.element=document.getElementById('product_style');
    if ($type=='grid') {
			$('.shop_container').removeClass('list').addClass('grid');
      $('.grid').addClass('active').siblings().removeClass('active');
		//	this.element.addClass('active').siblings().removeClass('active');
		}
		else if($type=='list') {
			$('.shop_container').removeClass('grid').addClass('list');
			//this.element.addClass('active').siblings().removeClass('active');
      $('.list').addClass('active').siblings().removeClass('active');

		}
		$(".shop_container").append('<div class="loading_pr"><div class="mfp-preloader"></div></div>');
		setTimeout(function(){
			$('.loading_pr').remove();
			$container.isotope('layout');
		}, 800);
  }
  ////////////
  },

 
};
</script>


<style >
.category.active
{
    color:var(--primary-color) !important;
}

.category
{
    cursor:pointer;
}
</style>
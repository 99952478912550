var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"sections_page",staticStyle:{"margin-top":"10%"}},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"page_title",staticStyle:{"text-align":"center","padding-bottom":"2%"}},[_vm._v(_vm._s(_vm.$t("sections")))]),(_vm.loading)?_c('div',{staticClass:"d-flex flex-row justify-content-center",staticStyle:{"margin-top":"100px","margin-bottom":"200px"}},[_c('hollow-dots-spinner',{attrs:{"animation-duration":1000,"dot-size":35,"dots-num":3,"color":"var(--primary-color)"}})],1):(!this.DBitems || this.DBitems.length == 0)?_c('div',[_c('div',{staticClass:"empty text-center center-text"},[_c('img',{attrs:{"src":"/pet_shop/assets/images/empty.png"}}),_c('br'),_vm._v(" "+_vm._s(_vm.$t('empty'))+" ")])]):_c('div',{staticClass:"row"},_vm._l((this.DBitems),function(item,index){return _c('div',{key:index,staticClass:"col-lg-3 col-md-3 col-6"},[_c('router-link',{staticClass:"section_list_link",attrs:{"to":{
          name: 'section',
          params: {
            storeName: _vm.$store.storeName,
            lang:_vm.$store.state.routeLang,
            id: item.id,
            name: item.name
          },
        }}},[_c('div',{staticClass:"center-text text-center"},[_c('img',{staticClass:"imag_section one_section_image",attrs:{"src":item.icon}}),_c('h5',{staticClass:"section_list_name"},[_vm._v(_vm._s(item.name))])])])],1)}),0),_c('div',{staticClass:"d-flex flex-row justify-content-center mt-5"},[_c('b-pagination',{attrs:{"total-rows":_vm.count,"per-page":_vm.pageSize,"prev-text":_vm.$t('prev'),"next-text":_vm.$t('next'),"pills":""},on:{"change":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="blog_post blog_style2 box_shadow1">
                    <div class="blog_img">
                        <router-link 
                        :to="{
      name: 'section',
      params: { storeName: $store.storeName, id: sectionid  , name:name,lang: $i18n.locale},
    }"
                        
                        >
                            <img :src="image" alt="blog_small_img1">
                        </router-link>
                    </div>
                    <div class="blog_content bg-white">
                        <div class="blog_text">
                            <h5 class="blog_title">
                                <router-link 
                                  :to="{
                                   name: 'section',
                                    params: { 
                                      storeName: $store.storeName
                                      , id: sectionid  
                                      , name:name
                                      ,lang: $i18n.locale},
                                       }"
                        
                                      >
                                   {{ name }}</router-link></h5>
                        </div>
                    </div>
                </div>
</template>

<script>
export default {
  props: {
    name: String,
    image: String,
    sectionid: Number,
  },
  data: function () {
    return {};
  },
};
</script>
<style>
.blog_post
{
    aspect-ratio: 1.5/1;
}

.blog_img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    aspect-ratio: 1.5/1;
    transition: all 0.5s ease-in-out;
    height: 100%;
}
.blog_title
{
  height: fit-content !important;
}
</style>
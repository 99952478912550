<template>

    <section style="margin-top: 10%;min-height:600px">
            <div class="container">
          <h2 style="text-align: center; padding-bottom: 2%;" class="page_title">
            {{ $t("Resultsfor") }}: {{ this.$route.params.word }}
          </h2>
          <div
            v-if="this.loadingProducts"
            class="d-flex flex-row justify-content-center loading_card_spinner"
          
          >
            <hollow-dots-spinner
              :animation-duration="1000"
              :dot-size="35"
              :dots-num="3"
              color="var(--primary-color)"
            />
          </div> 
                <div v-else-if="this.products.length!=0" class="row">
                    <div v-for="(item, idx) in this.products" :key="idx" 
                class="col-lg-3 col-md-3 col-6" >
                    <product
                    :key="index" 
              :name="item.name"
              :description="item.description"
              :is_favorit="item.is_favorit"
              :image="item.image"
              :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
              :rate="item.rate"
              :count_view="item.count_views"
              :is_option="item.is_option"
              :is_offer="item.is_offer"
               :unit="item.unit"
               :new="item.is_new"
               :fetuered="item.is_fetured"
               :discount="item.discount"
               :qnt="item.qnt"
               :min_qnt="item.min_qnt"
               :max_qnt="item.max_qnt"
                    >
                    </product>
                </div>
            </div>

            <div v-else>
                  <div class="empty text-center center-text">
                 
                  <img src="/pet_shop/assets/images/empty.png"/>
                  <br>
                  {{ $t('empty') }}
                  </div>
                  </div>


           
        </div>
        <div style="text-align:center" v-if="this.products.length>12">
            <button class="loadmorebutton" @click="LoadMoreProducts" >
                {{ $t("LoadMore") }}
                <img
                  v-if="loadMoreIcon"
                  src="https://etrolley.net/store/assets/image/icons/loader.gif"
                  style="width: 30px"
                />
              </button>
              </div>
        </section>
        
       
    </template>

<script>
import product from "../components/one_product_list.vue";
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

export default {
    components: { product, HollowDotsSpinner, HalfCircleSpinner },
    data() {
    return {
      loading: false,
      DBitems: null,

      sections: null,
      offers: null,
      products: null,
      loadingSections: false,
      loadingOffers: false,
      loadingProducts: true,

      pageSections: 1,
      allPageSections: 0,
     
      pageOffers: 1,
      allPageOffers: 0,
     

      pageProducts: 1,
      allPageProducts: 0,
    

      loadMoreIcon: false,
    };
  },
  watch: {
    "$route.params": {
      handler(newValue) {
        this.updatedata();
      },
      immediate: true,
    },
  },
  created() {
    if(this.$route.params.lang&&this.$route.params.lang!=this.$i18n.locale){
      this.$i18n.locale=this.$route.params.lang;this.$store.state.UnitPrice=this.$store.state.unitAR
      if(this.$i18n.locale=='en')
        {document.documentElement.setAttribute("dir", "ltr");
        let h = document.querySelector('body');
      }}
    //////////////////////sections//////////////////////
    document.getElementById('search_form').classList.add('hidden');
    if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
    //////////////////////sections//////////////////////
    this.loadingSections = true;
    this.axios
      .post(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/search_section",
        {
          word: this.$route.params.word,
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.sections = response.data.data.sections;
        this.allPageSections = response.data.meta.last_page;
        this.pageSizeSection=response.data.meta.per_page;

      })
      .then(() => (this.loadingSections = false));

    //////////////////////offers//////////////////////
    this.loadingOffers = true;
    this.axios
      .post(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/theme5/search_offer",
        {
          word: this.$route.params.word,
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.offers = response.data.data.offers;
        this.allPageOffers = response.data.meta.last_page;
        this.pageSizeOffers=response.data.meta.per_page;

        //this.countOffers = response.data.meta.total;
      })
      .then(() => (this.loadingOffers = false));

    //////////////////////products//////////////////////
    this.loadingProducts = true;
    this.axios
      .post(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/theme4/search_product",
        {
          word: this.$route.params.word,
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.products = response.data.data;
        this.allPageProducts = response.data.meta.last_page;
        //this.countProducts = response.data.meta.total;
        this.pageSizeProducts=response.data.meta.per_page;

      })
      .then(() => (this.loadingProducts = false));
  },
  methods: {
    updatedata() {
      //console.log("hooooooooooooooo");
      //////////////////////sections//////////////////////
      this.loadingSections = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/search_section",
          {
            word: this.$route.params.word,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.sections = response.data.data.sections;
        })
        .then(() => (this.loadingSections = false));

      //////////////////////offers//////////////////////
      this.loadingOffers = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/search_offer",
          {
            word: this.$route.params.word,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.offers = response.data.data;
        })
        .then(() => (this.loadingOffers = false));

      //////////////////////products//////////////////////
      this.loadingProducts = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/theme4/search_product",
          {
            word: this.$route.params.word,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.products = response.data.data;
        })
        .then(() => (this.loadingProducts = false));

      this.$store.state.langchange = false;
    },
    LoadMoreSections() {
      if (this.pageSections < this.allPageSections) {
        //this.loadingSections = true;
        this.loadMoreIcon = true;
        this.pageSections++;
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/search_section?page=" +
              this.pageSections,
            {
              word: this.$route.params.word,
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            }
          )
          .then((response) => {
            for (let i = 0; i < response.data.data.sections.length; i++) {
              this.sections.push(response.data.data.sections[i]);
            }
            this.loadMoreIcon = false;
          });
        //.then(() => (this.loadingSections = false));
      }
    },
 
    LoadMoreOffers() {
      if (this.pageOffers < this.allPageOffers) {
        this.loadMoreIcon = true;
        this.pageOffers++;
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/search_offer?page=" +
              this.pageOffers,
            {
              word: this.$route.params.word,
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            }
          )
          .then((response) => {
            for (let i = 0; i < response.data.data.offers.length; i++) {
              this.offers.push(response.data.data.offers[i]);
            }
            this.loadMoreIcon = false;
          });
      }
    },
    LoadMoreProducts() {
      if (this.pageProducts < this.allPageProducts) {
        this.loadMoreIcon = true;
        this.pageProducts++;
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/search_product?page=" +
              this.pageProducts,
            {
              word: this.$route.params.word,
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            }
          )
          .then((response) => {
            for (let i = 0; i < response.data.data.products.length; i++) {
              this.products.push(response.data.data.products[i]);
            }
            this.loadMoreIcon = false;
          });
      }
    },
  },
};
</script>
<template>
  <div class="add_address">
    <div
      v-if="loading"
      class="d-flex flex-row justify-content-center"
      style="margin-top: 100px; margin-bottom: 200px"
    >
      <HalfCircleSpinner
        :animation-duration="1000"
        :dot-size="35"
        :dots-num="3"
        color="var(--primary-color)"
      />
    </div>
    <div v-if="!loading" class="card add_address">
      <div
        style="
          margin-top: 30px;
          margin-bottom: 20px;
          text-align: center;
          font-size: 18px;
        "
      >
        <h4 style="color: var(--primary-color)">{{ address.address.title }}</h4>
      </div>
      <!--<div class="map">
        <div id="map-container-google-1" class="z-depth-1-half map-container">
          <iframe
            :src="
              'https://maps.google.com/maps?q=' +
              address.address.lat +
              ',' +
              address.address.long +
              '&hl=es&z=14&amp;output=embed'
            "
            frameborder="0"
            class="mapStyle"
            allowfullscreen
          ></iframe>
        </div>
      </div>-->
      <div class="map" v-if="this.$store.state.apperance_map_address==1" >
        <GmapMap
          :center="center"
          :zoom="18"
          map-style-id="roadmap"
          :options="mapOptions"
          class="mapStyle"
          style="width: 80%; height: 50vmin"
          ref="mapRef"
          @click="handleMapClick"
        >
          <GmapMarker
            :position="marker.position"
            :clickable="true"
            :draggable="true"
            @drag="handleMarkerDrag"
            @click="panToMarker"
          />
        </GmapMap>
      </div>
      <div class="input-icons">
        <input
          class="input-field"
          type="text"
          :placeholder="$t('placeHolderTitle')"
          required
          maxlength="30"
          v-model="address.address.title"
        />
        <v-select
        class="input-field select_address"
          v-model="city"
          :reduce="(option) => option.id"
          :options="address.cities"
          label="city"
          :placeholder="$t('city')"
        ></v-select>
        <v-select
        class="input-field select_address"
          v-model="region"
          :reduce="(option) => option.id"
          :options="regions"
          label="city"
          :placeholder="$t('region')"
        ></v-select>
        <input
          class="input-field"
          type="tel"
          :placeholder="$t('street')"
          maxlength="255"
          v-model="address.address.street"
        />
        <input
          class="input-field"
          type="tel"
          :placeholder="$t('building')"
          maxlength="255"
          v-model="address.address.building"
        />
        <textarea
          rows="5"
          cols="5"
          class="form-control"
          :placeholder="$t('OtherDetails')"
          style="margin-bottom: 40px"
          v-model="address.address.other_details"
        ></textarea>
        <button @click="deleteAddress">{{ $t("DeleteAddress") }}</button>
        <button class="button" @click="editAddress">{{ $t("SaveChanges") }}</button>
      </div>
    </div>
    <div
      v-if="Message != ''"
      style="
        position: fixed;
        width: 200px;
        bottom: 50px;
        left: 50px;
        border-radius: 5px;
        border: 2px solid var(--primary-color);
        text-align: center;
        padding: 5px;
        background-color: #fff;
      "
    >
      <p style="margin: 0">{{ Message }}</p>
    </div>
  </div>
</template>

<script>
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

export default {
  components: { HollowDotsSpinner, HalfCircleSpinner },

  props: {
    address: Object
  },
  data: function() {
    return {
      loading: false,
      city: null,
      region: null,
      regions: null,
      lat: 35.5,
      long: 33.5,
      lat_map: 0,
      long_map: 0,
      Message: "",

      marker: { position: { lat: 300, lng: 300 } },
      center: { lat: this.address.address.lat, lng: this.address.address.long },
      mapOptions: {
        disableDefaultUI: true
      }
    };
  },

  created() {
    this.loading = true;
    console.log("hiiiiiiiiiiiiiii");
    this.city = this.address.address.city;
    this.regions = this.address.regions;
    this.region = this.address.address.region;
    //this.marker.position.lat=this.address.address.lat;
    //this.marker.position.lng=this.address.address.long;
if(this.$store.state.apperance_map_address==1)
   {this.marker.position = {
      lat: this.address.address.lat,
      lng: this.address.address.long
    };
    this.marker.center = {
      lat: this.address.address.lat,
      lng: this.address.address.long
    };
    lat_map=this.address.address.lat,
    lng_map= this.address.address.long
  
  }

    //this.panToMarker();

    console.log(this.regions);
    console.log(this.marker.position.lat);
    console.log("loaaad " + this.loading);
    //setTimeout(() => {
    this.loading = false;
    //},100)
  },
  methods: {
    editAddress() {
      this.axios
        .put(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/addresses/" +
            this.address.address.id,
          {
            title: this.address.address.title,
            city_id: this.city,
            region_id: this.region,
            street: this.address.address.street,
            building: this.address.address.building,
            other_details: this.address.address.other_details,
            lat: this.lat_map,
            long: this.long_map,
            // lat: this.marker.position.lat,
            // long: this.marker.position.lng
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          console.log(response.data.message);
          this.Message = response.data.message;
          setTimeout(() => {
            this.Message = "";
          }, 3000);
        });
    },
    deleteAddress() {
      this.loading = true;
      this.axios
        .delete(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/addresses/" +
            this.address.address.id,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            console.log(response.data.message);
            this.Message = response.data.message;

            this.$destroy();
            //this.$el.parentNode.removeChild(this.$el);
            try {
              this.$parent.$parent.updatAddress();
            } catch (Ex) {
              this.$parent.updatAddress();
            }
            this.loading = false;
            setTimeout(() => {
              this.Message = "";
              //this.$router.go(this.$router.currentRoute)
            }, 3000);
          } else {
            console.log(response.data.message);
            this.Message = response.data.message;
            this.loading = false;
            setTimeout(() => {
              this.Message = "";
            }, 3000);
          }
        });
      //.then(setTimeout(() => {(this.loading = false)},100));
    },
    //detects location from browser
    /* geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.panToMarker();
      });
    },*/
    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },
    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      this.$refs.mapRef.setZoom(18);
    },
    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      console.log(e);
    }
  },
  /*mounted() {
    this.geolocate();
  },*/
  watch: {
    city(newVal) {
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/addresses/regions/" +
            this.city,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.regions = response.data.data.regions;
          if (this.regions.find(o => o.id == this.region) == null) {
            this.region = null;
          }
        });
    }
  }
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

#Second {
  margin-top: 120px;
}
.card {
  background-color: #f8f8f8;
  width: 100%;
  border-radius: 15px;
  color: var(--secondary-color);
  font-family: "Araboto-Bold";
  text-align: center;
  font-size: 16px;
  padding: 15px 3px;
  border: 0;
}

.card h4 {
  font-size: 15px;
  font-family: "Araboto-Medium";
  display: inline;
  color: var(--secondary-color);
}
.card h4:hover {
  color: var(--primary-color);
}
button {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  color: var(--secondary-color);
}
hr {
  height: 2px;
  margin: 12px;
  color: #cccccc;
}
[dir="rtl"] button img {
  left: 15px;
  margin-top: 10px;
  position: absolute;
}
[dir="ltr"] button img {
  right: 15px;
  margin-top: 10px;
  position: absolute;
}
a {
  display: block;
  background-color: #f8f8f8;
  padding: 13px 0 17px 0;
  width: 80%;
  border-radius: 15px;
  color: var(--secondary-color);
  font-family: "Araboto-Bold";
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
}

.router-link-exact-active,
.router-link-exact-active:focus {
  background-color: #fff;
  border: 2px solid var(--primary-color);
}
.mapStyle {
  margin-bottom: 20px;
  border-radius: 15px;
  margin-left: 10%;
  margin-right: 10%;
  height: 315px;
  width: 80%;
}
.input-icons {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.input-icons input,
textarea.form-control {
  width: 100%;
  border-radius: 9px;
  background-color: #fff;
  margin-bottom: 10px;
  border: 2px solid #cccccc;
  font-size: 13px;
  font-family: "Araboto-Medium";
  color: var(--secondary-color);
  padding: 10px 20px 15px 20px;
}

.button {
  background-color: var(--primary-color);
  border-radius: 10px;
  width: 60%;

  margin: 20px 20%;
  padding: 10px 15px;
  border: 2px solid var(--primary-color);
  color: #fff;
  font-size: 15px;
  font-family: "Araboto-Medium";
}

.button:hover {
  background-color: #fff;
  color: #000;
}

@media (max-width: 992px) {
  a {
    width: 100%;
  }
}
@media (max-width: 772px) {
  a {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
}

.select_address{
  width: 100%;
    border-radius: 9px;
    background-color: #fff;
    margin-bottom: 10px;
    border: 2px solid #cccccc !important;
    font-size: 13px;
    font-family: "Araboto-Medium";
    color: var(--secondary-color);
    padding: 10px 20px 15px 20px;
}
</style>

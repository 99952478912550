var render = function render(){var _vm=this,_c=_vm._self._c;return (this.$localStorage.get(this.$store.storeName) != null)?_c('section',{staticClass:"profile_page",attrs:{"id":"Second"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-md-4 mb-4"},[_c('router-link',{staticClass:"profile_btn",attrs:{"to":{
            name: 'profile',
            params: { storeName: this.$store.storeName,lang: _vm.$i18n.locale },
          }}},[_vm._v(" "+_vm._s(_vm.$t("profile"))+" ")]),_c('router-link',{attrs:{"to":{
            name: 'myAddresses',
            params: { storeName: this.$store.storeName,lang: _vm.$i18n.locale },
          }}},[_vm._v(" "+_vm._s(_vm.$t("myAddresses"))+" ")]),_c('router-link',{staticClass:"profile_btn",attrs:{"to":{
            name: 'myOrders',
            params: { storeName: this.$store.storeName,lang: _vm.$i18n.locale },
          }}},[_vm._v(" "+_vm._s(_vm.$t("myOrders"))+" ")])],1),(_vm.loading)?_c('div',{staticClass:"d-flex flex-row justify-content-center col-lg-9 col-md-8 mb-4",staticStyle:{"margin-top":"100px","margin-bottom":"200px"}},[_c('HalfCircleSpinner',{attrs:{"animation-duration":1000,"dot-size":35,"dots-num":3,"color":"var(--primary-color)"}})],1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"col-lg-9 col-md-8 mb-4"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-5 mb-4"},[_c('div',{staticClass:"card add_address"},[_vm._l((_vm.DBitems),function(item,index){return _c('div',{key:index},[_c('button',{on:{"click":function($event){return _vm.getAddress(item.id)}}},[_c('h4',[_vm._v(_vm._s(item.title))]),_c('img',{attrs:{"src":_vm.$i18n.locale == 'ar'
                          ? require('../../public/assets/image/icons/arrow-head.png')
                          : require('../../public/assets/image/icons/arrow-head-r.png')}})]),_c('hr')])}),_c('button',{on:{"click":function($event){_vm.addAddress = true;
                    _vm.address = null;}}},[_c('h4',{staticStyle:{"color":"var(--primary-color)"}},[_vm._v(_vm._s(_vm.$t("AddNewAddress")))])])],2)]),_c('transition',{attrs:{"name":"bounce","enter-active-class":"bounceInLeft","leave-active-class":"bounceOutRight"}},[(this.address != null)?_c('div',{staticClass:"col-lg-7 mb-4"},[_c('view-address',{attrs:{"address":_vm.address}})],1):_vm._e()]),_c('transition',{attrs:{"name":"bounce1","enter-active-class":"bounceInLeft","leave-active-class":"bounceOutRight"}},[(this.addAddress == true)?_c('div',{staticClass:"col-lg-7 mb-4"},[_c('add-address')],1):_vm._e()])],1)])]):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div v-if="$route.name == 'home1'">
      <div
        class="secondHeader homeHeader fixed-top d-flex align-items-center justify-content-between"
      >
        <div>
          <div
            class="d-flex align-items-center justify-content-center px-4 md:px-6 lg:px-8 2xl:px-10"
            style="width: 100%"
          >
            <div class="logo mobile-inactive">
              <img :src="this.$store.state.logo" alt />
            </div>
            <nav
              id="navbar"
              :class="'navbar' + (activeMobile ? ' navbar-mobile' : '')"
            >
              <i
                :class="
                  'bi bi-list mobile-nav-toggle ' +
                  (activeMobile ? ' bi-x' : '')
                "
                @click="activeMobile = !activeMobile"
              ></i>

              <ul>
                <li class="mobile-active">
                  <div>
                    <div
                      class="logo d-flex flex-row justify-content-center align-items-center"
                      style="height: 40px"
                    >
                      <img :src="this.$store.state.logo" alt />
                    </div>
                  </div>
                </li>
                <li class="mobile-active">
                  <i class="bi bi-search" @click="searchRoute"></i>
                  <input
                    id="search"
                    type="search"
                    autocomplete="false"
                    onblur="this.setAttribute('readonly', 'readonly') , word=''"
                    onfocus="this.removeAttribute('readonly');"
                    readonly
                    class="form-control rounded"
                    :placeholder="$t('search')"
                    :aria-label="$t('search')"
                    aria-describedby="search-addon"
                    style="
                      border-radius: 5px !important;
                      border: 0;
                      width: 80%;
                      margin: auto;
                    "
                    v-model="word"
                    @keyup.enter="searchRoute"
                  />
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'home1',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("home") }}</router-link
                  >
                </li>
                <li class="mobile-inactive">
                  <div class="filterDropdown dropdown">
                    <router-link
                      :to="{
                        name: 'sections',
                        params: { storeName: this.$store.storeName },
                      }"
                    >
                      {{ $t("sections") }}
                      ⮟
                    </router-link>
                    <ul>
                      <li v-for="(item, index) in headerSections" :key="index">
                        <router-link
                          :to="{
                            name: 'section',
                            params: {
                              storeName: $store.storeName,
                              id: item.id,
                              name: item.name,
                            },
                          }"
                        >
                          <img class="image1" :src="item.icon" />
                          {{ item.name }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="mobile-active">
                  <router-link
                    :to="{
                      name: 'sections',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("sections") }}</router-link
                  >
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'about',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("about") }}</router-link
                  >
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'contactUs',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("contactUs") }}</router-link
                  >
                </li>
                <li
                  v-if="this.$localStorage.get(this.$store.storeName) != null"
                >
                  <router-link
                    :to="{
                      name: 'profile',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("profile") }}</router-link
                  >
                </li>
                <li v-else>
                  <router-link
                    style="
                      color: #222 !important;
                      text-decoration: none !important;
                    "
                    :to="{
                      name: 'login',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("profile") }}</router-link
                  >
                </li>

                <li
                  v-if="this.$localStorage.get(this.$store.storeName) != null"
                >
                  <router-link
                    :to="{
                      name: 'myAddresses',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("myAddresses") }}</router-link
                  >
                </li>
                <li v-else>
                  <router-link
                    style="
                      color: #222 !important;
                      text-decoration: none !important;
                    "
                    :to="{
                      name: 'login',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("myAddresses") }}</router-link
                  >
                </li>
                <li
                  v-if="this.$localStorage.get(this.$store.storeName) != null"
                >
                  <router-link
                    :to="{
                      name: 'myOrders',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("myOrders") }}</router-link
                  >
                </li>
                <li v-else>
                  <router-link
                    style="
                      color: #222 !important;
                      text-decoration: none !important;
                    "
                    :to="{
                      name: 'login',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("myOrders") }}</router-link
                  >
                </li>
                <li
                  v-if="this.$localStorage.get(this.$store.storeName) != null"
                >
                  <router-link
                    :to="{
                      name: 'favorites',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("favorites") }}</router-link
                  >
                </li>
                <li v-else>
                  <router-link
                    style="
                      color: #222 !important;
                      text-decoration: none !important;
                    "
                    :to="{
                      name: 'login',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("favorites") }}</router-link
                  >
                </li>

                <li class="mobile-active">
                  <div @click="cartVodal = true">
                    {{ $t("shoppingCart") }}
                  </div>
                </li>
                <li class="mobile-active">
                  <router-link
                    :to="{
                      name: 'notifications',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("Notifications") }}</router-link
                  >
                </li>
                <li class="mobile-active">
                  <button v-if="$i18n.locale == 'ar'" @click="onChange('en')">
                    <a>English</a>
                  </button>
                  <button v-if="$i18n.locale == 'en'" @click="onChange('ar')">
                    <a>عربي</a>
                  </button>
                </li>
                <li
                  class="mobile-active"
                  v-if="this.$localStorage.get(this.$store.storeName) != null"
                >
                  <router-link
                    style="
                      color: #222 !important;
                      text-decoration: none !important;
                    "
                    :to="{
                      name: 'home1',
                      params: { storeName: this.$store.storeName },
                    }"
                    @click.native="logOut()"
                    >{{ $t("logOut") }}</router-link
                  >
                </li>
                <li
                  class="mobile-active"
                  v-if="this.$localStorage.get(this.$store.storeName) == null"
                >
                  <router-link
                    :to="{
                      name: 'login',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("logIn") }}</router-link
                  >
                </li>
              </ul>
              <!-- .navbar -->
            </nav>
            <div class="mobile-active" style="margin: 0 10px">
              <div
                style="
                  display: flex !important;
                  flex-direction: row-reverse;
                  align-items: center;
                  cursor: pointer;
                "
                @click="cartVodal = true"
              >
                {{ $t("shoppingCart") }}
                <i
                  style="font-size: 18px; margin: inherit"
                  class="bi bi-bag mr-5"
                ></i>
                <div
                  v-if="this.$store.state.cartCounter != 0"
                  style="
                    color: #fff;
                    font-size: 0.5rem;
                    padding: 0 5px;
                    background-color: var(--primary-color);
                    width: 100%;
                    border-radius: 25px;
                    margin: -10px -10px 0;
                    position: relative;
                    display: inline;
                    height: 15px;
                  "
                >
                  {{ this.$store.state.cartCounter }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <!--<li>
            <div
              style="
                display: flex !important;
                flex-direction: row-reverse;
                align-items: center;
                cursor: pointer;
              "
              @click="cartVodal = true"
            >
              {{ $t("shoppingCart") }}
              <i
                style="font-size: 18px; margin: inherit"
                class="bi bi-bag mr-5"
              ></i>
              <div
                v-if="this.$store.state.cartCounter != 0"
                style="
                  color: #fff;
                  font-size: 0.5rem;
                  padding: 0 5px;
                  background-color: var(--primary-color);
                  width: 100%;
                  border-radius: 25px;
                  margin: -10px -10px 0;
                  position: relative;
                  display: inline;
                  height: 15px;
                "
              >
                {{ this.$store.state.cartCounter }}
              </div>
            </div>
          </li>-->
          <li
            class="mobile-inactive"
            v-if="this.$localStorage.get(this.$store.storeName) != null"
          >
            <div class="dropdown">
              <a
                id="dLabel"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style="
                  display: flex !important;
                  flex-direction: row-reverse;
                  align-items: center;
                "
              >
                {{ $t("Notifications") }}
                <i
                  class="bi bi-bell"
                  style="font-size: 18px; margin: inherit"
                ></i>
                <div
                  v-if="this.notifications != null"
                  style="
                    color: rgb(255, 255, 255);
                    font-size: 0.5rem;
                    padding: 0px 5px;
                    background-color: var(--primary-color);
                    width: 100%;
                    height: 15px;
                    border-radius: 25px;
                    margin: -10px -12px 0;
                  "
                >
                  {{ notifications.length }}
                </div>
              </a>

              <ul
                v-if="this.notifications != null"
                class="dropdown-menu notifications"
                role="menu"
                aria-labelledby="dLabel"
              >
                <div
                  class="d-flex flex-row justify-content-between align-items-center"
                >
                  <div class="notification-heading">
                    <h4 class="menu-title mt-2">{{ $t("Notifications") }}</h4>
                  </div>
                  <button
                    v-if="this.notifications != null"
                    class="delete"
                    @click="show = true"
                  >
                    <h6 style="color: #ed472b">
                      {{ $t("deleteAll") }}
                      <span>
                        <i class="bi bi-trash"></i>
                      </span>
                    </h6>
                  </button>
                </div>
                <li class="divider"></li>
                <div class="notifications-wrapper" v-if="!loadingNote">
                  <a
                    class="content"
                    href="#"
                    v-for="(item, index) in notifications"
                    :key="index"
                  >
                    <div class="notification-item">
                      <div
                        class="d-flex flex-row justify-content-between align-items-center"
                      >
                        <h4 class="item-title">{{ item.title }}</h4>
                        <!--<p
                            style="color:#ed472b; cursor:pointer"
                            @click="deleteNote(item.id,index)"
                          >
                            <i class="bi bi-trash"></i>
                      </p>-->
                        <button
                          type="button"
                          class="close"
                          aria-label="Close"
                          @click="deleteNote(item.id, index)"
                        >
                          <p aria-hidden="true">&times;</p>
                        </button>
                      </div>
                      <p class="item-info">{{ item.body }}</p>
                      <span class="item-info">{{ item.date }}</span>
                    </div>
                  </a>
                </div>
                <li class="divider"></li>
                <div class="notification-footer pb-2">
                  <router-link
                    :to="{
                      name: 'notifications',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("Viewall") }}</router-link
                  >
                </div>
              </ul>
            </div>
          </li>
          <li>
            <button v-if="$i18n.locale == 'ar'" @click="onChange('en')">
              <a class="d-flex align-items-center"
                ><i
                  class="bi bi-globe2"
                  style="font-size: 18px; margin: inherit"
                ></i
                >English</a
              >
            </button>
            <button v-if="$i18n.locale == 'en'" @click="onChange('ar')">
              <a class="d-flex align-items-center"
                ><i
                  class="bi bi-globe2"
                  style="font-size: 18px; margin: inherit"
                ></i
                >عربي</a
              >
            </button>
          </li>
          <li
            class="mobile-inactive"
            v-if="this.$localStorage.get(this.$store.storeName) == null"
          >
            <router-link
              :to="{
                name: 'login',
                params: { storeName: this.$store.storeName },
              }"
              ><i
                class="bi bi-person-circle"
                style="font-size: 18px; margin: inherit"
              ></i
            ></router-link>
          </li>
          <li
            class="mobile-inactive"
            v-if="this.$localStorage.get(this.$store.storeName) != null"
          >
            <router-link
              style="color: #222 !important; text-decoration: none !important"
              :to="{
                name: 'home1',
                params: { storeName: this.$store.storeName },
              }"
              @click.native="logOut()"
              >{{ $t("logOut") }}</router-link
            >
          </li>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        class="secondHeader mobile-inactive fixed-top d-flex align-items-center justify-content-between"
      >
        <div class="logo mobile-inactive">
          <img :src="this.$store.state.logo" alt />
        </div>
        <div
          class="d-flex align-items-center justify-content-center px-4 md:px-6 lg:px-8 2xl:px-10"
          style="width: 100%"
        >
          <li class="mobile-inactive" style="width: 100%; max-width: 800px">
            <input
              id="search"
              type="search"
              autocomplete="false"
              onblur="this.setAttribute('readonly', 'readonly') , word=''"
              onfocus="this.removeAttribute('readonly');"
              readonly
              class="form-control rounded"
              :placeholder="$t('search')"
              :aria-label="$t('search')"
              aria-describedby="search-addon"
              style="
                min-width: 500px;
                margin-top: 25px;
                border-radius: 5px !important;
                height: 55px;
                background-color: #fff;
                border: 1px solid #e5e7eb;
              "
              v-model="word"
              @keyup.enter="searchRoute"
            />
            <i class="bi bi-search" @click="searchRoute"></i>
          </li>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <li>
            <div
                style="
                  display: flex !important;
                  flex-direction: row-reverse;
                  align-items: center;
                  cursor: pointer;
                  min-width: 150px;
                "
                @click="cartVodal = true"
              >
                {{ $t("shoppingCart") }}
                <i
                  style="font-size: 18px; margin: inherit"
                  class="bi bi-bag mr-5"
                ></i>
                <div
                  v-if="this.$store.state.cartCounter != 0"
                  style="
                    color: #fff;
                    font-size: 0.5rem;
                    padding: 0 5px;
                    background-color: var(--primary-color);
                    
                    border-radius: 25px;
                    margin: -10px -10px 0;
                    position: relative;
                    display: inline;
                    height: 15px;
                  "
                >
                  {{ this.$store.state.cartCounter }}
                </div>
              </div>
          </li>
          <li
            class="mobile-inactive"
            v-if="this.$localStorage.get(this.$store.storeName) != null"
          >
            <div class="dropdown">
              <a
                id="dLabel"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style="
                  display: flex !important;
                  flex-direction: row-reverse;
                  align-items: center;
                "
              >
                {{ $t("Notifications") }}
                <i
                  class="bi bi-bell"
                  style="font-size: 18px; margin: inherit"
                ></i>
                <div
                  v-if="this.notifications != null"
                  style="
                    color: rgb(255, 255, 255);
                    font-size: 0.5rem;
                    padding: 0px 5px;
                    background-color: var(--primary-color);
                    width: 100%;
                    height: 15px;
                    border-radius: 25px;
                    margin: -10px -12px 0;
                  "
                >
                  {{ notifications.length }}
                </div>
              </a>

              <ul
                v-if="this.notifications != null"
                class="dropdown-menu notifications"
                role="menu"
                aria-labelledby="dLabel"
              >
                <div
                  class="d-flex flex-row justify-content-between align-items-center"
                >
                  <div class="notification-heading">
                    <h4 class="menu-title mt-2">{{ $t("Notifications") }}</h4>
                  </div>
                  <button
                    v-if="this.notifications != null"
                    class="delete"
                    @click="show = true"
                  >
                    <h6 style="color: #ed472b">
                      {{ $t("deleteAll") }}
                      <span>
                        <i class="bi bi-trash"></i>
                      </span>
                    </h6>
                  </button>
                </div>
                <li class="divider"></li>
                <div class="notifications-wrapper" v-if="!loadingNote">
                  <a
                    class="content"
                    href="#"
                    v-for="(item, index) in notifications"
                    :key="index"
                  >
                    <div class="notification-item">
                      <div
                        class="d-flex flex-row justify-content-between align-items-center"
                      >
                        <h4 class="item-title">{{ item.title }}</h4>
                        <!--<p
                            style="color:#ed472b; cursor:pointer"
                            @click="deleteNote(item.id,index)"
                          >
                            <i class="bi bi-trash"></i>
                      </p>-->
                        <button
                          type="button"
                          class="close"
                          aria-label="Close"
                          @click="deleteNote(item.id, index)"
                        >
                          <p aria-hidden="true">&times;</p>
                        </button>
                      </div>
                      <p class="item-info">{{ item.body }}</p>
                      <span class="item-info">{{ item.date }}</span>
                    </div>
                  </a>
                </div>
                <li class="divider"></li>
                <div class="notification-footer pb-2">
                  <router-link
                    :to="{
                      name: 'notifications',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("Viewall") }}</router-link
                  >
                </div>
              </ul>
            </div>
          </li>
          <li>
            <button v-if="$i18n.locale == 'ar'" @click="onChange('en')">
              <a class="d-flex align-items-center"
                ><i
                  class="bi bi-globe2"
                  style="font-size: 18px; margin: inherit"
                ></i
                >English</a
              >
            </button>
            <button v-if="$i18n.locale == 'en'" @click="onChange('ar')">
              <a class="d-flex align-items-center"
                ><i
                  class="bi bi-globe2"
                  style="font-size: 18px; margin: inherit"
                ></i
                >عربي</a
              >
            </button>
          </li>
        </div>
      </div>
      <header v-if="!loading" id="header">
        <div
          class="headercontainer d-flex align-items-center justify-content-between"
          v-if="!this.$store.userTokenState"
        >
          <div
            class="d-flex flex-row justify-content-start align-items-center"
            style="width: 50%"
          >
            <nav
              id="navbar"
              :class="'navbar' + (activeMobile ? ' navbar-mobile' : '')"
            >
              <i
                :class="
                  'bi bi-list mobile-nav-toggle ' +
                  (activeMobile ? ' bi-x' : '')
                "
                @click="activeMobile = !activeMobile"
              ></i>

              <ul>
                <li class="mobile-active">
                  <div>
                    <div
                      class="logo d-flex flex-row justify-content-center align-items-center"
                      style="height: 70px; margin-bottom: 20px"
                    >
                      <img :src="this.$store.state.logo" alt />
                    </div>
                  </div>
                </li>
                <li class="mobile-active">
                  <i class="bi bi-search" @click="searchRoute"></i>
                  <input
                    id="search"
                    type="search"
                    autocomplete="false"
                    onblur="this.setAttribute('readonly', 'readonly') , word=''"
                    onfocus="this.removeAttribute('readonly');"
                    readonly
                    class="form-control rounded"
                    :placeholder="$t('search')"
                    :aria-label="$t('search')"
                    aria-describedby="search-addon"
                    style="
                      border-radius: 5px !important;
                      border: 0;
                      width: 80%;
                      margin: auto;
                    "
                    v-model="word"
                    @keyup.enter="searchRoute"
                  />
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'home1',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("home") }}</router-link
                  >
                </li>
                <li class="mobile-inactive">
                  <div class="filterDropdown dropdown">
                    <router-link
                      :to="{
                        name: 'sections',
                        params: { storeName: this.$store.storeName },
                      }"
                    >
                      {{ $t("sections") }}
                      ⮟
                    </router-link>
                    <ul>
                      <li v-for="(item, index) in headerSections" :key="index">
                        <router-link
                          :to="{
                            name: 'section',
                            params: {
                              storeName: $store.storeName,
                              id: item.id,
                              name: item.name,
                            },
                          }"
                        >
                          <img class="image1" :src="item.icon" />
                          {{ item.name }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="mobile-active">
                  <router-link
                    :to="{
                      name: 'sections',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("sections") }}</router-link
                  >
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'about',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("about") }}</router-link
                  >
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'contactUs',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("contactUs") }}</router-link
                  >
                </li>
                <li
                  v-if="this.$localStorage.get(this.$store.storeName) != null"
                >
                  <router-link
                    :to="{
                      name: 'profile',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("profile") }}</router-link
                  >
                </li>
                <li v-else>
                  <router-link
                    style="
                      color: #222 !important;
                      text-decoration: none !important;
                    "
                    :to="{
                      name: 'login',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("profile") }}</router-link
                  >
                </li>

                <li
                  v-if="this.$localStorage.get(this.$store.storeName) != null"
                >
                  <router-link
                    :to="{
                      name: 'myAddresses',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("myAddresses") }}</router-link
                  >
                </li>
                <li v-else>
                  <router-link
                    style="
                      color: #222 !important;
                      text-decoration: none !important;
                    "
                    :to="{
                      name: 'login',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("myAddresses") }}</router-link
                  >
                </li>
                <li
                  v-if="this.$localStorage.get(this.$store.storeName) != null"
                >
                  <router-link
                    :to="{
                      name: 'myOrders',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("myOrders") }}</router-link
                  >
                </li>
                <li v-else>
                  <router-link
                    style="
                      color: #222 !important;
                      text-decoration: none !important;
                    "
                    :to="{
                      name: 'login',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("myOrders") }}</router-link
                  >
                </li>
                <li
                  v-if="this.$localStorage.get(this.$store.storeName) != null"
                >
                  <router-link
                    :to="{
                      name: 'favorites',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("favorites") }}</router-link
                  >
                </li>
                <li v-else>
                  <router-link
                    style="
                      color: #222 !important;
                      text-decoration: none !important;
                    "
                    :to="{
                      name: 'login',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("favorites") }}</router-link
                  >
                </li>

                <li class="mobile-active">
                  <div @click="cartVodal = true">
                    {{ $t("shoppingCart") }}
                  </div>
                </li>
                <li class="mobile-active">
                  <router-link
                    :to="{
                      name: 'notifications',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("Notifications") }}</router-link
                  >
                </li>
                <li class="mobile-active">
                  <button v-if="$i18n.locale == 'ar'" @click="onChange('en')">
                    <a>English</a>
                  </button>
                  <button v-if="$i18n.locale == 'en'" @click="onChange('ar')">
                    <a>عربي</a>
                  </button>
                </li>
                <li
                  class="mobile-active"
                  v-if="this.$localStorage.get(this.$store.storeName) != null"
                >
                  <router-link
                    style="
                      color: #222 !important;
                      text-decoration: none !important;
                    "
                    :to="{
                      name: 'home1',
                      params: { storeName: this.$store.storeName },
                    }"
                    @click.native="logOut()"
                    >{{ $t("logOut") }}</router-link
                  >
                </li>
                <li
                  class="mobile-active"
                  v-if="this.$localStorage.get(this.$store.storeName) == null"
                >
                  <router-link
                    :to="{
                      name: 'login',
                      params: { storeName: this.$store.storeName },
                    }"
                    >{{ $t("logIn") }}</router-link
                  >
                </li>
              </ul>
              <!-- .navbar -->
            </nav>
            <div class="mobile-active" style="margin: 0 10px">
              <div
                style="
                  display: flex !important;
                  flex-direction: row-reverse;
                  align-items: center;
                  cursor: pointer;
                "
                @click="cartVodal = true"
              >
                {{ $t("shoppingCart") }}
                <i
                  style="font-size: 18px; margin: inherit"
                  class="bi bi-bag mr-5"
                ></i>
                <div
                  v-if="this.$store.state.cartCounter != 0"
                  style="
                    color: #fff;
                    font-size: 0.5rem;
                    padding: 0 5px;
                    background-color: var(--primary-color);
                    width: 100%;
                    border-radius: 25px;
                    margin: -10px -10px 0;
                    position: relative;
                    display: inline;
                    height: 15px;
                  "
                >
                  {{ this.$store.state.cartCounter }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <li
              class="mobile-inactive"
              v-if="this.$localStorage.get(this.$store.storeName) == null"
            >
              <router-link
                :to="{
                  name: 'login',
                  params: { storeName: this.$store.storeName },
                }"
                >{{ $t("logIn") }}</router-link
              >
            </li>
            <li
              class="mobile-inactive"
              v-if="this.$localStorage.get(this.$store.storeName) != null"
            >
              <router-link
                style="color: #222 !important; text-decoration: none !important"
                :to="{
                  name: 'home1',
                  params: { storeName: this.$store.storeName },
                }"
                @click.native="logOut()"
                >{{ $t("logOut") }}</router-link
              >
            </li>
          </div>

          <div class="logo mobile-active">
            <img :src="this.$store.state.logo" alt />
          </div>
        </div>
      </header>
    </div>
    <Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{ $t("confirmdeleteAllNoti") }}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="deleteAll()">
                  {{ $t("confirm") }}
                </button>
                <button
                  class="modal-default-button"
                  @click="(show = false), $emit('close')"
                >
                  {{ $t("cancel") }}
                </button>
              </slot>
            </div>
          </div>
        </div>
      </div>
      <div v-if="show1" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{ $t("confirmdeleteOneNoti") }}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="deleteOneNote()">
                  {{ $t("confirm") }}
                </button>
                <button
                  class="modal-default-button"
                  @click="(show1 = false), $emit('close')"
                >
                  {{ $t("cancel") }}
                </button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <!--<vodal
        :show="loginVodal"
        animation="flip"
        @hide="loginVodal = false"
        :width="600"
        :height="370"
      >
        <div style="height: 350px; overflow: scroll">
          <login></login>
        </div>
    </vodal>-->
    <vodal
      :show="cartVodal"
      animation="slideLeft"
      @hide="cartVodal = false"
      :width="500"
      :height="1000"
      class="cartVodal"
    >
      <div>
        <shopping-cart v-if="cartVodal"></shopping-cart>
      </div>
    </vodal>
  </div>
</template>

<script>
import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";

import ShoppingCart from "./ShoppingCart.vue";
export default {
  // eslint-disable-next-line
  /*eslint-disable */
  name: "Header",
  components: { Vodal, ShoppingCart },
  props: {
    // name: String,
    // image: String
    storeName: String,
  },
  data: function () {
    return {
      loading: false,
      loadingNote: false,
      activeMobile: false,
      word: "",
      show: false,
      show1: false,
      deleteNoteId: null,
      deleteNoteIndex: null,
      notifications: [],
      headerSections: null,
      loginVodal: false,
      cartVodal: false,
    };
  },
  computed: {
    myState: function () {
      return this.$store.state.notificationUpdate; // return the state value in `my_state`
    },
  },
  watch: {
    myState: function (newVal, oldVal) {
      //console.log(this.$store.notificationUpdate + " hiiiiiiiiiiii ");
      if (this.$localStorage.get(this.$store.storeName) != null) {
        this.loadingNote = true;
        this.axios
          .get(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/new_note",
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName),
              },
            }
          )
          .then((response) => {
            if (response.data.data.code == "1") {
              this.notifications = response.data.data.notes;
              /*console.log("heeeeeeeeeeeeeeeeeeee " + this.notifications);*/
            }
          })
          .then(() => {
            this.loadingNote = false;
          });
      }
    },
  },
  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/sections_theme2",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.headerSections = response.data.data;
      })
      .then(() => (this.loading = false));

    if (this.$localStorage.get(this.$store.storeName) != null) {
      this.loadingNote = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/new_note",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          if (response.data.data.code == "1") {
            this.notifications = response.data.data.notes;
            //console.log(this.notifications);
          }
        })
        .then(() => {
          this.loadingNote = false;
        });
    }
    let AllCookies = this.$cookies.keys();
    let counter = 0;
    for (let i = 0; i < AllCookies.length; i++) {
      if (AllCookies[i].split("/")[0] == this.$store.storeName) {
        counter++;
        //console.log(this.items);
      }
    }
    this.$store.state.cartCounter = counter;
    console.log(counter);
  },
  methods: {
    /*onChange: function (event) {
      this.$store.state.langchange = true;
      if (event.target.value == "ar") {
        document.documentElement.setAttribute("dir", "rtl");
      } else {
        document.documentElement.setAttribute("dir", "ltr");
      }
    },*/
    onChange(lang) {
      this.$store.state.langchange = true;
      this.$store.state.langchange1 = true; //for footer
      this.$i18n.locale = lang;
      if (lang == "ar") {
        document.documentElement.setAttribute("dir", "rtl");
      } else {
        document.documentElement.setAttribute("dir", "ltr");
      }
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/sections_theme2",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.headerSections = response.data.data;
        })
        .then(() => (this.loading = false));

      if (this.$localStorage.get(this.$store.storeName) != null) {
        this.loadingNote = true;
        this.axios
          .get(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/new_note",
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName),
              },
            }
          )
          .then((response) => {
            if (response.data.data.code == "1") {
              this.notifications = response.data.data.notes;
              //console.log(this.notifications);
            }
          })
          .then(() => {
            this.loadingNote = false;
          });
      }
    },
    logOut() {
      this.loading = true;
      this.axios.get(
        this.$store.state.base_api_url + this.$store.storeName + "/logout",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName),
          },
        }
      );
      //console.group("hiiiiii")
      console.log(
        "logout:" +
          //this.$localStorage.id() +
          this.$localStorage.get(this.$store.storeName)
      );
      //this.$localStorage.remove("userToken");
      let AllCookies = this.$cookies.keys();
      for (let i = 0; i < AllCookies.length; i++) {
        if (AllCookies[i].split("/")[0] == this.$store.storeName) {
          this.$cookies.remove(console.log(AllCookies[i]));
        }
      }
      console.log(AllCookies);
      /*if (this.$cookies.isKey(this.$store.storeName + product.product_id + product.final_option + product.option_check)) {
      this.$cookies.remove(this.$store.storeName + product.product_id + product.final_option + product.option_check)
      }*/
      this.$localStorage.remove(this.$store.storeName);
      window.location.reload();
      this.loading = false;
    },
    searchRoute() {
      if (this.$route.path.includes("/" + this.$store.storeName + "/Search")) {
        console.log(this.word + "hiiiiiii");
        this.$router.push({ name: "search", params: { word: this.word,lang: this.$i18n.locale } });
      } else {
        this.$router.push({
          name: "search",
          params: { storeName: this.$store.storeName, word: this.word ,lang: this.$i18n.locale},
        });
      }
    },
    deleteAll() {
      this.loadingNote = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/delete_allnote",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          if (response.data.code == "1") {
            this.notifications = null;
            this.count = 0;
          }
        })
        .then(() => {
          this.loadingNote = false;
          this.show = false;
        });
    },
    deleteOneNote() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/delete_note/" +
            this.deleteNoteId,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          if (response.data.code == "1") {
            this.$delete(this.notifications, this.deleteNoteIndex);

            // this.$el.parentNode.removeChild(this.$el);
          }
        })
        .then(() => {
          this.loading = false;
          this.show1 = false;
        });
    },
    deleteNote(itemId, itemIndex) {
      this.show1 = true;
      this.deleteNoteId = itemId;
      this.deleteNoteIndex = itemIndex;
    },
    /* onClickMobile: function () {
      $("#navbar").addClass(" navbar-mobile");
      $(".mobile-nav-toggle").addClass("bi-list bi-x");
    },*/
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.homeHeader {
  background-color: #fff !important;
}
.homeHeader li {
  padding: 5px 5px 7px 5px !important;
}
.homeHeader li a{
  font-size: 12px;
  font-family: "Araboto-normal";
}
.homeHeader .logo img {
   margin: 0 20px !important;
}
.topHeader {
  color: #fff;
  background-color: #484848;
  height: 30px;
  padding: 0 3%;
}
.topHeader a {
  color: #fff !important;
}
.topHeader button:hover,
.social-linkes img:hover {
  transform: scale(1.05);
  /*transform: rotate3d(1, 1, 1, 10deg);*/
}
.social-linkes img {
  width: 20px;
}
.secondHeader {
  /*margin-top: 30px;*/
  background-color: #f8f9fb;
  height: 80px;
  padding: 0 3%;
  /*border-bottom: 2px solid #f7f8fb;*/
}
.secondHeader li {
  padding: 5px 18px 7px 18px;
}
.secondHeader .logo img {
  padding: 0;
    margin: 0;
    max-height: 100px;
    max-width: 200px;
    padding-bottom: 2%;
    padding-top: 1%;
}
.image1 {
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin: auto 10px;
}
#header {
  background-color: #ffffff;
  border: 0;
  margin-top: 80px;
  height: 60px;
  border-bottom: 1px solid #e5e7eb;
  /*position: fixed;
    right: 0;
    left: 0;*/
}
#header .navbar {
  margin: 0;
}
#header .navbar li {
  padding: 0 !important;
}
#header .navbar ul {
  width: 100%;
  justify-content: space-between;
}
.headercontainer {
  padding: 0 3%;
  height: 100%;
}
.secondHeader .notification-item h4 {
  color: #000;
  text-align: start;
}
.secondHeader .notification-item p {
  font-size: 15px;
  margin: 0;
  max-width: 100%;
  line-break: anywhere;
  text-align: start;
  color: var(--secondary-color);
}
.secondHeader .notification-item span {
  color: var(--primary-color);
  font-size: 10px;
  text-align: start;
}
.secondHeader .notifications {
  min-width: 420px;
}

.secondHeader .dropdown ul {
  display: none;
}

.secondHeader .dropdown .show {
  display: inherit;
}

#dLabel {
  display: flex !important;
}

.secondHeader .notifications ul {
  margin: 0;
  display: inherit;
}

.secondHeader .notifications-wrapper {
  overflow: auto;
  max-height: 250px;
}

.secondHeader .menu-title {
  color: var(--primary-color);
  font-size: 1.2rem;
  display: inline-block;
}

.secondHeader .glyphicon-circle-arrow-right {
  margin-left: 10px;
}

.secondHeader .notification-heading,
.secondHeader .notification-footer {
  padding: 2px 10px;
}

.secondHeader .dropdown-menu.divider {
  margin: 5px 0;
}

.secondHeader .item-title {
  font-size: 1.2rem;
  color: #000;
}

.secondHeader .notifications a.content {
  text-decoration: none;
  background: #ccc;
  display: inline;
  padding: 0;
  margin: 0 !important;
}

.secondHeader .notification-item {
  padding: 10px;
  margin: 5px;
  background: #f5f5f5;
  border-radius: 4px;
}
/*///////////////////////////*/
.navbar a,
.navbar a:focus {
  color: #222;
}
/*@keyframes animate {
        0% {
             text-decoration-line: none ;
        }

        100% {
              text-decoration-line:underline  ;
        }
    }*/
.navbar a:hover,
.navbar a:focus,
.router-link-exact-active,
.router-link-exact-active:focus {
  font-size: 12px;
  text-decoration: 3px underline !important;
  text-underline-offset: 22px;
  animation: animate 100s linear;
  margin: 0 10px;
}

.homeHeader .navbar a:hover,
.homeHeader .navbar a:focus,
.homeHeader .router-link-exact-active,
.homeHeader .router-link-exact-active:focus {
  text-underline-offset: 34px;
}
[dir="rtl"] .bi-search {
  margin-right: 95% !important;
  top: -42px;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  color: #999;
}
[dir="ltr"] .bi-search {
  margin-left: 95% !important;
  top: -42px;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  color: #999;
}

.filterDropdown ul,
li {
  list-style: none;
}
.filterDropdown ul {
  display: block;
  position: absolute;
  left: 5px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0 0 20px #505050;
  transition: 0.3s;
  border-radius: 5px;
  min-height: 100px;
  max-height: 300px;
  min-width: 220px;
  overflow: auto;
}
[dir="rtl"] #header .filterDropdown ul {
  right: 10px;
}

/*.navbar .dropdown ul li,*/

.filterDropdown ul li {
  min-width: 200px;
  border-top: 1px solid #bebebe;
}

/*.navbar .dropdown ul a,*/

.filterDropdown ul a {
  padding: 10px 20px;
  color: #2a2c39;
  margin: 0 5px;
  font-size: 14px;
  justify-content: flex-start;
}

/*.navbar .dropdown ul a i,*/

.filterDropdown ul a i {
  font-size: 12px;
}

/*.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a,*/

.filterDropdown ul a:hover,
.filterDropdown ul .active:hover,
.filterDropdown ul li:hover > a {
  color: var(--primary-color);
}

/*.navbar .dropdown:hover>ul,*/

.filterDropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

/*.navbar .dropdown .dropdown ul,*/

.filterDropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

/*.navbar .dropdown .dropdown:hover>ul,*/

.filterDropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
button {
  background-color: transparent;
  border: 0;
  /*border: 1px solid var(--primary-color);
  border-radius: 15px;
  padding: 10px;*/
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header {
  margin-top: 0;
  color: var(--secondary-color) !important;
  font-family: "Araboto-Medium";
  margin-bottom: 20px;
}

.modal-body {
  margin: 20px 0;
  font-family: "Araboto-Medium";
}

.modal-default-button {
  float: right;
  font-family: "Araboto-Medium";
  margin-left: 5px;
  margin-right: 5px;
  font-size: 15px;
  border: 2px solid var(--primary-color);
  color: var(--secondary-color);
  border-radius: 5px;
}
.modal-default-button:hover {
  background-color: white;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/*
[dir="ltr"] .bi-search {
  margin-left: 470px;
}

[dir="rtl"] .bi-search {
  margin-right: 470px;
}
*/
li a {
  color: #222;
  font-size: 12px;
  margin: 0 10px;
  font-family: "Araboto-normal";
  
}

.navbar-mobile {
  top: 0px;
}
/*@media (min-width: 1400px) {
  [dir="rtl"] .bi-search {
    margin-right: 470px !important;
  }

  [dir="ltr"] .bi-search {
    margin-left: 470px;
  }
}*/
@media (max-width: 1200px) and (min-width: 992px) {
  .navbar li {
    padding: 5px 7px 7px 7px;
  }
  .navbar a {
    padding: 5px 5px;
  }
}
@media (max-width: 992px) {
  .mobile-inactive {
    display: none !important;
  }
  #header {
    margin-top: 0px;
  }
  .image1 {
    width: 15px;
    height: 15px;
  }
  #header .logo img {
    height: 70px !important;
    /* width: 40px !important;*/
  }
  [dir="rtl"] .bi-search {
    margin-right: 80% !important;
    top: 30px;
  }
  [dir="ltr"] .bi-search {
    margin-left: 80% !important;
    top: 30px;
  }
  .navbar a:hover,
  .navbar a:focus,
  .router-link-exact-active,
  .router-link-exact-active:focus {
    text-decoration: none !important;
  }

  .navbar-mobile div {
    padding: 10px 20px;
    font-size: 13px;
    margin: 0 10px;
    font-family: "Araboto-Medium";
  }
}
</style>

<template>
    <div class="cl_logo">
      <router-link 
                        :to="{
      name: 'brand',
      params: { storeName: $store.storeName, id: id  , name:name,lang: $i18n.locale},
    }"
                        
                        >
        <img 
        :src="image" 
        :alt="name"/>
        </router-link>
     </div>                 
</template>
<script>
export default {
  props: {
    name: String,
   image:String,
   id:Number
  },
  data: function () {
    return {};
  },
};
</script>
<style >
.cl_logo
{
  width:120px;
  height:120px;
}
.cl_logo img
{
  width:100%;
  height: 100%;
 object-fit: cover;
}
</style>
<template>
  <section v-if="this.$localStorage.get(this.$store.storeName) == null" id="Second">
    <div v-if=" this.$store.state.auth==0">
      <!-- email -->
      <div class="container">
        <div class="card">
          <div class="container">
            <div class="row">
              <div class="col-md-7 " style="
                      background-image: url(https://media.istockphoto.com/id/1385695992/photo/businessmen-protecting-personal-data-on-laptop-and-virtual-interfaces.webp?b=1&s=170667a&w=0&k=20&c=VH8TXb5f70cZsmhcl-Lc9ao99vFaoq6xdbB3Dfmldhg=);
                      background-repeat: no-repeat;
                      background-size:cover;
                  ">
              </div>
              <div class="col-md-5 p-lg-5  pt-4 pb-2">
                <img class="logo"  :src="this.$store.state.logo" alt />
                <h3>مرحباً بك مرة أخرى!</h3>
                <div v-if="errorMessage != ''" style="text-align: center; color: red; padding: 5px">
                  <p style="margin: 0">{{ errorMessage }}</p>
                </div>
                <router-link
                    class="text-center mt-3"
                    style=" font-size:14px"
                    :to="{
                        name: 'register',
                        params: { storeName: this.$store.storeName }, }">
                    <p>{{ $t("dontHaveAccount") }}</p>
                  </router-link>
                <form @submit.prevent="submitInfo" @keydown="form.onKeydown($event)">
                  <div class="input1">
                    <h4>{{ $t("Email") }}</h4>
                    <input
                      class="input-field"
                      type="email"
                      name="email"
                      v-model="form.email"
                      placeholder="example@gmail.com"
                      required
                    />
                  </div>
                  <div class="input1">
                    <h4>{{ $t("password") }}</h4>
                    <input
                      id="password"
                      class="input-field"
                      type="password"
                      name="name"
                      v-model="form.password"
                      :placeholder="$t('EnterPass')"
                      required
                      minlength="8"
                    />
                  </div>
                  <button type="submit" :disabled="form.busy" class="button1">{{ $t("logIn") }}</button>
                  
                </form>
              
              <div class="d-flex flex-row justify-content-center">
                <button
                  @click="(forgetVodal = true), (phone = null), (partphone = null)"
                  class="forgetpass"
                >
                  <p>{{ $t("forgetpass") }}</p>
                </button>
              </div>
              </div>
              <vodal
                :show="forgetVodal"
                animation="flip"
                @hide="forgetVodal = false"
                :width="500"
                :height="380"
                style="margin-top: 20px"
              >
                <div class="container" style="width: 80%; margin: 10%">
                  <h5 style="color: var(--primary-color)">{{ $t("Entertheemail") }} :</h5>

                  <input
                    class="input-field mt-5"
                    type="email"
                    name="passemail"
                    v-model="passemail"
                    style="width: 100%"
                    placeholder="example@gmail.com"
                    required
                  />

                  <button class="button text-center mt-5" @click="resetpass()">{{ $t("continue") }}</button>
                  <p style="text-align: center">{{ errorMessageVodal }}</p>
                  <button @click="forgetEmail()" class="forgetpass w-100 text-center">
                    <p>{{ $t("forgetemail") }}</p>
                  </button>
                </div>
              </vodal>
              <vodal
                :show="forgetEmailVodal"
                animation="slideLeft"
                @hide="forgetEmailVodal = false"
                :width="500"
                :height="350"
                style="margin-top: 20px"
              >
                <div class="container" style="width: 80%; margin: 10%">
                  <h5 style="color: var(--primary-color)">{{ $t("contactUs") }} :</h5>
                  <div dir="ltr" class="text-center" style="margin-top:60px">
                    <a
                      target="_blank"
                      :href="'https://api.whatsapp.com/send?phone='+'+97450988700'+'&text=Hello!'"
                    >
                      <h5>
                        <i class="bi bi-telephone-fill"></i> +97450988700
                      </h5>
                      <!--edittttttttttttt tow phone-->
                    </a>
                    <a target="_blank" :href="'mailto:'+  'info@etrolley.net'">
                      <!--edittttttttttttt-->
                      <h5 style="margin-top:20px">
                        <i class="bi bi-envelope-fill"></i> info@etrolley.net
                      </h5>
                    </a>
                  </div>
                  <!--<h5 style="color: var(--primary-color)">{{ $t("inputThePhone") }} :</h5>
                    <div class="input-field mt-5">
                      <VuePhoneNumberInput
                        dir="ltr"
                        style="width: 100%"
                        required
                        fetch-country
                        no-example
                        show-code-on-list
                        @update="sendPhone1($event)"
                        v-model="partphone"
                      />
                    </div>
                  <button class="button text-center mt-5" @click="resetpass1()">{{ $t("continue") }}</button>-->
                  <p style="text-align: center">{{ errorMessageVodal }}</p>
                </div>
              </vodal>
              <vodal
                  :show="codeVodal"
                  animation="slideLeft"
                  @hide="codeVodal = false"
                  :width="500"
                  :height="380"
                  style="margin-top: 20px"
                >
                <div class="container" style="width: 80%; margin: 10%">
                  <h5
                    style="
                        color: var(--primary-color);
                        margin-left: 20px;
                        margin-right: 20px;
                      "
                  >{{ $t("EntertheCode") }} :</h5>
                  <div dir="ltr" class="mt-5">
                    <CodeInput
                      :loading="false"
                      required
                      class="input"
                      @change="onChange"
                      @complete="onComplete"
                    />
                    <p
                      style="margin-top: 10px; margin-bottom: 0; text-align: center;"
                      v-if="$i18n.locale == 'en'"
                    >{{ countDown }} second</p>
                    <p
                      style="margin-top: 10px; margin-bottom: 0; text-align: center;"
                      v-else
                    >{{ countDown }} ثانية</p>
                  </div>
                  <button
                    v-if="countDown == 0"
                    class="ResendCode"
                    @click="ResendCode()"
                  >{{ $t("ResendCode") }}</button>
                  <button
                    class="button text-center mt-5"
                    @click="checkCode()"
                    :disabled="countDown == 0"
                  >{{ $t("continue") }}</button>
                  <p style="text-align: center; margin-top: 10px">{{ errorMessageVodal }}</p>
                </div>
              </vodal>
              <vodal
                  :show="newPassVodal"
                  animation="slideLeft"
                  @hide="newPassVodal = false"
                  :width="500"
                  :height="350"
                  style="margin-top: 20px"
                >
                <div class="container" style="width: 80%; margin: 10%">
                  <h5
                    style="
                  color: var(--primary-color);
                  margin-left: 20px;
                  margin-right: 20px;
                "
                  >{{ $t("EnterNewPass") }} :</h5>
                  <div dir="ltr" class="mt-5">
                    <input
                      id="newpassword"
                      class="input-field"
                      type="password"
                      name="name"
                      v-model="Newpassword"
                      :placeholder="$t('EnterPass')"
                      required
                      minlength="8"
                    />
                  </div>
                  <button
                    class="button text-center mt-5"
                    @click="SaveChanges()"
                  >{{ $t("SaveChanges") }}</button>
                  <p style="text-align: center; margin-top: 10px">{{ errorMessageVodal }}</p>
                </div>
              </vodal>
              <!--<div
                  v-if="errorMessage != ''"
                  style="
                    position: fixed;
                    width: 200px;
                    bottom: 50px;
                    left: 50px;
                    border-radius: 5px;
                    border: 2px solid red;
                    text-align: center;
                    padding: 5px;
                    background-color: #fff;
                  "
                >
                  <p style="margin: 0">{{ errorMessage }}</p>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <!-- phone -->
      <div v-if="this.$store.state.sms_type=='0'">
        <!--firebase-->
        {{ this.$router.push({
        name: "loginPhonesms",
        params: { storeName: this.$store.storeName }
        }) }}
      </div>
      <div v-else>
        <!--without firebase-->
        {{ this.$router.push({
        name: "loginPhone",
        params: { storeName: this.$store.storeName }
        }) }}
      </div>
    </div>
  </section>
</template>
<script>
import Form from "vform";
import { AlertErrors, AlertSuccess } from "vform/src/components/bootstrap5";
import CodeInput from "vue-verification-code-input";

import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";
import "vodal/slide-left.css";

import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  components: {
    AlertErrors,
    AlertSuccess,
    Vodal,
    CodeInput,
    VuePhoneNumberInput
  },
  data: function() {
    return {
      forgetVodal: false,
      forgetEmailVodal: false,
      codeVodal: false,
      newPassVodal: false,
      DBitems: null,
      errorMessageVodal: "",
      errorMessage: "",
      passemail: "",
      Newpassword: null,
      checkcomplete: false,
      validateMessage: "",
      finalcode: null,
      form: new Form({
        email: null,
        password: null,
        fsm_token: this.$store.state.fsm_token
      }),

      isValid: false,
      partphone: null,
      phone: null,
      emailTrue: false,

      countDown: 90
    };
  },
  created() {
    if (this.$localStorage.get(this.$store.storeName) != null) {
      this.$router.push({
        name: "home1",
        params: { storeName: this.$store.storeName }
      });
    }
  },
  mounted() {
    try {
      if (this.$i18n.locale == "ar") {
        document
          .getElementsByClassName("input-tel__input")[0]
          .setAttribute("placeholder", "رقم الهاتف");
        document.getElementsByClassName(
          "country-selector__label"
        )[0].innerHTML = "رمز الدولة";
        document.getElementsByClassName("input-tel__label")[0].innerHTML =
          "* رقم الهاتف";
        /*document.getElementsByClassName('select-country-container')[0].setAttribute('dir', 'ltr');
  document.getElementsByClassName('select-country-container')[0].setAttribute('dir', 'ltr');*/
      }
    } catch (e) {}
  },
  methods: {
    async submitInfo() {
      const response = await this.form
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/login",
          {
            email: this.form.email,
            password: this.form.password,
            fsm_token: this.form.fsm_token,
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            ////////////// , initializes a session with a 'session-id'.///////////////////
            //this.$localStorage.start();
            this.$localStorage.set(
              this.$store.storeName,
              response.data.data.token
            );
            //this.$localStorage.set("userToken", response.data.data.token);
            console.log(
              this.$localStorage.get(this.$store.storeName) +
                " " +
                this.$store.storeName
            );

            //console.log("login user token : " + this.$session.get("userToken"));
            /*this.$router.push({
              name: "home1",
              params: { storeName: this.$store.storeName },
            });
            window.location.reload();*/
            window.location.href="/";
            //window.location.href= "https://192.168.1.16:8000/"+this.$store.storeName +"/profile"
          } else if (response.data.code == "-1") {
            this.errorMessage = response.data.message;
            setTimeout(() => {
              this.errorMessage = "";
            }, 3000);
          }
          if (response.data.error) {
            this.errorMessage = "response.data.error";
            setTimeout(() => {
              this.errorMessage = "";
            }, 3000);
          }
        });
    },
    forgetEmail() {
      this.forgetVodal = false;
      this.forgetEmailVodal = true;
      this.passemail = null;
    },
    sendPhone1(event) {
      this.isValid = event.isValid;
      if (this.isValid == true) {
        this.phone = event.e164; //+ (' +' +event.countryCallingCode));
        //console.log(this.form.phone)
      }
      //console.log(event.isValid);
      //console.log(event);
    },
    resetpass() {
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/forget_password",
          {
            ///update_password
            email: this.passemail
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.forgetVodal = false;
            this.forgetEmailVodal = false;
            this.codeVodal = true;
            this.emailTrue = true;
            this.countDown = 90;
            this.countDownTimer();
            // console.log(response.data.message);
          } else if (response.data.code == "-1") {
            this.errorMessageVodal = response.data.message;
            setTimeout(() => {
              this.errorMessageVodal = "";
            }, 3000);
          }
          if (response.data.error) {
            this.errorMessageVodal = response.data.message;
            setTimeout(() => {
              this.errorMessageVodal = "";
            }, 3000);
          }
        });
    },
    resetpass1() {
      if (this.isValid == true) {
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/forget_password",
            {
              ///update_password
              phone: this.phone
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale
              }
            }
          )
          .then(response => {
            if (response.data.code == "1") {
              this.forgetVodal = false;
              this.forgetEmailVodal = false;
              this.codeVodal = true;
              this.emailTrue = false;
              this.countDown = 90;
              this.countDownTimer();
              // console.log(response.data.message);
            } else if (response.data.code == "-1") {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
            if (response.data.error) {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
          });
      } else {
        if (this.$i18n.locale == "en") {
          this.errorMessageVodal = "your number isn't valid";
          setTimeout(() => {
            this.errorMessageVodal = "";
          }, 3000);
        } else {
          this.errorMessageVodal = "هذا الرقم غير صالح";
          setTimeout(() => {
            this.errorMessageVodal = "";
          }, 3000);
        }
      }
    },
    checkCode() {
      if (this.checkcomplete == true) {
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/verivey_reset_password",
            {
              ///update_password
              code: this.finalcode,
              email: this.passemail,
              phone: this.phone
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale
              }
            }
          )
          .then(response => {
            if (response.data.code == "1") {
              this.forgetVodal = false;
              this.forgetEmailVodal = false;
              this.codeVodal = false;
              this.newPassVodal = true;
              // console.log(response.data.message);
            } else if (response.data.code == "-1") {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
            if (response.data.error) {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
          });
      } else {
        if (this.$i18n.locale == "en") {
          this.errorMessageVodal = "your code isn't complete";
          setTimeout(() => {
            this.errorMessageVodal = "";
          }, 3000);
        } else {
          this.errorMessageVodal = "الرمز المدخل غير مكتمل";
          setTimeout(() => {
            this.errorMessageVodal = "";
          }, 3000);
        }
      }
    },
    SaveChanges() {
      if (this.Newpassword != null) {
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/update_password",
            {
              ///update_password
              password: this.Newpassword,
              email: this.passemail,
              phone: this.phone,
              fsm_token: this.form.fsm_token
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale
              }
            }
          )
          .then(response => {
            if (response.data.code == "1") {
              this.forgetVodal = false;
              this.forgetEmailVodal = false;
              this.codeVodal = false;
              this.newPassVodal = false;
              // console.log(response.data.message);

              ////////////// , initializes a session with a 'session-id'.///////////////////
              //this.$localStorage.start();
              this.$localStorage.set(
                this.$store.storeName,
                response.data.data.token
              );
              //this.$localStorage.set("userToken", response.data.data.token);
              console.log(
                this.$localStorage.get(this.$store.storeName) +
                  "  " +
                  this.$store.storeName
              );

              //console.log("login user token : " + this.$session.get("userToken"));
              /*this.$router.push({
                name: "profile",
                params: { storeName: this.$store.storeName },
              });
              window.location.reload();*/
              window.location.href =
                "https://etrolley.net/store/" +
                this.$store.storeName +
                "/profile";
              //window.location.href= "https://192.168.1.16:8000/"+this.$store.storeName +"/profile"
            } else if (response.data.code == "-1") {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
            if (response.data.error) {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
          });
      } else {
        this.errorMessageVodal = "error";
        setTimeout(() => {
          this.errorMessageVodal = "";
        }, 3000);
      }
    },
    ResendCode() {
      let val = {};
      if (this.emailTrue == true) {
        val = { email: this.passemail };
      } else {
        val = { phone: this.phone };
      }
      console.log("val" + val);
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/forget_password",
          val,
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          if (response.data.code == "-1") {
            this.errorMessageVodal = response.data.message;
            setTimeout(() => {
              this.errorMessageVodal = "";
            }, 3000);
          } else if (response.data.code == "1") {
            this.countDown = 90;
            this.countDownTimer();
            this.errorMessageVodal = response.data.message;
            setTimeout(() => {
              this.errorMessageVodal = "";
            }, 3000);
          }
        });
    },
    onChange(v) {
      //console.log("onChange ", this.phone);
      this.checkcomplete = false;
    },
    onComplete(v) {
      //console.log("onComplete ", v);
      this.finalcode = v;
      this.checkcomplete = true;
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          if (this.countDown > 0) {
            this.countDown -= 1;
            this.countDownTimer();
          }
        }, 1000);
      }
    }
  }
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.input1 {
  width: 100%;
 /* margin-left: 10%;
  margin-right: 10%;*/
}
#Second {
  margin-top: 20px;
  min-height: 500px;
}

input {
  border: 1px solid #d7d7d7;
    width: 100%;
    height: 32px;
    border-radius: 5px;
    padding: 20px 5px 25px 5px;
    font-size: 14px;

}
h3 {
  font-size: 18px;
  font-family: "Araboto-Bold";
  color: #000;
  text-align: center;
}
h4 {
  font-size: 14px;
  font-family: "Araboto-normal";
  color: #6e6c6c;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button1 {
  background-color: var(--primary-color);
  border-radius: 5px;
  width: 100%;
  padding: 10px 0 15px;
  text-align: center;
  border:0;
  color: #fff;
  font-size: 14px;
  font-family: "Araboto-normal";
  margin-top: 20px;
}

.button1:hover,
.button:hover {
  opacity: 0.9;
}

.button {
  background-color: var(--primary-color);
  border-radius: 10px;
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  padding: 10px 0 15px;
  text-align: center;
  border: 2px solid var(--primary-color);
  color: #fff;
  font-size: 16px;
  font-family: "Araboto-Medium";
  margin-top: 20px;
}
.ResendCode {
  background-color: transparent;
  border: 0;
  color: var(--primary-color);
  text-decoration: 1px underline;
  text-underline-offset: 6px;
  margin-left: 25%;
  margin-right: 25%;
  width: 50%;
}
.ResendCode:hover {
  text-underline-offset: 10px;
}

.card{
  max-width: 1000px;
  margin: auto;
}
.card-img-actions .preview-1 {
  margin-top: 30px;
  width: 170px !important;
  height: 170px !important;
  border-radius: 50%;
  border: 4px solid var(--primary-color);
  object-fit: cover;
}

.logo{
    margin-bottom: 20px;
    width: 40%;
    margin-left: 30%;
    margin-right: 30%;
}
.forgetpass {
  background: transparent;
  border: 0;
      margin-top: 10px;
color: #6e6c6c;
}
.forgetpass:hover {
  color: var(--primary-color);
}
p{
  font-size: 14px;
}
.icon {
  margin-top: -120px;
}

/*@media (min-width: 1400px) {
  .container {
    max-width: 1200px !important;
  }
}*/
@media (max-width: 992px) {
  h4 {
    font-size: 15px;
  }
  .card-img-actions .preview-1 {
    margin-top: 30px;
    width: 120px !important;
    height: 120px !important;
  }
  .card-img-actions {
    margin-top: 40px;
  }
  .input1 {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 20px;
  }
  .button {
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
  }
}
@media (max-width: 772px) {
  .input1 {
    width: 100%;
    margin-left: 0%;
    margin-right:0%;
    margin-top: 20px;
  }
  .button {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
  }
}
</style>

<template>

<div class="container" id="container_order">
    <h2 style="text-align: center; "> {{ $t("favorites") }}</h2>
   <profile_header></profile_header>
                                      
    <section style="margin-top: 2%;">
            <div class="container">
          <h2 style="text-align: center; padding-bottom: 2%;" class="page_title">
            {{ $t("favorites") }}
          </h2>
          <div
            v-if="loading"
            class="d-flex flex-row justify-content-center"
            style="margin-top: 100px; margin-bottom: 200px"
          >
            <hollow-dots-spinner
              :animation-duration="1000"
              :dot-size="35"
              :dots-num="3"
              color="var(--primary-color)"
            />
          </div> 
                <div v-else-if="this.products.length!=0" class="row">
                    <div v-for="(item, idx) in this.products"
                     :key="idx" 
                class="col-lg-3 col-md-3 col-6" :id="'fav'+item.id">
                    <product
                    :key="index" 
              :id="item.id"
              :name="item.name"
              :description="item.description"
              :is_favorit="item.is_favorit"
              :image="item.image"
              :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
              :rate="item.rate"
              :count_view="item.count_views"
              :is_option="item.is_option"
              :is_offer="item.is_offer"
               :unit="item.unit"
               :new="item.is_new"
               :fetuered="item.is_fetured"
               :discount="item.discount"
               :qnt="item.qnt"
               :min_qnt="item.min_qnt"
               :max_qnt="item.max_qnt"
                    >
                    </product>
                </div>
            </div>
            <div  v-else="this.products.length==0" class="empty text-center center-text ">
                 
                 <img src="/assets/images/empty.png"/>
                 <br>
                 
                 </div>
            <div v-if="this.products.length!=0" 
            class="d-flex flex-row justify-content-center mt-5">
              <b-pagination
                v-model="page"
                :total-rows="count"
                :per-page="pageSize"
                :prev-text="$t('prev')"
                :next-text="$t('next')"
                pills
                @change="handlePageChange"
              ></b-pagination>
            </div>
        </div>
        </section>
        </div>
       
    </template>

    
<script>
import product from "../components/favourite_product.vue";
import profile_header from "../components/profile_header.vue";

import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

export default {
  components: { product, HollowDotsSpinner, HalfCircleSpinner,profile_header },
  data() {
    return {
        DBitems: null,
      loading: false,
      loading_product: false,
      products:[],
      page: 1,
      count: 0,
      pageSize: 12,
      langChange:"",
      sort:'id',
      order:'desc',
      paginate:12,
    };
  },
  created() {
    if(this.$route.params.lang&&this.$route.params.lang!=this.$i18n.locale){
      this.$i18n.locale=this.$route.params.lang;this.$store.state.UnitPrice=this.$store.state.unitAR
      if(this.$i18n.locale=='en')
        {document.documentElement.setAttribute("dir", "ltr");
        let h = document.querySelector('body');
      }
    }
    this.$store.state.topbar='topbar_light';
    this.$store.state.navbar='navbar_dark';
    this.loading = true;
    this.loading_product = true;
    this.axios
      .post(
        this.$store.state.base_api_url + this.$store.storeName + "/theme8/favourite_product",
        
            {
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        this.products = response.data.data;
        this.count = response.data.meta.total;
        this.pageto = response.data.meta.to;
        this.pagefrom = response.data.meta.from;
        this.loading_product = false
      })
      .then(() => (this.loading = false ));
      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  watch: {
    langChange() {
      this.loading = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/theme8/favourite_product?page=" +
            this.page,
            {
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate
        },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            }
          }
        )
        .then((response) => {
          this.products = response.data.data;
          //this.count = response.data.meta.total;
          this.count = response.data.meta.total;
        this.pageto = response.data.meta.to;
        this.pagefrom = response.data.meta.from;
        }).then(() => {
        this.loading = false;
      });
    },
  },
  updated() {
    this.langChange=this.$i18n.locale
  },
  methods: {
    changeFav(id) {
    console.log('ffffff')
this.axios
.get(
this.$store.state.base_api_url +
 this.$store.storeName +
 "/favourite_product_change/" +
 this.productid,
{
 headers: {
   "Accept-Language": this.$i18n.locale,
   Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
 },
}
)
.then((response) => {
if (response.data.code == "1") {
 this.fav = !this.fav;
 document.getElementById('favourite_'+id).style="display:none";
}
});
},
    retrieveTutorials() {
      const params = this.page;

      this.loading_product = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/theme8/favourite_product?page=" +
            params,
          
            {
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate
        },{
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            }
            }
          
        )
        .then((response) => {
          this.products = response.data.data;
          //this.count = response.data.meta.total;
          his.count = response.data.meta.total;
        this.pageto = response.data.meta.to;
        this.pagefrom = response.data.meta.from;
        })
        .then(() => (this.loading_product = false));
      /*.catch((e) => {
          console.log(params);
          console.log(e);
        });*/
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },

    upadatesort(event)
  {
    this.order = event.target.value.split("/")[0]; //asc
      this.sort = event.target.value.split("/")[1]; //id

      this.filter_product();
  },
  filter_product()
    {
        console.log('brands array  ',this.brands_array);


        this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "theme8/favourite_product",
            {
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate
        },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            }
          }
        )
        .then((response) => {
          this.products = response.data.data;
          //this.count = response.data.meta.total;
          this.count = response.data.meta.total;
        this.pageto = response.data.meta.to;
        this.pagefrom = response.data.meta.from;
        }).then(() => {
        this.loading = false;
      });
    },

  updatepaginate(event)
  {
    this.paginate = event.target.value

      this.filter_product();
  },
  
  },
};
</script>
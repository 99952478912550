var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"min-height":"600px"}},[(this.$store.state.langchange)?_c('div',[_vm._v(_vm._s(_vm.updatedata()))]):_vm._e(),_c('section',{staticClass:"relative table w-full items-center py-36 bg-[url('theme8/images/hero/pages.jpg')] bg-top bg-no-repeat bg-cover",staticStyle:{"background-image":"url('https://marketetrolly.s3.eu-north-1.amazonaws.com/media/227/slider/2024/08/1723628771_1723717856.jpg')"}},[_c('div',{staticClass:"absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"}),_c('div',{staticClass:"container relative"},[_c('div',{staticClass:"grid grid-cols-1 pb-8 text-center mt-10"},[_c('h3',{staticClass:"mb-3 text-4xl leading-normal tracking-wider font-semibold text-white"},[_vm._v(_vm._s(_vm.$t('search')))]),_c('p',{staticClass:"text-slate-400 text-lg max-w-xl mx-auto"},[_vm._v(_vm._s(_vm.$t("Resultsfor"))+": "+_vm._s(this.$route.params.word))])])]),_c('div',{staticClass:"absolute text-center z-10 bottom-5 start-0 end-0 mx-3"},[_c('ul',{staticClass:"tracking-[0.5px] mb-0 inline-block"},[_c('li',{staticClass:"inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"},[_c('router-link',{attrs:{"to":{
                          name: 'home',
                         params: { storeName: this.$store.storeName,lang:_vm.$store.state.routeLang },
                                 }}},[_vm._v(_vm._s(_vm.$t('home'))+" ")])],1),_vm._m(0),_c('li',{staticClass:"inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white",attrs:{"aria-current":"page"}},[_vm._v(_vm._s(_vm.$t('search')))])])])]),_c('div',{staticClass:"container-fluid relative mt-6 lg:mx-6 mx-3"},[_c('div',{staticClass:"grid xl:grid-cols-6 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6"},_vm._l((this.sections),function(item,index){return (this.sections.length!=0)?_c('div',{staticClass:"relative overflow-hidden group rounded-md shadow dark:shadow-slate-800"},[_c('router-link',{staticClass:"text-center",attrs:{"to":{
                   name: 'section',
                  params: { storeName: _vm.$store.storeName,
                   id: item.id  ,
                    name:item.name,lang:_vm.$store.state.routeLang},
                          }}},[_c('img',{staticClass:"group-hover:scale-110 duration-500 section_list_image",attrs:{"src":item.image,"alt":item.name}}),_c('span',{staticClass:"section_list_name bg-white dark:bg-slate-900 group-hover:text-orange-500 py-2 px-6 rounded-full shadow dark:shadow-gray-800 absolute bottom-4 mx-4 text-lg font-medium"},[_vm._v(" "+_vm._s(item.name))])])],1):_vm._e()}),0),(this.sections.length!=0)?_c('div',{staticStyle:{"text-align":"center"}},[_c('button',{staticClass:"loadmorebutton",on:{"click":_vm.LoadMoreSections}},[_vm._v(" "+_vm._s(_vm.$t("LoadMore"))+" "),(_vm.loadMoreIcon)?_c('img',{staticStyle:{"width":"30px"},attrs:{"src":"https://etrolley.net/store/assets/image/icons/loader.gif"}}):_vm._e()])]):_vm._e()]),_c('section',{staticClass:"relative md:py-24 py-16"},[_c('div',{staticClass:"container relative"},[_c('div',{staticClass:"grid items-end md:grid-cols-2 mb-6"},[_c('div',{staticClass:"md:text-start text-center"},[_c('h5',{staticClass:"font-semibold text-3xl leading-normal mb-4"},[_vm._v(_vm._s(_vm.$t('products')))]),_c('p',{staticClass:"text-slate-400 max-w-xl"},[_vm._v(" "+_vm._s(_vm.$t('Shop the latest products from the most popular items')))])]),_c('div',{staticClass:"md:text-end hidden md:block"},[_c('router-link',{staticClass:"text-slate-400 hover:text-orange-500",attrs:{"to":{
                          name: 'products',
                         params: { storeName: this.$store.storeName,lang:_vm.$store.state.routeLang },
                                 }}},[_vm._v(" "+_vm._s(_vm.$t('See More Items'))+" "),_c('i',{staticClass:"mdi mdi-arrow-right"})])],1)]),(this.products.length!=0)?_c('div',{staticClass:"grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 pt-6 gap-6"},_vm._l((this.products),function(item,index){return _c('product',{key:index,attrs:{"name":item.name,"description":item.description,"is_favorit":item.is_favorit,"image":item.image,"old_price":item.old_price,"new_price":item.new_price,"productid":item.id,"rate":item.rate,"count_view":item.count_views,"is_option":item.is_option,"is_offer":item.is_offer,"unit":item.unit,"new":item.is_new,"fetuered":item.is_fetured,"discount":item.discount,"qnt":item.qnt,"min_qnt":item.min_qnt,"max_qnt":item.max_qnt}})}),1):_c('div',[_c('div',{staticClass:"empty text-center center-text"},[_c('img',{attrs:{"src":"/pet_shop/assets/images/empty.png"}}),_c('br'),_vm._v(" "+_vm._s(_vm.$t('empty'))+" ")])])]),(this.products.length>12)?_c('div',{staticStyle:{"text-align":"center"}},[_c('button',{staticClass:"loadmorebutton",on:{"click":_vm.LoadMoreProducts}},[_vm._v(" "+_vm._s(_vm.$t("LoadMore"))+" "),(_vm.loadMoreIcon)?_c('img',{staticStyle:{"width":"30px"},attrs:{"src":"https://etrolley.net/store/assets/image/icons/loader.gif"}}):_vm._e()])]):_vm._e()]),_c('section',{staticClass:"relative md:py-24 py-16"},[_c('div',{staticClass:"container relative"},[_c('div',{staticClass:"grid items-end md:grid-cols-2 mb-6"},[_c('div',{staticClass:"md:text-start text-center"},[_c('h5',{staticClass:"font-semibold text-3xl leading-normal mb-4"},[_vm._v(_vm._s(_vm.$t('offers')))]),_c('p',{staticClass:"text-slate-400 max-w-xl"},[_vm._v(" "+_vm._s(_vm.$t('Shop the latest products from the most popular items')))])]),_c('div',{staticClass:"md:text-end hidden md:block"},[_c('router-link',{staticClass:"text-slate-400 hover:text-orange-500",attrs:{"to":{
                          name: 'offers',
                         params: { storeName: this.$store.storeName,lang:_vm.$store.state.routeLang },
                                 }}},[_vm._v(" "+_vm._s(_vm.$t('See More Items'))+" "),_c('i',{staticClass:"mdi mdi-arrow-right"})])],1)]),(this.offers.length!=0)?_c('div',{staticClass:"grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 pt-6 gap-6"},_vm._l((this.offers),function(item,index){return _c('product',{key:index,attrs:{"name":item.name,"description":item.description,"is_favorit":item.is_favorit,"image":item.image,"old_price":item.old_price,"new_price":item.new_price,"productid":item.id,"rate":item.rate,"count_view":item.count_views,"is_option":item.is_option,"is_offer":item.is_offer,"unit":item.unit,"new":item.is_new,"fetuered":item.is_fetured,"discount":item.discount,"qnt":item.qnt,"min_qnt":item.min_qnt,"max_qnt":item.max_qnt}})}),1):_c('div',[_c('div',{staticClass:"empty text-center center-text"},[_c('img',{attrs:{"src":"/pet_shop/assets/images/empty.png"}}),_c('br'),_vm._v(" "+_vm._s(_vm.$t('empty'))+" ")])])]),(this.offers.length!=0)?_c('div',{staticStyle:{"text-align":"center"}},[_c('button',{staticClass:"loadmorebutton",on:{"click":_vm.LoadMoreOffers}},[_vm._v(" "+_vm._s(_vm.$t("LoadMore"))+" "),(_vm.loadMoreIcon)?_c('img',{staticStyle:{"width":"30px"},attrs:{"src":"https://etrolley.net/store/assets/image/icons/loader.gif"}}):_vm._e()])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"},[_c('i',{staticClass:"mdi mdi-chevron-right"})])
}]

export { render, staticRenderFns }
<template>
  <div style="min-height: 600px">
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <!-- ======= Hero Section ======= -->
    <section id="hero">
      <div class="container">
        <!--style="max-width: 1180px">-->
        <div class="row">
          <div class="col-lg-6 p-0">
            <!--data-aos="zoom-in"-->
            <!-- <h2>ماركات | MARQAT</h2>-->
            <h2>{{ $t("Resultsfor") }}: {{ this.$route.params.word }}</h2>
          </div>
        </div>
      </div>
    </section>
    <!-- End hero -->

    <!-- ======= Second Section ======= -->
    <section id="Second" >
      <div class="container">
        <div class="section-title d-flex flex-row justify-content-between">
          <h2>{{ $t("sections") }}</h2>
        </div>
        <div v-if="!loadingSections">
          <div v-if="!this.sections.length">{{ $t("Noitems") }}</div>
          <div v-else>
            <div class="row">
              <transition-group
                appear
                enter-active-class="animate__animated animated slideInUp"
                leave-active-class="animate__animated animated slideDown"
                mode="out-in"
                tag="div"
                class="row"
              >
                <div
                  class="col-lg-3 col-md-4 col-sm-4 col-6 mb-5"
                  v-for="(item, index) in this.sections"
                  :key="index"
                >
                  <card1
                    :name="item.name"
                    :image="item.icon"
                    :sectionid="item.id"
                  ></card1>
                </div>
              </transition-group>

              <button class="loadmorebutton" @click="LoadMoreSections">
                {{ $t("LoadMore") }}
                <img
                  v-if="loadMoreIcon"
                  src="https://etrolley.net/store/assets/image/icons/loader.gif"
                  style="width: 30px"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Second -->

    <!-- ======= Third Section ======= -->
    <section id="Third" style="background-color: #f7f8fb" class="offers">
      <div class="container">
        <div class="section-title d-flex flex-row justify-content-between">
          <h2>{{ $t("offers") }}</h2>
        </div>
        <div v-if="!loadingOffers">
          <div v-if="!this.offers.length">{{ $t("Noitems") }}</div>
          <div v-else>
            <div class="row">
              <transition-group
                appear
                enter-active-class="animate__animated animated slideInUp"
                leave-active-class="animate__animated animated slideDown"
                mode="out-in"
                tag="div"
                class="row"
              >
              <div
                class="collg5 col-lg-3 col-md-4 col-sm-6 mb-5"
                v-for="(item, index) in this.offers"
                :key="index"
              >
                <card2
                :name="item.name"
              :description="item.description"
              :is_favorit="item.is_favorit"
              :image="item.image"
              :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
              :rate="item.rate"
              :count_view="item.count_views"
              :is_option="item.is_option"
              :is_offer="item.is_offer"
               :unit="item.unit"
               :new="item.is_new"
               :fetuered="item.is_fetured"
               :discount="item.discount"
               :qnt="item.qnt"
               :min_qnt="item.min_qnt"
               :max_qnt="item.max_qnt"
                ></card2>
              </div>
              </transition-group>
              <button class="loadmorebutton" @click="LoadMoreOffers">
                {{ $t("LoadMore") }}
                <img
                  v-if="loadMoreIcon"
                  src="https://etrolley.net/store/assets/image/icons/loader.gif"
                  style="width: 30px"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Third -->

    <!-- ======= Fourth Section ======= -->
    <section id="Fourth">
      <div class="container">
        <div class="section-title d-flex flex-row justify-content-between">
          <h2>{{ $t("products") }}</h2>
        </div>
        <div v-if="!loadingProducts">
          <div v-if="!this.products.length">{{ $t("Noitems") }}</div>
          <div v-else>
            <div class="row">
              <transition-group 
                appear
                enter-active-class="animate__animated animated slideInUp"
                leave-active-class="animate__animated animated slideDown"
                mode="out-in"
                tag="div"
                class="row"
              >
              <div
                class="collg6 col-lg-3 col-6 mb-5"
                v-for="(item, index) in this.products"
                :key="index"
              >
                <card3
                :name="item.name"
              :description="item.description"
              :is_favorit="item.is_favorit"
              :image="item.image"
              :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
              :rate="item.rate"
              :count_view="item.count_views"
              :is_option="item.is_option"
              :is_offer="item.is_offer"
               :unit="item.unit"
               :new="item.is_new"
               :fetuered="item.is_fetured"
               :discount="item.discount"
               :qnt="item.qnt"
               :min_qnt="item.min_qnt"
               :max_qnt="item.max_qnt"
                ></card3>
              </div></transition-group>
              <button class="loadmorebutton" @click="LoadMoreProducts">
                {{ $t("LoadMore") }}
                <img
                  v-if="loadMoreIcon"
                  src="https://etrolley.net/store/assets/image/icons/loader.gif"
                  style="width: 30px"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Fourth -->
  </div>
</template>
<script>
import card1 from "../components/section_list.vue";
import card2 from "../components/product_list.vue";
import card3 from "../components/product_list.vue";

export default {
  components: { card1, card2, card3 },
  data() {
    return {
      loading: false,
      DBitems: null,

      sections: null,
      offers: null,
      products: null,
      loadingSections: false,
      loadingOffers: false,
      loadingProducts: false,

      pageSections: 1,
      allPageSections: 0,
     
      pageOffers: 1,
      allPageOffers: 0,
     

      pageProducts: 1,
      allPageProducts: 0,
    

      loadMoreIcon: false,
    };
  },
  watch: {
    "$route.params": {
      handler(newValue) {
        this.updatedata();
      },
      immediate: true,
    },
  },
  created() {
    //////////////////////sections//////////////////////
    
    if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
    //////////////////////sections//////////////////////
    this.loadingSections = true;
    this.axios
      .post(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/search_section",
        {
          word: this.$route.params.word,
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.sections = response.data.data.sections;
        this.allPageSections = response.data.meta.last_page;
        this.pageSizeSection=response.data.meta.per_page;

      })
      .then(() => (this.loadingSections = false));

    //////////////////////offers//////////////////////
    this.loadingOffers = true;
    this.axios
      .post(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/theme5/search_offer",
        {
          word: this.$route.params.word,
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.offers = response.data.data.offers;
        this.allPageOffers = response.data.meta.last_page;
        this.pageSizeOffers=response.data.meta.per_page;

        //this.countOffers = response.data.meta.total;
      })
      .then(() => (this.loadingOffers = false));

    //////////////////////products//////////////////////
    this.loadingProducts = true;
    this.axios
      .post(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/theme4/search_product",
        {
          word: this.$route.params.word,
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.products = response.data.data;
        this.allPageProducts = response.data.meta.last_page;
        //this.countProducts = response.data.meta.total;
        this.pageSizeProducts=response.data.meta.per_page;

      })
      .then(() => (this.loadingProducts = false));
  },
  methods: {
    updatedata() {
      //console.log("hooooooooooooooo");
      //////////////////////sections//////////////////////
      this.loadingSections = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/search_section",
          {
            word: this.$route.params.word,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.sections = response.data.data.sections;
        })
        .then(() => (this.loadingSections = false));

      //////////////////////offers//////////////////////
      this.loadingOffers = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/search_offer",
          {
            word: this.$route.params.word,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.offers = response.data.data;
        })
        .then(() => (this.loadingOffers = false));

      //////////////////////products//////////////////////
      this.loadingProducts = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/theme4/search_product",
          {
            word: this.$route.params.word,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.products = response.data.data;
        })
        .then(() => (this.loadingProducts = false));

      this.$store.state.langchange = false;
    },
    LoadMoreSections() {
      if (this.pageSections < this.allPageSections) {
        //this.loadingSections = true;
        this.loadMoreIcon = true;
        this.pageSections++;
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/search_section?page=" +
              this.pageSections,
            {
              word: this.$route.params.word,
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            }
          )
          .then((response) => {
            for (let i = 0; i < response.data.data.sections.length; i++) {
              this.sections.push(response.data.data.sections[i]);
            }
            this.loadMoreIcon = false;
          });
        //.then(() => (this.loadingSections = false));
      }
    },
    LoadMoreOffers() {
      if (this.pageOffers < this.allPageOffers) {
        this.loadMoreIcon = true;
        this.pageOffers++;
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/search_offer?page=" +
              this.pageOffers,
            {
              word: this.$route.params.word,
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            }
          )
          .then((response) => {
            for (let i = 0; i < response.data.data.offers.length; i++) {
              this.offers.push(response.data.data.offers[i]);
            }
            this.loadMoreIcon = false;
          });
      }
    },
    LoadMoreProducts() {
      if (this.pageProducts < this.allPageProducts) {
        this.loadMoreIcon = true;
        this.pageProducts++;
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/search_product?page=" +
              this.pageProducts,
            {
              word: this.$route.params.word,
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            }
          )
          .then((response) => {
            for (let i = 0; i < response.data.data.products.length; i++) {
              this.products.push(response.data.data.products[i]);
            }
            this.loadMoreIcon = false;
          });
      }
    },
  },
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

#hero {
  margin-top: 20px;
  padding-bottom: 0;
}

#hero h2 {
  font-family: "Araboto-Black";
  font-size: 22px;
  color: var(--primary-color);
  text-decoration: underline;
  text-underline-offset: 10px;
}
.loadmorebutton {
  color: var(--primary-color);
  width: 20%;
  margin-left: 40%;
  margin-right: 40%;
  border-radius: 25px;
  padding: 10px;
  background-color: transparent;
  border: 1px solid lightgray;
}
/*@media (min-width: 2300px) {
  .collg5{
    width: 15% !important;
  }
}
@media (min-width: 2000px) {
  .collg6 {
    width: 15%;
  }
}*/

@media (max-width: 1200px) {
  .card {
    width: 100%;
  }
}
@media (max-width: 768px) and (min-width: 490px) {
  .col-sm-6 {
    width: 50%;
  }
}
@media (max-width: 575px) {
   .loadmorebutton {
  width: 50%;
  margin: auto;
  }
  #Third .mask1 {
    left:  20% !important;
    right: 20% !important;
  }
  #hero h2 {
    font-size: 18px;
  }
  .card {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media (max-width: 400px) {
  #hero h2 {
    font-size: 14px;
  }
}
</style>

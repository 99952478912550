<template>
    <section style="margin-top: 13%;">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-8 col-12">
                    <h3 class="color_font">{{ $t('Plant Your Dream') }}</h3>
                    <h4>{{ $t('Continue your Green Journey Now!') }}</h4>
                    <div>
                        <form @submit.prevent="submitInfo" @keydown="form.onKeydown($event)">
                        
                                      <span class="flag flag_profile">  
                                <h2>{{ $store.state.country_code }}</h2>
                              <img :src="this.$store.state.country_flag" />
                              
                             
                              </span>
                            <input class="input_all" type="text" :placeholder="$t('inputThePhone')" name="phone"
                                v-model="form.basic_phone" placeholder="xxxx xxxx" required
                                :minlength=$store.state.country_length :maxlength=$store.state.country_length
                                style="text-align:center">
                            <br>
                            <input class="input_all" type="password" id="password"
                                    name="password"
                                   v-model="form.password"
                                  :placeholder="$t('EnterPass')"
                                  required
                                  minlength="8">
                            <br>
                            <br>

                            <a  class="color_font" style="font-size: 18px;"
                @click="(forgetVodal = true), (phone = null), (partphone = null)">{{ $t('forgetpass') }}
              </a>
                            <a style="text-decoration: none;">
              <button type="submit" class="butt_green login_but center_align login_but" style="margin-top: 5%;">
                {{ $t('logIn') }}
              </button>
            </a>
            <div v-if="errorMessage != ''" style="text-align: center; color: red; padding: 5px">
              <p style="margin: 0">{{ errorMessage }}</p>
            </div>
                                <p style="font-size: 20px ; text-align: center; margin-top: 4%;">
              {{ $t('dontHaveAccount') }}
              <router-link :to="{
                name: 'register',
                params: { storeName: this.$store.storeName, lang:$store.state.routeLang },
              }" class="color_font  " style="font-size: 20px; ;   ">
                {{ $t('signup') }}
              </router-link>
            </p>

                        </form>
                    </div>

                </div>
                <div class="col-lg-4 col-md-4 col-12">
                    <img src="/theme10/images/login.png" class="img_signup" style="margin-top: -30%;">
                </div>
            </div>
        </div>

        <vodal
          :show="forgetVodal"
          animation="flip"
          @hide="forgetVodal = false"
         
          style="margin-top: 5px"
        >
          <div class="container" dir="ltr">
            
            <h5 style="color: var(--primary-color);text-align:center">{{ $t("Enterthephone") }} </h5>
            <span  >
            {{ $store.state.country_code }}
            </span>
            <input
              class="input-phone"
                type="phone"
                name="phone"
                v-model="passphone_basic"
                placeholder="xxxx xxxx"
                required
                :minlength=$store.state.country_length
                :maxlength=$store.state.country_length
              />
             
             

            
       

            <button class="button text-center mt-5 butt_green login_but center_align" @click="resetpass()">{{ $t("continue") }}</button>
            <p style="text-align: center">{{ errorMessageVodal }}</p>
            <button @click="forgetEmail()" class="forgetpass w-100 text-center butt_green login_but center_align">
              {{ $t("forgetphone") }}
            </button>
          </div>
        </vodal>
        <vodal
          :show="forgetEmailVodal"
          animation="slideLeft"
          @hide="forgetEmailVodal = false"
         
        >
          <div class="container" >
            <h5 style="color: var(--primary-color)">{{ $t("contactUs") }} :</h5>
            <div dir="ltr" class="text-center" style="margin-top:10px">
              <a
                target="_blank"
                :href="'https://api.whatsapp.com/send?phone='+this.$store.state.phone+'&text=Hello!'"
              >
                <h5>
                  <i class="bi bi-telephone-fill"></i> {{ this.$store.state.phone }}
                </h5>
                <!--edittttttttttttt tow phone-->
              </a>
              <a target="_blank" :href="'mailto:'+  this.$store.state.email">
                <!--edittttttttttttt-->
                <h5 style="margin-top:20px">
                  <i class="bi bi-envelope-fill"></i> {{ this.$store.state.email }}
                </h5>
              </a>
            </div>
           
            <p style="text-align: center">{{ errorMessageVodal }}</p>
          </div>
        </vodal>
        <vodal
          :show="codeVodal"
          animation="slideLeft"
          @hide="codeVodal = false"
         
        >
          <div class="container">
            <h5
              style="
              color: var(--primary-color);
              text-align:center
            "
            >{{ $t("EntertheCode") }} :</h5>
            <div dir="ltr" class="mt-5">
              <CodeInput
                :loading="false"
                required
                class="input"
                @change="onChange"
                @complete="onComplete"
              />
              <p
                style="margin-top: 10px; margin-bottom: 0; text-align: center;"
                v-if="$i18n.locale == 'en'"
              >{{ countDown }} second</p>
              <p
                style="margin-top: 10px; margin-bottom: 0; text-align: center;"
                v-else
              >{{ countDown }} ثانية</p>
            </div>
            <button
              v-if="countDown == 0"
              class="ResendCode butt_green login_but center_align"
              @click="ResendCode()"
            >{{ $t("ResendCode") }}</button>
            <button
              class="button text-center mt-5 butt_green login_but center_align"
              @click="checkCode()"
              :disabled="countDown == 0"
            >{{ $t("continue") }}</button>
            <p style="text-align: center; margin-top: 10px">{{ errorMessageVodal }}</p>
          </div>
        </vodal>
        <vodal
          :show="newPassVodal"
          animation="slideLeft"
          @hide="newPassVodal = false"
         
        >
          <div class="container">
            <h5
              style="
              color: var(--primary-color);
             text-align:center
            "
            >{{ $t("EnterNewPass") }} :</h5>
            <div dir="ltr" class="mt-5">
              <input
                id="newpassword"
                class="input-field"
                type="password"
                name="name"
                v-model="Newpassword"
                :placeholder="$t('EnterPass')"
                required
                minlength="8"
              />
            </div>
            <button class="button text-center mt-5 butt_green login_but center_align" @click="SaveChanges()">{{ $t("SaveChanges") }}</button>
            <p style="text-align: center; margin-top: 10px">{{ errorMessageVodal }}</p>
          </div>
        </vodal>


    </section>
</template>
<script>
import Form from "vform";
import { AlertErrors, AlertSuccess } from "vform/src/components/bootstrap5";
import CodeInput from "vue-verification-code-input";

import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";
import "vodal/slide-left.css";

import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
    components: {
        AlertErrors,
        AlertSuccess,
        Vodal,
        CodeInput,
        VuePhoneNumberInput
    },
    data: function () {
        return {
            forgetVodal: false,
            forgetEmailVodal: false,
            codeVodal: false,
            newPassVodal: false,
            DBitems: null,
            errorMessageVodal: "",
            errorMessage: "",
            passemail: "",
            passphone_basic: null,
            Newpassword: null,
            checkcomplete: false,
            validateMessage: "",
            finalcode: null,
            form: new Form({
                email: null,
                password: null,
                fsm_token: this.$store.state.fsm_token,
                phone: null,
                basic_phone: null,
                country_code: this.$store.state.country_code,
                loading: false,
            }),

            isValid: false,
            partphone: null,
            phone: null,
            emailTrue: false,

            countDown: 90
        };
    },
    created() {
        this.loading = true;
        if (this.$localStorage.get(this.$store.storeName) != null) {
            this.$router.push({
                name: "home1",
                params: { storeName: this.$store.storeName,lang: this.$i18n.locale }
            });
        }
        this.loading = false;
        if (this.$i18n.locale == 'ar') {
            document.title = this.$store.state.title_ar;
            document.getElementById("description_meta").content = this.$store.state.description_ar;
            document.getElementById("keywords_meta").content = this.$store.state.keyword_ar;
        }
        else {
            document.title = this.$store.state.title_en;
            document.getElementById("description_meta").content = this.$store.state.description_en;
            document.getElementById("keywords_meta").content = this.$store.state.keyword_en;
        }
    },
    mounted() {
        try {
            if (this.$i18n.locale == "ar") {
                document
                    .getElementsByClassName("input-tel__input")[0]
                    .setAttribute("placeholder", "رقم الهاتف");
                document.getElementsByClassName(
                    "country-selector__label"
                )[0].innerHTML = "رمز الدولة";
                document.getElementsByClassName("input-tel__label")[0].innerHTML =
                    "* رقم الهاتف";
                /*document.getElementsByClassName('select-country-container')[0].setAttribute('dir', 'ltr');
          document.getElementsByClassName('select-country-container')[0].setAttribute('dir', 'ltr');*/
            }
        } catch (e) { }
    },
    methods: {
        async submitInfo() {
            this.form.phone = this.form.country_code + this.form.basic_phone

            const response = await this.form
                .post(
                    this.$store.state.base_api_url + this.$store.storeName + "/login",
                    {

                        phone: this.form.phone,
                        password: this.form.password,
                        fsm_token: this.form.fsm_token,
                        headers: {
                            "Accept-Language": this.$i18n.locale
                        }
                    }
                )
                .then(response => {
                    if (response.data.code == "1") {
                        ////////////// , initializes a session with a 'session-id'.///////////////////
                        //this.$localStorage.start();
                        this.$localStorage.set(
                            this.$store.storeName,
                            response.data.data.token
                        );
                        //this.$localStorage.set("userToken", response.data.data.token);
                        console.log(
                            this.$localStorage.get(this.$store.storeName) +
                            " " +
                            this.$store.storeName
                        );

                        //console.log("login user token : " + this.$session.get("userToken"));
                        /*this.$router.push({
                          name: "home1",
                          params: { storeName: this.$store.storeName },
                        });
                        window.location.reload();*/
                        window.location.href =
                            "/";
                        //window.location.href= "https://192.168.1.16:8000/"+this.$store.storeName +"/profile"
                    } else if (response.data.code == "-1") {
                        this.form.basic_phone = null;
                        this.errorMessage = response.data.message;
                        setTimeout(() => {
                            this.errorMessage = "";
                        }, 3000);
                    }
                    if (response.data.error) {
                        this.errorMessage = "response.data.error";
                        setTimeout(() => {
                            this.errorMessage = "";
                        }, 3000);
                    }
                });
        },
        forgetEmail() {
            this.forgetVodal = false;
            this.forgetEmailVodal = true;
            this.passemail = null;
        },
        sendPhone1(event) {
            this.isValid = event.isValid;
            if (this.isValid == true) {
                this.phone = event.e164; //+ (' +' +event.countryCallingCode));
                //console.log(this.form.phone)
            }
            //console.log(event.isValid);
            //console.log(event);
        },
        resetpass() {
            this.passphone = this.$store.state.country_code + this.passphone_basic;
            this.axios
                .post(
                    this.$store.state.base_api_url +
                    this.$store.storeName +
                    "/forget_password",
                    {
                        ///update_password
                        phone: this.passphone
                    },
                    {
                        headers: {
                            "Accept-Language": this.$i18n.locale
                        }
                    }
                )
                .then(response => {
                    if (response.data.code == "1") {
                        this.forgetVodal = false;
                        this.forgetEmailVodal = false;
                        this.codeVodal = true;
                        this.emailTrue = true;
                        this.countDown = 90;
                        this.countDownTimer();
                        // console.log(response.data.message);
                    } else if (response.data.code == "-1") {
                        this.errorMessageVodal = response.data.message;
                        setTimeout(() => {
                            this.errorMessageVodal = "";
                        }, 3000);
                    }
                    if (response.data.error) {
                        this.errorMessageVodal = response.data.message;
                        setTimeout(() => {
                            this.errorMessageVodal = "";
                        }, 3000);
                    }
                });
        },
        resetpass1() {
            this.passphone = this.$store.state.country_code + this.passphone_basic;

            if (this.isValid == true) {
                this.axios
                    .post(
                        this.$store.state.base_api_url +
                        this.$store.storeName +
                        "/forget_password",
                        {
                            ///update_password
                            phone: this.passphone
                        },
                        {
                            headers: {
                                "Accept-Language": this.$i18n.locale
                            }
                        }
                    )
                    .then(response => {
                        if (response.data.code == "1") {
                            this.forgetVodal = false;
                            this.forgetEmailVodal = false;
                            this.codeVodal = true;
                            this.emailTrue = false;
                            this.countDown = 90;
                            this.countDownTimer();
                            // console.log(response.data.message);
                        } else if (response.data.code == "-1") {
                            this.errorMessageVodal = response.data.message;
                            setTimeout(() => {
                                this.errorMessageVodal = "";
                            }, 3000);
                        }
                        if (response.data.error) {
                            this.errorMessageVodal = response.data.message;
                            setTimeout(() => {
                                this.errorMessageVodal = "";
                            }, 3000);
                        }
                    });
            } else {
                if (this.$i18n.locale == "en") {
                    this.errorMessageVodal = "your number isn't valid";
                    setTimeout(() => {
                        this.errorMessageVodal = "";
                    }, 3000);
                } else {
                    this.errorMessageVodal = "هذا الرقم غير صالح";
                    setTimeout(() => {
                        this.errorMessageVodal = "";
                    }, 3000);
                }
            }
        },
        checkCode() {
            if (this.checkcomplete == true) {
                this.axios
                    .post(
                        this.$store.state.base_api_url +
                        this.$store.storeName +
                        "/verivey_reset_password",
                        {
                            ///update_password
                            code: this.finalcode,
                            phone: this.passphone
                        },
                        {
                            headers: {
                                "Accept-Language": this.$i18n.locale
                            }
                        }
                    )
                    .then(response => {
                        if (response.data.code == "1") {
                            this.forgetVodal = false;
                            this.forgetEmailVodal = false;
                            this.codeVodal = false;
                            this.newPassVodal = true;
                            // console.log(response.data.message);
                        } else if (response.data.code == "-1") {
                            this.errorMessageVodal = response.data.message;
                            setTimeout(() => {
                                this.errorMessageVodal = "";
                            }, 3000);
                        }
                        if (response.data.error) {
                            this.errorMessageVodal = response.data.message;
                            setTimeout(() => {
                                this.errorMessageVodal = "";
                            }, 3000);
                        }
                    });
            } else {
                if (this.$i18n.locale == "en") {
                    this.errorMessageVodal = "your code isn't complete";
                    setTimeout(() => {
                        this.errorMessageVodal = "";
                    }, 3000);
                } else {
                    this.errorMessageVodal = "الرمز المدخل غير مكتمل";
                    setTimeout(() => {
                        this.errorMessageVodal = "";
                    }, 3000);
                }
            }
        },
        SaveChanges() {
            if (this.Newpassword != null) {
                this.passphone = this.$store.state.country_code + this.passphone_basic;

                this.axios
                    .post(
                        this.$store.state.base_api_url +
                        this.$store.storeName +
                        "/update_password",
                        {
                            ///update_password
                            password: this.Newpassword,
                            email: this.passemail,
                            phone: this.passphone,
                            fsm_token: this.form.fsm_token
                        },
                        {
                            headers: {
                                "Accept-Language": this.$i18n.locale
                            }
                        }
                    )
                    .then(response => {
                        if (response.data.code == "1") {
                            this.forgetVodal = false;
                            this.forgetEmailVodal = false;
                            this.codeVodal = false;
                            this.newPassVodal = false;
                            // console.log(response.data.message);

                            ////////////// , initializes a session with a 'session-id'.///////////////////
                            //this.$localStorage.start();
                            this.$localStorage.set(
                                this.$store.storeName,
                                response.data.data.token
                            );
                            //this.$localStorage.set("userToken", response.data.data.token);
                            console.log(
                                this.$localStorage.get(this.$store.storeName) +
                                "  " +
                                this.$store.storeName
                            );


                            window.location.href =

                                "/profile";
                        } else if (response.data.code == "-1") {
                            this.errorMessageVodal = response.data.message;
                            setTimeout(() => {
                                this.errorMessageVodal = "";
                            }, 3000);
                        }
                        if (response.data.error) {
                            this.errorMessageVodal = response.data.message;
                            setTimeout(() => {
                                this.errorMessageVodal = "";
                            }, 3000);
                        }
                    });
            } else {
                this.errorMessageVodal = "error";
                setTimeout(() => {
                    this.errorMessageVodal = "";
                }, 3000);
            }
        },
        ResendCode() {
            let val = {};
            if (this.emailTrue == true) {
                val = { email: this.passemail };
            } else {
                val = { phone: this.passphone };
            }
            console.log("val" + val);
            this.axios
                .post(
                    this.$store.state.base_api_url +
                    this.$store.storeName +
                    "/forget_password",
                    val,
                    {
                        headers: {
                            "Accept-Language": this.$i18n.locale
                        }
                    }
                )
                .then(response => {
                    if (response.data.code == "-1") {
                        this.errorMessageVodal = response.data.message;
                        setTimeout(() => {
                            this.errorMessageVodal = "";
                        }, 3000);
                    } else if (response.data.code == "1") {
                        this.countDown = 90;
                        this.countDownTimer();
                        this.errorMessageVodal = response.data.message;
                        setTimeout(() => {
                            this.errorMessageVodal = "";
                        }, 3000);
                    }
                });
        },
        onChange(v) {
            //console.log("onChange ", this.phone);
            this.checkcomplete = false;
        },
        onComplete(v) {
            //console.log("onComplete ", v);
            this.finalcode = v;
            this.checkcomplete = true;
        },
        countDownTimer() {
            if (this.countDown > 0) {
                setTimeout(() => {
                    if (this.countDown > 0) {
                        this.countDown -= 1;
                        this.countDownTimer();
                    }
                }, 1000);
            }
        }
    }
};
</script>

<template>
  <router-link 
  :to="{
                                       name: 'product',
                                      params: { storeName: $store.storeName, 
                                        id: this.item.product_id ,
                                         name:this.DBitems.product.name,lang: $i18n.locale },
                                             }"

  class="flex items-center justify-between py-1.5 px-4">


                                        <span class="flex items-center">
                                            <img 
                                            :src="DBitems.product.image" 
                                           :alt="DBitems.product.name"
                                            class="rounded shadow dark:shadow-gray-800 w-9 item_shoping_header_img"
                                             >
                                            <span class="ms-3">
                                                <span class="block font-semibold">{{DBitems.product.name}}</span>
                                                <span class="block text-sm text-slate-400"
                                                v-if="item.offer_price != 0"
                                    >
                                    {{ (item.offer_price/item.quantity).toFixed(2)}}
                                              X{{ item.quantity}}</span>

                                              <span class="block text-sm text-slate-400"
                                                v-else
                                    >
                                    {{ (item.total_price.toFixed(2)/item.quantity).toFixed(2)}}
                                              X{{ item.quantity}}</span>
                                            </span>


                                            </span>

                                        <span   v-if="item.offer_price != 0"
                                         class="font-semibold">{{ item.offer_price.toFixed(2) }}</span>
                                        <span v-else class="font-semibold">{{item.total_price.toFixed(2)}}</span>
  </router-link>
</template>

<script>
export default {
  props: {
    itemId: String
  },
  data: function() {
    return {
      loading: false,
      DBitems: null,
      item: null,
      show: false,
      langChange: '',
      unit: this.$store.state.unit,
        };
  },
  created() {
    if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    else
    {
        this.unit=this.$store.state.unit;
    }
    console.log('new_gg')
    this.loading = true;
    this.item = this.$cookies.get(this.itemId);
    if (this.item != null) {
      console.log('itemsss',this.$i18n.locale)
      //console.log(this.item)
      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/cart_item",
          {
            product_id: this.item.product_id,
            final_option: this.item.final_option,
            option_check: this.item.option_check
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
       
        })
        .then(() => (this.loading = false));
    }
  },
  methods: {
    updatedata() {
      if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    else
    {
        this.unit=this.$store.state.unit;
    }
    this.$store.state.langchange=false;
    this.loading = true;
    this.item = this.$cookies.get(this.itemId);
    if (this.item != null) {
      //console.log(this.item)
      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/cart_item",
          {
            product_id: this.item.product_id,
            final_option: this.item.final_option,
            option_check: this.item.option_check
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
       
        })
        .then(() => (this.loading = false));
    }
    }
  },
  watch:{
    langChange(){
      this.loading = true;
    this.item = this.$cookies.get(this.itemId);
    if (this.item != null) {
      //console.log(this.item)
      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/cart_item",
          {
            product_id: this.item.product_id,
            final_option: this.item.final_option,
            option_check: this.item.option_check
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
       
        })
        .then(() => (this.loading = false));
    }
    },
    update_cart_list(){
      this.loading = true;
    this.item = this.$cookies.get(this.itemId);
    if (this.item != null) {
      //console.log(this.item)
      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/cart_item",
          {
            product_id: this.item.product_id,
            final_option: this.item.final_option,
            option_check: this.item.option_check
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
       
        })
        .then(() => (this.loading = false));
    }
    }
  },
  updated() {
    this.langChange=this.$i18n.locale
    this.update_cart_list=this.item.id
  },
};
</script>
<template>
  <section id="Second">
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <div class="container">
      <div
        class="d-flex flex-row justify-content-between align-items-center mb-5"
      >
        <div class="section-title" style="padding-bottom: 0">
          <h2 style="display: inline">
            <span>
              <i class="bi bi-cart" style="font-weight: 900"></i>
            </span>
            {{ $t("shoppingCart") }}
          </h2>
          <div
            v-if="
              this.$localStorage.get(this.$store.storeName) == null &&
              this.items.length != 0
            "
            style="display: inline; color: #9c0008"
          >
            ( {{ $t("loginMessage1") }}
            <router-link
              class=""
              style="
                color: #9c0008;
                text-decoration: 2px underline !important;
                text-underline-offset: 5px;
                font-weight: bold;
              "
              :to="{
                name: 'login',
                params: { storeName: this.$store.storeName,lang: $i18n.locale },
              }"
              >{{ $t("loginMessage2") }}</router-link
            >
            {{ $t("loginMessage3") }} )
          </div>
        </div>
        <button
          v-if="this.items.length != 0"
          class="delete"
          @click="show = true"
        >
          <h4>
            <span>
              <i class="bi bi-eraser-fill"></i>
            </span>
            {{ $t("Emptycart") }}
          </h4>
        </button>
        <div v-if="this.items.length == 0">
          <h4
            style="
              text-align: center;
              text-decoration: 2px underline;
              text-underline-offset: 3px;
            "
          >
            {{ $t("cartEmpty") }} !!
          </h4>
        </div>
      </div>
      <img
        v-if="this.items.length == 0"
        style="
          width: 50%;
          margin-left: 25%;
          margin-right: 25%;
          filter: grayscale(0.85);
        "
        src="../../../public/assets/image/emptyCard.gif"
      />
      <div class="row" v-if="!loading">
        <div
          class="collg4 col-lg-4 col-sm-6 mb-4"
          v-for="item in this.items"
          :key="item"
        >
          <card4 :item-id="item"></card4>
        </div>
      </div>
      <div
        v-if="this.items.length == 0"
        class="d-flex flex-row justify-content-center"
      >
        <button
          class="Continueshopping"
          @click="
            $router.push({
              name: 'home1',
              params: { storeName: $store.storeName,lang: $i18n.locale },
            })
          "
        >
          {{ $t("Continueshopping") }}
        </button>
      </div>
      <div
        v-if="this.items.length != 0"
        class="d-flex flex-row justify-content-between mt-5"
      >
      <div>
        <button
          class="Continueshopping"
          @click="
            $router.push({
              name: 'home1',
              params: { storeName: $store.storeName,lang: $i18n.locale },
            })
          "
        >
          {{ $t("Continueshopping") }}
        </button>
        </div>
        <div>
          <h5 class="subTotal"  v-if=" $i18n.locale == 'ar'" style="margin-bottom: 20px;">{{ $t("subTotal") }} : {{ (price*$store.state.rate).toFixed(2) }} {{$store.state.UnitPrice}} </h5>
          <h5 class="subTotal" v-else style="margin-bottom: 20px;">{{ $t("subTotal") }} : {{ (price*$store.state.rate).toFixed(2) }} {{$store.state.UnitPrice}} </h5>

          <div v-if="this.$localStorage.get(this.$store.storeName) != null">
            <button class="button" @click="confirmOrder()">
              {{ $t("confirmOrder") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{ $t("confirmdeleteAll") }}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button
                  class="modal-default-button"
                  @click="deleteAllCookies()"
                >
                  {{ $t("confirm") }}
                </button>
                <button
                  class="modal-default-button"
                  @click="(show = false), $emit('close')"
                >
                  {{ $t("cancel") }}
                </button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </section>
</template>

<script>
import card4 from "../components/CardStyle4.vue";
export default {
  components: { card4 },
  data: function () {
    return {
      items: [],
      products: [],
      loading: false,
      show: false,
      price: 0,
    };
  },
  created() {
    let AllCookies = this.$cookies.keys();
    this.products = [];

    for (let i = 0; i < AllCookies.length; i++) {
      if (AllCookies[i].split("/")[0] == this.$store.storeName) {
        this.items.push(AllCookies[i]);
        this.products.push(this.$cookies.get(AllCookies[i]));

        if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.price += this.$cookies.get(AllCookies[i]).offer_price;
        } else {
          this.price += this.$cookies.get(AllCookies[i]).total_price;
        }
        //console.log(this.items);
      }
    }
    console.log(this.items);

    //console.log(this.items[0])
    /* this.$cookies.isKey(
          this.$store.storeName +
            product.product_id +
            product.final_option +
            product.option_check
        )*/

        if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  methods: {
    updateProducts() {
      // console.log("hiiiiiiiiiiiiiii");
      this.items = [];
      this.products = [];
      this.price = 0;
      let AllCookies = this.$cookies.keys();
      for (let i = 0; i < AllCookies.length; i++) {
        if (AllCookies[i].split("/")[0] == this.$store.storeName) {
          this.items.push(AllCookies[i]);
          this.products.push(this.$cookies.get(AllCookies[i]));

          if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
            this.price += this.$cookies.get(AllCookies[i]).offer_price;
          } else {
            this.price += this.$cookies.get(AllCookies[i]).total_price;
          }
          //console.log(this.items);
        }
      }
    },
    updatedata() {
      this.loading = true;
      this.items = [];
      this.products = [];
      this.price = 0;
      let AllCookies = this.$cookies.keys();
      for (let i = 0; i < AllCookies.length; i++) {
        if (AllCookies[i].split("/")[0] == this.$store.storeName) {
          this.items.push(AllCookies[i]);
          this.products.push(this.$cookies.get(AllCookies[i]));

          if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
            this.price += this.$cookies.get(AllCookies[i]).offer_price;
          } else {
            this.price += this.$cookies.get(AllCookies[i]).total_price;
          }
          //console.log(this.items);
        }
      }
      this.$store.state.langchange = false;
      setTimeout(() => {
        // this.updateall();
        this.loading = false;
      }, 0.1);
    },
    deleteAllCookies() {
      this.loading = true;
      for (let i = 0; i < this.items.length; i++) {
        this.$cookies.remove(this.items[i]);
      }
      this.items = [];

      console.log("hiiiiiiii" + this.$cookies.keys());
      this.$store.state.cartCounter = 0;
      this.loading = false;
      this.show = false;
    },
    confirmOrder() {
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/check_all_item_card",
          {
            products: this.products,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          if (response.data.code == "1") {
            this.$router.push({
              name: "confirmOrder",
              params: { storeName: this.$store.storeName,lang: this.$i18n.locale },
            });
          } else {
            alert(response.data.message);
            // this.couponMsg = response.data.message;
          }
          //this.addresses = response.data.data.addresses.addresses;
          // console.log(this.addresses);
        })
        .catch((error) => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href="/";
          }
        });
    },
  },
};
</script>
<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

#Second {
  margin-top: 20px;
  min-height: 600px;
}
.Continueshopping {
  background: transparent;
  border: 0;
  color: #004e1f;
  text-decoration: underline;
  text-underline-offset: 7px;
  font-size: 20px;
  margin-bottom: 50px;
}
.Continueshopping:hover {
  text-decoration: underline;
  text-underline-offset: 10px;
}

h4 {
  font-size: 16px;
  font-family: "Araboto-Bold";
  color: #9c0008;
  margin-bottom: 10%;
}

.button {
  background-color: var(--primary-color);
  border-radius: 10px;
  width: auto;
  padding: 10px 65px 15px 65px;
  border: 2px solid var(--primary-color);
  color: #fff;
  font-size: 16px;
  font-family: "Araboto-Medium";
}

.button:hover {
  background-color: #fff;
  color: #000;
}

.delete {
  background-color: transparent;
  border: 0;
}
.delete:hover {
  transform: scale(1.05);
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header {
  margin-top: 0;
  color: var(--secondary-color) !important;
  font-family: "Araboto-Medium";
  margin-bottom: 20px;
}

.modal-body {
  margin: 20px 0;
  font-family: "Araboto-Medium";
}

.modal-default-button {
  float: right;
  font-family: "Araboto-Medium";
  margin-left: 5px;
  margin-right: 5px;
  font-size: 15px;
  border: 2px solid var(--primary-color);
  color: var(--secondary-color);
  border-radius: 5px;
}
.modal-default-button:hover {
  background-color: white;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/*@media (min-width: 1500px) {
  .collg4 {
    width: 25%;
  }
}
@media (min-width: 2000px) {
  .collg4 {
    width: 20%;
  }
}*/
@media (max-width: 575px) {
  h2 {
    font-size: 20px;
  }

  h4 {
    font-size: 12px;
  }
  .button {
    font-size: 12px;
    padding: 5px;
  }
  button ,.subTotal ,.Continueshopping {
    font-size: 12px;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer_dark"},[(this.$store.state.langchange ||this.$store.state.langchange1)?_c('div',[_vm._v(_vm._s(_vm.updatedata()))]):_vm._e(),_c('div',{staticClass:"footer_top"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('div',{staticClass:"widget"},[_c('div',{staticClass:"footer_logo"},[_c('router-link',{attrs:{"to":{
                               name: 'home',
                              params: { storeName: this.$store.storeName,lang: _vm.$i18n.locale },
                                   }}},[_c('img',{attrs:{"alt":this.$store.state.storeName,"src":_vm.DBitems.market.logo_footer}})])],1),_c('p',[_vm._v(_vm._s(_vm.DBitems.market.name)+" "+_vm._s(_vm.DBitems.market.description)+" ")]),_c('p',{staticStyle:{"color":"var(--primary-color)"}},[_vm._v(_vm._s(_vm.DBitems.market.sub_description))])]),_c('div',{staticClass:"widget"},[_c('ul',{staticClass:"social_icons social_white"},[(_vm.DBitems.social.facebook)?_c('li',[_c('a',{attrs:{"target":"_blank","href":_vm.DBitems.social.facebook}},[_c('i',{staticClass:"ion-social-facebook"})])]):_vm._e(),(_vm.DBitems.social.whatsapp)?_c('li',[_c('a',{attrs:{"target":"_blank","href":'https://api.whatsapp.com/send?phone='+_vm.DBitems.social.whatsapp+'&text=Hello!'}},[_c('i',{staticClass:"ion-social-whatsapp"})])]):_vm._e(),(_vm.DBitems.social.twitter)?_c('li',[_c('a',{attrs:{"target":"_blank","href":_vm.DBitems.social.twitter}},[_c('i',{staticClass:"ion-social-twitter"})])]):_vm._e(),(_vm.DBitems.social.tiktok)?_c('li',{attrs:{"target":"_blank"}},[_c('a',{attrs:{"href":_vm.DBitems.social.tiktok}},[_c('i',{staticClass:"ion-social-tiktok"})])]):_vm._e(),_c('li',[(_vm.DBitems.social.snapchat)?_c('a',{attrs:{"target":"_blank","href":_vm.DBitems.social.snapchat}},[_c('i',{staticClass:"ion-social-snapchat"})]):_vm._e()]),(_vm.DBitems.social.instgram)?_c('li',[_c('a',{attrs:{"target":"_blank","href":_vm.DBitems.social.instgram}},[_c('i',{staticClass:"ion-social-instagram-outline"})])]):_vm._e()])])]),_c('div',{staticClass:"col-lg-2 col-md-3 col-sm-6 footer_column"},[_c('div',{staticClass:"widget"},[_c('h6',{staticClass:"widget_title"},[_vm._v(_vm._s(_vm.$t('Useful Links')))]),_c('ul',{staticClass:"widget_links"},[_c('li',[_c('router-link',{attrs:{"to":{
                                  name: 'page',
                                  params: { storeName: this.$store.storeName ,page: 'PrivacyPolice' 
                                  },
                                  }}},[_vm._v(_vm._s(_vm.$t('privacyPolicy')))])],1),_c('li',[_c('router-link',{attrs:{"to":{
                                      name: 'page',
                               params: { storeName: _vm.$store.storeName,page: 'Aboutus',  },
                                }}},[_vm._v(_vm._s(_vm.$t('about')))])],1),_c('li',[_c('router-link',{attrs:{"to":{
                               name: 'sections',
                              params: { storeName: this.$store.storeName,lang: _vm.$i18n.locale },
                                   }}},[_vm._v(_vm._s(_vm.$t('sections')))])],1),_c('li',[_c('router-link',{attrs:{"to":{
                               name: 'products',
                              params: { storeName: this.$store.storeName,lang: _vm.$i18n.locale },
                                   }}},[_vm._v(_vm._s(_vm.$t('shop')))])],1)])])]),_c('div',{staticClass:"col-lg-2 col-md-3 col-sm-6 footer_column"},[_c('div',{staticClass:"widget"},[_c('h6',{staticClass:"widget_title"},[_vm._v(_vm._s(_vm.$t('sections')))]),_c('ul',{staticClass:"widget_links"},_vm._l((this.DBitems.sections),function(item,index){return _c('li',{key:index},[_c('router-link',{attrs:{"to":{
                                                name: 'section',
                                                params: { storeName: _vm.$store.storeName, id: item.id  , name:item.label,lang: _vm.$i18n.locale},
                                                 }}},[_vm._v(_vm._s(item.label))])],1)}),0)])]),_c('div',{staticClass:"col-lg-2 col-md-6 col-sm-6 footer_column"},[_c('div',{staticClass:"widget"},[_c('h6',{staticClass:"widget_title"},[_vm._v(_vm._s(_vm.$t('My Account')))]),_c('ul',{staticClass:"widget_links"},[_c('li',[(this.$localStorage.get(this.$store.storeName) == null)?_c('router-link',{attrs:{"to":{
                               name: 'login',
                              params: { storeName: this.$store.storeName ,lang: _vm.$i18n.locale},
                                   }}},[_vm._v(_vm._s(_vm.$t('profile')))]):_c('router-link',{attrs:{"to":{
                               name: 'profile',
                              params: { storeName: this.$store.storeName,lang: _vm.$i18n.locale },
                                   }}},[_vm._v(_vm._s(_vm.$t('profile')))])],1),_c('li',[_c('router-link',{attrs:{"to":{
                               name: 'shopping_cart',
                              params: { storeName: this.$store.storeName,lang: _vm.$i18n.locale },
                                   }}},[_vm._v(_vm._s(_vm.$t('shoppingCart')))])],1),_c('li',[(this.$localStorage.get(this.$store.storeName) == null)?_c('router-link',{attrs:{"to":{
                               name: 'login',
                              params: { storeName: this.$store.storeName,lang: _vm.$i18n.locale },
                                   }}},[_vm._v(_vm._s(_vm.$t('myOrders')))]):_c('router-link',{attrs:{"to":{
                               name: 'myOrders',
                              params: { storeName: this.$store.storeName ,lang: _vm.$i18n.locale },
                                   }}},[_vm._v(_vm._s(_vm.$t('myOrders'))+" ")])],1)])])]),_c('div',{staticClass:"col-lg-3 col-md-4 col-sm-6 footer_column"},[_c('div',{staticClass:"widget"},[_c('h6',{staticClass:"widget_title"},[_vm._v(_vm._s(_vm.$t('Contact Info')))]),_c('ul',{staticClass:"contact_info contact_info_light"},[(this.DBitems.market.address)?_c('li',[_c('i',{staticClass:"ti-location-pin"}),_c('p',[_vm._v(_vm._s(this.DBitems.market.address))])]):_vm._e(),(this.DBitems.social.email)?_c('li',[_c('i',{staticClass:"ti-email"}),_c('a',{attrs:{"href":this.DBitems.social.email}},[_vm._v(_vm._s(this.DBitems.social.email))])]):_vm._e(),(this.DBitems.social.phone)?_c('li',[_c('i',{staticClass:"ti-mobile"}),_c('p',{attrs:{"dir":"ltr"}},[_vm._v(_vm._s(this.DBitems.social.phone))])]):_vm._e(),(this.DBitems.social.other_phone)?_c('li',[_c('i',{staticClass:"ti-mobile"}),_c('p',{attrs:{"dir":"ltr"}},[_vm._v(_vm._s(this.DBitems.social.other_phone))])]):_vm._e()])])])])])]),_c('div',{staticClass:"bottom_footer border-top-tran"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[(_vm.DBitems.market.copy_right)?_c('p',{staticClass:"mb-md-0 text-center text-md-left"},[_vm._v(_vm._s(this. DBitems.market.copy_right))]):_c('p',{staticClass:"mb-md-0 text-center text-md-left"},[_vm._v(_vm._s(_vm.$t("all rights are save"))+" "+_vm._s(this.DBitems.market.name))])]),_vm._m(0),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"footer_payment text-center text-lg-right"},[(this.$i18n.locale=='ar')?_c('a',{staticStyle:{"float":"left"},attrs:{"href":"https://etrolley.net"}},[_vm._v(_vm._s(_vm.$t('Powered By Etrolley'))+" ")]):_c('a',{staticStyle:{"float":"right"},attrs:{"href":"https://etrolley.net"}},[_vm._v(_vm._s(_vm.$t('Powered By Etrolley')))])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"footer_payment text-center text-lg-right col-md-4"},[_c('li',[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":"/pet_shop/assets/images/visa.png","alt":"visa"}})])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":"/pet_shop/assets/images/discover.png","alt":"discover"}})])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":"/pet_shop/assets/images/master_card.png","alt":"master_card"}})])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":"/pet_shop/assets/images/paypal.png","alt":"paypal"}})])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":"/pet_shop/assets/images/amarican_express.png","alt":"amarican_express"}})])])])
}]

export { render, staticRenderFns }
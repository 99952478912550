<template>
  <div class="mt-3">
                                    <div class="form-group col-12">
                                        <div class="star_rating">
                                          <span v-for="(n, index) in rate" :key="index">
          <i
            v-if="n == true"
           class="mdi mdi-star"
            style="color:gold !important"
            :id="'star'+index"
            @click="rateChange(index)"
          ></i>


          <i
            v-if="n == false"
           class="mdi mdi-star"
            :id="'star'+index"
            @click="rateChange(index)"
           
          ></i
        ></span>
                                        </div>
                                    </div>
                                    <div class="form-group col-12">
                                        <textarea
                                        rows="4"
        cols="4"
        class="form-control"
        required
        :placeholder="$t('DescribeExperience')"
        v-model="message"
                                           ></textarea>
                                    </div>
                                    
                                   
                                    <div class="form-group col-12">
                                        <button  class="add_review button py-2 px-5 inline-block
                                             tracking-wide align-middle
                                              duration-500 text-base text-center
                                               bg-orange-500 text-white rounded-md" 
                                        style="padding:2px 5px; border:2px solid black ;color:white;"
                                         @click="addEval"  name="submit" >{{ $t('Submit Review') }}</button>
                                    </div>

                                    <div v-if="responseMsg != ''">{{ responseMsg }}</div>
                                    <div v-if="loading" class="empty text-center center-text">
                 
                                  <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                                            <br>
                 
                                        </div>
                                </div>
                                
</template>


<script>
export default {
  data: function () {
    return {
      rate: [false, false, false, false, false],
      message: "",
      responseMsg: "",
      eval: true,
      loading:false
    };
  },

  created() {
    
  },

  methods: {
    addEval() {
      this.loading=true
      let rateN = 0;
      for (let i = 0; i < this.rate.length; i++) {
        if (this.rate[i] == true) {
          rateN++;
        }
      }
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/rate_product/" +
            this.$route.params.id,
          {
            rate: rateN,
            message: this.message,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.loading=false;
          this.responseMsg = response.data.message;
        });
    },
    rateChange(n) {
      document.getElementById('star'+n).style="color:gold";
      this.eval = false;
      console.log("hiiiiiiiiiii " + this.rate);
      for (let i = 0; i < this.rate.length; i++) {
        this.rate[i] = true;
        if (i > n) {
          this.rate[i] = false;
          document.getElementById('star'+n).style="color:grey";
        }
      }
      this.eval = true;
    },
  },
  watch: {},
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

#Second {
  margin-top: 120px;
}
.card {
  background-color: #fff;
  width: 100%;
  border-radius: 15px;
  color: var(--secondary-color);
  font-family: "Araboto-Bold";
  text-align: center;
  font-size: 16px;
  padding: 15px 3px;
  padding-top: 0;
  border: 0;
}

h5 {
  font-size: 1rem;
}

span {
  font-size: 2rem;
  color: #8a8d9f;
}

.card h2 {
  font-size: 20px;
  font-family: "Araboto-Medium";
  display: inline;
  color: var(--primary-color);
  line-height: 50px;
}

button {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  color: var(--secondary-color);
}
hr {
  height: 2px;
  margin: 12px;
  color: #cccccc;
}
[dir="rtl"] button img {
  left: 15px;
  margin-top: 10px;
  position: absolute;
}
[dir="ltr"] button img {
  right: 15px;
  margin-top: 10px;
  position: absolute;
}
a {
  display: block;
  background-color: #f8f8f8;
  padding: 13px 0 17px 0;
  width: 80%;
  border-radius: 15px;
  color: var(--secondary-color);
  font-family: "Araboto-Bold";
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
}

.router-link-exact-active,
.router-link-exact-active:focus {
  background-color: #fff;
  border: 2px solid var(--primary-color);
}
.mapStyle {
  margin-bottom: 10px;
  border-radius: 15px;
  height: 315px;
  width: 80%;
}
.input-icons {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.input-icons input,
textarea.form-control {
  width: 100%;
  border-radius: 9px;
  background-color: #fff;
  margin-bottom: 10px;
  border: 2px solid #cccccc;
  font-size: 13px;
  font-family: "Araboto-Medium";
  color: var(--secondary-color);
  padding: 10px 20px 15px 20px;
}

.button {
  cursor: pointer ;
  background-color: var(--primary-color);
  border-radius: 10px;
  width: 60%;

  margin: 20px 20%;
  padding: 10px 15px;
  border: 2px solid var(--primary-color);
  color: #fff;
  font-size: 15px;
  font-family: "Araboto-Medium";
}

.button:hover {
  background-color: #fff;
  color: #000;
}

.star-container {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 10px;
  font-size: 25px;
}
.star {
  cursor: pointer ;
  color: rgb(201, 201, 201);
  margin-left: 2px;
  margin-right: 2px;
}
.star-fill {
  cursor: pointer ;
  color: #ffcc00;
  margin-left: 2px;
  margin-right: 2px;
}

@media (max-width: 992px) {
  a {
    width: 100%;
  }
}
@media (max-width: 772px) {
  a {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
}
</style>

<template>
  <div class="card">
    <div class="trip-image">
      <img :src="image" style="cursor: pointer" @click="showProduct=true" />
      <div v-if="this.$localStorage.get(this.$store.storeName) != null">
        <img
          class="fav-img"
          :style="fav ? 'display: none' : 'display: block'"
          src="../../../public/assets/image/icons/fav.svg"
          @click="changeFav()"
        />
        <img
          class="fav-full-img"
          :style="fav ? 'display: block' : 'display: none'"
          src="../../../public/assets/image/icons/fav-fill.svg"
          @click="changeFav()"
        />
      </div>
    </div>  
    <div class="container" @click="showProduct=true" style="cursor: pointer">
      <div class="d-flex flex-row justify-content-between align-items-center">
        <div v-if="old_price != 0" class="price d-flex flex-row justify-content-start">
          <div v-if="new_price != 0">
            <p
              v-if="$i18n.locale == 'ar'"
              style="
                text-decoration: line-through;
                font-size: 10px;
                margin: 0 10px;
              "
            >{{ old_price }} {{ this.$store.state.unitAR }}</p>
            <p
              v-else
              style="
                text-decoration: line-through;
                font-size: 10px;
                margin: 0 10px;
              "
            >{{ old_price }} {{ this.$store.state.unit }}</p>
            <p
              v-if="$i18n.locale == 'ar'"
              
            >{{ new_price }} {{ this.$store.state.unitAR }}</p>
            <p
              v-else
              
            >{{ new_price }} {{ this.$store.state.unit }}</p>
          </div>
          <div v-else>
            <p>{{ old_price }}</p>
            <p v-if="$i18n.locale == 'ar'">{{ this.$store.state.unitAR }}</p>
            <p v-else>{{ this.$store.state.unit }}</p>
            <!--unitttttt-->
          </div>
          <!--unitttttt-->
        </div>
        <div v-else>
          <span>{{ $t("PriceOnRequest") }}</span>
        </div>
        <div>
          <!--<img class="add-img1" src="../../public/assets/image/icons/add.svg" />-->
          <svg
            class="add-img1"
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 44 44"
          >
            <g id="Group_36669" data-name="Group 36669" transform="translate(-740 -2262)">
              <rect
                id="Rectangle"
                width="44"
                height="44"
                rx="50"
                transform="translate(740 2262)"
                fill="#3896a0"
              />
              <g id="Icon" transform="translate(752.643 2274.466)">
                <path
                  id="Path"
                  d="M.429,0V18.653"
                  transform="translate(8.898)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="2"
                />
                <path
                  id="Path-2"
                  data-name="Path"
                  d="M0,.429H18.653"
                  transform="translate(0 8.898)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="2"
                />
              </g>
            </g>
          </svg>
        </div>
      </div>
      <h3>{{ name }}</h3>
      <!--<p>{{description}}</p>-->
      <div
        v-html="description"
        class="MoB-description"
        style="
          overflow: hidden;
          height: 3.6em;
          line-height: 1.8em;
          font-size: 15px ;
          font-weight: 300 !important; 
          color: #565656;
        "
      ></div>
     
    </div>
    <div v-if="showProduct">
     <vodal
        :show="showProduct"
        animation="flip"
        @hide="showProduct = false"
        :width="1050"
        :height="600"
      >
        <div style="height: 550px; overflow-y: scroll">
          <product :id="this.productid" :name="this.name " ></product>
        </div>
      </vodal>
      </div>
  </div>
</template>
<script>
import Product from './Product';

import Vodal from "vodal";
import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";

export default {
  components: {
    Vodal,
    Product
  },
  props: {
    name: String,
    description: String,
    is_favorit: Boolean,
    image: String,
    old_price: Number,
    new_price: Number,
    productid: Number
    // name: String,
    // image: String
  },
  data: function() {
    return {
      fav: false,
      showProduct: false
    };
  },
  created() {
    this.fav = this.is_favorit;
  },
  methods: {
    changeFav() {
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/favourite_product_change/" +
            this.productid,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.fav = !this.fav;
          }
        });
    },
    ////{ path: `/user/${id}` }
    productRoute() {
      if (this.$route.path.includes("/" + this.$store.storeName + "/product")) {
        this.$router.push({
          name: "product",
          params: { id: this.productid, name: this.name ,lang: this.$i18n.locale}
        });
      } else {
        this.$router.push({
          name: "product",
          params: {
            storeName: this.$store.storeName,
            id: this.productid,
            name: this.name,
            lang: this.$i18n.locale
          }
        });
      }
    }
  }
};
</script>


<style scoped>
.card {
  width: 200px;
  border: 0;
  border-radius: 5px;
  height: 100%;
  box-shadow: 0px 0px 5px #edebe9;
  color: #000;
  padding-bottom: 10px;
}

.card:hover {
  border: 0;
  border-radius: 5px;
  box-shadow: 0 0px 5px 0 #dddddd;
  color: var(--primary-color);
}
.card:hover > div img {
  transform: scale(1.04);
}

.card .trip-image {
  height: 150px;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.card .trip-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.card h3 {
  text-align: start;
  font-family: "Araboto-Medium";
  font-size: 14px;
  margin-top: 10px;
}
.card p {
  margin-bottom: 0;
  font-family: "Araboto-light";
  color: var(--secondary-color);
  font-size: 16px;
}

.card .price p {
  display: inline;
  color: #000000;
  font-size: 14px;
  font-family: "Araboto-Bold";
}

.card .price h5 {
  display: inline;
  color: #000000;
  font-size: 16px;
  font-family: "Araboto-Bold";
  margin: 0 10px;
}

.card .trip-image .fav-img,
.card .trip-image .fav-full-img {
  width: 48px;
  height: 48px;
  position: absolute;
  left: 15px;
  top: 15px;
}

.card .add-img1 {
  width: 30px;
  margin: 0 10px 0 0px;
}
.card .add-img1:hover {
  transform: scale(1.05);
}

svg g rect {
  fill: var(--primary-color);
}

.vodal, .vodal-mask {
    z-index: 10000;
}
.vodal-dialog{
  z-index: 10001;
}

@media (min-width: 1200px) {
  .card {
    width: 100%;
  }
}
/*@media (min-width: 1400px) and (max-width:2000px ){
  .card {
  width: 290px;
}

.card .trip-image {
  height: 240px;
}

.card h3 {
  font-size: 30px;
  margin-top: 10px;
}
.card p {
  margin-bottom: 0;
  font-size: 25px;
}

.card .price p {
  font-size: 20px;
  font-family: "Araboto-Medium";
}

.card .price h5 {
  font-size: 25px;
  margin: 0 10px;
}

.card .trip-image .fav-img,
.card .trip-image .fav-full-img {
  width: 88px;
  height: 88px;
  position: absolute;
  left: 15px;
  top: 15px;
}

}
@media (max-width: 1230px) {
  .card h3 {
    font-size: 18px;
  }
  .card p {
    font-size: 16px;
  }
  .card .price p {
    font-size: 12px;
  }
  .card .price h5 {
    font-size: 14px;
  }
}*/
@media (max-width: 575px) {
  .card .trip-image {
    height: 120px !important;
  }
  .card h3 {
    font-size: 12px;
  }
  .card p {
    font-size: 12px;
  }
  .card .price p {
    font-size: 10px;
  }

  /* .col2{
    margin-bottom: 20px !important;
  }*/

  .card .add-img1 {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 5px 0;
  }
  .MoB-description {
    height: 3em !important;
    line-height: 1.5em !important;
    font-size: 13px !important;
  }

  .card {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>

<template>
  <!-- ======= Second Section ======= -->
  <div>
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <section id="Section" v-if="!loading" data-aos="fade-up" data-aos-duration="2000">
      <div class="container mt-3">
        <div class="row sectionSlider" style="justify-content:center">
          <div class="col-lg-2 col-md-3 col-sm-4 col-6 mb-2" v-for="(item, index) in DBitems.categories.category"
              :key="index">
              <button
                class="item"
                @click="
                   $router.push({
                    name: 'section',
                     params: {
                       storeName: $store.storeName ,
                       id: s_id ,
                       name: s_name,
                       category_id_selected:item.id,
                       lang: $i18n.locale }
                   });">
                  <img style="width: 150px; height: 150px; object-fit: cover; border-radius: 50%; transition: 500ms;" :src="item.icon" />
                  <h5>{{ item.name }}</h5>
              </button>
          </div>
        </div>
        <!--<div>
          <section-products
            ref="childref"
            :category-id="categoryID"
            :section-id="DBitems.section.id"
          ></section-products>
        </div>-->
      </div>
    </section>
  </div>
  <!-- End Second -->
</template>

<script>
import SectionProducts from "../components/SectionProducts.vue";
import VueHorizontalList from "vue-horizontal-list";
import { KinesisContainer, KinesisElement } from "vue-kinesis";

import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";

import MultiRangeSlider from "multi-range-slider-vue";
import "../../../node_modules/multi-range-slider-vue/MultiRangeSliderBlack.css";
import "../../../node_modules/multi-range-slider-vue/MultiRangeSliderBarOnly.css";

import {
  Hooper,
  Slide,
  Progress as HooperProgress,
  Pagination as HooperPagination,
  Navigation as HooperNavigation
} from "hooper";
import "hooper/dist/hooper.css";

export default {
  components: {
    Vodal,
    SectionProducts,
    VueHorizontalList,
    KinesisContainer,
    KinesisElement,
    MultiRangeSlider,
    Hooper,
    Slide,
    HooperProgress,
    HooperPagination,
    HooperNavigation
  },
  props: {
    s_id: Number,
    s_name: String
  },
  data() {
    return {
      search: false,

      minPrice: 0,
      maxPrice: 10000,
      rangePrice: [-20, 70],

      minQuantity: 0,
      maxQuantity: 10000,
      rangeQuantity: [-20, 70],

      checkBoxOffer: false,

      options: {
        responsive: [
          { end: 576, size: 2 },
          { start: 576, end: 768, size: 4 },
          { start: 768, end: 992, size: 6 },
          { size: 7 }
        ],
        list: {
          // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
          windowed: 1200,

          // Because: #app {padding: 80px 24px;}
          padding: 5
        },
        position: {
          start: 2
        },
        autoplay: { play: false, repeat: true, speed: 2500 }
      },
      /*items: [
        { title: "headphones", icon: "bi bi-headphones" },
        { title: "laptop", icon: "bi bi-laptop" },
        { title: "printer", icon: "bi bi-printer" },
        { title: "headphones", icon: "bi bi-headphones" },
        { title: "laptop", icon: "bi bi-laptop" },
        { title: "printer", icon: "bi bi-printer" },
        { title: "headphones", icon: "bi bi-headphones" },
        { title: "laptop", icon: "bi bi-laptop" },
        { title: "printer", icon: "bi bi-printer" },
      ],*/
      DBitems: null,
      loading: false,
      categoryID: 0,
      //langChange:""

      hooperSettings: {
        itemsToShow: 1,
        initialSlide: 0,
        itemsToSlide: 2,
        trimWhiteSpace: true,
        /*infiniteScroll: true,
        autoPlay: true,
        playSpeed: 3000,*/
        //playSpeed:2000,
        //transition:300,

        breakpoints: {
          375: {
            itemsToShow: 2,
            itemsToSlide: 1
          },
          430: {
            itemsToShow: 2.5,
            itemsToSlide: 1
          },
          768: {
            itemsToShow: 4
          },
          992: {
            itemsToShow: 5
          }
        }
      }
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/section/" +
          this.s_id,
        {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        }
      )
      .then(response => {
        this.DBitems = response.data.data;
      })
      .then(() => (this.loading = false));
  },
  methods: {
    updatedata() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/section/" +
            this.s_id,
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
        })
        .then(() => (this.loading = false));

      //this.$refs.childref.update(this.categoryID)
      this.$store.state.langchange = false;
    },
    update_oBarValues(e) {
      this.minPrice = e.minValue;
      this.maxPrice = e.maxValue;
    },
    update_oBarValues1(e) {
      this.minQuantity = e.minValue;
      this.maxQuantity = e.maxValue;
    }
  },
  watch: {
    s_id: function(newVal, oldVal) {
      this.categoryID = 0;
      this.updatedata();
    }
  }
  /* updated() {
    this.langChange = this.$i18n.locale;
  },*/
  /*watch: {
    langChange() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/section/" +
            this.s_id,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.section;
        })
        .then(() => (this.loading = false));
    },
  },*/
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

#Section {
  margin-top: 0;
  padding: 0;
  /*min-height: 512px;*/
  /* For Firefox */
}

.mask {
  background-image: linear-gradient(
    to left,
    rgb(0, 0, 0, 0.9),
    rgb(255, 255, 255, 0)
  );
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  /*height: 325px;*/
  aspect-ratio: 1200 / 400;
  top: 0;
}
.image1 {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 100px;
  margin-left: 20px;
}
.bi-search:hover {
  transform: scale(1.03);
  color: var(--primary-color);
}

.item {
  font-size: 14px;
  background: #fff;
  border: 0;
  border-radius: 15px;
  width: 100%;
  padding: 7px 0 2px 0;
  /* padding: 0;
  margin: 0;*/
}

.item:hover > h5 {
  color: var(--primary-color);
  /*transform: scale(1.02);*/
}

.item:hover > img {
  border-radius: 15px !important;
}
.cover {
  /*width: 100%;
  height: 325px;*/
  aspect-ratio: 1200 / 400;
  margin-bottom: 0;
}
.cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
h2 {
  font-size: 60px;
  color: #fff;
}
.pagination .page-item a {
  color: rgb(184 189 205);
  background-color: rgba(22, 22, 26, 0);
  border: none;
  text-decoration: underline;
  line-height: 0;
  border-radius: 100%;
  padding: 12px 10px 15px 10px;
}

.pagination .page-item a:hover {
  background-color: var(--primary-color);
  color: #fff;
  text-decoration: none;
}

.filterDropdown ul,
li {
  list-style: none;
  width: 270px;
}

[dir="ltr"] .filterDropdown ul {
  display: block;
  position: absolute;
  right: 5px;
  top: calc(100% + 30px);
  margin: 0 0 0 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 15px;
}
[dir="rtl"] .filterDropdown ul {
  display: block;
  position: absolute;
  left: 5px;
  top: calc(100% + 30px);
  margin: 0 0 0 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 15px;
}

/*.navbar .dropdown ul li,*/

.filterDropdown ul li {
  min-width: 200px;
}

/*.navbar .dropdown ul a,*/

.filterDropdown ul a {
  padding: 10px 20px;
  color: #2a2c39;
  margin: 0 5px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Araboto-Light";
  cursor: pointer;
}

/*.navbar .dropdown ul a i,*/

.filterDropdown ul a i {
  font-size: 12px;
}

/*.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a,*/

.filterDropdown ul a:hover,
.filterDropdown ul .active:hover,
.filterDropdown ul li:hover > a {
  color: var(--primary-color);
}

/*.navbar .dropdown:hover>ul,*/

.filterDropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

/*.navbar .dropdown .dropdown ul,*/

.filterDropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

/*.navbar .dropdown .dropdown:hover>ul,*/

.filterDropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

.vue-horizontal-list {
  background: #f8f8f8;
  border-radius: 10px;
  margin-bottom: 30px;
  height: 50px;
}

.button {
  cursor: pointer;
  background-color: var(--primary-color);
  border-radius: 10px;
  width: 60%;

  margin: 20px 20%;
  padding: 10px 15px;
  border: 2px solid var(--primary-color);
  color: #fff;
  font-size: 15px;
  font-family: "Araboto-Medium";
}

.button:hover {
  background-color: #fff;
  color: #000;
}
@media (min-width: 1400px) {
  [dir="rtl"] .bi-search {
    margin-right: 0 !important;
  }
}
@media (max-width: 1200px) {
  .card {
    width: 100%;
  }
}
@media (max-width: 700px) {
  h2 {
    font-size: 30px;
  }

  .image1 {
    width: 40px;
    height: 40px;
    margin-right: 20px;
    margin-left: 20px;
  }
  /*.cover {
    height: 200px;
  }
  .mask {
    height: 200px;
  }*/
}

@media (max-width: 390px) {
  h2 {
    font-size: 20px;
  }

  .image1 {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    margin-left: 20px;
  }
  /*.cover {
    height: 150px;
  }
  .mask {
    height: 150px;
  }*/

  [dir="ltr"] .filterDropdown ul {
    right: -20px;
  }
  [dir="rtl"] .filterDropdown ul {
    left: -20px;
  }
}
</style>

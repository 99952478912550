<template>
  <section id="Second" v-if="this.$localStorage.get(this.$store.storeName) != null">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-4 mb-4 mobile-inactive ">
          <div class="card">
            <router-link :to="(name = 'profile')">
              <i class="bi bi-person-circle"></i>
              {{$t("profile")}}
            </router-link>
            <router-link :to="(name = 'myOrders')">
              <i class="bi bi-bag"></i>
              {{$t("myOrders")}}
            </router-link>
            <router-link :to="(name ='favorites')">
              <i class="bi bi-heart"></i>
              {{ $t("favorites") }}
            </router-link>
            <router-link :to="(name = 'myAddresses')">
              <i class="bi bi-geo-alt"></i>
              {{ $t("myAddresses") }}
            </router-link>
            <router-link :to="(name = 'notifications')">
              <i class="bi bi-bell"></i>
              {{ $t("Notifications") }}
            </router-link>
            <router-link
              :to="{
                    name: 'home1',
                    params: { storeName: this.$store.storeName },
                  }"
              @click.native="logOut()"
            >
              <i class="bi bi-box-arrow-right"></i>
              {{ $t("logOut") }}
            </router-link>
          </div>
        </div>
        <div
          v-if="loading"
          class="d-flex flex-row justify-content-center col-lg-9 col-md-8 mb-4"
          style="margin-top: 100px; margin-bottom: 200px"
        >
          <HalfCircleSpinner
            :animation-duration="1000"
            :dot-size="35"
            :dots-num="3"
            color="var(--primary-color)"
          />
        </div>
        <div v-if="!loading" class="col-lg-9 col-md-8 mb-4">
          <div class="container">
            <div class="card" style="padding:25px 30px 20px 30px;    min-height: 327px;">
              <div class="row">
                <div class="col-md-6 mb-4" v-for="(item, index) in DBitems" :key="index">
                  <div
                    class="card card2 d-flex flex-row justify-content-between"
                    @click="getAddress(item.id)"
                  >
                    <button>
                      <h4>{{ item.title }}</h4>
                      <p>{{ item.description }}</p>
                    </button>
                    <i class="bi bi-pencil-square"></i>
                  </div>
                </div>
                <div class="col-md-6 mb-4">
                  <div class="card" style="flex-direction: row;" @click="
                      addAddress = true;
                      address = null;
                      ">
                    <button>
                      <h4 style="color: var(--primary-color)">
                        <i class="bi bi-plus-lg" style="padding:0 10px"></i>
                        {{ $t("AddNewAddress") }}
                      </h4>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <vodal
              :show="editVodal"
              animation="flip"
              @hide="editVodal = false"
              :width="700"
              :height="450"
              style="margin-top: 20px ;"
            >
              <div v-if="this.address != null" style="overflow:auto; max-height:400px">
                <view-address :address="address"></view-address>
              </div>
            </vodal>
            <vodal
              :show="addAddress"
              animation="flip"
              @hide="addAddress = false"
              :width="700"
              :height="450"
              style="margin-top: 20px ;"
            >
              <div v-if="this.addAddress == true" style="overflow:auto; max-height:400px">
                <add-address></add-address>
              </div>
            </vodal>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ViewAddress from "../components/ViewAddress.vue";
import AddAddress from "../components/AddAddress.vue";
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";
import "vodal/slide-left.css";
export default {
  components: {
    ViewAddress,
    AddAddress,
    HollowDotsSpinner,
    HalfCircleSpinner,
    Vodal
  },
  data: function() {
    return {
      loading: false,
      DBitems: null,
      address: null,
      addAddress: false,
      editVodal: false,
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/addresses",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.DBitems = response.data.data.addresses.addresses;
        // console.log(this.DBitems);
      })
      .then((this.loading = false))
      .catch(error => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
          window.location.href =
            "https://etrolley.net/store/" + this.$store.storeName;
        }
      });
    // console.log("from add : "+this.$localStorage.id())
  },
  methods: {
    getAddress(id) {
      this.loading = true;
      this.addAddress = false;
      this.address = null;
      this.editVodal = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/addresses/" +
            id +
            "/edit",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.address = response.data.data;

          //console.log(this.address.address.title);
        })
        .then((this.loading = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href="/";
          }
        });
    },
    updatAddress() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/addresses",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data.addresses.addresses;
          this.address = null;
          this.addAddress = true;
          //console.log(this.addAddress)
          // console.log(this.DBitems);
        })
        .then((this.loading = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href="/";
          }
        });
    }
  }
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

#Second {
  margin-top: 20px;
  min-height: 500px;
}

.bi-pencil-square {
  padding: 20px;
  color: var(--primary-color);
  display: none;
}

.card {
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  min-height: 100px;
  height: 100%;
}
.card2 {
  cursor: pointer;
}
.card2:hover > i {
  display: inline;
}
a {
  display: block;
  padding: 13px 10px 17px 10px;
  width: 100%;
  color: #000;
  font-family: "Araboto-normal";
  font-size: 14px;
  text-align: start;
}
a i {
  padding: 0 10px;
  color: #acacac;
  font-size: 16px;
}
a:hover {
  color: #000 !important;
}
.router-link-exact-active,
.router-link-exact-active:focus {
  background-color: #f8f8f8;
  color: #000 !important;
  /*border: 2px solid var(--primary-color);*/
}
.card1 {
  background-color: #f8f8f8;
  width: 100%;
  border-radius: 15px;
  color: var(--secondary-color);
  font-family: "Araboto-Bold";
  text-align: center;
  font-size: 16px;
  padding: 15px 3px;
  border: 0;
}

h4 {
  display: block;
  padding: 13px 10px 0 10px;
  width: 100%;
  color: #000;
  font-family: "Araboto-normal";
  font-size: 14px;
  text-align: start;
}
h4:hover {
  color: var(--primary-color);
}

p {
  display: block;
  padding: 0 20px;
  width: 100%;
  color: #000;
  font-family: "Araboto-light";
  font-size: 14px;
  text-align: start;
}
button {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  color: var(--secondary-color);
}
hr {
  height: 2px;
  margin: 12px;
  color: #cccccc;
}
[dir="rtl"] button img {
  left: 15px;
  margin-top: 10px;
  position: absolute;
}
[dir="ltr"] button img {
  right: 15px;
  margin-top: 10px;
  position: absolute;
}

.mapStyle {
  margin-bottom: 10px;
  border-radius: 15px;
  height: 315px;
  width: 80%;
}
.input-icons {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.input-icons input,
textarea.form-control {
  width: 100%;
  border-radius: 9px;
  background-color: #fff;
  margin-bottom: 10px;
  border: 2px solid #cccccc;
  font-size: 13px;
  font-family: "Araboto-Medium";
  color: var(--secondary-color);
  padding: 10px 20px 15px 20px;
}

.button {
  background-color: var(--primary-color);
  border-radius: 10px;
  width: 60%;

  margin: 20px 20%;
  padding: 10px 15px;
  border: 2px solid var(--primary-color);
  color: #fff;
  font-size: 15px;
  font-family: "Araboto-Medium";
}

.button:hover {
  background-color: #fff;
  color: #000;
}

/*@media (min-width: 1400px){
.container {
    max-width: 1400px !important;
}

}*/

@media (max-width: 992px) {
  a {
    width: 100%;
  }
}
@media (max-width: 772px) {
  a {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
}
</style>

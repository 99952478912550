<template>
  <div class="container" id="container_order">

    <h2 style="text-align: center; ">{{ $t('myAddresses') }}</h2>
    <profile_header></profile_header>
    <div class="container" style="margin-top:3%">

      <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>

      <div v-if="this.DBitems.length != 0" class="row">

        <div v-for="(item, index) in DBitems" :key="index" class="col-lg-4 col-md-6 col-12" :id="'address' + item.id">
          <div class="div_address address_list">
            <img src="/theme10/images/pin.png" style="width: 5%; margin-left: 3%;">
            <span style="font-size: 18px;font-weight: 500;line-height: 3; color: #54B536; padding-top: 5%;">
              {{ item.title }}</span>
            <p style="padding-left: 3%;" class="address_description">{{ item.description }}</p>
            <button class="but_edit " style="background-color: #54B536; color: white; border: none; margin-right: 2%;"
              @click="getAddress(item.id)">{{ $t('edit') }}</button>
            
            <button class="but_edit " @click="deleteAddress(item.id)"
              style="color: #54B536; background-color: white; border: 1px solid #54B536;">{{ $t('delete') }}</button>
          </div>
        </div>

      </div>

      <div class="text-center center-text">
        <button class="butt_green but_order center_align but_add_address" @click="open_address">
          {{ $t("AddNewAddress") }}
        </button>
      </div>

    </div>
    <vodal class="add_address_order" :show="this.addAddress && $store.state.addAddress" animation="flip"
      @hide="close_address">
      <div class="card_add_address">
        <add-address></add-address>
      </div>
    </vodal>



    <vodal class="add_address_order " :show="$store.state.addAddress&&this.address != null" animation="flip"
      @hide="close_address">
      <div class="card_add_address">
        <view_address :address="address"
        ></view_address>
      </div>
    </vodal>

  </div>
</template>


<script>
import AddAddress from "../components/AddAddress.vue";
import view_address from "../components/ViewAddress.vue";
import profile_header from "../components/profile_header.vue";
import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

export default {
  components: { Vodal, AddAddress, HollowDotsSpinner, HalfCircleSpinner, view_address, profile_header },

  data: function () {
    return {
      loading: false,
      DBitems: [],
      address: null,
      addAddress: false,
      show2: false,
      langChange: '',
      object_address: null
    };
  },
  created() {
    if (this.$route.params.lang && this.$route.params.lang != this.$i18n.locale) {
      this.$i18n.locale = this.$route.params.lang; this.$store.state.UnitPrice = this.$store.state.unitAR
      if (this.$i18n.locale == 'en') {
        document.documentElement.setAttribute("dir", "ltr");
        let h = document.querySelector('body');
      }
    }
    // this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/addresses",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.DBitems = response.data.data.addresses.addresses;

      })
      .then((this.loading = false))

    if (this.$i18n.locale == 'ar') {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content = this.$store.state.description_ar;
      document.getElementById("keywords_meta").content = this.$store.state.keyword_ar;
    }
    else {
      document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content = this.$store.state.description_en;
      document.getElementById("keywords_meta").content = this.$store.state.keyword_en;
    }
    this.loading = false
  },
  methods: {

    getAddress(id) {
      this.addAddress = false;
      this.address = null;
      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/addresses/" +
          id +
          "/edit",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.address = response.data.data;
          this.show2 = true;
          this.$store.state.addAddress = true;
          console.log("*************************************************************")
        })
        .then((this.loading = false))

      console.log("Address Details" + this.address);


    },
    updatAddress() {
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/addresses",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data.addresses.addresses;
          this.address = null;
          this.addAddress = true;
          //console.log(this.addAddress)
          // console.log(this.DBitems);
        })
        .then((this.loading = false))
        .catch(error => {

        });
    },
    open_address() {
      this.addAddress = true;
      this.address = null;
      this.show2 = false;
      this.$store.state.addAddress = true
    },
    close_address() {
      this.$store.state.addAddress = false
      this.addAddress = false;
      this.address = null;
      this.show2 = false;
    },
    updatedata() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/addresses",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data.addresses.addresses;
          console.log('**** addressess', this.DBitems);
        })
        .then((this.loading = false))

      // console.log("from add : "+this.$localStorage.id())
      if (this.$i18n.locale == 'ar') {
        document.title = this.$store.state.title_ar;
        document.getElementById("description_meta").content = this.$store.state.description_ar;
        document.getElementById("keywords_meta").content = this.$store.state.keyword_ar;
      }
      else {
        document.title = this.$store.state.title_en;
        document.getElementById("description_meta").content = this.$store.state.description_en;
        document.getElementById("keywords_meta").content = this.$store.state.keyword_en;
      }
    },
    deleteAddress(id) {
      this.loading = true;
      this.axios
        .delete(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/addresses/" +
          id,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            console.log(response.data.message);
            this.Message = response.data.message;
            this.loading = false;
            document.getElementById('address' + id).style = "display:none"
            setTimeout(() => {
              this.Message = "";
            }, 3000);
          } else {
            console.log(response.data.message);
            this.Message = response.data.message;
            this.loading = false;
            setTimeout(() => {
              this.Message = "";
            }, 3000);
          }
        });
    },

  },
  watch:
  {
    langChange() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/addresses",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data.addresses.addresses;
          // console.log(this.DBitems);
        })

    }
  },
  updated() {
    this.langChange = this.$i18n.locale
  },


};
</script>
<template>
  <div class="" >
<div  id="animated-text-strip" style="width:100%">
  <span v-for="(item,index) in DBitems" :key="index" class="marquee">
    {{ item.name }}: {{ item.description }}
  </span>

</div>
<div class="track_popup" v-if="show">
      <div  class="track_popup_form">
       

        <div class="wrapper">
          <div class="container">
            <hooper style="height: 100%;padding-top: 0px;padding-bottom: 0px;" :rtl=" $i18n.locale == 'ar' ? true : false" :settings="hooperSettings">
    <slide style="padding:10px" v-for="(item,index) in DBitems" :key="index">

      <div class="row track_row" >
        <div class="col-lg-6 trick_image" >
          <img :src=item.image  class="track_pop_img">
        </div>  

        <div class="col-lg-6">
          <i class="bi bi-x track_close_icon_pop" @click="(show = false)"></i>
          <div class="track_description">
          <h4>{{item.name}}</h4>
          <br>
          <p>{{item.description}}</p>
          </div>
        </div> 

      </div>
      <div class="text-center center-text">
      <button class="track-button" @click="(show = false)">{{ $t('close') }}</button>
      </div>


    </slide>  
  </hooper>
         
           
          </div>
        </div>
      </div>
    
    </div>
</div>
    </template>

    <script>
    import {
  Hooper,
  Slide,
  Progress as HooperProgress,
  Pagination as HooperPagination,
  Navigation as HooperNavigation
} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
 
  name: "news_tickers",
  props: {
    storeName: String
  },
  components: {
    Hooper,
    Slide,
    HooperProgress,
    HooperPagination,
    HooperNavigation,
  },
  data: function() {
    return {
loading:false,
DBitems:null,
show:true,

hooperSettings: {
        itemsToShow: 1,
        initialSlide:0,
        itemsToSlide:1,
        autoPlay:true,
        playSpeed:3000,
        trimWhiteSpace:true,
        wheelControl:false,
        
      }
    };
    
  },

  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +"/last_news",
       
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.DBitems = response.data.data;
      })
      .then(() => (this.loading = false));
  },
  methods: {
   

    
  },
  watch: {
    langChange() {
      this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +"/last_news",
       
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.DBitems = response.data.data;
      })
      .then(() => (this.loading = false));
    }
  }
};
</script>

<style >

  </style>